import CTAButton from "components/utils/Button/CTAButton";
import { RecordSetting } from "components/utils/Table/_Table.settings";
import { GamingGearRecord } from "data/GamingGearRecord";
import { Routing } from "helpers/routing";
import React, { useContext } from "react";
import { EllipsisHIcon, LockIcon } from "styles/fontAwesome";
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'

const Settings: RecordSetting<GamingGearRecord> = [

  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'name',
    Header: 'Name',
  },
  {
    accessor: 'type',
    Header: 'Type'
  },
  {
    accessor: 'image',
    Header: 'Image'
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton path={Routing.getGamingGearDetailUrl({ gamingGear: row.values.id })}>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
