import { RootRepository } from 'data/RootRepository'
// Records
import { BaseRecord } from 'data/BaseRecord'
import { SportRecord } from 'data/SportRecord'
import { TournamentRecord } from 'data/TournamentRecord'
import { TeamRecord } from 'data/TeamRecord'
import { MatchRecord } from 'data/MatchRecord'
import { MatchMapRecord } from 'data/MatchMapRecord'
import { MatchMapVetoRecord } from 'data/MatchMapVetoRecord'
import { GameMapRecord } from 'data/GameMapRecord'
import { PlayerRecord } from 'data/PlayerRecord'
import { TournamentStageRecord } from 'data/TournamentStageRecord'
import { TournamentRoundRecord } from 'data/TournamentRoundRecord'
import { SportsmanRecord } from 'data/SportsmanRecord'
import { TournamentTeamRankRecord } from 'data/TournamentTeamRankRecord'
import { TournamentSportsmanRankRecord } from 'data/TournamentSportsmanRankRecord'
import { TimespacesRecord } from 'data/TimespacesRecord'
import { SportsmanRoleRecord } from 'data/SportsmanRoleRecord'
import { SportsmanStatSummaryRecord } from 'data/SportsmanStatSummaryRecord'
import { TeamStatSummaryRecord } from 'data/TeamStatSummaryRecord'
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { SportsmanStatScalesRecord } from 'data/SportsmanStatScalesRecord'
import { TeamStatScalesRecord } from 'data/TeamStatScalesRecord'
// Additional
import { Compare } from 'utils/compare'
import { AlertManager } from 'react-alert'
import { SportsmanBiographyRecord } from 'data/SportsmanBiographyRecord'
import { TeamBiographyRecord } from 'data/TeamBiographyRecord'
import { GamingGearRecord } from 'data/GamingGearRecord'
import { SportsmanGamingGearsRecord } from 'data/SportsmanGamingGearsRecord'
import { NearestMatchRecord } from 'data/NearesMatchRecord'
import { AzureDiretcoryRecord } from 'data/AzureDirectoryRecord'
import { AzureBlobRecord } from 'data/AzureBlobRecord'
import { DatasourceRecord } from 'data/DatasourceRecord'
import { TeamOrganizationRecord } from 'data/TeamOrganizationRecord'
import { SportsmanDatasourceIdentifierRecord } from 'data/SportsmanDatasourceIdentifierRecord'
import { TeamDatasourceIdentifierRecord } from 'data/TeamDatasourceIdentifierRecord'
import { TeamOrganizationDatasourceIdentifierRecord } from 'data/TeamOrganizationDatasourceIdentifierRecord'
import { TournamentDatasourceIdentifierRecord } from 'data/TournamentDatasourceIdentifierRecord'
import { TournamentStageDatasourceIdentifierRecord } from 'data/TournamentStageDatasourceIdentifierRecord'
import { MatchDatasourceIdentifierRecord } from 'data/MatchDatasourceIdentifierRecord'
import { promises } from 'dns'

export module Data {
  const parseRecords = <T extends BaseRecord | any>(
    records: Array<T | null | undefined> | null
  ) => records?.filter((x) => x)?.map((x) => x!) ?? []

  /* eslint-disable @typescript-eslint/ban-types */
  const tryGetData = async <T extends BaseRecord | any>(
    asyncFn: Function,
    alert?: AlertManager
  ) => {
    try {
      return (await asyncFn()) as Array<T> | T
    } catch (err) {
      alert?.show('An error occurred while retrieving data from the server!', {
        // @ts-ignore
        type: 'danger',
      })

      throw err
    }
  }

  //#region Datasources
  export const getDatasourceById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager}
  ) => {
    return tryGetData<DatasourceRecord>(async () => {
      const datasource = await db?.datasources.get(id, { reload: true})

      await datasource?.preload()

      return datasource
    }, useErrorHandle?.alert) as Promise<DatasourceRecord | undefined>
  }

  export const getAllDatasources = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager}
  ) => {
    return tryGetData<DatasourceRecord>(async () => {
      const datasources = parseRecords(await db?.datasources.getAll({ reload: true }))

      await Promise.all(datasources.map((x) => x.preload))

      return datasources
    }, useErrorHandle?.alert) as Promise<DatasourceRecord[] | undefined>
  }
  //#endregion
  //#region Sports
  export const getSportById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportRecord>(async () => {
      const sport = await db?.sports.get(id, { reload: true })

      await sport?.preload()

      return sport
    }, useErrorHandle?.alert) as Promise<SportRecord | undefined>
  }

  export const getAllSports = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportRecord>(async () => {
      const sports = parseRecords(await db?.sports.getAll({ reload: true }))

      await Promise.all(sports.map((x) => x.preload()))

      return sports
    }, useErrorHandle?.alert) as Promise<SportRecord[] | undefined>
  }
  //#endregion
  //#region Tournaments
  export const getTournamentById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentRecord>(async () => {
      const tournament = await db?.tournaments.get(id, { reload: true })

      await tournament?.preload()

      return tournament
    }, useErrorHandle?.alert) as Promise<TournamentRecord | undefined>
  }

  export const getAllTournaments = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TournamentRecord>(async () => {
      const tournaments = parseRecords(
        await db?.tournaments.getAll({ reload: true })
      )

      if (!options?.ignore?.preload) {
        await Promise.all(tournaments.map((x) => x.preload()))
      }

      return tournaments.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentRecord[] | undefined>
  }

  export const getAllTournamentsBySportId = async (
    db: RootRepository,
    sId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentRecord>(async () => {
      return (await getAllTournaments(db))?.filter((x) => x.sport?.id === sId)
    }, useErrorHandle?.alert) as Promise<TournamentRecord[] | undefined>
  }
  //#endregion
  //#region Tournament Datasource Identifiers
  export const getTournamentDatasourceIdentifierById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentDatasourceIdentifierRecord>(async () => {
      const tournamentDatasourceIdentifier = await db?.tournamentDatasourceIdentifiers.get(id, { reload: true })

      await tournamentDatasourceIdentifier?.preload()

      return tournamentDatasourceIdentifier
    }, useErrorHandle?.alert) as Promise<TournamentDatasourceIdentifierRecord | undefined>
  }

  export const getTournamentDatasourceIdentifiersByTournamentId = async (
    db:RootRepository,
    tournamentId:string,
    useErrorHandle?: { alert?: AlertManager}
  ) => {
    return tryGetData<TournamentDatasourceIdentifierRecord>(async () => {
      const tournamentDatasourceIdentifiers = parseRecords(
        await db?.tournamentDatasourceIdentifiers.getByTournamentId(tournamentId, {
          reload: true
        })
      )

      await Promise.all(tournamentDatasourceIdentifiers.map((x) => x.preload()))

      return tournamentDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<
    TournamentDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllTournamentDatasourceIdentifiers = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TournamentDatasourceIdentifierRecord>(async () => {
      const tournamentDatasourceIdentifiers = parseRecords(
        await db?.tournamentDatasourceIdentifiers.getAll({ reload: true })
      )

      if (!options?.ignore?.preload) {
        await Promise.all(tournamentDatasourceIdentifiers.map((x) => x.preload()))
      }

      return tournamentDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TournamentDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
  //#region Tournament Stage Datasource Identifiers
  export const getTournamentStageDatasourceIdentifierById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentStageDatasourceIdentifierRecord>(async () => {
      const tournamentStageDatasourceIdentifier = await db?.tournamentStageDatasourceIdentifiers.get(id, { reload: true })

      await tournamentStageDatasourceIdentifier?.preload()

      return tournamentStageDatasourceIdentifier
    }, useErrorHandle?.alert) as Promise<TournamentStageDatasourceIdentifierRecord | undefined>
  }

  export const getTournamentsStageDatasourceIdentifiersByTournamentStageId = async (
    db:RootRepository,
    tournamentStageId:string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentStageDatasourceIdentifierRecord>(async () => {
      const stageDatasourceIdentifiers = parseRecords(
        await db?.tournamentStageDatasourceIdentifiers.getByTournamentStageId(tournamentStageId, {
          reload: true
        })
      )

      await Promise.all(stageDatasourceIdentifiers.map((x) => x.preload()))

      return stageDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<
    TournamentStageDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllTournamentStageDatasourceIdentifiers = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TournamentStageDatasourceIdentifierRecord>(async () => {
      const tournamentStageDatasourceIdentifiers = parseRecords(
        await db?.tournamentStageDatasourceIdentifiers.getAll({ reload: true })
      )

      if (!options?.ignore?.preload) {
        await Promise.all(tournamentStageDatasourceIdentifiers.map((x) => x.preload()))
      }

      return tournamentStageDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TournamentStageDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
  //#region Matches
  export const getMatchById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchRecord>(async () => {
      const match = await db?.matches.get(id, { reload: true })

      await match?.preload()

      return match
    }, useErrorHandle?.alert) as Promise<MatchRecord | undefined>
  }

  export const getAllMatches = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<MatchRecord>(async () => {
      const matches = Array<MatchRecord>()

      const _tournaments = await Data.getAllTournaments(db, undefined, options)

      if (!_tournaments) return

      const _matches = await Promise.all(
        _tournaments.map(async (s) =>
          parseRecords(
            await db?.matches.getByTournamentId(s.id!, { reload: true })
          )
        )
      )

      _matches.forEach((r) => matches.push(...r))

      if (!options?.ignore?.preload) {
        await Promise.all(matches.map((x) => x.preload()))
      }

      return matches.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<MatchRecord[] | undefined>
  }

  export const getMatchesByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchRecord>(async () => {
      const matches = parseRecords(
        await db?.matches.getByTournamentId(tId, { reload: true })
      )

      await Promise.all(matches.map((x) => x.preload()))

      return matches.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<MatchRecord[] | undefined>
  }
  //#endregion
  //#region Match maps
  export const getMatchMapById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapRecord>(async () => {
      const matchMap = await db?.matchMaps.get(id, { reload: true })

      await matchMap?.preload()

      return matchMap
    }, useErrorHandle?.alert) as Promise<MatchMapRecord | undefined>
  }

  export const getAllMatchMaps = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapRecord>(async () => {
      const matchMaps = parseRecords(
        await db?.matchMaps.getAll({ reload: true })
      )

      await Promise.all(matchMaps.map((x) => x.preload()))

      return matchMaps.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<MatchMapRecord[] | undefined>
  }

  export const getMatchMapsByMatchId = async (
    db: RootRepository,
    mId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapRecord>(async () => {
      const matchMaps = parseRecords(
        await db?.matchMaps.getByMatchId(mId, { reload: true })
      )

      await Promise.all(matchMaps.map((x) => x.preload()))

      return matchMaps.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<MatchMapRecord[] | undefined>
  }
  //#endregion
  //#region Match maps vetos
  export const getMatchMapVetosById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapVetoRecord>(async () => {
      const matchMapVetos = await db?.matchMapVetos.get(id, { reload: true })

      await matchMapVetos?.preload()

      return matchMapVetos
    }, useErrorHandle?.alert) as Promise<MatchMapVetoRecord | undefined>
  }

  export const getAllMatchMapVetos = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapVetoRecord>(async () => {
      const matchMapVetos = parseRecords(
        await db?.matchMapVetos.getAll({ reload: true })
      )

      await Promise.all(matchMapVetos.map((x) => x.preload()))

      return matchMapVetos
    }, useErrorHandle?.alert) as Promise<MatchMapVetoRecord[] | undefined>
  }

  export const getMatchMapVetosByMatchId = async (
    db: RootRepository,
    mId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchMapVetoRecord>(async () => {
      const matchMapVetos = parseRecords(
        await db?.matchMapVetos.getByMatchId(mId, { reload: true })
      )

      await Promise.all(matchMapVetos.map((x) => x.preload()))

      return matchMapVetos
    }, useErrorHandle?.alert) as Promise<MatchMapVetoRecord[] | undefined>
  }
  //#endregion
  //#region Game maps
  export const getAllGameMaps = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<GameMapRecord>(async () => {
      const gameMaps = parseRecords(await db?.gameMaps.getAll({ reload: true }))

      await Promise.all(gameMaps.map((x) => x.preload()))

      return gameMaps
    }, useErrorHandle?.alert) as Promise<GameMapRecord[] | undefined>
  }
  //#endregion
  //#region Stages
  export const getStageById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentStageRecord>(async () => {
      const stage = await db?.tournamentStages.get(id, { reload: true })

      await stage?.preload()

      return stage
    }, useErrorHandle?.alert) as Promise<TournamentStageRecord | undefined>
  }

  export const getAllStages = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TournamentStageRecord>(async () => {
      const stages = Array<TournamentStageRecord>()

      const _tournaments = await Data.getAllTournaments(db, undefined, options)

      if (!_tournaments) return

      const _stages = await Promise.all(
        _tournaments.map(async (s) =>
          parseRecords(
            await db?.tournamentStages.getByTournamentId(s.id!, {
              reload: true,
            })
          )
        )
      )

      _stages.forEach((r) => stages.push(...r))

      if (!options?.ignore?.preload) {
        await Promise.all(stages.map((x) => x.preload()))
      }

      return stages.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentStageRecord[] | undefined>
  }

  export const getStagesByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentStageRecord>(async () => {
      const stages = parseRecords(
        await db?.tournamentStages.getByTournamentId(tId, { reload: true })
      )

      await Promise.all(stages.map((x) => x.preload()))

      return stages.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentStageRecord[] | undefined>
  }
  //#endregion
  //#region Rounds
  export const getRoundById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentRoundRecord>(async () => {
      const round = await db?.tournamentRounds.get(id, { reload: true })

      await round?.preload()

      return round
    }, useErrorHandle?.alert) as Promise<TournamentRoundRecord | undefined>
  }

  export const getAllRounds = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TournamentRoundRecord>(async () => {
      const rounds = Array<TournamentRoundRecord>()

      const _stages = await Data.getAllStages(db, undefined, options)

      if (!_stages) return

      const _rounds = await Promise.all(
        _stages.map(async (s) =>
          parseRecords(
            await db?.tournamentRounds.getByTournamentStageId(s.id!, {
              reload: true,
            })
          )
        )
      )

      _rounds.forEach((r) => rounds.push(...r))

      if (!options?.ignore?.preload) {
        await Promise.all(rounds.map((x) => x.preload()))
      }

      return rounds.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentRoundRecord[] | undefined>
  }

  export const getRoundsByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentRoundRecord>(async () => {
      const rounds = Array<TournamentRoundRecord>()

      const _stages = await Data.getStagesByTournamentId(db, tId)

      if (!_stages) return

      const _rounds = await Promise.all(
        _stages.map(async (s) =>
          parseRecords(
            await db?.tournamentRounds.getByTournamentStageId(s.id!, {
              reload: true,
            })
          )
        )
      )

      _rounds.forEach((r) => rounds.push(...r))

      await Promise.all(rounds.map((x) => x.preload()))

      return rounds.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentRoundRecord[] | undefined>
  }

  export const getRoundIdents = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData(async () => {
      const idents = await db?.tournamentRounds.getIdents()

      // @ts-ignore
      return idents.ident
    }, useErrorHandle?.alert) as Promise<string[] | undefined>
  }
  //#endregion
  //#region Teams
  export const getTeamById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamRecord>(async () => {
      const team = await db?.teams.get(id, { reload: true })

      await team?.preload()

      return team
    }, useErrorHandle?.alert) as Promise<TeamRecord | undefined>
  }

  export const getAllTeams = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamRecord>(async () => {
      const teams = Array<TeamRecord>()

      const _leagues = await Data.getAllSports(db)

      if (!_leagues) return

      const _teams = await Promise.all(
        _leagues.map(async (s) =>
          parseRecords(await db?.teams.getBySportId(s.id!, { reload: true }))
        )
      )

      _teams.forEach((r) => teams.push(...r))

      if (!options?.ignore?.preload) {
        await Promise.all(teams.map((x) => x.preload()))
      }

      return teams.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamRecord[] | undefined>
  }

  export const getTeamsByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamRecord>(async () => {
      const tournament = await getTournamentById(db, tId)

      if (!tournament?.sport?.id) return []

      const teams = parseRecords(
        await db?.teams.getBySportId(tournament?.sport?.id, { reload: true })
      )

      await Promise.all(teams.map((x) => x.preload()))

      return teams.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamRecord[] | undefined>
  }

  export const getTeamsBySportId = async (
    db: RootRepository,
    lId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamRecord>(async () => {
      const teams = parseRecords(
        await db?.teams.getBySportId(lId!, { reload: true })
      )

      await Promise.all(teams.map((x) => x.preload()))

      return teams.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamRecord[] | undefined>
  }

  export const getTeamByTeamOrgId = async (
    db: RootRepository,
    teamOrgId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamRecord>(async () => {
      const teams = parseRecords(
        await db?.teams.getByTeamOrganizationId(teamOrgId!, { reload: true })
      )

      await Promise.all(teams.map((x) => x.preload()))

      return teams.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamRecord[] | undefined>
  }
  //#endregion
  //#region Team Biographies
  export const getTeamBiographybyId = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamBiographyRecord>(async () => {
      const teamBiography = await db?.teamBiographies.get(
        id,
        { reload: true }
      )

      await teamBiography?.preload()

      return teamBiography
    }, useErrorHandle?.alert) as Promise<TeamBiographyRecord | undefined>
  }

  export const getAllTeamBiographies = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamBiographyRecord>(async () => {
      const teamBiographies = parseRecords(await db?.teamBiographies.loadAll())

      await Promise.all(teamBiographies.map((x) => x.preload()))

      return teamBiographies
    }, useErrorHandle?.alert) as Promise<TeamBiographyRecord[] | undefined>
  }

  export const getTeamBiographyByTeamId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamBiographyRecord>(async () =>  {
      const teamBiography = await db?.teamBiographies.getByTeamId(
        tId,
        { reload: true, }
      )

      if (!teamBiography) return

      return teamBiography[0]
    }, useErrorHandle?.alert) as Promise<TeamBiographyRecord | undefined>
  }

  //#endregion
  //#region Team ranks
  export const getTeamRankById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentTeamRankRecord>(async () => {
      const teamRank = await db?.tournamentTeamRanks.get(id, { reload: true })

      await teamRank?.preload()

      return teamRank
    }, useErrorHandle?.alert) as Promise<TournamentTeamRankRecord | undefined>
  }

  export const getAllTeamRanks = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentTeamRankRecord>(async () => {
      const teamRanks = Array<TournamentTeamRankRecord>()

      const _tournaments = await Data.getAllTournaments(db)

      if (!_tournaments) return

      const _teamRanks = await Promise.all(
        _tournaments.map(async (s) =>
          parseRecords(
            await db?.tournamentTeamRanks.getByTournamentId(s.id!, {
              reload: true,
            })
          )
        )
      )

      _teamRanks.forEach((r) => teamRanks.push(...r))

      await Promise.all(teamRanks.map((x) => x.preload()))

      return teamRanks.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentTeamRankRecord[] | undefined>
  }

  export const getTeamRanksByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentTeamRankRecord>(async () => {
      const teamRanks = parseRecords(
        await db?.tournamentTeamRanks.getByTournamentId(tId, { reload: true })
      )

      await Promise.all(teamRanks.map((x) => x.preload()))

      return teamRanks.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TournamentTeamRankRecord[] | undefined>
  }
  //#endregion
  //#region Sportsmen
  export const getSportsmanById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanRecord>(async () => {
      const sportsman = await db?.sportsmen.get(id, { reload: true })

      await sportsman?.preload()

      return sportsman
    }, useErrorHandle?.alert) as Promise<SportsmanRecord | undefined>
  }

  export const getAllSportsmen = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanRecord>(async () => {
      const sportsmen = Array<SportsmanRecord>()

      const _leagues = await Data.getAllSports(db)

      if (!_leagues) return

      const _sportsmen = await Promise.all(
        _leagues.map(async (s) =>
          parseRecords(
            await db?.sportsmen.getBySportId(s.id!, { reload: true })
          )
        )
      )

      _sportsmen.forEach((s) => sportsmen.push(...s))

      await Promise.all(sportsmen.map((x) => x.preload()))

      return sportsmen.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<SportsmanRecord[] | undefined>
  }

  export const getSportsmenBySportId = async (
    db: RootRepository,
    lId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanRecord>(async () => {
      const sportsmen = parseRecords(
        await db?.sportsmen.getBySportId(lId!, { reload: true })
      )

      await Promise.all(sportsmen.map((x) => x.preload()))

      return sportsmen.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<SportsmanRecord[] | undefined>
  }

  export const getSportsmenByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanRecord>(async () => {
      const tournament = await getTournamentById(db, tId)

      if (!tournament?.sport?.id) return []

      const sportsmen = parseRecords(
        await db?.sportsmen.getBySportId(tournament?.sport?.id, {
          reload: true,
        })
      )

      await Promise.all(sportsmen.map((x) => x.preload()))

      return sportsmen.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<SportsmanRecord[] | undefined>
  }
  //#endregion
  //#region SportsmanBiographies
  export const getSportsmanBiographyById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanBiographyRecord>(async () => {
      const sportsmanBiography = await db?.sportsmanBiographies.get(
        id,
        { reload: true}
      )

      await sportsmanBiography?.preload()

      return sportsmanBiography
    }, useErrorHandle?.alert) as Promise<SportsmanBiographyRecord | undefined>
  }

  export const getAllSportsmanBiographies = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanBiographyRecord>(async () => {
      const sportsmanBiographies = parseRecords(await db?.sportsmanBiographies.loadAll())

      await Promise.all(sportsmanBiographies.map((x) => x.preload()))

      return sportsmanBiographies
    }, useErrorHandle?.alert) as Promise<SportsmanBiographyRecord[] | undefined>
  }

  export const getSportsmanBiographyBySportsmanId = async (
    db: RootRepository,
    sId: string,
    useErrorHandle?: { alert?: AlertManager}
  ) => {
    return tryGetData<SportsmanBiographyRecord>(async () => {
      const sportsmanBiography = await db?.sportsmanBiographies.getBySportsmanId(
          sId,
          { reload: true, }
      )

      if (!sportsmanBiography) return

      return sportsmanBiography[0]
    }, useErrorHandle?.alert) as Promise<SportsmanBiographyRecord | undefined>
  }
  //#endregion
  //#region Sportsman Gaming Gears
  export const getSportsmanGamingGearsById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager}
  ) => {
    return tryGetData<SportsmanGamingGearsRecord>(async () => {
      const sportsmanGamingGears = await db?.sportsmanGamingGears.get(
        id,
        { reload: true}
      )

      await sportsmanGamingGears?.preload()

      return sportsmanGamingGears
    }, useErrorHandle?.alert) as Promise<SportsmanGamingGearsRecord | undefined>
  }

  export const getAllSportsmanGamingGears = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanGamingGearsRecord>(async () => {
      const sportsmanGamingGears = parseRecords(await db?.sportsmanGamingGears.loadAll())

      await Promise.all(sportsmanGamingGears.map((x) => x.preload()))

      return sportsmanGamingGears
    }, useErrorHandle?.alert) as Promise<SportsmanGamingGearsRecord[] | undefined>
  }

  export const getSportsmanGamingGearsBySportsmanId = async (
    db: RootRepository,
    sId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanGamingGearsRecord>(async () => {
      const sportmsanGamingGears = await db?.sportsmanGamingGears.getBySportsmanId(
        sId,
        { reload: true ,}
      )

      if (!sportmsanGamingGears) return

      return sportmsanGamingGears[0]
    }, useErrorHandle?.alert) as Promise<SportsmanGamingGearsRecord | undefined>
  }

  //#endregion
  //#region Sportsman Role
  export const getAllSportsmanRoles = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanRoleRecord>(async () => {
      const sportsmanRoles = parseRecords(
        await db?.sportsmanRoles.getAll({ reload: true })
      )

      await Promise.all(sportsmanRoles.map((x) => x.preload()))

      return sportsmanRoles
    }, useErrorHandle?.alert) as Promise<SportsmanRoleRecord[] | undefined>
  }
  //#endregion
  //#region Sportsman ranks
  export const getSportsmanRankById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentSportsmanRankRecord>(async () => {
      const sportsmanRank = await db?.tournamentSportsmanRanks.get(id, {
        reload: true,
      })

      await sportsmanRank?.preload()

      return sportsmanRank
    }, useErrorHandle?.alert) as Promise<
      TournamentSportsmanRankRecord | undefined
    >
  }

  export const getAllSportsmanRanks = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentSportsmanRankRecord>(async () => {
      const sportsmanRanks = Array<TournamentSportsmanRankRecord>()

      const _tournaments = await Data.getAllTournaments(db)

      if (!_tournaments) return

      const _sportsmanRanks = await Promise.all(
        _tournaments.map(async (s) =>
          parseRecords(
            await db?.tournamentSportsmanRanks.getByTournamentId(s.id!, {
              reload: true,
            })
          )
        )
      )

      _sportsmanRanks.forEach((r) => sportsmanRanks.push(...r))

      await Promise.all(sportsmanRanks.map((x) => x.preload()))

      return sportsmanRanks.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<
      TournamentSportsmanRankRecord[] | undefined
    >
  }

  export const getSportsmanRanksByTournamentId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TournamentSportsmanRankRecord>(async () => {
      const sportsmanRanks = parseRecords(
        await db?.tournamentSportsmanRanks.getByTournamentId(tId, {
          reload: true,
        })
      )

      await Promise.all(sportsmanRanks.map((x) => x.preload()))

      return sportsmanRanks.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<
      TournamentSportsmanRankRecord[] | undefined
    >
  }
  //#endregion
  //#region Timespaces
  export const getTimespaceById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TimespacesRecord>(async () => {
      const timespace = await db?.timespaces.get(id, { reload: true })

      await timespace?.preload()

      return timespace
    }, useErrorHandle?.alert) as Promise<TimespacesRecord | undefined>
  }

  export const getAllTimespaces = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TimespacesRecord>(async () => {
      const timespaces = parseRecords(
        await db?.timespaces.getAll({ reload: true })
      )

      await Promise.all(timespaces.map((x) => x.preload()))

      return timespaces
    }, useErrorHandle?.alert) as Promise<TimespacesRecord[] | undefined>
  }
  //#endregion
  //#region Sportsman stat summaries
  export const getSportsmanStatSummariesByTimespaceId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanStatSummaryRecord>(async () => {
      const sportsmanStatSummaries = parseRecords(
        await db?.sportsmanStatSummaries.getAll({ reload: true })
      ).filter((x) => x.timespace_id === tId)

      await Promise.all(sportsmanStatSummaries.map((x) => x.preload()))

      return sportsmanStatSummaries.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<
      SportsmanStatSummaryRecord[] | undefined
    >
  }

  export const getAllSportsmanStatSummaries = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanStatSummaryRecord>(async () => {
      const sportsmanStatSummaries = parseRecords(
        await db?.sportsmanStatSummaries.getAll({ reload: true })
      )

      await Promise.all(sportsmanStatSummaries.map((x) => x.preload()))

      return sportsmanStatSummaries.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<
      SportsmanStatSummaryRecord[] | undefined
    >
  }
  //#endregion
  //#region Team stat summaries
  export const getTeamStatSummariesByTimespaceId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamStatSummaryRecord>(async () => {
      const teamStatSummaries = parseRecords(
        await db?.teamStatSummaries.getAll({ reload: true })
      ).filter((x) => x.timespace_id === tId)

      await Promise.all(teamStatSummaries.map((x) => x.preload()))

      return teamStatSummaries.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamStatSummaryRecord[] | undefined>
  }

  export const getAllTeamStatSummaries = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamStatSummaryRecord>(async () => {
      const teamStatSummaries = parseRecords(
        await db?.teamStatSummaries.getAll({ reload: true })
      )

      await Promise.all(teamStatSummaries.map((x) => x.preload()))

      return teamStatSummaries.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<TeamStatSummaryRecord[] | undefined>
  }
  //#endregion
  //#region Sportsman Team Memberships
  export const getSportsmanTeamMembershipById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanTeamMembershipRecord>(async () => {
      const sportsmanTeamMemebership = await db?.sportsmanTeamMemberships.get(
        id,
        { reload: true }
      )

      await sportsmanTeamMemebership?.preload()

      return sportsmanTeamMemebership
    }, useErrorHandle?.alert) as Promise<
      SportsmanTeamMembershipRecord | undefined
    >
  }

  export const getSportsmanTeamMembershipsBySportsmanId = async (
    db: RootRepository,
    sId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanTeamMembershipRecord>(async () => {
      const sportsmanTeamMemberships = parseRecords(
        await db?.sportsmanTeamMemberships.getBySportsmanId(sId, {
          reload: true,
        })
      )

      await Promise.all(sportsmanTeamMemberships.map((x) => x.preload()))

      return sportsmanTeamMemberships.sort((a, b) =>
        Compare.getAscendingSortCompare(
          a.sportsmanSortValues,
          b.sportsmanSortValues
        )
      )
    }, useErrorHandle?.alert) as Promise<
      SportsmanTeamMembershipRecord[] | undefined
    >
  }

  export const getSportsmanTeamMembershipsByTeamId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanTeamMembershipRecord>(async () => {
      const sportsmanTeamMemberships = parseRecords(
        await db?.sportsmanTeamMemberships.getByTeamId(tId, {
          reload: true,
        })
      )

      await Promise.all(sportsmanTeamMemberships.map((x) => x.preload()))

      return sportsmanTeamMemberships.sort((a, b) =>
        Compare.getAscendingSortCompare(a.teamSortValues, b.teamSortValues)
      )
    }, useErrorHandle?.alert) as Promise<
      SportsmanTeamMembershipRecord[] | undefined
    >
  }
  //#endregion
  //#region Player
  export const getPlayerById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<PlayerRecord>(async () => {
      const player = await db?.players.get(id, { reload: true })

      await player?.preload()

      return player
    }, useErrorHandle?.alert) as Promise<PlayerRecord | undefined>
  }

  export const getPlayersByMatchId = async (
    db: RootRepository,
    mId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<PlayerRecord>(async () => {
      const players = parseRecords(
        await db?.players.getByMatchId(mId, { reload: true })
      )

      await Promise.all(players.map((x) => x.preload()))

      return players.sort((a, b) =>
        Compare.getAscendingSortCompare(a.sortValues, b.sortValues)
      )
    }, useErrorHandle?.alert) as Promise<PlayerRecord[] | undefined>
  }
  //#endregion
  //#region Sportsman Stat Scales
  export const getSportsmanStatScalesById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanStatScalesRecord>(async () => {
      const statScales = await db?.sportsmanStatScales.get(id, { reload: true })

      await statScales?.preload()

      return statScales
    }, useErrorHandle?.alert) as Promise<SportsmanStatScalesRecord | undefined>
  }

  export const getAllSportsmanStatScales = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<SportsmanStatScalesRecord>(async () => {
      const statStales = parseRecords(await db?.sportsmanStatScales.getAll({ reload: true }))

      if (!options?.ignore?.preload) {
        await Promise.all(statStales.map((x) => x.preload()))
      }

      return statStales
    }, useErrorHandle?.alert) as Promise<SportsmanStatScalesRecord[] | undefined>
  }
  //#endregion
  //#region Team Stat Scales
  export const getTeamStatScalesById = async (
    db: RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamStatScalesRecord>(async () => {
      const statScales = await db?.teamStatScales.get(id, { reload: true })

      if (!options?.ignore?.preload) {
        await statScales?.preload()
      }

      return statScales
    }, useErrorHandle?.alert) as Promise<TeamStatScalesRecord | undefined>
  }

  export const getAllTeamStatScales = async (
    db: RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamStatScalesRecord>(async () => {
      const statStales = parseRecords(await db?.teamStatScales.getAll({ reload: true }))

      if (!options?.ignore?.preload) {
        await Promise.all(statStales.map((x) => x.preload()))
      }

      return statStales
    }, useErrorHandle?.alert) as Promise<TeamStatScalesRecord[] | undefined>
  }
  //#endregion
  //#region Gaming Gears
  export const getGamingGearById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<GamingGearRecord>(async () => {
      const gamingGear = await db?.gamingGears.get(id, { reload: true })

      if (!options?.ignore?.preload) {
        await gamingGear?.preload()
      }

      return gamingGear
    }, useErrorHandle?.alert) as Promise<GamingGearRecord | undefined>
  }

  export const getAllGamingGears = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<GamingGearRecord>(async () => {
      const gamingGears = parseRecords(await db?.gamingGears.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(gamingGears.map((x) => x.preload()))
      }

      return gamingGears
    }, useErrorHandle?.alert) as Promise<GamingGearRecord[] | undefined>
  }
  //#endregion
  //#region Nearest matches
  export const getNearesMatchById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<NearestMatchRecord>(async () => {
      const nearestMatch = await db?.nearestMatches.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await nearestMatch?.preload()
      }

      return nearestMatch
    }, useErrorHandle?.alert) as Promise<NearestMatchRecord | undefined>
  }

  export const getAllNearestMatches = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<NearestMatchRecord>(async () => {
      const nearestMatches = parseRecords(await db?.nearestMatches.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(nearestMatches.map((x) => x.preload()))
      }

      return nearestMatches
    }, useErrorHandle?.alert) as Promise<NearestMatchRecord[] | undefined>
  }

  //#endregion
  //#region AzureDirectory
  export const getAzureDirectories = async (
    db: RootRepository,
    name: string,
    prefix: string,
    withSubDirs?: boolean,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<AzureDiretcoryRecord>(async () => {
      const directories = await db?.azureDirectory.getAllByNameAndPrefix(name, prefix, withSubDirs ?? false)

      return directories
    }, useErrorHandle?.alert) as Promise<AzureDiretcoryRecord[] | undefined>
  }
  //#endregion
  //#region AzureBlobs
  export const getAzureBlobs = async (
    db: RootRepository,
    name: string,
    prefix: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<AzureBlobRecord>(async () => {
      const blobs = await db?.azureBlobs.getAllByNameAndPrefix(name, prefix)

      return blobs
    }, useErrorHandle?.alert) as Promise<AzureBlobRecord[] | undefined>
  }
  //#endregion
  //#region Team Organizations
  export const getTeamOrganizationById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamOrganizationRecord>(async () => {
      const teamOrganization = await db?.teamOrganizations.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await teamOrganization?.preload()
      }

      return teamOrganization
    }, useErrorHandle?.alert) as Promise<TeamOrganizationRecord | undefined>
  }

  export const getAllTeamOrganizations = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamOrganizationRecord>(async () => {
      const teamOrganizations = parseRecords(await db?.teamOrganizations.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(teamOrganizations.map((x) => x.preload()))
      }

      return teamOrganizations
    }, useErrorHandle?.alert) as Promise<TeamOrganizationRecord[] | undefined>
  }
  //#endregion
  //#region Sporstman Datasource Identifiers
  export const getSportsmanDatasourceIdentifierById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<SportsmanDatasourceIdentifierRecord>(async () => {
      const sportsmanDatasourceIdentifier = await db?.sportsmanDatasourceIdentifiers.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await sportsmanDatasourceIdentifier?.preload()
      }

      return sportsmanDatasourceIdentifier
    }, useErrorHandle?.alert) as Promise<SportsmanDatasourceIdentifierRecord | undefined>
  }

  export const getSportsmanDatasourceIdentifiersBySportsmanId = async (
    db:RootRepository,
    sId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<SportsmanDatasourceIdentifierRecord>(async () => {
      const sportsmanDatasourceIdentifiers = parseRecords(
        await db?.sportsmanDatasourceIdentifiers.getBySportsmanId(sId, {
          reload: true
        })
      )

      await Promise.all(sportsmanDatasourceIdentifiers.map((x) => x.preload()))

      return sportsmanDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<
    SportsmanDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllsportsmanDatasourceIdentifiers = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<SportsmanDatasourceIdentifierRecord>(async () => {
      const sportsmanDatasourceIdentifiers = parseRecords(await db?.sportsmanDatasourceIdentifiers.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(sportsmanDatasourceIdentifiers.map((x) => x.preload()))
      }

      return sportsmanDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<SportsmanDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
  //#region Team Datasource Identifiers
  export const getTeamDatasourceIdentifierById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamDatasourceIdentifierRecord>(async () => {
      const teamDatasourceIdentifiers = await db?.teamDatasourceIdentifiers.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await teamDatasourceIdentifiers?.preload()
      }

      return teamDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TeamDatasourceIdentifierRecord | undefined>
  }

  export const getTeamDatasourceIdentifiersByTeamId = async (
    db: RootRepository,
    tId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamDatasourceIdentifierRecord>(async () => {
      const teamDatasourceidentifiers = parseRecords(
        await db?.teamDatasourceIdentifiers.getByTeamId(tId, {
          reload: true
        })
      )

      await Promise.all(teamDatasourceidentifiers.map((x) => x.preload()))

      return teamDatasourceidentifiers
    }, useErrorHandle?.alert) as Promise<
    TeamDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllTeamDatasourceIdentifiers = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamDatasourceIdentifierRecord>(async () => {
      const teamDatasourceIdentifiers = parseRecords(await db?.teamDatasourceIdentifiers.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(teamDatasourceIdentifiers.map((x) => x.preload()))
      }

      return teamDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TeamDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
  //#region Team Organization Datasource Identifiers
  export const getTeamOrganizationDatasourceIdentifierById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamOrganizationDatasourceIdentifierRecord>(async () => {
      const teamOrganizationDatasourceIdentifiers = await db?.teamOrganizationDatasourceIdentifiers.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await teamOrganizationDatasourceIdentifiers?.preload()
      }

      return teamOrganizationDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TeamOrganizationDatasourceIdentifierRecord | undefined>
  }

  export const getTeamOrganizationDatasourceIdentifiersByTeamOrgId = async (
    db: RootRepository,
    teamOrgId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<TeamOrganizationDatasourceIdentifierRecord>(async () => {
      const teamOrganizationDatasourceIdentifiers = parseRecords(
        await db?.teamOrganizationDatasourceIdentifiers.getByTeamOrgId(teamOrgId, {
          reload: true
        })
      )

      await Promise.all(teamOrganizationDatasourceIdentifiers.map((x) => x.preload()))

      return teamOrganizationDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<
    TeamOrganizationDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllTeamOrganizationDatasourceIdentifiers = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<TeamOrganizationDatasourceIdentifierRecord>(async () => {
      const teamOrganizationDatasourceIdentifiers = parseRecords(await db?.teamOrganizationDatasourceIdentifiers.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(teamOrganizationDatasourceIdentifiers.map((x) => x.preload()))
      }

      return teamOrganizationDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<TeamOrganizationDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
  //#region Match Datasource Identifiers
  export const getMatchDatasourceIdentifierById = async (
    db:RootRepository,
    id: string,
    useErrorHandle?: { alert?: AlertManager },
    options?: OptionsForGet
  ) => {
    return tryGetData<MatchDatasourceIdentifierRecord>(async () => {
      const MatchDatasourceIdentifier = await db?.matchDatasourceIdentifiers.get(id, { reload: true})

      if (!options?.ignore?.preload) {
        await MatchDatasourceIdentifier?.preload()
      }

      return MatchDatasourceIdentifier
    }, useErrorHandle?.alert) as Promise<MatchDatasourceIdentifierRecord | undefined>
  }

  export const getMatchDatasourceIdentifiersByMatchId = async (
    db: RootRepository,
    mId: string,
    useErrorHandle?: { alert?: AlertManager }
  ) => {
    return tryGetData<MatchDatasourceIdentifierRecord>(async () => {
      const matchDatasourceIdentifiers = parseRecords(
        await db?.matchDatasourceIdentifiers.getByMatchId(mId, {
          reload: true
        })
      )

      await Promise.all(matchDatasourceIdentifiers.map((x) => x?.match_id == mId))

      return matchDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<
    MatchDatasourceIdentifierRecord[] | undefined
    >
  }

  export const getAllMatchDatasourceIdentifiers = async (
    db:RootRepository,
    useErrorHandle?: { alert?: AlertManager},
    options?: OptionsForGet
  ) => {
    return tryGetData<MatchDatasourceIdentifierRecord>(async () => {
      const MatchDatasourceIdentifiers = parseRecords(await db?.matchDatasourceIdentifiers.getAll({ reload: true}))

      if (!options?.ignore?.preload) {
        await Promise.all(MatchDatasourceIdentifiers.map((x) => x.preload()))
      }

      return MatchDatasourceIdentifiers
    }, useErrorHandle?.alert) as Promise<MatchDatasourceIdentifierRecord[] | undefined>
  }
  //#endregion
}

type OptionsForGet = {
  ignore?: {
    preload?: boolean
  }
}
