import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { Settings } from './Table.settings'
import { AppContext } from 'App'
import { TeamContext } from 'components/Team/__Team.context'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { Data } from 'helpers/data'

const SportsmanTeamMemberships: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: t_params } = useContext(TeamContext)
  const { params: s_params } = useContext(SportsmanContext)
  const { params: tt_params } = useContext(TournamentContext)

  const loadData = async () => {
    if (t_params?.team_id)
      return await Data.getSportsmanTeamMembershipsByTeamId(
        a_db!,
        t_params?.team_id,
        { alert: a_alert }
      )

    if (s_params?.sportsman_id)
      return await Data.getSportsmanTeamMembershipsBySportsmanId(
        a_db!,
        s_params?.sportsman_id,
        { alert: a_alert }
      )

    return []
  }

  return (
    <TableLoader<SportsmanTeamMembershipRecord>
      reloadFrom={[Tab.SportsmanTeamMemberships]}
      loadDataFn={loadData}
      settings={Settings(
        {
          sport: s_params?.sport_id ?? t_params?.sport_id,
          team: t_params?.team_id,
          sportsman: s_params?.sportsman_id,
          tournament: tt_params?.tournament_id,
        },
        {
          hide: {
            team: !!t_params?.team_id,
            sportsman: !!s_params?.sportsman_id,
            tournament: !!tt_params?.tournament_id,
          },
        }
      )}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default SportsmanTeamMemberships
