import styled from 'styled-components'

export { ItemWrapper } from 'styles/common'
export { OverflowTextWrapper } from 'styles/common'

export const ArrowDown = styled.div`
  &:after {
    content: ' ▼';
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
