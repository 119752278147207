import React, { useState, useContext, ComponentProps } from 'react'
import { Form as FormHelper } from 'helpers/form'
import { TeamBiographyRecord } from "data/TeamBiographyRecord";
import { Routing as RoutingHelper } from 'helpers/routing'
import { AppContext } from 'App';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import FormItem from 'components/utils/FormItem';
import { Col, Form } from 'react-bootstrap';
import { ItemWrapper, OffsetedContainer } from 'styles/common';
import RichTextEditor from 'components/RichTextEditor';
import Image from 'components/utils/Image/Image'
import { FormContext } from 'components/utils/Form.context';
import Footer from 'components/utils/Form/Footer';
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent';
import ImageGallery from 'components/utils/Image/ImageGallery';

const SubmitKeys = [
  'facebook',
  'images',
  'instagram',
  'perex',
  'team_id',
  'text',
  'twich',
  'twitter',
] as Array<keyof TeamBiographyRecord>

const TeamBiographyForm: React.FC<FormHelper.Props<TeamBiographyRecord> & { teamId?: string }
> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: TeamBiographyRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Team Biography',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      id: form.data?.id,
      team_id: p.teamId,
    },

    onSubmit: async (values) => {
      if (!db?.teamBiographies) return

      try {
        values.id = form?.data?.id // TMP the form somehow loses its id

        const data = await FormHelper.submitChanges<TeamBiographyRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TeamBiographyRecord(), values ?? {}),
          repository: db.teamBiographies,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },
  })

  //#region Form Components
  const _Facebook = (
    <FormItem
      label={<Form.Label>Facebook</Form.Label>}
      input={
        <>
          <Form.Control
            name={'facebook' as keyof TeamBiographyRecord}
            value={formik.values.facebook ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Instagram = (
    <FormItem
      label={<Form.Label>Instagram</Form.Label>}
      input={
        <>
          <Form.Control
            name={'instagram' as keyof TeamBiographyRecord}
            value={formik.values.instagram ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Twich = (
    <FormItem
      label={<Form.Label>Twich</Form.Label>}
      input={
        <>
          <Form.Control
            name={'twich' as keyof TeamBiographyRecord}
            value={formik.values.twich ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Twitter = (
    <FormItem
      label={<Form.Label>Twitter</Form.Label>}
      input={
        <>
          <Form.Control
            name={'twitter' as keyof TeamBiographyRecord}
            value={formik.values.twitter ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Perex = (
    <FormItem
      label={<Form.Label>Perex</Form.Label>}
      input={
        <>
          <Form.Control
            name={'perex' as keyof TeamBiographyRecord}
            value={formik.values.perex ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Text = (
    <FormItem
      label={<Form.Label>Text</Form.Label>}
      input={
        <>
          <ItemWrapper />
          <RichTextEditor
            text={formik.values.text ?? ''}
            readonly={p.locked?.value}
            onChange={(text?: string) => {
              formik.setFieldValue('text' as keyof TeamBiographyRecord, text)
            }}
          />
        </>
      }
    />
  )

  const _Image = (
    <ImageGallery
      inputName={'images' as keyof TeamBiographyRecord}
      imageUrl={formik.values.images ?? ''}
      isLocked={p.locked?.value}
      labelName='Bio Photo'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'teams'}
                actualImageString={formik.values.images ?? ''}
                useMultipleSelect={{
                  divider: '|',
                }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'images' as keyof TeamBiographyRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('images' as keyof TeamBiographyRecord, val)
      }
    />
  )

  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Footer {..._ComputeFooterProps()} />

          <Form.Row>
            <Col lg={12}>
              <ItemWrapper />
              <Form.Row>
                <Col as={ItemWrapper}>{_Facebook}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Instagram}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Twich}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Twitter}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Perex}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Text}</Col>
              </Form.Row>
              <ItemWrapper />
              <Form.Row>
                <Col as={ItemWrapper}>{_Image}</Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default TeamBiographyForm
