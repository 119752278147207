export module object {
  /* eslint-disable @typescript-eslint/ban-types */
  export function replaceEmptyStringsByNull<T extends object>(input:T) {
    if (input == null) {
      // nothing to replace
      return
    }

    for(const key in input) {
      const value = (input as any)[key]
      if (value === "") {
        (input as any)[key] = null
      }
    }

    return input
  }
}
