import React from 'react'
import { TabMapper } from "components/utils/TabsLoader";
import { Tab } from "static/tabs";
import TournamentStageDatasourceIdentifier from "../../TournamentStageDatasourceIdentifiers/__TournamentStagetDatasourceIdentifier";

export const Mapper: TabMapper = {
  [Tab.TournamentStageDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.TournamentStageDatasourceIdentifiers}`]: {
        node: <TournamentStageDatasourceIdentifier phase={"select"} />
      }
    }
  }
}
