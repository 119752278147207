import React from 'react'
import styled, { css } from 'styled-components'
// Styles
import { Breakpoint } from 'styles/responsive'

interface ContainerStyleProps {
  readonly betweenItemsOffset?: string

  readonly verticalOffset?: string
  readonly horizontalOffset?: string

  readonly numMaxItemsInRow?: number

  readonly useFullAvailableWidth?: boolean
  readonly disableTabbleMobileFullWidth?: boolean
}

/**
 * @param {string} betweenItemsOffset Offset between each item inside container in css unit size e.g. 8px, 1rem, 13em...
 * If not provided, default 8px unit size is used
 * @param {string} verticalOffset Vertical offset betwwen container and items in css unit size e.g. 8px, 1rem, 13em...
 * @param {string} horizontalOffset Vertical offset betwwen container and items in css unit size e.g. 8px, 1rem, 13em...
 * If not provided, default 8px unit size is used
 * @param {number} numMaxItemsInRow Number of max items in row
 * If not provided, default 5 item is used
 * @param {number} disableTabbleMobileFullWidth Items in the container are full width on mobile devices. Enabling this property interrupt this behavior
 */
const GridContainer: React.FC<
  {
    items: Array<React.ReactNode>
  } & ContainerStyleProps
> = (p) => {
  const styleProps = {
    ...p,
    betweenItemsOffset: p.betweenItemsOffset ?? '8px',
    verticalOffset: p.verticalOffset ?? '8px',
    horizontalOffset: p.horizontalOffset ?? '0px',
    numMaxItemsInRow: p.numMaxItemsInRow ?? 5,
  } as ContainerStyleProps

  return (
    <Container {...styleProps}>
      {p.items.map((i, idx) => (
        <Item key={`Container.Item_${idx}`}>{i}</Item>
      ))}
    </Container>
  )
}

const Item = styled.div``

const Container = styled.div<ContainerStyleProps>`
  display: flex;

  margin: -${({ betweenItemsOffset }) => betweenItemsOffset};

  padding-top: calc(3 * ${({ verticalOffset }) => verticalOffset});
  padding-bottom: calc(3 * ${({ verticalOffset }) => verticalOffset});

  padding-left: calc(3 * ${({ horizontalOffset }) => horizontalOffset});
  padding-right: calc(3 * ${({ horizontalOffset }) => horizontalOffset});

  ${Item} {
    align-self: center;

    margin: ${({ betweenItemsOffset }) => betweenItemsOffset};

    ${({ useFullAvailableWidth, numMaxItemsInRow }) => {
      if (useFullAvailableWidth)
        return css`
          flex: 1 1 auto;
        `
        
      return css`
        flex: 0 1 ${100 / (numMaxItemsInRow ?? 1)}%;
      `
    }}
  }

  ${({ disableTabbleMobileFullWidth }) => {
    if (disableTabbleMobileFullWidth) return

    return Breakpoint.ForTabletMobile(css`
      flex-wrap: wrap;

      ${Item} {
        flex: 0 1 100%;
      }
    `)
  }}
`

export default GridContainer
