import CTAButton from "components/utils/Button/CTAButton";
import { RecordSetting } from "components/utils/Table/_Table.settings";
import { NearestMatchRecord } from "data/NearesMatchRecord";
import { Routing } from "helpers/routing";
import React, { useContext } from "react";
import { EllipsisHIcon, LockIcon } from "styles/fontAwesome";
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { DateTime } from 'utils/dateTime'

const Settings: RecordSetting<NearestMatchRecord> = [

  {
    accessor: "id",
    className: 'table-hidden'
  },
  {
    accessor: 'order_number',
    Header: 'Order'
  },
  {
    accessor: 'match_id',
    Header: 'Match Id'
  },
  {
    // accessor: 'tournament_name',
    accessor: 'finalTournamentName',
    Header: 'Tournament'
  },
  {
    accessor: 'finalStartsAt',
    Header: 'Starts At',
    Cell: ({ row }: any) =>
      row.values?.finalStartsAt ? DateTime.shortDate(row.values?.finalStartsAt) : '---',
  },
  {
    accessor: 'finalTeam1Name',
    Header: 'Team 1'
  },
  {
    accessor: 'finalTeam2Name',
    Header: 'Team 2'
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      return (
        <CTAButton path={Routing.getNearestMatchDetailUrl({ nearestMatch: row.values.id })}>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]

export default Settings
