import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { MatchRecord } from "./MatchRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { SportsmanRecord } from "./SportsmanRecord";
import { SportsmanRoleRecord } from "./SportsmanRoleRecord";
import { TeamRecord } from "./TeamRecord";

export class PlayerRecord<TJsonData extends PlayerRecord.CtorJsonData = PlayerRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  data_approved_at?: Date | null

  /// >>> match >>>
  #match:BaseRecord.RefField<MatchRecord> = {}
  
  match_id?: string

  get match() {
    return this.#match?.object
  }
  getMatch() {
    this.#match.id = this.match_id

    return this.getObjectFromRefField(this.#match, "matches")
  }
  /// <<< match <<<

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}
  
  team_id?: string

  get team() {
    return this.#team?.object
  }
  getTeam() {
    this.#team.id = this.team_id

    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  /// >>> sportsman >>>
  #sportsman:BaseRecord.RefField<SportsmanRecord> = {}
 
  sportsman_id?: string

  get sportsman() {
    return this.#sportsman?.object
  }
  getSportsman() {
    this.#sportsman.id = this.sportsman_id

    return this.getObjectFromRefField(this.#sportsman, "sportsmen")
  }
  /// <<< sportsman <<<

  /// >>> sportsman role >>>
  #sportsmanRole:BaseRecord.RefField<SportsmanRoleRecord> = {}
  
  sportsman_role_id?: string

  get sportsman_role() {
    return this.#sportsmanRole?.object
  }
  getSportsmanRole() {
    this.#sportsmanRole.id = this.sportsman_role_id

    return this.getObjectFromRefField(this.#sportsmanRole, "sportsmanRoles")
  }
  /// <<< sportsman role <<<

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }
  
  get team_name() {
    return this.team?.full_name
  }

  get sportsman_is_approved() {
    return this.sportsman?.isApproved
  }

  get sportsman_nickname() {
    return this.sportsman?.nickname
  }

  get sportsman_role_name() {
    return this.sportsman_role?.name
  }

  get sortValues() {
    return [
      this.team_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
      this.sportsman_nickname?.toLowerCase() ?? Number.MAX_SAFE_INTEGER
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:PlayerRecord.CtorJsonData) {
    return new PlayerRecord(db, jsonData)
  }
  
  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)
    
    this.match_id = jsonData?.match_id
    this.team_id = jsonData?.team_id
    this.sportsman_id = jsonData?.sportsman_id
    this.sportsman_role_id = jsonData?.sportsman_role_id
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      match_id: this.match_id,
      team_id: this.team_id,
      sportsman_id: this.sportsman_id,
      sportsman_role_id: this.sportsman_role_id,
      data_approved_at: this.data_approved_at?.toISOString(),
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const match = await this.getMatch()
    await match?.preload()

    const team = await this.getTeam()
    await team?.preload()

    const sportsman = await this.getSportsman()
    await sportsman?.preload()

    const sportsmanRole = await this.getSportsmanRole()
    await sportsmanRole?.preload()
  }
}

export module PlayerRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    match_id?: string
    team_id?: string
    sportsman_id?: string
    sportsman_role_id?: string
    data_approved_at?: string
  }

  export class Repository extends RecordRepository<PlayerRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by match_id >>>
    #getByMatchId:RecordRepository.NestingGetFieldFlags = {}

    getByMatchId(matchId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByMatchId, matchId, options, {
        peek: () => this.peekByMatchId(matchId),
        load: () => this.loadByMatchId(matchId)
      })
    }

    peekByMatchId(matchId:string) {
      return this.peekAll(x => x?.match_id == matchId)
    }

    protected loadByMatchId(matchId:string) {
      return this.getApi().getMorePlayersByMatchId(matchId)
    }
    /// <<< by match_id <<<

    create(record: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOnePlayer(record)
    }

    protected loadAll() {
      return this.getApi().getAllPlayers()
    }

    protected loadById(id: string): Promise<PlayerRecord | null> {
      return this.getApi().getOnePlayerById(id)
    }

    protected updateById(id: string, patch: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOnePlayer(id, patch)
    }

    protected deleteById(id: string): Promise<PlayerRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOnePlayer(id)
    }

    protected approveById(id: string, patch: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOnePlayerById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOnePlayerById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<PlayerRecord<CtorJsonData>>): Promise<PlayerRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllPlayers()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOnePlayerById(id)
    }
  }
}