import React, { useState } from 'react'
// Components
import { Form } from 'react-bootstrap'
// Styles
import * as S from './_Picker.styles'
// Additional
import { DateTime } from 'utils/dateTime'
// TempusDominus Components
/* eslint-disable @typescript-eslint/no-var-requires */
const { DatePicker, DateTimePicker } = require('react-tempusdominus-bootstrap')

export interface Props {
  readonly picker: 'date' | 'datetime'
  readonly name: string
  readonly value?: Date
  readonly readOnly?: boolean
  readonly isRequired?: boolean
  readonly isInvalid?: boolean
  readonly onChangeCallback?: (date: Date | null) => void
}

const Picker: React.FC<Props> = (p) => {
  const [value, setValue] = useState<Date | null | undefined>(p.value)

  const options = {
    readOnly: p.readOnly,
    defaultDate: p.value,
    onChange: (e: any) => {
      const _date = DateTime.parseDateOrNull(e?.date?.toDate?.())

      setValue(_date)

      p.onChangeCallback?.(_date)
    },
  }

  return (
    <>
      <Form.Control
        hidden
        readOnly
        name={p.name}
        isInvalid={p.isInvalid}
        required={p.isRequired}
        value={value?.toString() ?? ''}
      />
      <S.DateTimePicker isColored={p.isRequired && value === undefined}>
        {p.picker === 'datetime' && (
          <DateTimePicker {...options} format={'DD.MM.yyyy HH:mm'} />
        )}
        {p.picker === 'date' && (
          <DatePicker {...options} format={'DD.MM.yyyy'} />
        )}
      </S.DateTimePicker>
    </>
  )
}

export default Picker
