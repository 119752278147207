import styled, { css } from "styled-components"

export interface DirItemProps {
  readonly text?: string
}

export const DirItem = styled.button.attrs<DirItemProps>(({ text }) => ({
   text
}))<DirItemProps>`
  cursor: pointer;

  color: grey;
  border-color: #17a2b8;
  border: 1px solid grey;
  border-radius: 0.25rem;

  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &::hover & {
    background-color: grey;
    color: white;
  }
`

export const DirPathWrapper = styled.div`
  display: flex;

  gap: 5px;
`

export const DirPathItem = styled.div<{ clickable?: boolean }>`
  ${({ clickable }) => clickable && css`
    color: blue;

    cursor: pointer;
  `}
`

export const DirItemWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;

  height: inherit;
  width: inherit;

  position: relative;
`
export const DirsItemsWrapper = styled.div`
  max-height: 50vh;

  display: grid;

  grid-template-columns: repeat(auto-fill,minmax(120px,1fr));

  width: fill-available;
  height: fill-available;

  align-items: center;

  gap: 10px;

  overflow: auto;
`
