import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export interface StyleProps {
  readonly table?: FlattenSimpleInterpolation
  readonly thead?: FlattenSimpleInterpolation
  readonly theadR?: FlattenSimpleInterpolation
  readonly theadH?: FlattenSimpleInterpolation
  readonly tbody?: FlattenSimpleInterpolation
  readonly tbodyR?: FlattenSimpleInterpolation
  readonly tbodyD?: FlattenSimpleInterpolation
}

interface CSSExtension {
  addCSS?: FlattenSimpleInterpolation
}

export const Table = styled.table<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableHead = styled.thead<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableHeadRow = styled.tr<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableHeadHeader = styled.th<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableBody = styled.tbody<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableBodyRow = styled.tr<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableBodyDataCell = styled.td<CSSExtension>`
  ${({ addCSS }) => addCSS}
`

export const TableCollapsedStyle = css`
  width: -webkit-fill-available;

  border-collapse: collapse;
`

export const Cell = styled.div`
  padding: 1px 3px;
`

export const TextCellAlignCenter = styled(Cell)`
  text-align: center;
`

export const TextCellAlignRight = styled(Cell)`
  text-align: right;
`

export const NoDataAvailable = styled.span`
  display: flex;
  justify-content: center;
`

const StickyCss = css`
  position: sticky !important;
  z-index: 3 !important;
`

const StickyLeftCss = css`
  ${StickyCss};
  left: -1px !important;
`

const StickyRightCss = css`
  ${StickyCss};
  right: -1px !important;
`

export const TableRowStyle = css`
  white-space: nowrap;
  height: 39px;

  .table-lock {
    width: 36px;
    min-width: 36px; // due to sticky column style
  }

  .table-hidden {
    display: none;
  }
  
  .table-stick-left {
    ${StickyLeftCss};
  }

  .table-stick-right {
    ${StickyRightCss};
  }

  .table-cta-button {
    ${StickyRightCss};
    
    padding: 1px !important;
    width: 85px;
    min-width: 85px; // due to sticky column style
  }

  th {
    background-color: #e8e8e8;
  }

  td {
    background-color: white;
  }
`