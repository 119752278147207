import React, { useState, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
// Components
import { FormControl } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
// Additional
import { OnGlobalFilterChangeListener } from 'components/utils/Filter/_Filter'
import { OnReloadFilterListener } from 'components/utils/Filter/_Filter'

interface Props {
  readonly style?: {
    readonly disabled?: boolean
  }

  readonly from: OnReloadFilterListener.Location
}

const UIFullTextFilter: React.FC<Props> = (p) => {
  const [inputValue, setInputValue] = useState<string>('')

  const formControlRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleReloadFilter = () => {
      const _value = formControlRef?.current?.value

      _value && dispatchFilterValue(_value)
    }

    OnReloadFilterListener.addAndRemove(p.from, handleReloadFilter)
  }, [])

  const dispatchFilterValue = (value: string): void => {
    OnGlobalFilterChangeListener.dispatch({ filterValue: value })
  }

  const debounceTypedText = useDebouncedCallback(
    (value: string) => {
      dispatchFilterValue(value)
    },
    300,
    {}
  )

  const handleTextTyped = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const _value = e.target.value

    if (_value.length > 0) {
      debounceTypedText(_value)
    } else {
      debounceTypedText.cancel()

      dispatchFilterValue(_value)
    }

    setInputValue(_value)
  }

  return (
    <InputGroup>
      <FormControl
        ref={formControlRef}
        value={inputValue}
        placeholder={'Search...'}
        onChange={handleTextTyped}
        disabled={p.style?.disabled}
      />
    </InputGroup>
  )
}

export default UIFullTextFilter
