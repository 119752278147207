import React, { useImperativeHandle } from 'react'
import styled from 'styled-components'
// Components
import Dropdown, { Props as DProps, Item } from 'components/utils/Dropdown'
import { Form } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
// Addtitional
import { OnFilterChangeListener } from 'components/utils/Filter/_Filter'
import { DropdownInputGroup as _DropdownInputGroup } from 'styles/common'

const DropdownInputGroup = styled(_DropdownInputGroup)`
  &,
  *:not(.dropdown-toggle) {
    min-width: 100%;
  }
`

interface Handle {
  forceFilter: (item: Item) => void
}

interface RProps extends DProps {
  readonly accessor?: string
  readonly from: OnFilterChangeListener.Location
}

const DropdownFilter: React.ForwardRefRenderFunction<Handle, RProps> = (
  p,
  ref
) => {
  const filterItem = (item: Item) => {
    OnFilterChangeListener.dispatch(p.from, {
      columnName: p.accessor as string,
      filteredValue: item.id !== p.defaultItem?.id ? item.id : undefined,
    })
  }

  useImperativeHandle(ref, () => ({
    forceFilter(item: Item) {
      filterItem(item)
    },
  }))

  const handleItemSelected = (item: Item): void => {
    filterItem(item)

    p.onSelect?.(item)
  }

  return (
    <>
      <Form.Control hidden readOnly value={p.value?.id ?? ''} />
      <InputGroup as={DropdownInputGroup}>
        <Dropdown {...p} onSelect={handleItemSelected} />
      </InputGroup>
    </>
  )
}

export default React.forwardRef(DropdownFilter)
