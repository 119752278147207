import { AppContext } from "App"

import TableLoader from "components/utils/Table/Table"
import { NearestMatchRecord } from "data/NearesMatchRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from './Table.settings'
import * as R from './Table.styles'

const NearestMatchesTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const loadData = async () => {
    /**
     * Ignorujeme preloading, a nacteme vsechny data najednou.
     * Sie preneseme vice dat, ale vyhneme se "problemu N+1"
     * a cele to bude rychlejsi.
     *
     * Toto bude dobre fungovat do doby, nez databaze nebude
     * obsahovat nesmyslne mnozstvi data. Pak to bude naopak
     * pomalejsi.
     */
    const options = {ignore: {preload: true}}

    await Data.getAllTeams(a_db!, { alert: a_alert}, options)
    await Data.getAllMatches(a_db!, { alert: a_alert}, options)
    await Data.getAllTournaments(a_db!, { alert: a_alert}, options)
    await Data.getAllStages(a_db!, { alert: a_alert}, options)
    await Data.getAllRounds(a_db!, { alert: a_alert}, options)

    return await Data.getAllNearestMatches(a_db!, { alert: a_alert }, options)
  }

  return (
    <TableLoader<NearestMatchRecord>
      reloadFrom={[Tab.NearestMatches]}
      loadDataFn={loadData}
      settings={Settings}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default NearestMatchesTable
