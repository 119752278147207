import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportsmanRecord } from "./SportsmanRecord"

export class SportsmanStatSummaryRecord<TJsonData extends SportsmanStatSummaryRecord.CtorJsonData = SportsmanStatSummaryRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  timespace_id?: string
  group_size?: number

  // >>> SF points >>>
  fantasy_points_sum?: number
  fantasy_points_per_match_avg?: number
  fantasy_points_per_map_avg?: number
  fantasy_points_per_round_avg?: number
  fantasy_points_rank_from?: number
  fantasy_points_rank_to?: number
  // <<< SF points <<<

  // >>> match summary >>>
  matches_played_sum?: number
  matches_won_sum?: number
  matches_lost_sum?: number
  matches_tied_sum?: number
  matches_won_ratio?: number
  // <<< match summary <<<

  // >>> map summary >>>
  maps_played_sum?: number
  maps_won_sum?: number
  maps_lost_sum?: number
  maps_tied_sum?: number
  // <<< map summary <<<

  // >>> round summary >>>
  rounds_played_sum?: number
  rounds_played_ct_sum?: number
  rounds_played_t_sum?: number
  rounds_won_sum?: number
  rounds_won_ct_sum?: number
  rounds_won_t_sum?: number
  rounds_lost_sum?: number
  rounds_lost_ct_sum?: number
  rounds_lost_t_sum?: number
  rounds_won_ratio?: number
  rounds_won_t_ratio?: number
  rounds_won_ct_ratio?: number
  // <<< round summary <<<

  // >>> kill summary >>>
  kills_sum?: number
  kills_sum_rank_from?: number
  kills_sum_rank_to?: number
  kill_death_ratio?: number
  kill_death_ratio_rank_from?: number
  kill_death_ratio_rank_to?: number
  teamkills_sum?: number
  assists_sum?: number
  deaths_sum?: number
  suicides_sum?: number
  headshots_sum?: number
  entry_kills_sum?: number
  // <<< kill summary <<<
  
  // >>> bombs >>>
  opponent_bomb_plants_sum?: number
  bomb_defuses_sum?: number
  bomb_plants_sum?: number
  bomb_explodes_sum?: number
  bomb_plants_ratio_per_round?: number
  bomb_defuses_ratio_per_round?: number
  bomb_explodes_ratio_per_round?: number
  // <<< bombs <<<
  
  // >>> clutches >>>
  clutches_v1_sum?: number
  clutches_v1_won_sum?: number
  clutches_v2_sum?: number
  clutches_v2_won_sum?: number
  clutches_v3_sum?: number
  clutches_v3_won_sum?: number
  clutches_v4_sum?: number
  clutches_v4_won_sum?: number
  clutches_v5_sum?: number
  clutches_v5_won_sum?: number
  clutches_ratio_per_round?: number
  clutches_won_ratio_per_round?: number
  // <<< clutches <<<
  
  damage_sum?: number
  weapon_kills?: string
  
  data_frozen_at?: Date | null

  /// >>> sportsman >>>
  #sportsman:BaseRecord.RefField<SportsmanRecord> = {}
  get sportsman_id() {
    return this.#sportsman.id
  }
  set sportsman_id(value) {
    this.#sportsman.id = value
  }

  get sportsman() {
    return this.#sportsman?.object
  }
  getSportsman() {
    return this.getObjectFromRefField(this.#sportsman, "sportsmen")
  }
  /// <<< sportsman <<<

  /// >>> computed values  >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sportsman_name() {
    return this.sportsman?.full_name
  }

  get sportsman_nickname() {
    return this.sportsman?.nickname
  }
  get sortValues() {
    return [
      this.sportsman_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER
    ]
  }
  /// <<< computed values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanStatSummaryRecord.CtorJsonData) {
    return new SportsmanStatSummaryRecord(db, jsonData)
  }
  
  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)
    
    this.timespace_id = jsonData?.timespace_id
    this.sportsman_id = jsonData?.sportsman_id
    this.group_size = jsonData?.group_size

    // >>> SF points >>>
    this.fantasy_points_sum = jsonData?.fantasy_points_sum
    this.fantasy_points_per_match_avg = jsonData?.fantasy_points_per_match_avg
    this.fantasy_points_per_map_avg = jsonData?.fantasy_points_per_map_avg
    this.fantasy_points_per_round_avg = jsonData?.fantasy_points_per_round_avg
    this.fantasy_points_rank_from = jsonData?.fantasy_points_rank_from
    this.fantasy_points_rank_to = jsonData?.fantasy_points_rank_to
    // <<< SF points <<<

    // >>> match summary >>>
    this.matches_played_sum = jsonData?.matches_played_sum
    this.matches_won_sum = jsonData?.matches_won_sum
    this.matches_lost_sum = jsonData?.matches_lost_sum
    this.matches_tied_sum = jsonData?.matches_tied_sum
    this.matches_won_ratio = jsonData?.matches_won_ratio
    // <<< match summary <<<

    // >>> map summary >>>
    this.maps_played_sum = jsonData?.maps_played_sum
    this.maps_won_sum = jsonData?.maps_won_sum
    this.maps_lost_sum = jsonData?.maps_lost_sum
    this.maps_tied_sum = jsonData?.maps_tied_sum
    // <<< map summary <<<

    // >>> round summary >>>
    this.rounds_played_sum = jsonData?.rounds_played_sum
    this.rounds_played_ct_sum = jsonData?.rounds_played_ct_sum
    this.rounds_played_t_sum = jsonData?.rounds_played_t_sum
    this.rounds_won_sum = jsonData?.rounds_won_sum
    this.rounds_won_ct_sum = jsonData?.rounds_won_ct_sum
    this.rounds_won_t_sum = jsonData?.rounds_won_t_sum
    this.rounds_lost_sum = jsonData?.rounds_lost_sum
    this.rounds_lost_ct_sum = jsonData?.rounds_lost_ct_sum
    this.rounds_lost_t_sum = jsonData?.rounds_lost_t_sum
    this.rounds_won_ratio = jsonData?.rounds_won_ratio
    this.rounds_won_t_ratio = jsonData?.rounds_won_t_ratio
    this.rounds_won_ct_ratio = jsonData?.rounds_won_ct_ratio
    // <<< round summary <<<

    // >>> kill summary >>>
    this.kills_sum = jsonData?.kills_sum
    this.kills_sum_rank_from = jsonData?.kills_sum_rank_from
    this.kills_sum_rank_to = jsonData?.kills_sum_rank_to
    this.kill_death_ratio = jsonData?.kill_death_ratio
    this.kill_death_ratio_rank_from = jsonData?.kill_death_ratio_rank_from
    this.kill_death_ratio_rank_to = jsonData?.kill_death_ratio_rank_to
    this.teamkills_sum = jsonData?.teamkills_sum
    this.assists_sum = jsonData?.assists_sum
    this.deaths_sum = jsonData?.deaths_sum
    this.suicides_sum = jsonData?.suicides_sum
    this.headshots_sum = jsonData?.headshots_sum
    this.entry_kills_sum = jsonData?.entry_kills_sum
    // <<< kill summary <<<

    // >>> bombs >>>
    this.opponent_bomb_plants_sum = jsonData?.opponent_bomb_plants_sum
    this.bomb_defuses_sum = jsonData?.bomb_defuses_sum
    this.bomb_plants_sum = jsonData?.bomb_plants_sum
    this.bomb_explodes_sum = jsonData?.bomb_explodes_sum
    this.bomb_plants_ratio_per_round = jsonData?.bomb_plants_ratio_per_round
    this.bomb_defuses_ratio_per_round = jsonData?.bomb_defuses_ratio_per_round
    this.bomb_explodes_ratio_per_round = jsonData?.bomb_explodes_ratio_per_round
    // <<< bombs <<<

    // >>> clutches >>>
    this.clutches_v1_sum = jsonData?.clutches_v1_sum
    this.clutches_v1_won_sum = jsonData?.clutches_v1_won_sum
    this.clutches_v2_sum = jsonData?.clutches_v2_sum
    this.clutches_v2_won_sum = jsonData?.clutches_v2_won_sum
    this.clutches_v3_sum = jsonData?.clutches_v3_sum
    this.clutches_v3_won_sum = jsonData?.clutches_v3_won_sum
    this.clutches_v4_sum = jsonData?.clutches_v4_sum
    this.clutches_v4_won_sum = jsonData?.clutches_v4_won_sum
    this.clutches_v5_sum = jsonData?.clutches_v5_sum
    this.clutches_v5_won_sum = jsonData?.clutches_v5_won_sum
    this.clutches_ratio_per_round = jsonData?.clutches_ratio_per_round
    this.clutches_won_ratio_per_round = jsonData?.clutches_won_ratio_per_round
    // <<< clutches <<<

    this.damage_sum = jsonData?.clutches_won_ratio_per_round
    this.weapon_kills = jsonData?.weapon_kills
  
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      timespace_id: this.timespace_id,
      sportsman_id: this.sportsman_id,
      group_size: this.group_size,

      // >>> SF points >>>
      fantasy_points_sum: this.fantasy_points_sum,
      fantasy_points_per_match_avg: this.fantasy_points_per_match_avg,
      fantasy_points_per_map_avg: this.fantasy_points_per_map_avg,
      fantasy_points_per_round_avg: this.fantasy_points_per_round_avg,
      fantasy_points_rank_from: this.fantasy_points_rank_from,
      fantasy_points_rank_to: this.fantasy_points_rank_to,
      // <<< SF points <<<

      // >>> match summary >>>
      matches_played_sum: this.matches_played_sum,
      matches_won_sum: this.matches_won_sum,
      matches_lost_sum: this.matches_lost_sum,
      matches_tied_sum: this.matches_tied_sum,
      matches_won_ratio: this.matches_won_ratio,
      // <<< match summary <<<

      // >>> map summary >>>
      maps_played_sum: this.maps_played_sum,
      maps_won_sum: this.maps_won_sum,
      maps_lost_sum: this.maps_lost_sum,
      maps_tied_sum: this.maps_tied_sum,
      // <<< map summary <<<

      // >>> round summary >>>
      rounds_played_sum: this.rounds_played_sum,
      rounds_played_ct_sum: this.rounds_played_ct_sum,
      rounds_played_t_sum: this.rounds_played_t_sum,
      rounds_won_sum: this.rounds_won_sum,
      rounds_won_ct_sum: this.rounds_won_ct_sum,
      rounds_won_t_sum: this.rounds_won_t_sum,
      rounds_lost_sum: this.rounds_lost_sum,
      rounds_lost_ct_sum: this.rounds_lost_ct_sum,
      rounds_lost_t_sum: this.rounds_lost_t_sum,
      rounds_won_ratio: this.rounds_won_ratio,
      rounds_won_t_ratio: this.rounds_won_t_ratio,
      rounds_won_ct_ratio: this.rounds_won_ct_ratio,
      // <<< round summary <<<

      // >>> kill summary >>>
      kills_sum: this.kills_sum,
      kills_sum_rank_from: this.kills_sum_rank_from,
      kills_sum_rank_to: this.kills_sum_rank_to,
      kill_death_ratio: this.kill_death_ratio,
      kill_death_ratio_rank_from: this.kill_death_ratio_rank_from,
      kill_death_ratio_rank_to: this.kill_death_ratio_rank_to,
      teamkills_sum: this.teamkills_sum,
      assists_sum: this.assists_sum,
      deaths_sum: this.deaths_sum,
      suicides_sum: this.suicides_sum,
      headshots_sum: this.headshots_sum,
      entry_kills_sum: this.entry_kills_sum,
      // <<< kill summary <<<

      // >>> bombs >>>
      opponent_bomb_plants_sum: this.opponent_bomb_plants_sum,
      bomb_defuses_sum: this.bomb_defuses_sum,
      bomb_plants_sum: this.bomb_plants_sum,
      bomb_explodes_sum: this.bomb_explodes_sum,
      bomb_plants_ratio_per_round: this.bomb_plants_ratio_per_round,
      bomb_defuses_ratio_per_round: this.bomb_defuses_ratio_per_round,
      bomb_explodes_ratio_per_round: this.bomb_explodes_ratio_per_round,
      // <<< bombs <<<

      // >>> clutches >>>
      clutches_v1_sum: this.clutches_v1_sum,
      clutches_v1_won_sum: this.clutches_v1_won_sum,
      clutches_v2_sum: this.clutches_v2_sum,
      clutches_v2_won_sum: this.clutches_v2_won_sum,
      clutches_v3_sum: this.clutches_v3_sum,
      clutches_v3_won_sum: this.clutches_v3_won_sum,
      clutches_v4_sum: this.clutches_v4_sum,
      clutches_v4_won_sum: this.clutches_v4_won_sum,
      clutches_v5_sum: this.clutches_v5_sum,
      clutches_v5_won_sum: this.clutches_v5_won_sum,
      clutches_ratio_per_round: this.clutches_ratio_per_round,
      clutches_won_ratio_per_round: this.clutches_won_ratio_per_round,
      // <<< clutches <<<

      damage_sum: this.clutches_won_ratio_per_round,
      weapon_kills: this.weapon_kills,
      data_frozen_at: this.data_frozen_at?.toISOString()
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const sportsman = await this.getSportsman()
    await sportsman?.preload()
  }
}

export module SportsmanStatSummaryRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    timespace_id?: string
    sportsman_id?: string

    group_size?: number

    // >>> SF points >>>
    fantasy_points_sum?: number
    fantasy_points_per_match_avg?: number
    fantasy_points_per_map_avg?: number
    fantasy_points_per_round_avg?: number
    fantasy_points_rank_from?: number
    fantasy_points_rank_to?: number
    // <<< SF points <<<

    // >>> match summary >>>
    matches_played_sum?: number
    matches_won_sum?: number
    matches_lost_sum?: number
    matches_tied_sum?: number
    matches_won_ratio?: number
    // <<< match summary <<<

    // >>> map summary >>>
    maps_played_sum?: number
    maps_won_sum?: number
    maps_lost_sum?: number
    maps_tied_sum?: number
    // <<< map summary <<<

    // >>> round summary >>>
    rounds_played_sum?: number
    rounds_played_ct_sum?: number
    rounds_played_t_sum?: number
    rounds_won_sum?: number
    rounds_won_ct_sum?: number
    rounds_won_t_sum?: number
    rounds_lost_sum?: number
    rounds_lost_ct_sum?: number
    rounds_lost_t_sum?: number
    rounds_won_ratio?: number
    rounds_won_t_ratio?: number
    rounds_won_ct_ratio?: number
    // <<< round summary <<<

    // >>> kill summary >>>
    kills_sum?: number
    kills_sum_rank_from?: number
    kills_sum_rank_to?: number
    kill_death_ratio?: number
    kill_death_ratio_rank_from?: number
    kill_death_ratio_rank_to?: number
    teamkills_sum?: number
    assists_sum?: number
    deaths_sum?: number
    suicides_sum?: number
    headshots_sum?: number
    entry_kills_sum?: number
    // <<< kill summary <<<
    
    // >>> bombs >>>
    opponent_bomb_plants_sum?: number
    bomb_defuses_sum?: number
    bomb_plants_sum?: number
    bomb_explodes_sum?: number
    bomb_plants_ratio_per_round?: number
    bomb_defuses_ratio_per_round?: number
    bomb_explodes_ratio_per_round?: number
    // <<< bombs <<<
    
    // >>> clutches >>>
    clutches_v1_sum?: number
    clutches_v1_won_sum?: number
    clutches_v2_sum?: number
    clutches_v2_won_sum?: number
    clutches_v3_sum?: number
    clutches_v3_won_sum?: number
    clutches_v4_sum?: number
    clutches_v4_won_sum?: number
    clutches_v5_sum?: number
    clutches_v5_won_sum?: number
    clutches_ratio_per_round?: number
    clutches_won_ratio_per_round?: number
    // <<< clutches <<<
    
    damage_sum?: number
    weapon_kills?: string
    
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<SportsmanStatSummaryRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    protected loadAll() {
      return this.getApi().getAllSportsmanStatSummaries()
    }

    protected loadById(id: string): Promise<SportsmanStatSummaryRecord | null> {
      return this.getApi().getOneSportsmanStatSummaryById(id)
    }

    protected updateById(id: string, patch: Partial<SportsmanStatSummaryRecord<CtorJsonData>>): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected deleteById(id: string): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<SportsmanStatSummaryRecord<CtorJsonData>>): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<SportsmanStatSummaryRecord<CtorJsonData>>): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<SportsmanStatSummaryRecord<CtorJsonData>>): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<SportsmanStatSummaryRecord<CtorJsonData>>): Promise<SportsmanStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSportsmanStatSummaries()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportsmanStatSummaryById(id)
    }
  }
}