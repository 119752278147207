export module OnReloadTriggerListener {
  const BASE_EVENT_NAME = 'onReloadTrigger'

  type ReloadTriggerArgs = { listener: EventListener, from: Location }

  export type Location = string

  const getEventName = (from: Location) => `${BASE_EVENT_NAME}.from${from}`

  export function add(args: ReloadTriggerArgs) {
    const { listener, from } = args

    document.addEventListener(getEventName(from), listener)
  }

  export function remove(args: ReloadTriggerArgs) {
    const { listener, from } = args

    document.removeEventListener(getEventName(from), listener)
  }

  export function dispatch(from: Location) {
    document.dispatchEvent(
      new CustomEvent(getEventName(from))
    )
  }

  export function addAndRemove(args: ReloadTriggerArgs) {
    add(args)

    return () => {
      remove(args)
    }
  }
}