import React, { ComponentProps, useState } from 'react'
import DirMenu from 'components/utils/DirMenu/DirMenu'
import ImageGalleryPicker from 'components/utils/ImageGalleryPicker/ImageGalleryPicker'
import styled from 'styled-components'

const ROOT_PATH = 'sazkaesport/media/content'

const Wrapper = styled.div`
  display: flex;

  flex-direction: column;

  gap: 15px;
`

interface Props
  extends Omit<ComponentProps<typeof ImageGalleryPicker>, 'actualDir'> {
  readonly actualDir: string
}

const ImageGalleryPickerParent: React.FC<Props> = (p) => {
  const [paths, setPaths] = useState<Array<string>>([p.actualDir])

  const handleClickCallback = (path: string) => {
    setPaths((prev) => [...prev, path])
  }

  const handleClickPathCallback = (path: string) => {
    const pathIdx = paths.indexOf(path)

    const pathArr = [] as Array<string>

    for (let i = 0; i <= pathIdx; i++) pathArr.push(paths[i])

    setPaths(pathArr)
  }

  return (
    <Wrapper>
      <DirMenu
        {...p}
        rootPath={ROOT_PATH}
        addPaths={paths}
        totalPath={`${ROOT_PATH}/${paths.reduce(
          (acc, val) => `${acc}${val}/`,
          ''
        )}`}
        onClickCallback={handleClickCallback}
        onClickPath={handleClickPathCallback}
      />
      <ImageGalleryPicker
        {...p}
        actualDir={`${ROOT_PATH}/${paths.reduce(
          (acc, val) => `${acc}${acc.length > 0 ? '/' : ''}${val}`,
          ''
        )}`}
      />
    </Wrapper>
  )
}

// export const _ImageGalleryPickerParent: React.FC = () => <></>

export default ImageGalleryPickerParent
