import React, { useEffect, useContext } from 'react'
// Components
import UIFilters from './Filters'
import Table from './Table/TableWithAdd'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'
import { TeamOrganizationContext } from 'components/TeamOrganizations/__TeamOrganizations.context'

const Select: React.FC = () => {
  const { location: a_location } = useContext(AppContext)
  const { params: to_params} = useContext(TeamOrganizationContext)


  useEffect(() => {
    if(!to_params?.team_organization_id)
      a_location?.set(Tab.Teams)
  }, [])

  if(to_params?.team_organization_id)
    return ( <Table />)

  return (
    <>
      <UIFilters />
      <Table />
    </>
  )
}

export default Select
