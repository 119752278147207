import React, { useContext } from 'react'
// Components
import Accordion from 'components/utils/Accordion/Accordion'
import Table from './Table'
import CTAButton from 'components/utils/Button/CTAButton'
import TouchButton from 'components/utils/Button/TouchButton'
import ReloadButton from 'components/utils/Button/ReloadButton'
import Container from 'components/utils/GridContainer/GridContainer'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'
import { TeamContext } from 'components/Team/__Team.context'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Reload as ReloadHelper } from 'helpers/reload'
import { Data as DataHelper } from 'helpers/data'

const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)
  const { params: s_params } = useContext(SportsmanContext)
  const { params: t_params } = useContext(TeamContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.SportsmanTeamMemberships,
      },
    },
  }

  const touchAll = async () => {
    if (!db?.sportsmanTeamMemberships) return

    await FormHelper.touchAll({
      repository: db.sportsmanTeamMemberships,
      optional: opt,
    })
  }

  const reload = async () => {
    if (!db?.sportsmanTeamMemberships) return

    if (t_params?.team_id)
      return await ReloadHelper.reload({
        getAllFn: () =>
          DataHelper.getSportsmanTeamMembershipsByTeamId(
            db!,
            t_params?.team_id
          ),
        optional: opt,
      })

    if (s_params?.sportsman_id)
      return await ReloadHelper.reload({
        getAllFn: () =>
          DataHelper.getSportsmanTeamMembershipsBySportsmanId(
            db!,
            s_params?.sportsman_id
          ),
        optional: opt,
      })
  }

  const computeRoutingPath = () => {
    const _routingParams = {
      sportsman: s_params?.sportsman_id ?? RoutingHelper.NewRecordSymbol,
      team: t_params?.team_id ?? RoutingHelper.NewRecordSymbol,
      sportsmanTeamMembership: RoutingHelper.NewRecordSymbol,
      sport:
        s_params?.sport_id ??
        t_params?.sport_id ??
        RoutingHelper.NewRecordSymbol,
    }

    if (s_params?.sportsman_id)
      return RoutingHelper.getSportsmanTeamMembershipDetailUrlFromSportsman(
        _routingParams
      )

    if (t_params?.team_id)
      return RoutingHelper.getSportsmanTeamMembershipDetailUrlFromTeam(
        _routingParams
      )
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <CTAButton key={'Container.CTA_New'} path={computeRoutingPath()}>
                New
              </CTAButton>,
              <TouchButton key={'Container.CTA_Touch_All'}>
                Touch All
              </TouchButton>,
              <ReloadButton
                key={'Container.RealoadButton'}
                reloadFn={reload}
                from={opt.alert.texts.form}
              />,
            ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )
}

export default TableWithAdd
