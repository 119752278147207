import React from 'react'
// Components
import TeamStats from 'components/TeamStatSummary/Table/Table'
import SportsmanStats from 'components/SportsmanStatSummary/Table/Table'
// Additional
import { Tab } from 'static/tabs'
import { TabMapper } from 'components/utils/TabsLoader'

export const Mapper: TabMapper = {
  // [Tab.TeamStats]: {
  //   routes: {
  //     [`/${Tab.TeamStats}`]: {
  //       node: <TeamStats />,
  //     },
  //   },
  // },
  // [Tab.SportsmanStats]: {
  //   routes: {
  //     [`/${Tab.SportsmanStats}`]: {
  //       node: <SportsmanStats />,
  //     },
  //   },
  // },
}