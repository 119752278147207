import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { SportRecord } from "./SportRecord";
import { TeamOrganizationRecord } from "./TeamOrganizationRecord";

export class TeamRecord<TJsonData extends TeamRecord.CtorJsonData = TeamRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  team_organization_id?: string
  binding_id?: string
  sport_id?: string
  is_sazka_eleague?: boolean | null
  full_name?: string
  short_name?: string
  logo_url?: string
  country?: string
  photo_url?: string
  sponzored_by_sazka?: boolean | null
  is_clickable?: boolean | null
  data_approved_at?: Date | null
  data_frozen_at?: Date | null
  is_popular?: boolean | null
  popularity_order?: number

  /// >>> sport >>>
  #sport: BaseRecord.RefField<SportRecord> = {}

  get sport() {
    this.#sport.id = this.sport_id

    return this.peekObjectFromRefField(this.#sport, "sports")
  }
  getSport() {
    this.#sport.id = this.sport_id

    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<
  /// >>> Team Organization >>>
  #teamOrganization: BaseRecord.RefField<TeamOrganizationRecord> = {}

  get teamOrganizations() {
    this.#teamOrganization.id = this.team_organization_id

    return this.peekObjectFromRefField(this.#teamOrganization, "teamOrganizations")
  }
  getTeamOrganizations() {
    this.#teamOrganization.id = this.team_organization_id

    return this.getObjectFromRefField(this.#teamOrganization, "teamOrganizations")
  }
  /// <<< Team Organization <<<
  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sport_name() {
    return this.sport?.short_name
  }

  get team_organization_name() {
    return this.teamOrganizations?.name
  }

  get sortValues() {
    return [
      this.full_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamRecord.CtorJsonData) {
    return new TeamRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.team_organization_id = jsonData?.team_organization_id
    this.binding_id = jsonData?.binding_id
    this.sport_id = jsonData?.sport_id
    this.is_sazka_eleague = jsonData?.is_sazka_eleague
    this.full_name = jsonData?.full_name
    this.short_name = jsonData?.short_name
    this.logo_url = jsonData?.logo_url
    this.country = jsonData?.country
    this.photo_url = jsonData?.photo_url
    this.sponzored_by_sazka = jsonData?.sponzored_by_sazka
    this.is_clickable = jsonData?.is_clickable
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.is_popular = jsonData?.is_popular
    this.popularity_order = jsonData?.popularity_order
  }

  toJson():TJsonData{
    return Object.assign(super.toJson(), {
      team_organization_id: this.team_organization_id,
      binding_id: this.binding_id,
      sport_id: this.sport_id,
      is_sazka_eleague: this.is_sazka_eleague,
      full_name: this.full_name,
      short_name: this.short_name,
      logo_url: this.logo_url,
      photo_url: this.photo_url,
      country: this.country,
      sponzored_by_sazka: this.sponzored_by_sazka,
      is_clickable: this.is_clickable,
      data_approved_at: this.data_approved_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      is_popular: this.is_popular,
      popularity_order: this.popularity_order
    }) as TJsonData
  }

}

export module TeamRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    team_organization_id: string
    binding_id?: string
    sport_id?: string
    is_sazka_eleague?: boolean | undefined
    full_name?: string
    short_name?: string
    logo_url?: string
    country?: string
    photo_url?: string
    sponzored_by_sazka?: boolean | undefined
    is_clickable?: boolean | undefined
    data_approved_at?: string
    data_frozen_at?: string
    is_popular?: boolean | undefined
    popularity_order?: number
  }

  export class Repository extends RecordRepository<TeamRecord> {
    /// >>> by sport_id >>>
    #getBySportId:RecordRepository.NestingGetFieldFlags = {}
    getBySportId(sportId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getBySportId, sportId, options, {
        peek: () => this.peekBySportId(sportId),
        load: () => this.loadBySportId(sportId)
      })
    }

    peekBySportId(sportId:string) {
      return this.peekAll(x => x?.sport_id == sportId)
    }

    protected loadBySportId(sportId:string) {
      return this.getApi().getMoreTeamsBySportId(sportId)
    }
    /// <<< by sport_id <<<
    /// >>> by team_organization_id >>>
    #getByTeamOrganizationId:RecordRepository.NestingGetFieldFlags = {}
    getByTeamOrganizationId(teamOrgId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTeamOrganizationId, teamOrgId, options, {
        peek: () => this.peekByTeamOrgId(teamOrgId),
        load: () => this.loadByTeamOrgId(teamOrgId)
      })
    }

    peekByTeamOrgId(teamOrgId:string) {
      return this.peekAll(x => x?.team_organization_id == teamOrgId)
    }

    protected loadByTeamOrgId(teamOrgId:string) {
      return this.getApi().getMoreTeamsByTeamOrgId(teamOrgId)
    }

    /// <<< by team_organization_id <<<

    create(record: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTeam(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneTeamById(id)
    }

    protected updateById(id: string, patch: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeam(id, patch)
    }

    protected deleteById(id: string): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTeam(id)
    }

    protected approveById(id: string, patch: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneTeamById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneTeamById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTeamById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<TeamRecord<CtorJsonData>>): Promise<TeamRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTeamById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTeams()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTeamById(id)
    }
  }
}
