import React from 'react'
import styled from 'styled-components'
// Styles
import { WarningIcon, IconSize } from 'styles/fontAwesome'

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${WarningIcon} {
    margin: 1rem 0;
  }
`

const Warning: React.FC<{ text?: string, size?: IconSize }> = (p) => (
  <WarningWrapper>
    {/* @ts-ignore */}
    <WarningIcon size={p.size ?? 5} />
    {p.text && (
      <span>
        {p.text}
      </span>
    )}
  </WarningWrapper>
)


export default Warning
