import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components'
import { ContentState, convertFromHTML, convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const RichTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 200px;
  width: fill-available;

  margin: 36px 0;
`

const RichTextEditor = (props: {
  text: string
  readonly: boolean | undefined
  onChange?: (text?: string) => void
}) => {
  const contentBlock = htmlToDraft(props.text)
  const originalContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const originalEditorState = EditorState.createWithContent(originalContentState)
  const [editorState, setEditorState] = useState(originalEditorState)
  const _readonly = props.readonly

  useEffect(() => {
    if (!editorState) return

    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    html && props.onChange?.(html)
  }, [editorState])

  return (
    <RichTextWrapper>
      <Editor
        editorState={editorState}
        //defaultContentState={contentState}
        onEditorStateChange={setEditorState}
        wrapperClassName='wrapper-class'
        editorClassName='editor-class'
        toolbarClassName='toolbar-class'
        readOnly={_readonly}
      />
    </RichTextWrapper>
  )
}

export default RichTextEditor
