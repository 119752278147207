import React from 'react'
import styled from 'styled-components'
// Components
import { Form } from 'react-bootstrap'
import Switch from 'react-switch'
// Styles
import { RowContainer, RowContainerProps } from 'styles/common'

const COLOR = {
  GREEN: '#28a745',
  RED: '#dc3545',
  YELLOW: '#fcfcd3',

  DISABLE: '#e9ecef',
}

type Scheme = 'green' | 'red' | 'yellow'

export interface Props extends RowContainerProps {
  readonly title: string

  readonly scheme: Scheme

  readonly disabled?: boolean
  readonly checked?: boolean

  readonly checkedCallback?: () => void
  readonly uncheckedCallback?: () => void
}

const SwitchHelper = {
  getColor: (scheme: Scheme) => {
    switch (scheme) {
      case 'green':
        return COLOR.GREEN
      case 'red':
        return COLOR.RED
      case 'yellow':
        return COLOR.YELLOW

      default:
        return COLOR.GREEN
    }
  },
}

const SwitchButton: React.FC<Props> = (p) => (
  <Wrapper offset={{ ...p.offset, size: p.offset.size ?? 9 }}>
    {p.offset.direction === 'left' && <Label>{p.title}</Label>}
    <Switch
      checked={p.checked ?? false}
      onChange={() => {
        p.checked && p.uncheckedCallback?.()
        !p.checked && p.checkedCallback?.()
      }}
      checkedIcon={false}
      uncheckedIcon={false}
      disabled={p.disabled}
      onColor={p.disabled ? COLOR.DISABLE : SwitchHelper.getColor(p.scheme)}
    />
    {p.offset.direction === 'right' && <Label>{p.title}</Label>}
  </Wrapper>
)

const Wrapper = styled(RowContainer)`
  margin-top: 12px;
  margin-bottom: 12px;

  padding-left: 5px;
  padding-right: 5px;

  display: flex;
  
  .react-switch-bg {
    box-shadow: inset 0px 0px 1px 1px black; // #ced4da
  }

  .react-switch-handle {
    box-shadow: 0px 0px 1px 1px black; // #ced4da
  }
`

const Label = styled(Form.Label)`
  color: #6c757d;
  align-self: center;
  margin-bottom: 0;
`

export default SwitchButton
