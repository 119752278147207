import React from 'react'
// Components
import { Form } from 'react-bootstrap'
import Switch, {
  Props as SwitchProps,
} from 'components/utils/Button/SwitchButton'

interface Props {
  readonly name: string

  readonly value: any

  readonly required?: boolean
  readonly invalid?: boolean
}

const FormCheck: React.FC<Props & SwitchProps> = (p) => (
  <>
    <Form.Control
      hidden
      readOnly
      name={p.name}
      value={p.value}
      isInvalid={p.invalid}
      required={p.required}
    />
    <Switch {...p} />
  </>
)

export default FormCheck
