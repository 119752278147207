import { AppContext } from "App"
import { SportsmanContext } from "components/Sportsmen/__Sportsmen.context"
import TableLoader from "components/utils/Table/Table"
import { SportsmanDatasourceIdentifierRecord } from "data/SportsmanDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const SportsmanDatasourceIdentifiersTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: s_params } = useContext(SportsmanContext)

  const loadData = async () => {
    if (s_params?.sportsman_id)
    return await Data.getSportsmanDatasourceIdentifiersBySportsmanId(
      a_db!,
      s_params?.sportsman_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<SportsmanDatasourceIdentifierRecord>
      reloadFrom={[Tab.SportsmanDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        sportsman: s_params?.sportsman_id,
        sport: s_params?.sport_id
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default SportsmanDatasourceIdentifiersTable
