import React from 'react'
import styled, { css } from 'styled-components'
// Compoenents
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Navbar } from 'react-bootstrap'
// Additional
import { Props as RefProps } from 'types/reactRef'

export const ItemPixelOffset = 18

export const ItemWrapper = styled.div`
  /* margin-bottom: 18px; */
  margin-bottom: ${ItemPixelOffset}px;
`

const NotSelectable = css`
  user-select: none;
  pointer-events: none;
`

export const NotSelectableInput = styled.input`
  ${NotSelectable}
`
const OverflowCSS = css`
  overflow: auto;
`

export const Overflow = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  ${OverflowCSS}
`

export const Center = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FillAvailableWidthCSS = css`
  width: -webkit-fill-available;
  width: -moz-available;
`

export const FillAvailableWidth = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  ${FillAvailableWidthCSS}
`

export const StickyTopCSS = css`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  z-index: 4;
`

export const StickyTop = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  ${StickyTopCSS}
`

export const FillAvailableHeightCSS = css`
  /* height: -webkit-fill-available;
  height: -moz-available; */
  height: 100%; // due to Safari -> 100%
`

export const FillAvailableHeight = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  ${FillAvailableHeightCSS}
`

export const DesktopOnlyCSS = css`
  @media screen and (max-width: 767px) {
    display: none !important;
  }
`

export const MobileOnlyCSS = css`
  @media screen and (min-width: 768px) {
    display: none !important;
  }
`

export const CenteredContent = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  vertical-align: middle !important;
`

export const SelectedColor = '#d0d0d0'

export const SecondaryColor = '#e8e8e8'

export const SelectedBackgroundColor = css`
  background-color: ${SelectedColor};
`

export const SecondaryBackgroundColor = css`
  background-color: ${SecondaryColor};
`

export const OverflowTextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`

export const DropdownInputGroup = styled.div`
  .show .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:active {
    background-color: initial !important;
    color: initial !important;
  }

  .dropdown-toggle {
    ${FillAvailableWidthCSS}

    display: flex;

    text-align: left;

    align-items: center;

    :disabled {
      user-select: all;
    }

    div {
      margin-right: -10px;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-item {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    &:active {
      background-color: initial;
      color: initial;
    }
  }
`

const OffsetedContainerCSS = styled(Col).attrs({
  className: 'offseted-container',
})`
  padding-left: 0;
  padding-right: 0;
`

export const OffsetedContainer = React.forwardRef(
  (props: RefProps, ref: React.Ref<HTMLElement>) => (
    <OffsetedContainerCSS
      xs={12}
      sm={12}
      md={12}
      lg={{ span: 10, offset: 1 }}
      xl={{ span: 8, offset: 2 }}
      style={props.style}
    >
      {props.children}
    </OffsetedContainerCSS>
  )
)
OffsetedContainer.displayName = 'Offseted Container'

const NavbarExtended = css`
  padding-left: 0;
  padding-right: 0;

  border-bottom: 2px solid ${SecondaryColor};
`

export const NavbarLarge = styled(Navbar).attrs({ className: 'navbar-large' })`
  ${NavbarExtended}

  min-height: 110px;
  padding: ${ItemPixelOffset}px;
  margin-bottom: ${ItemPixelOffset}px;
`

export const NavbarSmall = styled(Navbar).attrs({ className: 'navbar-small' })`
  ${NavbarExtended}

  flex-direction: column;
`

interface AlignProps {
  readonly disablePadding?: boolean
}

export const ColAlignLeft = styled(Col)<AlignProps>`
  justify-content: flex-start;

  ${({ disablePadding }) => {
    if (disablePadding) {
      return css`
        padding-left: 0;
      `
    }
  }}
`

export const ColAlignRight = styled(Col)<AlignProps>`
  justify-content: flex-end;

  ${({ disablePadding }) => {
    if (disablePadding) {
      return css`
        padding-right: 0;
      `
    }
  }}
`

export const FormRowAlignRight = styled(Form.Row)`
  justify-content: flex-end;
`

export const FormRowAlignSpaceBetween = styled(Form.Row)`
  justify-content: space-between;
`

export const FormRowAlignBottom = styled(Form.Row)`
  align-items: flex-end;
`

type Direction = 'left' | 'right'

export interface RowContainerProps {
  readonly offset: {
    readonly direction: Direction
    readonly size?: number
  }

  readonly float?: Direction
}

export const RowContainer = styled.div<RowContainerProps>`
  display: inline-flex;

  ${({ float }) => {
    if (!float) return

    return css`
      float: ${float};
    `
  }}

  > * {
    ${({ offset }) => {
      const _size = offset.size ?? ItemPixelOffset

      if (offset.direction === 'left') {
        return css`
          margin-left: ${_size}px;
        `
      } else if (offset.direction === 'right') {
        return css`
          margin-right: ${_size}px;
        `
      }
    }}
  }
`

export const CustomTabsWrapper = styled.nav`
  .nav-item {
    text-transform: capitalize;

    color: #555;

    &.active,
    &:hover {
      transition: border-color 0.5s color 0.3s;

      color: black;
    }
  }
`

export const Divider = styled.div`
  border: 1px solid ${SecondaryColor};

  margin-left: -5px;
  margin-right: -5px;
`
