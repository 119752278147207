import React, { useContext } from 'react'
// Components
import Table from './Table/TableWithAdd'

import { AppContext } from 'App'

const Select: React.FC = () => {
  const { location: a_location } = useContext(AppContext)


  return (
    <>
      <Table />
    </>
  )
}

export default Select
