import React, { useState, useContext, ComponentProps } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import * as S from './ImageGalleryPicker.styles'
import Button from 'components/utils/Button/CTAButton'
// Components
import Loading from 'components/utils/Loading'
// Additional
import { AppContext } from 'App'
import { Data as DataHelper } from 'helpers/data'

interface ItemProps extends S.ImageProps, S.CheckProps {
  readonly onSelectCallback?: () => void
}

const PickerItem: React.FC<ItemProps> = (p) => (
  <S.PickerItemWrapper onClick={p.onSelectCallback}>
    <S.Check {...p} />
    <S.PickerImage {...p} />
  </S.PickerItemWrapper>
)

interface Props {
  readonly actualDir: string
  readonly actualImageString: string

  readonly useMultipleSelect?: {
    readonly divider: string
  }

  readonly onSubmitCallback?: (actualImageString: string) => void
}

const ImageGalleryPicker: React.FC<Props> = (p) => {
  const [content, setContent] = useState<_Props>()

  const { db: a_db, alert: a_alert } = useContext(AppContext)

  useAsyncEffect(async () => {
    if (!a_db) return

    const data = await DataHelper.getAzureBlobs(
      a_db!,
      'kentico-media',
      p.actualDir,
      {
        alert: a_alert,
      }
    )

    if (!data) return

    setContent({
      items: data.map((x, idx) => ({
        src: x.url,
        selected: p.useMultipleSelect
          ? p.actualImageString
              .split(p.useMultipleSelect.divider)
              .some((x2) => x2 == x?.url) // some returns true for one predicate occurence
          : p.actualImageString == x?.url,
        onSelectCallback: () =>
          setContent((prev) => ({
            ...prev,
            items: prev?.items?.map((x2, idx2) => ({
              ...x2,
              selected:
                idx == idx2 // -> targeted item
                  ? !x2.selected // -> targeted item toggle
                  : p.useMultipleSelect
                  ? x2.selected // -> multi keeps all items
                  : false, // -> single unselects all items
            })),
          })),
      })),
    })
  }, [p.actualDir])

  if (!content) return <Loading />

  return (
    <_ImageGalleryPicker
      {...content}
      onSubmitCallback={() => {
        const selectedItems = content.items?.filter((x) => x.selected)

        const output = p.useMultipleSelect
          ? selectedItems?.reduce(
              (acc, val) =>
                // {prev_value}{divider}{new_value}
                `${acc}${
                  acc.length > 0 ? p.useMultipleSelect?.divider ?? '|' : ''
                }${val.src}`,
              ''
            )
          : selectedItems?.[0]?.src

        p.onSubmitCallback?.(output ?? '')
      }}
    />
  )
}

interface _Props {
  readonly items?: Array<ComponentProps<typeof PickerItem>>

  readonly onSubmitCallback?: () => void
}

export const _ImageGalleryPicker: React.FC<_Props> = (p) => (
  <S.GalleryPicker>
    <S.PickerContainer>
      {p.items?.map((x, idx) => (
        <PickerItem key={`PickerItem_${idx}`} {...x} />
      ))}
    </S.PickerContainer>
    <Button onClick={p.onSubmitCallback} theme={'white'}>
      Save
    </Button>
  </S.GalleryPicker>
)

export default ImageGalleryPicker
