import { AppContext } from 'App'
import React, { useContext } from 'react'
import { Tab } from 'static/tabs'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Reload as ReloadHelper } from 'helpers/reload'
import { Data as DataHelper } from 'helpers/data'
import Accordion from 'components/utils/Accordion/Accordion'
import Container from 'components/utils/GridContainer/GridContainer'
import CTAButton from 'components/utils/Button/CTAButton'
import Table from './Table'
import ReloadButton from 'components/utils/Button/ReloadButton'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'

const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)
  const { params: s_params } = useContext(SportsmanContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.SportsmanDatasourceIdentifiers,
      },
    },
  }

  const touchAll = async () => {
    if (!db?.gamingGears) return

    await FormHelper.touchAll({
      repository: db.sportsmanDatasourceIdentifiers,
      optional: opt,
    })
  }

  const reload = async () => {
    if (!db?.sportsmanDatasourceIdentifiers) return

    if (s_params?.sport_id)
    return await ReloadHelper.reload({
      getAllFn: () => DataHelper.getSportsmanDatasourceIdentifiersBySportsmanId(
        db,
        s_params.sport_id
      ),
      optional: opt
    })
  }

  const computeRoutingPath = () => {
    const _routingParams = {
      sportsmanDatasourceIdentifier: RoutingHelper.NewRecordSymbol,
      sportsman: s_params?.sportsman_id ?? RoutingHelper.NewRecordSymbol,
      sport: s_params?.sport_id ?? RoutingHelper.NewRecordSymbol
    }

    if (s_params?.sport_id)
      return RoutingHelper.getSportsmanDatasourceIdentifierUrlFromSportsman(
        _routingParams
      )
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <CTAButton
                key={'Container.CTA_New'}
                path={computeRoutingPath()}
              >
                New
              </CTAButton>,
              <ReloadButton
              key={'Container.RealoadButton'}
              reloadFn={reload}
              from={opt.alert.texts.form}
            />
          ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )
}

export default TableWithAdd
