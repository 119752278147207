import styled from 'styled-components'
// Additional
import { SelectedColor } from 'styles/common'

const PaginationItemPixelWidth = 38

export const CustomPaginationWrapper = styled.div`
  .pagination {
    a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      transition: background-color 0.3s;
    }

    .page-item {
      &.active span {
        background-color: ${SelectedColor};
        color: black !important;

        &.page-link {
          border: unset !important;
        }
      }

      .page-link {
        user-select: none;

        width: ${PaginationItemPixelWidth}px !important;
        height: ${PaginationItemPixelWidth}px !important;

        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        border: unset !important;
        margin-left: unset !important;
        text-align: center !important;
      }
    }
  }
`