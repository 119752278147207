import React, { ComponentProps, useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import FormDatePicker from 'components/utils/Form/Picker/DatePicker'
import FormCheck from 'components/utils/FormCheck'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import {
  ItemWrapper,
  OffsetedContainer,
  FormRowAlignRight,
  RowContainer,
} from 'styles/common'
// Additional
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { SportsmanRecord } from 'data/SportsmanRecord'
import { TournamentRecord } from 'data/TournamentRecord'
import { AppContext } from 'App'
import { SportsmanTeamMembershipContext } from 'components/SportsmanTeamMemberships/__SportsmanTeamMemberships.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'
import { TeamRecord } from 'data/TeamRecord'

const SubmitKeys = [
  'team_id',
  'sportsman_id',
  'first_match_at',
  'last_match_at',
  'is_current',
  'is_last',
] as Array<keyof SportsmanTeamMembershipRecord>

type ValidationKeys = Record<
  keyof Pick<SportsmanTeamMembershipRecord, 'team_id' | 'sportsman_id'>,
  any
>

const SportmanTeamMembershipForm: React.FC<
  FormHelper.Props<SportsmanTeamMembershipRecord>
> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: stm_params } = useContext(SportsmanTeamMembershipContext)

  const [form, setForm] = useState<{
    data?: SportsmanTeamMembershipRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Sportsman team membership',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      team_id: form.data?.team?.id,
      team_text: form.data?.team?.full_name,

      sportsman_id: form.data?.sportsman?.id,
      sportsman_text: form.data?.sportsman_nickname,
    },

    onSubmit: async (values) => {
      if (!db?.sportsmanTeamMemberships) return

      try {
        const data =
          await FormHelper.submitChanges<SportsmanTeamMembershipRecord>({
            action: form.state === 'new' ? 'create' : 'update',
            keys: SubmitKeys,
            data: Object.assign(
              new SportsmanTeamMembershipRecord(),
              values ?? {}
            ),
            repository: db.sportsmanTeamMemberships,
            optional: opt,
          })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          const computeRoutingPath = () => {
            const _routingParams = {
              sportsman:
                stm_params?.sportsman_id ?? RoutingHelper.NewRecordSymbol,
              team: stm_params?.team_id ?? RoutingHelper.NewRecordSymbol,
              sportsmanTeamMembership: RoutingHelper.NewRecordSymbol,
              sport: RoutingHelper.NewRecordSymbol,
            }

            if (stm_params?.sportsman_id)
              return RoutingHelper.getSportsmanTeamMembershipDetailUrlFromSportsman(
                _routingParams
              )

            if (stm_params?.team_id)
              return RoutingHelper.getSportsmanTeamMembershipDetailUrlFromTeam(
                _routingParams
              )
          }

          history?.replace(computeRoutingPath())
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      team_id: Yup.string().required('Team is a required field'),
      sportsman_id: Yup.string().required('Sportsman is a required field'),
    }),
  })

  const freeze = async () => {
    if (!db?.sportsmanTeamMemberships) return

    await FormHelper.freeze({
      data: Object.assign(
        new SportsmanTeamMembershipRecord(),
        formik.values ?? {}
      ),
      repository: db.sportsmanTeamMemberships,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.sportsmanTeamMemberships) return

    await FormHelper.unfreeze({
      data: Object.assign(
        new SportsmanTeamMembershipRecord(),
        formik.values ?? {}
      ),
      repository: db.sportsmanTeamMemberships,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.sportsmanTeamMemberships) return

    await FormHelper.delete_({
      data: Object.assign(
        new SportsmanTeamMembershipRecord(),
        formik.values ?? {}
      ),
      repository: db.sportsmanTeamMemberships,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.sportsmanTeamMemberships) return

    await FormHelper.touch({
      data: Object.assign(
        new SportsmanTeamMembershipRecord(),
        formik.values ?? {}
      ),
      repository: db.sportsmanTeamMemberships,
      optional: opt,
    })
  }

  const [sportsmen, setSportsmen] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.sportsman_id ?? '',
      text: formik.values.sportsman_text,
    },
    loaded: false,
  })

  const [teams, setTeams] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team_id ?? '',
      text: formik.values.team_text,
    },
    loaded: false,
  })

  const _setSportsmen = async () => {
    const _getSportsmanItem = (record: SportsmanRecord): Item =>
      ({
        id: record.id ?? '',
        text: record.nickname ?? '',
        color: !record.isApproved ? 'red' : null,
      } as Item)

    const _getData = async () => {
      if (stm_params?.sport_id) {
        return await DataHelper.getSportsmenBySportId(
          db!,
          stm_params?.sport_id
        )
      }

      return null
    }

    const _data = (await _getData())?.map((s) => _getSportsmanItem(s))

    setSportsmen((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data,
    }))

    return _data
  }

  const _setTeams = async () => {
    const _getTeamItem = (record: TeamRecord): Item =>
      ({
        id: record.id ?? '',
        text: record.short_name ?? '',
      } as Item)

    const _getData = async () => {
      const _sportId = stm_params?.sport_id

      if (!_sportId) return

      const _sport = await DataHelper.getSportById(db!, _sportId)

      if (!_sport?.id) return

      const _sports = await DataHelper.getAllSports(
        db!
      )

      if (!_sports) return

      const _outTeams = Array<TeamRecord>()

      for (const l of _sports) {
        const _teams = await DataHelper.getTeamsBySportId(db!, l.id!)

        _teams && _outTeams.push(..._teams)
      }

      return _outTeams
    }

    const _data = (await _getData())?.map((s) => _getTeamItem(s))

    setTeams((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data,
    }))

    return _data
  }

  const handleSportsmanChange = (item: Item) => {
    _setSportsman(item)
  }

  const _setSportsman = (item: Item) => {
    formik.setFieldValue(
      'sportsman_id' as keyof SportsmanTeamMembershipRecord,
      item.id
    )

    setSportsmen((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleTeamChange = (item: Item) => {
    _setTeam(item)
  }

  const _setTeam = (item: Item) => {
    formik.setFieldValue(
      'team_id' as keyof SportsmanTeamMembershipRecord,
      item.id
    )

    setTeams((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  useEffect(() => {
    const __setSportsmen__ = async () => {
      const _sportsmen = await _setSportsmen()

      const _findItem = _sportsmen?.find(
        (d) => d.id === stm_params?.sportsman_id ?? form.data?.sportsman_id
      )

      _findItem && _setSportsman(_findItem)
    }

    const __setTeams__ = async () => {
      const _teams = await _setTeams()

      const _findItem = _teams?.find(
        (d) => d.id === stm_params?.team_id ?? form.data?.team_id
      )

      _findItem && _setTeam(_findItem)
    }

    __setSportsmen__()
    __setTeams__()
  }, [])

  //#region Form Components
  const _Sportsman = (
    <FormItem
      label={<Form.Label>Sportsman</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'sportsman_id' as keyof SportsmanTeamMembershipRecord}
            items={sportsmen.data ?? []}
            onSelect={handleSportsmanChange}
            value={sportsmen.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.sportsman_id}
            disabled={p.locked?.value}
            loading={!sportsmen.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.sportsman_id &&
                  RoutingHelper.getSportsmanDetailUrl({
                    sport: RoutingHelper.NewRecordSymbol,
                    sportsman: formik.values.sportsman_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.sportsman_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Team = (
    <FormItem
      label={<Form.Label>Team</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'team_id' as keyof SportsmanTeamMembershipRecord}
            items={teams.data ?? []}
            onSelect={handleTeamChange}
            value={teams.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.team_id}
            disabled={p.locked?.value}
            loading={!teams.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.team_id &&
                  RoutingHelper.getTeamDetailUrl({
                    sport: RoutingHelper.NewRecordSymbol,
                    team: formik.values.team_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.team_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _FirstMatchAt = (
    <FormItem
      label={<Form.Label>First Match At</Form.Label>}
      input={
        <FormDatePicker
          name={'first_match_at' as keyof SportsmanTeamMembershipRecord}
          value={formik.values.first_match_at ?? undefined}
          isInvalid={formik.submitCount > 0 && !!formik.errors.first_match_at}
          onChangeCallback={(date: Date | null) => {
            formik.setFieldValue(
              'first_match_at' as keyof SportsmanTeamMembershipRecord,
              date
            )
          }}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _LastMatchAt = (
    <FormItem
      label={<Form.Label>Last Match At</Form.Label>}
      input={
        <FormDatePicker
          name={'last_match_at' as keyof SportsmanTeamMembershipRecord}
          value={formik.values.last_match_at ?? undefined}
          isInvalid={formik.submitCount > 0 && !!formik.errors.last_match_at}
          onChangeCallback={(date: Date | null) => {
            formik.setFieldValue(
              'last_match_at' as keyof SportsmanTeamMembershipRecord,
              date
            )
          }}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _IsCurrent = (
    <FormCheck
      name={'is_current' as keyof SportsmanTeamMembershipRecord}
      value={formik.values.is_current ?? false}
      checked={formik.values.is_current ?? false}
      offset={{ direction: 'left' }}
      title={'Current'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue(
          'is_current' as keyof SportsmanTeamMembershipRecord,
          true
        )
      }}
      uncheckedCallback={() => {
        formik.setFieldValue(
          'is_current' as keyof SportsmanTeamMembershipRecord,
          false
        )
      }}
      disabled={p.locked?.value}
    />
  )

  const _IsLast = (
    <FormCheck
      name={'is_last' as keyof SportsmanTeamMembershipRecord}
      value={formik.values.is_last ?? false}
      checked={formik.values.is_last ?? false}
      offset={{ direction: 'left' }}
      title={'Last'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue(
          'is_last' as keyof SportsmanTeamMembershipRecord,
          true
        )
      }}
      uncheckedCallback={() => {
        formik.setFieldValue(
          'is_last' as keyof SportsmanTeamMembershipRecord,
          false
        )
      }}
      disabled={p.locked?.value}
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          freeze: freeze,
          unfreeze: unfreeze,
          delete: delete_,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_Sportsman}</Col>
          </Form.Row>
          <Form.Row>
            <Col as={ItemWrapper}>{_Team}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper} lg={6}>
              {_FirstMatchAt}
            </Col>
            <Col as={ItemWrapper} lg={6}>
              {_LastMatchAt}
            </Col>
          </Form.Row>

          <FormRowAlignRight>
            <RowContainer offset={{ direction: 'left' }}>
              {_IsCurrent}
              {_IsLast}
            </RowContainer>
          </FormRowAlignRight>

          <ItemWrapper />

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportmanTeamMembershipForm
