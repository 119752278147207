import { AppContext } from "App"
import TableLoader from "components/utils/Table/Table"
import { TeamOrganizationRecord } from "data/TeamOrganizationRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'


const TeamOrganizationsTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const loadData = async () => {
    return await Data.getAllTeamOrganizations(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<TeamOrganizationRecord>
      reloadFrom={[Tab.TeamOrganizations]}
      loadDataFn={loadData}
      settings={Settings}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TeamOrganizationsTable
