import { AppContext } from "App"
import React,  { useContext, useEffect, useState } from "react"
import { NearestMatchesContext } from "./__NearestMatches.context"
import { Routing as RoutingHelper } from 'helpers/routing'
import { String as StringHelper } from 'helpers/string'
import { Data as DataHelper } from 'helpers/data'
import { NearestMatchRecord } from "data/NearesMatchRecord"
import useAsyncEffect from "use-async-effect"
import { Tab } from "static/tabs"
import { Text } from 'static/text'
import Warning from "components/utils/Warning"
import Loading from "components/utils/Loading"
import { ItemWrapper } from "styles/common"
import NearestMatchForm from "./Form"

const Detail: React.FC = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    locked: a_locked,
    location: a_location,
  } = useContext(AppContext)

  const { params: m_params } = useContext(NearestMatchesContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    m_params?.nearest_match_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //

  const [content, setContent] = useState<{
    data?: NearestMatchRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !m_params?.nearest_match_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true }}))

    try{
      const _data = await DataHelper.getNearesMatchById(a_db!, m_params.nearest_match_id, {
        alert: a_alert
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false }
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true }
      }))
    }
  }, [m_params])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: NearestMatchRecord) => {
    a_location?.set?.(
      Tab.NearestMatches,
      data && `${StringHelper.spaceTo(`[${data?.order_number}] ${data?.match_id ?? ''} ${data?.team_1_name ?? 'team 1'} vs ${data?.team_2_name ?? 'team 2'}`)}`
    )
  }

  useEffect(() => {
    _setLocation(content?.data)

    content?.data && a_locked?.set(!!content?.data?.data_frozen_at)
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <NearestMatchForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
          submitCallback={_setLocation}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
