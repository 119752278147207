import React, { ComponentProps, useContext, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import FormCheck from 'components/utils/FormCheck'
import FormDateTimePicker from 'components/utils/Form/Picker/DateTimePicker'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import {
  FormRowAlignRight,
  RowContainer,
  ItemWrapper,
  OffsetedContainer,
} from 'styles/common'
// Additional
import { MatchRecord } from 'data/MatchRecord'
import { AppContext } from 'App'
import { MatchContext } from './__Matches.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'
import RichTextEditor from 'components/RichTextEditor'

const SubmitKeys = [
  'tournament_id',
  'box',
  'binding_id',
  'datasource_id',
  'team1_id',
  'team2_id',
  'team1_name',
  'team2_name',
  'tournament_round_id',
  'team1_score_edit',
  'team2_score_edit',
  'team1_league_points_edit',
  'team2_league_points_edit',
  'starts_at_edit',
  'started_at_edit',
  'finished_at_edit',
  'canceled_at_edit',
  'box_edit',
  'ignore_league_points',
  'youtube_code',
  'match_text_summary',
] as Array<keyof MatchRecord>

type ValidationKeys = Record<
  keyof Pick<MatchRecord, 'tournament_round_id'>,
  any
>

const MatchForm: React.FC<FormHelper.Props<MatchRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: m_params } = useContext(MatchContext)
  const { params: t_params } = useContext(TournamentContext)

  const [form, setForm] = useState<{
    data?: MatchRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Match',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      ignore_league_points: form.data?.ignore_league_points,

      tournament_id: form.data?.tournament?.id ?? m_params?.tournament_id,

      tournament_round_id: form.data?.tournamentRound?.id,
      tournament_round_text: form.data?.tournamentRound?.name,

      team1_score_edit: form.data?.team1_score_edit,
      team2_score_edit: form.data?.team2_score_edit,
      team1_league_points_edit: form.data?.team1_league_points_edit,
      team2_league_points_edit: form.data?.team2_league_points_edit,
      starts_at_edit: form.data?.starts_at_edit,
      started_at_edit: form.data?.started_at_edit,
      finished_at_edit: form.data?.finished_at_edit,
      canceled_at_edit: form.data?.canceled_at_edit,
      box_edit: form.data?.box_edit,
    },

    onSubmit: async (values) => {
      if (!db?.matches) return

      try {
        const data = await FormHelper.submitChanges<MatchRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new MatchRecord(), values ?? {}),
          repository: db.matches,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getMatchDetailUrl({
              tournament:
                t_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              match: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      tournament_round_id: Yup.string().required('Round is a required field'),
    }),
  })

  const approve = async () => {
    if (!db?.matches) return

    await FormHelper.approve({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.matches) return

    await FormHelper.unapprove({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const freeze = async () => {
    if (!db?.matches) return

    await FormHelper.freeze({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.matches) return

    await FormHelper.unfreeze({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.matches) return

    await FormHelper.delete_({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.matches) return

    await FormHelper.touch({
      data: Object.assign(new MatchRecord(), formik.values ?? {}),
      repository: db.matches,
      optional: opt,
    })
  }

  const team1DefaultItem: Item = { id: null, text: '&nbsp;' }

  const [teams1, setTeams1] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team1_id ?? '',
      text: formik.values.team1_name,
    },
    loaded: false,
  })

  const team2DefaultItem: Item = { id: null, text: '&nbsp;' }

  const [teams2, setTeams2] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team2_id ?? '',
      text: formik.values.team2_name,
    },
    loaded: false,
  })

  const [rounds, setRounds] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.tournament_round_id ?? '',
      text: formik.values.tournament_round_text,
    },
    loaded: false,
  })

  const _setTeams = async () => {
    const _data = await DataHelper.getTeamsByTournamentId(
      db!,
      form.data?.tournament?.id ?? m_params?.tournament_id ?? '-'
    )

    const _mappedData = _data?.map((s) => {
      return { id: s.id ?? '', text: s.full_name } as Item
    })

    setTeams1((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _mappedData,
    }))

    setTeams2((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _mappedData,
    }))
  }

  const _setRounds = async () => {
    const _data = await DataHelper.getRoundsByTournamentId(
      db!,
      form.data?.tournament?.id ?? m_params?.tournament_id ?? '-'
    )

    setRounds((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.name } as Item
      }),
    }))
  }

  const _setTeam1 = (item: Item) => {
    formik.setFieldValue('team1_id' as keyof MatchRecord, item.id)
    formik.setFieldValue('team1_name' as keyof MatchRecord, item.text)

    setTeams1((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setTeam2 = (item: Item) => {
    formik.setFieldValue('team2_id' as keyof MatchRecord, item.id)
    formik.setFieldValue('team2_name' as keyof MatchRecord, item.text)

    setTeams2((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setRound = (item: Item) => {
    formik.setFieldValue('tournament_round_id' as keyof MatchRecord, item.id)

    setRounds((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleRoundChange = (item: Item) => {
    _setRound(item)
  }

  const handleTeam1Change = (item: Item) => {
    _setTeam1(item)
  }

  const handleTeam2Change = (item: Item) => {
    _setTeam2(item)
  }

  useEffect(() => {
    _setRounds()
    _setTeams()
  }, [])

  //#region Form Components
  const _Round = (
    <FormItem
      label={<Form.Label>Round</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'tournament_round_id' as keyof MatchRecord}
            items={rounds.data ?? []}
            onSelect={handleRoundChange}
            value={rounds.value}
            required
            isInvalid={
              formik.submitCount > 0 && !!formik.errors.tournament_round_id
            }
            disabled={p.locked?.value}
            loading={!rounds.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.tournament_round_id &&
                  RoutingHelper.getRoundDetailUrl({
                    tournament: RoutingHelper.NewRecordSymbol,
                    round: formik.values.tournament_round_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.tournament_round_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Team1Name = (
    <FormItem
      label={<Form.Label>Team 1 Name</Form.Label>}
      input={
        <FormDropdown
          name={'team1_id' as keyof MatchRecord}
          defaultItem={team1DefaultItem}
          items={teams1.data ?? []}
          onSelect={handleTeam1Change}
          value={teams1.value}
          isInvalid={formik.submitCount > 0 && !!formik.errors.team1_id}
          disabled={p.locked?.value}
          disableId={[teams2.value?.id ?? '']}
          loading={!teams1.loaded}
          append={
            <LinkToButton
              path={
                formik.values.team1_id &&
                RoutingHelper.getTeamDetailUrl({
                  sport: RoutingHelper.NewRecordSymbol,
                  team: formik.values.team1_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _Team2Name = (
    <FormItem
      label={<Form.Label>Team 2 Name</Form.Label>}
      input={
        <FormDropdown
          name={'team2_id' as keyof MatchRecord}
          defaultItem={team2DefaultItem}
          items={teams2.data ?? []}
          onSelect={handleTeam2Change}
          value={teams2.value}
          isInvalid={formik.submitCount > 0 && !!formik.errors.team2_id}
          disabled={p.locked?.value}
          disableId={[teams1.value?.id ?? '']}
          loading={!teams2.loaded}
          append={
            <LinkToButton
              path={
                formik.values.team2_id &&
                RoutingHelper.getTeamDetailUrl({
                  sport: RoutingHelper.NewRecordSymbol,
                  team: formik.values.team2_id,
                })
              }
            />
          }
        />
      }
    />
  )

  const _Tournament = (
    <Form.Control
      name={'tournament_id' as keyof MatchRecord}
      value={formik.values.tournament_id}
      hidden
    />
  )

  const _Team1ScoreOrig = (
    <FormItem
      label={<Form.Label>Team 1 Score Orig</Form.Label>}
      input={
        <Form.Control
          name={'team1_score_orig' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team1_score_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team1ScoreEdit = (
    <FormItem
      label={<Form.Label>Team 1 Score Edit</Form.Label>}
      input={
        <Form.Control
          name={'team1_score_edit' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team1_score_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team2ScoreOrig = (
    <FormItem
      label={<Form.Label>Team 2 Score Orig</Form.Label>}
      input={
        <Form.Control
          name={'team2_score_orig' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team2_score_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team2ScoreEdit = (
    <FormItem
      label={<Form.Label>Team 2 Score Edit</Form.Label>}
      input={
        <Form.Control
          name={'team2_score_edit' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team2_score_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team1LeaguePointsOrig = (
    <FormItem
      label={<Form.Label>Team 1 League Points Orig</Form.Label>}
      input={
        <Form.Control
          name={'team1_league_points_orig' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team1_league_points_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team1LeaguePointsEdit = (
    <FormItem
      label={<Form.Label>Team 1 League Points Edit</Form.Label>}
      input={
        <Form.Control
          name={'team1_league_points_edit' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team1_league_points_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team2LeaguePointsOrig = (
    <FormItem
      label={<Form.Label>Team 2 League Points Orig</Form.Label>}
      input={
        <Form.Control
          name={'team2_league_points_orig' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team2_league_points_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team2LeaguePointsEdit = (
    <FormItem
      label={<Form.Label>Team 2 League Points Edit</Form.Label>}
      input={
        <Form.Control
          name={'team2_league_points_edit' as keyof MatchRecord}
          type={'number'}
          value={formik.values.team2_league_points_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _StartsAtOrig = (
    <FormItem
      label={<Form.Label>Starts At Orig</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'starts_at_orig' as keyof MatchRecord}
            value={formik.values.starts_at_orig ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('starts_at_orig' as keyof MatchRecord, date)
            }}
            readOnly
          />
        </>
      }
    />
  )

  const _StartsAtEdit = (
    <FormItem
      label={<Form.Label>Starts At Edit</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'starts_at_edit' as keyof MatchRecord}
            value={formik.values.starts_at_edit ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('starts_at_edit' as keyof MatchRecord, date)
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _StartedAtOrig = (
    <FormItem
      label={<Form.Label>Started At Orig</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'started_at_orig' as keyof MatchRecord}
            value={formik.values.started_at_orig ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('started_at_orig' as keyof MatchRecord, date)
            }}
            readOnly
          />
        </>
      }
    />
  )

  const _StartedAtEdit = (
    <FormItem
      label={<Form.Label>Started At Edit</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'started_at_edit' as keyof MatchRecord}
            value={formik.values.started_at_edit ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue('started_at_edit' as keyof MatchRecord, date)
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _FinishedAtOrig = (
    <FormItem
      label={<Form.Label>Finished At Orig</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'finished_at_orig' as keyof MatchRecord}
            value={formik.values.finished_at_orig ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'finished_at_orig' as keyof MatchRecord,
                date
              )
            }}
            readOnly
          />
        </>
      }
    />
  )

  const _FinishedAtEdit = (
    <FormItem
      label={<Form.Label>Finished At Edit</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'finished_at_edit' as keyof MatchRecord}
            value={formik.values.finished_at_edit ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'finished_at_edit' as keyof MatchRecord,
                date
              )
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _CanceledAtOrig = (
    <FormItem
      label={<Form.Label>Canceled At Orig</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'canceled_at_orig' as keyof MatchRecord}
            value={formik.values.canceled_at_orig ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'canceled_at_orig' as keyof MatchRecord,
                date
              )
            }}
            readOnly
          />
        </>
      }
    />
  )

  const _CanceledAtEdit = (
    <FormItem
      label={<Form.Label>Canceled At Edit</Form.Label>}
      input={
        <>
          <FormDateTimePicker
            name={'canceled_at_edit' as keyof MatchRecord}
            value={formik.values.canceled_at_edit ?? undefined}
            onChangeCallback={(date: Date | null) => {
              formik.setFieldValue(
                'canceled_at_edit' as keyof MatchRecord,
                date
              )
            }}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _BindingId = (
    <FormItem
      label={<Form.Label>Binding-ID</Form.Label>}
      input={
        <Form.Control
          name={'binding_id' as keyof MatchRecord}
          value={formik.values.binding_id ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _DatasourceId = (
    <FormItem
      label={<Form.Label>Datasource-ID</Form.Label>}
      input={
        <Form.Control
          name={'datasource_id' as keyof MatchRecord}
          value={formik.values.datasource_id ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _YoutubeCode = (
    <FormItem
      label={<Form.Label>Youtube-Code</Form.Label>}
      input={
        <Form.Control
          name={'youtube_code' as keyof MatchRecord}
          value={formik.values.youtube_code ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
          />
      }
    />
  )

  const _TextSummaryEditor = (
    <FormItem
      label={<Form.Label>Text-Summary</Form.Label>}
      input={
        <>
          <ItemWrapper/>
          <RichTextEditor
            text={formik.values.match_text_summary ?? ''}
            readonly={p.locked?.value}
            onChange={(text?: string) => {
              formik.setFieldValue('match_text_summary' as keyof MatchRecord, text)
            }}
          />
        </>
      }
    />
  )

  const _BoxOrig = (
    <FormItem
      label={<Form.Label>Box Orig</Form.Label>}
      input={
        <Form.Control
          name={'box_orig' as keyof MatchRecord}
          type={'number'}
          value={formik.values.box_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _BoxEdit = (
    <FormItem
      label={<Form.Label>Box Edit</Form.Label>}
      input={
        <Form.Control
          name={'box_edit' as keyof MatchRecord}
          type={'number'}
          value={formik.values.box_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _IgnoreLeaguePoints = (
    <FormCheck
      name={'ignore_league_points' as keyof MatchRecord}
      value={formik.values.ignore_league_points ?? false}
      checked={formik.values.ignore_league_points ?? false}
      offset={{ direction: 'left' }}
      title={'Ignore League Points'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue('ignore_league_points' as keyof MatchRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('ignore_league_points' as keyof MatchRecord, false)
      }}
      disabled={p.locked?.value}
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* ----------- Hidden Inputs Section ----------- */}
          {_Tournament}
          {/* --------------------------------------------- */}

          <Header {..._ComputeHeaderProps()} />

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team1Name}
              </Col>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_Team2Name}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                {_Round}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
              {_YoutubeCode}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                as={ItemWrapper}
              >
              {_TextSummaryEditor}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <hr />
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_Team1ScoreOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_Team1ScoreEdit}
              </Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_Team2ScoreOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_Team2ScoreEdit}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_Team1LeaguePointsOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_Team1LeaguePointsEdit}
              </Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_Team2LeaguePointsOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_Team2LeaguePointsEdit}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_StartsAtOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_StartsAtEdit}
              </Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_StartedAtOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_StartedAtEdit}
              </Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_FinishedAtOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_FinishedAtEdit}
              </Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col
                  as={ItemWrapper}
                  xs={{ span: 12 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 6 }}
                >
                  {_CanceledAtOrig}
                </Col>
              )}
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: form.state === 'new' ? 12 : 6 }}
              >
                {_CanceledAtEdit}
              </Col>
            </Form.Row>
          </ItemWrapper>

          <Form.Row>
            {form.state === 'existing' && (
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
              >
                {_BoxOrig}
              </Col>
            )}
            <Col
              as={ItemWrapper}
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: form.state === 'new' ? 12 : 6 }}
            >
              {_BoxEdit}
            </Col>
          </Form.Row>
          <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                {_BindingId}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                as={ItemWrapper}
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                {_DatasourceId}
              </Col>
            </Form.Row>

          <FormRowAlignRight>
            <RowContainer offset={{ direction: 'left' }}>
              {_IgnoreLeaguePoints}
            </RowContainer>
          </FormRowAlignRight>

          <ItemWrapper />

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default MatchForm
