import { css } from 'styled-components'
// Styles
import * as stls from 'styles/common'
import { TableRowStyle as _TableRowStyle } from 'components/utils/Table/_Table.styles'

export const TableHeadStyle = css`
  ${stls.SecondaryBackgroundColor}
`

export const TableRowStyle = css`
  text-align: center;

  ${_TableRowStyle}
`

export const TableCellStyle = css`
  ${stls.CenteredContent}
`
