import React, { useContext } from 'react'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'
import TabsLoader from 'components/utils/TabsLoader'
import { Mapper } from './Tabs.mapper'

const Tabs: React.FC = () => {
  const { history } = useContext(AppContext)

  return (
    <TabsLoader mapper={Mapper} defaultTab={Tab.TeamBiographies} history={history} />
  )
}

export default Tabs
