export module Compare {
  export function getChanges<T extends any>(
    origValues: any,
    compValues: any,
    keys: Array<keyof T>
  ): Partial<T> {
    const changes: Partial<T> = {}
    for (const key of keys) {
      const cVal = compValues[key]
      // if (cVal == null) continue
      const comparableNewValue = cVal instanceof Date ? cVal.getTime() : cVal
  
      const oValue = origValues[key]
      const comparableOrigValue =
        oValue instanceof Date ? oValue.getTime() : oValue
  
      if (
        JSON.stringify(comparableNewValue ?? undefined) ===
        JSON.stringify(comparableOrigValue ?? undefined)
      ) continue

      (changes as any)[key] = cVal
    }
  
    return changes
  }
  
  /** Sort comparer (typical usage -> javascript .sort() function)
   * @param a
   * @param b
   * @param isNullLast
   * @returns {number} -1|1 - swap values | 0 - values are identical
   */
  export const getAscendingSortCompare = (
    a: any,
    b: any,
    isNullLast?: boolean
  ): number => {
    const _nullValue =
      isNullLast ?? true ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER

    // Transfer null to unreachable value, to keep null as the lowest/highest value
    a = Array.isArray(a) ? a.map((_a) => _a ?? _nullValue) : [a ?? _nullValue]
    b = Array.isArray(b) ? b.map((_b) => _b ?? _nullValue) : [b ?? _nullValue]

    for (let i = 0; i < a.length && b.length; ++i) {
      if (a[i] < b[i]) return -1
      if (a[i] > b[i]) return 1
    }

    return 0
  }
}
