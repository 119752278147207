import React from 'react'
import { usePagination } from '@material-ui/lab/Pagination'
// Components
import { Pagination as BPagiantion } from 'react-bootstrap'
// Styles
import { CustomPaginationWrapper } from './Pagination.styles'

interface Props {
  readonly previousPageFn: () => void
  readonly nextPageFn: () => void
  readonly gotoPageFn: (pageIndex: number) => void

  readonly pageIndex: number
  readonly pageLength: number
}

const Pagination: React.FC<
  Props & { syncKey?: string; urlHrefFn?: (page: string) => string }
> = (p) => {
  const { items } = usePagination({
    count: p.pageLength,
    page: p.pageIndex + 1,
  })

  return (
    <CustomPaginationWrapper>
      <BPagiantion>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <BPagiantion.Ellipsis key={`${index}_${p.syncKey}`} disabled />
            )
          } else if (type === 'page') {
            children = (
              <BPagiantion.Item
                {...item}
                key={`${index}_${p.syncKey}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (e.button == 0) {
                    e.preventDefault()
                  }

                  p.gotoPageFn(page - 1)
                }}
                active={selected}
                href={p.urlHrefFn?.(page.toString())}
              >
                {page}
              </BPagiantion.Item>
            )
          } else {
            switch (type) {
              case 'next': {
                children = (
                  <BPagiantion.Next
                    {...item}
                    key={`${index}_${p.syncKey}`}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      if (e.button == 0) {
                        e.preventDefault()
                      }
    
                      p.nextPageFn()
                    }}
                    href={p.urlHrefFn?.(page.toString())}
                  />
                )
                break
              }
              case 'previous': {
                children = (
                  <BPagiantion.Prev
                    {...item}
                    key={`${index}_${p.syncKey}`}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      if (e.button == 0) {
                        e.preventDefault()
                      }
    
                      p.previousPageFn()
                    }}
                    href={p.urlHrefFn?.(page.toString())}
                  />
                )
                break
              }
            }
          }

          return children
        })}
      </BPagiantion>
    </CustomPaginationWrapper>
  )
}

export default Pagination
