import { AppContext } from "App";
import TabsLoader from "components/utils/TabsLoader";
import React, { useContext } from "react";
import { Tab } from 'static/tabs'
import { Mapper } from "./Tabs.mapper";

const Tabs: React.FC = () => {
  const { history } = useContext(AppContext)

  return (
    <TabsLoader mapper={Mapper} defaultTab={Tab.Teams} history={history} />
  )
}

export default Tabs
