import React from 'react'
// Components
import { Col } from 'react-bootstrap'
import ApproveButton from 'components/utils/Button/ApproveButton'
import FreezeButton from 'components/utils/Button/FreezeButton'
import TouchButton from 'components/utils/Button/TouchButton'
import GridContainer from 'components/utils/GridContainer/GridContainer'
// Styles
import {
  FormRowAlignRight,
  FormRowAlignSpaceBetween,
  RowContainer,
} from 'styles/common'
import { Container } from './Form.styles'
import { Breakpoint } from 'styles/responsive'

interface Props {
  readonly useApprove?: {
    readonly defaultValue: boolean
  }
  readonly useFreeze?: {
    readonly defaultValue: boolean
  }
  readonly useTouch?: boolean

  readonly insertElements?: Array<JSX.Element>

  readonly locked?: boolean
}

const ITEMS_IN_ROW = 2

const Header: React.FC<Props> = (p) => {
  const _ApproveButton = (
    <ApproveButton
      defaultValue={!!p.useApprove?.defaultValue}
      isLocked={p.locked}
    />
  )

  const _FreezeButton = (
    <FreezeButton defaultValue={!!p.useFreeze?.defaultValue} />
  )

  const _TouchButton = <TouchButton />

  const _InsertItems = p.useTouch ? [_TouchButton] : []
  p.insertElements && _InsertItems.push(...p.insertElements)

  const _Header = () => {
    const _isRightSideRendered = p.useApprove || p.useFreeze

    return (
      <>
        <Col
          lg={_isRightSideRendered ? 8 : 12}
          md={12}
          sm={12}
          xs={12}
          style={{ alignSelf: 'center' }}
        >
          <GridContainer
            items={_InsertItems}
            numMaxItemsInRow={ITEMS_IN_ROW}
            verticalOffset={'0'}
          />
        </Col>
        {_isRightSideRendered && (
          <Col lg={4} md={12} sm={12} xs={12} style={{ alignSelf: 'center' }}>
            <RowContainer offset={{ direction: 'left' }} float={'right'}>
              {p.useApprove && _ApproveButton}
              {p.useFreeze && _FreezeButton}
            </RowContainer>
          </Col>
        )}
      </>
    )
  }

  return (
    <Container>
      <Breakpoint.Desktop>
        <FormRowAlignSpaceBetween>{_Header()}</FormRowAlignSpaceBetween>
      </Breakpoint.Desktop>
      <Breakpoint.TabletMobile>
        <FormRowAlignRight>{_Header()}</FormRowAlignRight>
      </Breakpoint.TabletMobile>
    </Container>
  )
}

export default Header
