import React from 'react'
import styled, { css } from 'styled-components'
// Styles
import { CrossIcon } from 'styles/fontAwesome'

interface StyleProps {
  readonly isApproved?: boolean
}

const Wrapper = styled.div`
  display: inline-flex;

  align-items: center;

  > * {
    margin-right: 5px;
  }
`

const TextWrapper = styled.div<StyleProps>`
  ${({ isApproved }) => {
    if (isApproved) return css``

    return css`
      color: red;
    `
  }}
`

const SportsmanNickname: React.FC<{ nickname?: string } & StyleProps> = (p) => (
  <Wrapper>
    <TextWrapper {...p}>{p.nickname}</TextWrapper>
    {!p.isApproved && <CrossIcon />}
  </Wrapper>
)

export default SportsmanNickname
