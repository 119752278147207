import React, { createContext } from 'react'

export module FormContext {
  type ActionType = () => Promise<any>

  export const Context = createContext<{
    submit?: ActionType

    delete?: ActionType

    approve?: ActionType
    unapprove?: ActionType

    freeze?: ActionType
    unfreeze?: ActionType

    isFreeze?: boolean

    touch?: ActionType
  }>({})

  export const Provider: React.FC<{
    value: {
      submit?: ActionType
      delete?: ActionType
      approve?: ActionType
      unapprove?: ActionType
      freeze?: ActionType
      unfreeze?: ActionType
      isFreeze?: boolean
      touch?: ActionType
    }
  }> = (p) => (
    <Context.Provider
      value={{
        ...p.value,
      }}
    >
      {p.children}
    </Context.Provider>
  )
}
