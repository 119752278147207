export module DateTime {
  export const parseDateOrNull = (input: string | null | undefined) => {
    return input ? new Date(input) : null
  }

  export const shortDate = (value: any, options?: any): string => {
    const timestamp = Date.parse(value)
    if (isNaN(timestamp)) {
      return value
    }

    const omitCurrentYear = options && options.includes('omitCurrentYear')
    const omitYear = options && options.includes('omitYear')

    const date = new Date(timestamp)
    const d = date.getDate()
    const m = date.getMonth() + 1
    const y = date.getFullYear()

    if (omitCurrentYear || omitYear) {
      const currentYear = new Date().getFullYear()
      if (currentYear === y || omitYear) {
        return `${d}. ${m}`
      }
    }

    return `${d}. ${m}. ${y}`
  }
}
