import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { TeamRecord } from "./TeamRecord";
import { TournamentRecord } from "./TournamentRecord";

export class TournamentTeamRankRecord<TJsonData extends TournamentTeamRankRecord.CtorJsonData = TournamentTeamRankRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  rank_from?: number
  rank_to?: number
  data_frozen_at?: Date | null

  /// >>> tournament >>>
  #tournament:BaseRecord.RefField<TournamentRecord> = {}
  
  tournament_id?: string

  get tournament() {
    return this.#tournament.object
  }

  getTournament() {
    this.#tournament.id = this.tournament_id

    return this.getObjectFromRefField(this.#tournament, "tournaments")
  }
  /// <<< tournament <<<

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}
  
  team_id?: string

  get team() {
    return this.#team.object
  }

  getTeam() {
    this.#team.id = this.team_id
    
    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get team_name() {
    return this.team?.short_name
  }
  get tournament_name() {
    return this.tournament?.name
  }
  get sortValues() {
    return [
      this.rank_from ?? Number.MAX_SAFE_INTEGER,
      this.team_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TournamentTeamRankRecord.CtorJsonData) {
    return new TournamentTeamRankRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.tournament_id = jsonData?.tournament_id
    this.team_id = jsonData?.team_id
    this.rank_from = jsonData?.rank_from
    this.rank_to = jsonData?.rank_to
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      tournament_id: this.tournament_id,
      team_id: this.team_id,
      rank_from: this.rank_from,
      rank_to: this.rank_to,
      data_frozen_at: this.data_frozen_at?.toISOString()
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module TournamentTeamRankRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    tournament_id?: string
    team_id?: string
    rank_from?: number
    rank_to?: number
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<TournamentTeamRankRecord> {
    /// >>> by tournament_id >>>
    #getByTournamentId:RecordRepository.NestingGetFieldFlags = {}

    getByTournamentId(tournamentId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTournamentId, tournamentId, options, {
        peek: () => this.peekByTournamentId(tournamentId),
        load: () => this.loadByTournamentId(tournamentId)
      })
    }

    peekByTournamentId(tournamentId:string) {
      return this.peekAll(x => x?.tournament_id == tournamentId)
    }

    protected loadByTournamentId(tournamentId:string) {
      return this.getApi().getMoreTournamentTeamRanksByTournamentId(tournamentId)
    }
    /// <<< by tournament id <<<

    create(record: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournamentTeamRank(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneTournamentTeamRankById(id)
    }

    protected updateById(id: string, patch: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTournamentTeamRank(id, patch)
    }

    protected deleteById(id: string): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournamentTeamRank(id)
    }

    protected approveById(id: string, patch: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTournamentTeamRankById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<TournamentTeamRankRecord<CtorJsonData>>): Promise<TournamentTeamRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTournamentTeamRankById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTournamentTeamRanks()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTournamentTeamRankById(id)
    }
  }
}