import React, { useState, useContext } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import * as S from './DirMenu.styles'
import Button from 'components/utils/Button/CTAButton'
// Components
import Loading from 'components/utils/Loading'
// Additional
import { AppContext } from 'App'
import { Data as DataHelper } from 'helpers/data'

interface Props extends Pick<_Props, 'onClickPath' | 'rootPath' | 'addPaths'> {
  readonly totalPath: string

  readonly onClickCallback?: (path: string) => void
}

const DirMenu: React.FC<Props> = (p) => {
  const [content, setContent] = useState<_Props>()

  const { db: a_db, alert: a_alert } = useContext(AppContext)
  useAsyncEffect(async () => {
    if (!a_db) return

    const data = await DataHelper.getAzureDirectories(
      a_db!,
      'kentico-media',
      p.totalPath,
      false,
      {
        alert: a_alert,
      }
    )

    if (!data) return

    const items = data.map(
      (x) => x.name?.substring(p.totalPath.length, x.name.length - 1) ?? ''
    )

    setContent({
      rootPath: p.rootPath,
      addPaths: p.addPaths,
      items: items,
      onClickPath: p.onClickPath,
      onClickCallback: (idx: number) => {
        p.onClickCallback?.(items[idx])
      },
    })
  }, [p.addPaths])

  if (!content) return <Loading />

  return <_DirMenu {...content} />
}

interface _Props {
  readonly rootPath?: string
  readonly addPaths?: Array<string>

  readonly items?: Array<string>

  readonly onClickPath?: (path: string) => void

  readonly onClickCallback?: (idx: number) => void
}

export const _DirMenu: React.FC<_Props> = (p) => (
  <>
    <S.DirItemWrapper>
      <S.DirPathWrapper>
        <S.DirPathItem onClick={() => p.onClickPath?.('')} clickable>
          {p.rootPath}
        </S.DirPathItem>
        {p.addPaths?.map((x, idx, arr) => {
          const _isLast = idx == arr.length - 1

          return (
            <S.DirPathItem
              key={`ClickablePath_${idx}`}
              clickable={!_isLast}
              onClick={() => {
                if (_isLast) return

                p.onClickPath?.(x)
              }}
            >
              / &nbsp; {x}
            </S.DirPathItem>
          )
        })}
      </S.DirPathWrapper>


    </S.DirItemWrapper>
    <S.DirsItemsWrapper>
      {p.items?.map((x, idx) => (
        <Button
          key={`button${idx}`}
          onClick={() => p.onClickCallback?.(idx)}
        >
        {x}
          </Button>
      ))}
      </S.DirsItemsWrapper>
  </>
)

export default DirMenu
