import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportRecord } from "./SportRecord"

export class GameMapRecord<TJsonData extends GameMapRecord.CtorJsonData = GameMapRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  ident?: string
  name?: string
  abbr?: string
  is_active?: boolean
  binding_names?: string[]

  /// >>> sport >>>
  #sport:BaseRecord.RefField<SportRecord> = {}
  get sport_id() {
    return this.#sport.id
  }
  set sport_id(value) {
    this.#sport.id = value
  }

  get sport() {
    return this.#sport?.object
  }
  getSport() {
    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:GameMapRecord.CtorJsonData) {
    return new GameMapRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)
    
    this.ident = jsonData?.ident
    this.sport_id = jsonData?.sport_id
    this.name = jsonData?.name
    this.abbr = jsonData?.abbr
    this.is_active = jsonData?.is_active
    this.binding_names = jsonData?.binding_names
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      ident: this.ident,
      sport_id: this.sport_id,
      name: this.name,
      abbr: this.abbr,
      is_active: this.is_active,
      binding_names: this.binding_names,
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const sport = await this.getSport()
    await sport?.preload()
  }
}

export module GameMapRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    ident?: string
    sport_id?: string
    name?: string
    abbr?: string
    is_active?: boolean
    binding_names?: string[]
  }

  export class Repository extends RecordRepository<GameMapRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    protected loadAll() {
      return this.getApi().getAllGameMaps()
    }

    protected loadById(id: string) {
      return this.getApi().getOneGameMapById(id)
    }

    protected updateById(id: string, patch: Partial<GameMapRecord<CtorJsonData>>): Promise<GameMapRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneGameMap(id, patch)
    }

    protected deleteById(id: string): Promise<GameMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<GameMapRecord<CtorJsonData>>): Promise<GameMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<GameMapRecord<CtorJsonData>>): Promise<GameMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<GameMapRecord<CtorJsonData>>): Promise<GameMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<GameMapRecord<CtorJsonData>>): Promise<GameMapRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllGameMaps()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneGameMapById(id)
    }
  }
}