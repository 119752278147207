import { AppContext } from "App"
import React, { useContext, useEffect, useState } from "react"
import { TeamOrganizationContext } from "./__TeamOrganizations.context"
import { String as StringHelper } from 'helpers/string'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'
import { TeamOrganizationRecord } from "data/TeamOrganizationRecord"
import useAsyncEffect from "use-async-effect"
import { Tab } from "static/tabs"
import Warning from "components/utils/Warning"
import { Text } from "static/text"
import Loading from "components/utils/Loading"
import { ItemWrapper } from "styles/common"
import TeamOrganizationForm from "./Form"
import Tabs from "./Tabs/Tabs"

const Detail: React.FC = (p) => {
  const{
    db: a_db,
    alert: a_alert,
    location: a_location,
  } = useContext(AppContext)

  const {params: t_params } = useContext(TeamOrganizationContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    t_params?.team_organization_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: TeamOrganizationRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !t_params?.team_organization_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try {
      const _data = await DataHelper.getTeamOrganizationById(a_db!, t_params?.team_organization_id, {
        alert: a_alert
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [t_params?.team_organization_id])

  // -------------------- Set Location ------------------- //
  const _setlocation = (data?: TeamOrganizationRecord) => {
    a_location?.set?.(
      Tab.TeamOrganizations,
      data && `${StringHelper.spaceTo(data?.name ?? '')}`
    )
  }

  useEffect(() => {
    _setlocation(content?.data)

    // content?.data
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <TeamOrganizationForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          submitCallback={_setlocation}
        />
      </ItemWrapper>

      {!isNewRecord && <Tabs />}
    </>
  )
}

export default Detail
