import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";
import { SportRecord } from "./SportRecord";

export class SportsmanRecord<TJsonData extends SportsmanRecord.CtorJsonData = SportsmanRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  sportsman_datasource_identifier_id?: string
  binding_id?: string
  sport_id?: string
  is_sazka_eleague?: boolean | null
  first_name?: string
  last_name?: string
  full_name?: string
  nickname?: string
  photo_url?: string
  photo_profile?: string
  country?: string
  team_position?: string
  birthdate?: Date | null
  data_approved_at?: Date | null
  data_frozen_at?: Date | null
  is_popular?: boolean | null
  popularity_order?: number

  /// >>> sport >>>
  #sport: BaseRecord.RefField<SportRecord> = {}

  get sport() {
    this.#sport.id = this.sport_id

    return this.peekObjectFromRefField(this.#sport, "sports")
  }
  getSport() {
    this.#sport.id = this.sport_id

    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  /// >>> computed_values >>>
  get isApproved() {
    return !!this.data_approved_at
  }

  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sport_name() {
    return this.sport?.short_name
  }

  get sortValues() {
    return [
      this.nickname?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanRecord.CtorJsonData) {
    return new SportsmanRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.sportsman_datasource_identifier_id = jsonData?.sportsman_datasource_identifier_id
    this.sport_id = jsonData?.sport_id
    this.is_sazka_eleague = jsonData?.is_sazka_eleague
    this.binding_id = jsonData?.binding_id
    this.first_name = jsonData?.first_name
    this.last_name = jsonData?.last_name
    this.full_name = jsonData?.full_name
    this.nickname = jsonData?.nickname
    this.photo_url = jsonData?.photo_url
    this.photo_profile = jsonData.photo_profile
    this.country = jsonData?.country
    this.team_position = jsonData?.team_position
    this.birthdate = DateTime.parseDateOrNull(jsonData?.birthdate)
    this.data_approved_at = DateTime.parseDateOrNull(jsonData?.data_approved_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.is_popular = jsonData?.is_popular
    this.popularity_order = jsonData?.popularity_order
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      sportsman_datasource_identifier_id: this.sportsman_datasource_identifier_id,
      sport_id: this.sport_id,
      is_sazka_eleague: this.is_sazka_eleague,
      binding_id: this.binding_id,
      first_name: this.first_name,
      last_name: this.last_name,
      full_name: this.full_name,
      nickname: this.nickname,
      photo_url: this.photo_url,
      photo_profile: this.photo_profile,
      country: this.country,
      team_position: this.team_position,
      birthdate: this.birthdate,
      data_approved_at: this.data_approved_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      is_popular: this.is_popular,
      popularity_order: this.popularity_order
    }) as TJsonData
  }
}

export module SportsmanRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    sportsman_datasource_identifier_id?: string
    sport_id?: string
    is_sazka_eleague?: boolean | undefined
    binding_id?: string
    first_name?: string
    last_name?: string
    full_name?: string
    nickname?: string
    photo_url?: string
    photo_profile?: string
    country?: string
    team_position?: string
    birthdate?: string
    data_approved_at?: string
    data_frozen_at?: string
    is_popular?: boolean | undefined
    popularity_order?: number
  }

  export class Repository extends RecordRepository<SportsmanRecord> {
    /// >>> by sport_id >>>
    #getBySportId:RecordRepository.NestingGetFieldFlags = {}
    getBySportId(leagueId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getBySportId, leagueId, options, {
        peek: () => this.peekBySportId(leagueId),
        load: () => this.loadByLeagueId(leagueId)
      })
    }

    peekBySportId(sportId:string) {
      return this.peekAll(x => x?.sport_id == sportId)
    }

    protected loadByLeagueId(leagueId:string) {
      return this.getApi().getMoreSportsmenBySportId(leagueId)
    }
    /// <<< by sport_id <<<

    create(record: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSportman(record)
    }

    protected loadById(id: string) {
      return this.getApi().getOneSportsmanById(id)
    }

    protected updateById(id: string, patch: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsman(id, patch)
    }

    protected deleteById(id: string): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSportsman(id)
    }

    protected approveById(id: string, patch: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.approveOneSportsmanById(id, patch)
    }

    protected unapproveById(id: string, patch: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unapproveOneSportsmanById(id, patch)
    }

    protected freezeById(id: string, patch: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneSportsmanById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<SportsmanRecord<CtorJsonData>>): Promise<SportsmanRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneSportsmanById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSportsmen()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportsmanById(id)
    }
  }
}
