import React, { ComponentProps, useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { MatchMapRecord } from 'data/MatchMapRecord'
import { AppContext } from 'App'
import { MatchMapContext } from 'components/MatchMaps/__MatchMaps.context'
import { MatchContext } from 'components/Matches/__Matches.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = [
  'team1_rounds_won_sum_edit',
  'team2_rounds_won_sum_edit',
  'order_number',
  'game_map_id',
] as Array<keyof MatchMapRecord>

type ValidationKeys = Record<keyof Pick<MatchMapRecord, 'game_map_id'>, any>

const MatchMapForm: React.FC<FormHelper.Props<MatchMapRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: mm_params } = useContext(MatchMapContext)
  const { params: m_params } = useContext(MatchContext)

  const [form, setForm] = useState<{
    data?: MatchMapRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Match Map',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...p.data,

      match_id: p.data?.match?.id ?? mm_params?.match_id,

      game_map_id: p.data?.gameMap?.id,
      game_map_text: p.data?.gameMap?.name,

      team1_rounds_won_sum_edit: p.data?.team1_rounds_won_sum_edit,
      team2_rounds_won_sum_edit: p.data?.team2_rounds_won_sum_edit,
    },

    onSubmit: async (values) => {
      if (!db?.matchMaps) return

      try {
        const data = await FormHelper.submitChanges<MatchMapRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new MatchMapRecord(), values ?? {}),
          repository: db.matchMaps,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getMatchMapDetailUrl({
              tournament:
                m_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              match: m_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              matchMap: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      game_map_id: Yup.string().required('Game Map is a required field'),
    }),
  })

  const freeze = async () => {
    if (!db?.matchMaps) return

    await FormHelper.freeze({
      data: Object.assign(new MatchMapRecord(), formik.values ?? {}),
      repository: db.matchMaps,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.matchMaps) return

    await FormHelper.unfreeze({
      data: Object.assign(new MatchMapRecord(), formik.values ?? {}),
      repository: db.matchMaps,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.matchMaps) return

    await FormHelper.delete_({
      data: Object.assign(new MatchMapRecord(), formik.values ?? {}),
      repository: db.matchMaps,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.matchMaps) return

    await FormHelper.touch({
      data: Object.assign(new MatchMapRecord(), formik.values ?? {}),
      repository: db.matchMaps,
      optional: opt,
    })
  }

  const [gameMap, setGameMap] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.game_map_id ?? '',
      text: formik.values.game_map_text,
    },
    loaded: false,
  })

  const _setGameMaps = async () => {
    const _data = await DataHelper.getAllGameMaps(db!)

    setGameMap((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.name ?? '' } as Item
      }),
    }))
  }

  const handleGameMapSelect = (item: Item) => {
    formik.setFieldValue('game_map_id' as keyof MatchMapRecord, item.id)

    setGameMap((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  useEffect(() => {
    _setGameMaps()
  }, [])

  //#region Form Components
  const _GameMap = (
    <FormItem
      label={<Form.Label>Game Map</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'game_map_id' as keyof MatchMapRecord}
            items={gameMap.data ?? []}
            onSelect={handleGameMapSelect}
            value={gameMap.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.game_map_id}
            disabled={p.locked?.value}
            loading={!gameMap.loaded}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.game_map_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Match = (
    <Form.Control
      name={'match_id' as keyof MatchMapRecord}
      value={formik.values.match_id}
      hidden
    />
  )

  const _Team1RoundsWonSumOrig = (
    <FormItem
      label={<Form.Label>Team 1 Rounds Won Sum</Form.Label>}
      input={
        <Form.Control
          name={'team1_rounds_won_sum_orig' as keyof MatchMapRecord}
          type={'number'}
          value={formik.values.team1_rounds_won_sum_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team1RoundsWonSumEdit = (
    <FormItem
      label={<Form.Label>Team 1 Rounds Won Sum</Form.Label>}
      input={
        <Form.Control
          name={'team1_rounds_won_sum_edit' as keyof MatchMapRecord}
          type={'number'}
          value={formik.values.team1_rounds_won_sum_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Team2RoundsWonSumOrig = (
    <FormItem
      label={<Form.Label>Team 2 Rounds Won Sum</Form.Label>}
      input={
        <Form.Control
          name={'team2_rounds_won_sum_orig' as keyof MatchMapRecord}
          type={'number'}
          value={formik.values.team2_rounds_won_sum_orig ?? ''}
          onChange={formik.handleChange}
          readOnly
        />
      }
    />
  )

  const _Team2RoundsWonSumEdit = (
    <FormItem
      label={<Form.Label>Team 2 Rounds Won Sum</Form.Label>}
      input={
        <Form.Control
          name={'team2_rounds_won_sum_edit' as keyof MatchMapRecord}
          type={'number'}
          value={formik.values.team2_rounds_won_sum_edit ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _OrderNumber = (
    <FormItem
      label={<Form.Label>Order Number</Form.Label>}
      input={
        <Form.Control
          name={'order_number' as keyof MatchMapRecord}
          type={'number'}
          value={formik.values.order_number ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* ----------- Hidden Inputs Section ----------- */}
          {_Match}
          {/* --------------------------------------------- */}

          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_GameMap}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col>{_OrderNumber}</Col>
          </Form.Row>

          <ItemWrapper>
            <hr />
          </ItemWrapper>

          <ItemWrapper>
            <Form.Row>
              {form.state === 'existing' && (
                <Col as={ItemWrapper}>{_Team1RoundsWonSumOrig}</Col>
              )}
              <Col as={ItemWrapper}>{_Team1RoundsWonSumEdit}</Col>
            </Form.Row>
            <Form.Row>
              {form.state === 'existing' && (
                <Col as={ItemWrapper}>{_Team2RoundsWonSumOrig}</Col>
              )}
              <Col as={ItemWrapper}>{_Team2RoundsWonSumEdit}</Col>
            </Form.Row>
          </ItemWrapper>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default MatchMapForm
