import React from 'react'
import styled, { css } from 'styled-components'
// Components
import {
  Dropdown as BDropdown,
  DropdownButton as BDropdownButton,
} from 'react-bootstrap'
import { Spinner as BSpinner } from 'react-bootstrap'

export type Id = string | number | null | undefined

export type Item = { id: Id; text?: string; color?: string }

export interface Props {
  readonly defaultItem?: Item
  readonly items: Array<Item>

  readonly value?: Item

  readonly onSelect?: (item: Item) => void

  readonly disabled?: boolean
  readonly loading?: boolean

  readonly disableId?: Array<Id>
}

const Dropdown: React.FC<Props> = (p) => {
  const prepareItem = (item: Item, key?: string) => (
    <>
      {item.text && (
        <BDropdown.Item key={key} onSelect={() => p.onSelect?.(item)}>
          <div
            style={{ color: item.color }}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </BDropdown.Item>
      )}
    </>
  )

  const getParsedText = (text?: string) => (text?.length !== 0 ? text : null)

  const checkAndPrepareItem = (item: Item, key?: string) => {
    if (p.disableId?.includes(item.id)) return

    return prepareItem(
      {
        ...item,
        text: getParsedText(item.text) ?? getParsedText(item.id?.toString()),
      } as Item,
      key
    )
  }

  const getButtonText = () =>
    getParsedText(p.value?.text) ??
    getParsedText(p.value?.id?.toString()) ??
    getParsedText(p.defaultItem?.text) ??
    getParsedText(p.defaultItem?.id?.toString()) ??
    '&nbsp;'

  return (
    <CustomDropdownButton
      as={BDropdownButton}
      variant={'outline-secondary'}
      title={
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: getButtonText(),
            }}
          ></div>
          {p.loading && (
            <CustomLoadingSpinner as={(props_) => <BSpinner {...props_} />} />
          )}
        </>
      }
      disabled={p.disabled || p.loading} // disable button for loading state as well
      loading={p.loading}
    >
      {p.defaultItem && (
        <>
          {prepareItem(p.defaultItem, `DropdownItem_Default`)}
          <BDropdown.Divider />
        </>
      )}
      {p.items.map((i, idx) => (
        <React.Fragment key={`DropdownItem_Fragment_${idx}`}>
          {checkAndPrepareItem(i, `DropdownItem_${idx}`)}
        </React.Fragment>
      ))}
    </CustomDropdownButton>
  )
}

const CustomLoadingSpinner = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
).attrs({
  size: 'sm',
  animation: 'border',
})`
  position: absolute;

  top: 9px;
  right: 15px;
`

export const CustomDropdownButton = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)<{ disabled?: boolean; loading?: boolean }>`
  .dropdown-toggle {
    position: relative;

    ${CustomLoadingSpinner} {
      min-width: unset;
    }
  }

  ${({ disabled, loading }) => {
    if (!disabled) return css``

    return css`
      .dropdown-toggle {
        &,
        &:hover {
          background-color: ${loading ? '#f3f5f6' : '#e9ecef'}!important;
          border-color: #ced4da !important;
          color: #495057 !important;
          opacity: 1 !important;
          cursor: default !important;
        }
      }
    `
  }}

  // ------------ loading effect ------------ //
  .dropdown-toggle:after {
    border-top: unset;

    ${({ loading }) => {
      if (loading) return css``

      return css`
        border-top: 0.3em solid;
      `
    }}
  }
  // ---------------------------------------- //
`

export default Dropdown
