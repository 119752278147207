import React, { ComponentProps, useContext, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { TournamentRoundRecord } from 'data/TournamentRoundRecord'
import { AppContext } from 'App'
import { RoundContext } from 'components/Rounds/__Rounds.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = [
  'tournament_stage_id',
  'name',
  'order_number',
  'ident',
] as Array<keyof TournamentRoundRecord>

type ValidationKeys = Record<
  keyof Pick<TournamentRoundRecord, 'name' | 'tournament_stage_id'>,
  any
>

const RoundForm: React.FC<FormHelper.Props<TournamentRoundRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: r_params } = useContext(RoundContext)
  const { params: t_params } = useContext(TournamentContext)

  const [form, setForm] = useState<{
    data?: TournamentRoundRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Round',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      tournament_stage_id: form.data?.tournamentStage?.id,
      tournament_stage_text: form.data?.tournamentStage?.name,
    },

    onSubmit: async (values) => {
      if (!db?.tournamentRounds) return

      try {
        const data = await FormHelper.submitChanges<TournamentRoundRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TournamentRoundRecord(), values ?? {}),
          repository: db.tournamentRounds,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getRoundDetailUrl({
              tournament:
                t_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              round: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      name: Yup.string().required('Name is a required field'),
      tournament_stage_id: Yup.string().required('Stage is a required field'),
    }),
  })

  const approve = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.approve({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.unapprove({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const freeze = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.freeze({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.unfreeze({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.delete_({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.tournamentRounds) return

    await FormHelper.touch({
      data: Object.assign(new TournamentRoundRecord(), formik.values ?? {}),
      repository: db.tournamentRounds,
      optional: opt,
    })
  }

  const [stages, setStages] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.tournament_stage_id ?? '',
      text: formik.values.tournament_stage_text,
    },
    loaded: false,
  })

  const identsDefaultItem: Item = { id: null, text: '&nbsp;' }

  const [idents, setIdents] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.ident ?? '',
      text: formik.values.ident,
    },
    loaded: false,
  })

  const _setIdents = async () => {
    const _data = await DataHelper.getRoundIdents(db!)

    setIdents((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s ?? '', text: s } as Item
      }),
    }))
  }

  const _setStages = async () => {
    const _data = await DataHelper.getStagesByTournamentId(
      db!,
      form.data?.tournament?.id ?? r_params?.tournament_id ?? '-'
    )

    setStages((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.name } as Item
      }),
    }))
  }

  const _setStage = (item: Item) => {
    formik.setFieldValue(
      'tournament_stage_id' as keyof TournamentRoundRecord,
      item.id
    )

    setStages((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const _setIdent = (item: Item) => {
    formik.setFieldValue('ident' as keyof TournamentRoundRecord, item.id)

    setIdents((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleStageChange = (item: Item) => {
    _setStage(item)
  }

  const handleIdentChange = (item: Item) => {
    _setIdent(item)
  }

  useEffect(() => {
    _setStages()
    _setIdents()
  }, [])

  //#region Form Components
  const _Stage = (
    <FormItem
      label={<Form.Label>Stage</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'tournament_stage_id' as keyof TournamentRoundRecord}
            items={stages.data ?? []}
            onSelect={handleStageChange}
            value={stages.value}
            required
            isInvalid={
              formik.submitCount > 0 && !!formik.errors.tournament_stage_id
            }
            disabled={form.state === 'existing' || p.locked?.value}
            loading={!stages.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.tournament_stage_id &&
                  RoutingHelper.getStageDetailUrl({
                    tournament: RoutingHelper.NewRecordSymbol,
                    stage: formik.values.tournament_stage_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.tournament_stage_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Ident = (
    <FormItem
      label={<Form.Label>Ident</Form.Label>}
      input={
        <FormDropdown
          name={'ident' as keyof TournamentRoundRecord}
          defaultItem={identsDefaultItem}
          items={idents.data ?? []}
          onSelect={handleIdentChange}
          value={idents.value}
          disabled={p.locked?.value}
          loading={!idents.loaded}
        />
      }
    />
  )

  const _Name = (
    <FormItem
      label={<Form.Label>Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'name' as keyof TournamentRoundRecord}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _OrderNumber = (
    <FormItem
      label={<Form.Label>Order Number</Form.Label>}
      input={
        <Form.Control
          name={'order_number' as keyof TournamentRoundRecord}
          type={'number'}
          value={formik.values.order_number ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_Stage}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_Ident}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_Name}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_OrderNumber}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default RoundForm
