import React, { ComponentProps, useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import Image from 'components/utils/Image/Image'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { GamingGearRecord } from "data/GamingGearRecord";
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'

const SubmitKeys = [
  'type',
  'name',
  'image'
] as Array<keyof GamingGearRecord>

type ValidationKeys = Record<keyof Pick<GamingGearRecord, 'name' | 'type'>, any>

const GamingGearForm: React.FC<FormHelper.Props<GamingGearRecord>> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: GamingGearRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new'})

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Gaming gear'
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    }
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,
    },

    onSubmit: async (values) => {
      if (!db?.gamingGears) return

      try{
        const data = await FormHelper.submitChanges<GamingGearRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new GamingGearRecord(), values ?? {}),
          repository: db.gamingGears,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing'})

          history?.replace(
            RoutingHelper.getGamingGearDetailUrl({
              gamingGear: data.id
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      name: Yup.string().required('Name is a required field'),
      type: Yup.string().required('Type is a required field')
    }),
  })

  const delete_ = async () => {
    if (!db?.gamingGears) return

    await FormHelper.delete_({
      data: Object.assign(new GamingGearRecord(), formik.values ?? {}),
      repository: db.gamingGears,
      optional: opt,
    })
  }
  //#region Form Components

  const _Name = (
    <FormItem
      label={<Form.Label>Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'name' as keyof GamingGearRecord}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Type = (
    <FormItem
      label={<Form.Label>Type</Form.Label>}
      input={
        <>
          <Form.Control
            name={'type' as keyof GamingGearRecord}
            value={formik.values.type ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.type}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.type}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Image = (
    <Image
      inputName={'image' as keyof GamingGearRecord}
      imageUrl={formik.values.image ?? ''}
      isLocked={p.locked?.value}
      labelName='image'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'gaming-gear'}
                actualImageString={formik.values.image ?? ''}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'image' as keyof GamingGearRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('image' as keyof GamingGearRecord, val)
      }
    />
  )

  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
        }}
        >
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Header />

            <Form.Row>
              <Col as={ItemWrapper}>{_Name}</Col>
            </Form.Row>
            <Form.Row>
              <Col as={ItemWrapper}>{_Type}</Col>
            </Form.Row>
            <ItemWrapper />
            <Form.Row>
              <Col as={ItemWrapper}>{_Image}</Col>
            </Form.Row>

            <Footer {..._ComputeFooterProps()} />
          </Form>
        </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default GamingGearForm
