import React from "react";
import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router-dom";
import { TournamentStageDatasourceIdentifierContext } from "./TournamentStageDatasourceIdentifier.context";
import Detail from "./_Detail";
import Select from "./_Select";

const TournamentStageDatasourceIdentifier: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TournamentStageDatasourceIdentifiersParams>()

  return (
    <TournamentStageDatasourceIdentifierContext.Provider value={{ params: params}}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TournamentStageDatasourceIdentifierContext.Provider>
  )
}

export default TournamentStageDatasourceIdentifier
