import React, { useState, useContext } from 'react'
// Components
import SwitchButton from './SwitchButton'
import { FormContext } from 'components/utils/Form.context'

const ApproveButton: React.FC<{
  defaultValue: boolean
  isLocked?: boolean
}> = (p) => {
  const { approve: f_approve, unapprove: f_unapprove } = useContext(
    FormContext.Context
  )

  const [approved, setApproved] = useState<{
    value: boolean
  }>({ value: p.defaultValue })

  const handleSubmitApprove = async (): Promise<void> => {
    try {
      await f_approve?.()
      
      setApproved({ value: true })
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmitUnapprove = async (): Promise<void> => {
    try {
      await f_unapprove?.()
      
      setApproved({ value: false })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <SwitchButton
      offset={{ direction: 'left' }}
      title={'Approve'}
      scheme={'green'}
      disabled={p.isLocked}
      checked={approved.value}
      checkedCallback={handleSubmitApprove}
      uncheckedCallback={handleSubmitUnapprove}
    />
  )
}

export default ApproveButton
