import { DateTime } from "utils/dateTime";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class GamingGearRecord<TJsonData extends GamingGearRecord.CtorJsonData = GamingGearRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  type?: string
  name?: string
  image?: string
  data_frozen_at?: Date | null

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get sortValues() {
    return [
      this.name ?? ''
    ]
  }
  /// <<< comuted_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:GamingGearRecord.CtorJsonData) {
    return new GamingGearRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.type = jsonData?.type
    this.name = jsonData?.name
    this.image = jsonData?.image
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      type: this.type,
      name: this.name,
      image: this.image,
      data_frozen_at: this.data_frozen_at?.toISOString()
    }) as TJsonData
  }

}

export module GamingGearRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    type?: string
    name?: string
    image?: string
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<GamingGearRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneGamingGear(record)
    }

    protected loadAll() {
      return this.getApi().getAllGamingGears()
    }

    protected loadById(id: string): Promise<GamingGearRecord | null> {
      return this.getApi().getOneGamingGearById(id)
    }

    protected updateById(id: string, patch: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneGamingGear(id, patch)
    }

    protected deleteById(id: string): Promise<GamingGearRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneGamingGear(id)
    }

    /// >>> not implemented methods >>>
    protected approveById(id: string, patch: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<GamingGearRecord<CtorJsonData>>): Promise<GamingGearRecord<CtorJsonData> | null> {
        throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
        throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
        throw new Error("Method not implemented.");
    }
    /// <<< not implemented methods <<<
  }

}
