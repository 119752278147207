import { Tab } from 'static/tabs'
import { String } from 'helpers/string'
import { TeamBiographyRecord } from 'data/TeamBiographyRecord'
import { TeamOrganizationRecord } from 'data/TeamOrganizationRecord'
import { TournamentDatasourceIdentifierRecord } from 'data/TournamentDatasourceIdentifierRecord'
import { TournamentStageDatasourceIdentifierRecord } from 'data/TournamentStageDatasourceIdentifierRecord'

export module Routing {
  //#region Params
  export interface DatasourceParams {
    readonly datasource_id: string
  }
  export interface SportParams {
    readonly sport_id: string
  }
  export interface TournamentParams {
    readonly tournament_id: string
  }
  export interface TournamentDatasourceIdentifiersParams {
    readonly tournament_datasource_identifier_id: string
  }
  export interface TournamentStageDatasourceIdentifiersParams {
    readonly tournament_stage_datasource_identifier_id: string
  }
  export interface StageParams extends TournamentParams {
    readonly stage_id: string
  }
  export interface RoundParams extends TournamentParams {
    readonly round_id: string
  }
  export interface MatchParams extends TournamentParams {
    readonly match_id: string
  }
  export interface MatchDatasourceIdentifierParams
    extends MatchParams {
    readonly match_datasource_identifier_id: string
  }
  export interface MatchMapParams extends MatchParams {
    readonly match_map_id: string
  }
  export interface MatchMapVetoParams extends MatchParams {
    readonly match_map_veto_id: string
  }
  export interface PlayerParams extends MatchParams {
    readonly player_id: string
  }
  export interface SportsmanParams extends SportParams {
    readonly sportsman_id: string
  }
  export interface SportsmanDatasourceIdentifierParams
    extends SportsmanParams {
    readonly sportsman_datasource_identifier_id: string
  }
  export interface SportsmanRankParams extends TournamentParams {
    readonly sportsman_rank_id: string
  }
  export interface SportsmanStatSummaryParams {
    readonly sportsman_stat_summary_id: string
  }
  export interface SportsmanBiographyParams
  extends SportsmanParams {
    readonly sportsman_biography_id: string
  }
  export interface SportsmanGamingGearsParams
  extends SportsmanParams {
    readonly sportsman_gaming_gears_id: string
  }
  export interface SportsmanTeamMembershipParams
    extends SportsmanParams,
      TeamParams {
    readonly sportsman_team_membership_id: string
  }
  export interface TeamParams extends SportParams {
    readonly team_id: string
  }
  export interface TeamDatasourceIdentifierParams
    extends TeamParams {
    readonly team_datasource_identifier_id: string
  }
  export interface TeamOrganizationDatasourceIdentifierParams {
    readonly team_organization_datasource_identifier_id: string
  }
  export interface TeamOrganizationParams
    extends TeamParams {
    readonly team_organization_id: string
  }
  export interface TeamRankParams extends TournamentParams {
    readonly team_rank_id: string
  }
  export interface TeamStatSummaryParams {
    readonly team_stat_summary_id: string
  }
  export interface TeamBiographyParams
  extends TeamParams {
    readonly team_biography_id: string
  }
  export interface TimespaceParams {
    readonly timespace_id: string
  }
  export interface SportsmanStatScalesParams {
    readonly sportsman_stat_scales_id: string
  }
  export interface TeamStatScalesParams {
    readonly team_stat_scales_id: string
  }
  export interface GamingGearParams {
    readonly gaming_gear_id: string
  }
  export interface NearestMatchParams {
    readonly nearest_match_id: string
  }
  //#endregion

  //#region Constant Params
  type DatasourceConstParams = { datasource: string }
  type SportConstantParams = { sport: string }
  type TournamentConstantParams = { tournament: string }
  type TournamentDatasourceIdentifiersConstantParams = { tournamentDatasourceIdentifier: string }
  type TournamentStageDatasourceIdentifiersConstantParams = { tournamentStageDatasourceIdentifier: string }
  type StageConstantParams = { stage: string } & TournamentConstantParams
  type RoundConstantParams = { round: string } & TournamentConstantParams
  type MatchConstantParams = { match: string } & TournamentConstantParams
  type MatchDatasourceIdentifierConstantParams = { matchDatasourceIdentifier: string } & MatchConstantParams
  type MatchMapConstantParams = {
    matchMap: string
  } & MatchConstantParams
  type MatchMapVetoConstantParams = {
    matchMapVeto: string
  } & MatchConstantParams
  type PlayerConstantParams = { player: string } & MatchConstantParams
  type SportsmanConstantParams = { sportsman: string } & SportConstantParams
  type SportsmanDatasourceIdentifierConstantParams = { sportsmanDatasourceIdentifier: string} & SportsmanConstantParams
  type SportsmanRankConstantParams = {
    sportsmanRank: string
  } & TournamentConstantParams
  type SportsmanBiographyConstantParams = { sportsmanBiography: string }
  type SportsmanGamingGearsConstantParams = { sportsmanGamingGears: string }
  type SportsmanTeamMembershipConstantParams = {
    sportsmanTeamMembership: string
  } & SportsmanConstantParams &
    TeamConstantParams
  type TeamConstantParams = { team: string } & SportConstantParams
  type TeamDatasourceIdentifiersConstantParams = {
    teamDatasourceIdentifier: string
  } & TeamConstantParams
  type TeamOrganizationDatasourceIdentifiersConstantParams = { teamOrganizationDatasourceIdentifier: string }
  type TeamOrganizationConstantParams = { teamOrganization: string}
  type TeamRankConstantParams = { teamRank: string } & TournamentConstantParams
  type TeamBiographyConstantParams = { teamBiography: string }
  type TimespaceConstantParams = { timespace: string }
  type SportsmanStatScalesConstantParams = { sportsmanStatScales: string }
  type TeamStatScalesConstantParams = { teamStatScales: string }
  type GamingGearConstantParams = { gamingGear: string }
  type NearestMatchConstantParams = { nearestMatch: string}
  //#endregion

  //#region Constants
  export const NewRecordSymbol = '-'

  const DEFAULT = () => `/`

  const DATASOURCE_SELECT = () => `/${Tab.Datasources}`
  const DATASOURCE_DETAIL = (id?: DatasourceConstParams) =>
    `/${Tab.Datasources}-form/${
      id?.datasource ?? getParsedParam('datasource_id' as keyof DatasourceConstParams)
    }`

  const SPORT_SELECT = () => `/${Tab.Sports}`
  const SPORT_DETAIL = (id?: SportConstantParams) =>
    `/${Tab.Sports}-form/${
      id?.sport ?? getParsedParam('sport_id' as keyof SportParams)
    }`

  const TOURNAMENT_SELECT = () => `/${Tab.Tournaments}`
  const TOURNAMENT_DETAIL = (id?: TournamentConstantParams) =>
    `/${Tab.Tournaments}-form/${
      id?.tournament ??
      getParsedParam('tournament_id' as keyof TournamentParams)
    }`

  const TOURNAMENT_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.TournamentDatasourceIdentifiers}`
  const TOURNAMENT_DATASOURCE_IDENTIFIER_DETAIL = (id?: TournamentDatasourceIdentifiersConstantParams) =>
    `/${Tab.TournamentDatasourceIdentifiers}-form/${
      id?.tournamentDatasourceIdentifier ??
      getParsedParam('tournament_datasource_identifier_id' as keyof TournamentDatasourceIdentifierRecord)
    }`

  const TOURNAMENT_STAGE_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.TournamentStageDatasourceIdentifiers}`
  const TOURNAMENT_STAGE_DATASOURCE_IDENTIFIER_DETAIL = (id?: TournamentStageDatasourceIdentifiersConstantParams) =>
    `/${Tab.TournamentDatasourceIdentifiers}-form/${
      id?.tournamentStageDatasourceIdentifier ??
      getParsedParam('tournament_stage_datasource_identifier_id' as keyof TournamentStageDatasourceIdentifierRecord)
    }`

  const STAGE_SELECT = () => `/${Tab.Stages}`
  const STAGE_DETAIL = (id?: StageConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ?? getParsedParam('tournament_id' as keyof StageParams)
    }/${Tab.Stages}-form/${
      id?.stage ?? getParsedParam('stage_id' as keyof StageParams)
    }`

  const ROUND_SELECT = () => `/${Tab.Rounds}`
  const ROUND_DETAIL = (id?: RoundConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ?? getParsedParam('tournament_id' as keyof RoundParams)
    }/${Tab.Rounds}-form/${
      id?.round ?? getParsedParam('round_id' as keyof RoundParams)
    }`

  const MATCH_SELECT = () => `/${Tab.Matches}`
  const MATCH_DETAIL = (id?: MatchConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ?? getParsedParam('tournament_id' as keyof MatchParams)
    }/${Tab.Matches}-form/${
      id?.match ?? getParsedParam('match_id' as keyof MatchParams)
    }`

  const MATCH_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.MatchDatasourceIdentifiers}`
  const MATCH_DATASOURCE_IDENTIFIER_DETAIL = (id?: MatchDatasourceIdentifierConstantParams) =>
    `/${Tab.MatchDatasourceIdentifiers}/${
      id?.matchDatasourceIdentifier ?? getParsedParam('match_datasiource_identifier_id' as keyof MatchDatasourceIdentifierConstantParams)
    }`
  const MATCH_DATASOURCE_IDENTIFIER_DETAIL_FROM_TEAM = (id?: MatchDatasourceIdentifierConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ??
      getParsedParam('tournament_id' as keyof MatchDatasourceIdentifierParams)
    }/${Tab.Matches}/${
      id?.match ??
      getParsedParam('match_id' as keyof MatchDatasourceIdentifierParams)
    }${MATCH_DATASOURCE_IDENTIFIER_DETAIL(id)}`


  const MATCH_MAP_DETAIL = (id?: MatchMapConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ?? getParsedParam('tournament_id' as keyof MatchMapParams)
    }/${Tab.Matches}/${
      id?.match ?? getParsedParam('match_id' as keyof MatchMapParams)
    }/${Tab.MatchMaps}-form/${
      id?.matchMap ?? getParsedParam('match_map_id' as keyof MatchMapParams)
    }`

  const MATCH_MAP_VETO_DETAIL = (id?: MatchMapVetoConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ??
      getParsedParam('tournament_id' as keyof MatchMapVetoParams)
    }/${Tab.Matches}/${
      id?.match ?? getParsedParam('match_id' as keyof MatchMapVetoParams)
    }/${Tab.MatchMapVetos}-form/${
      id?.matchMapVeto ??
      getParsedParam('match_map_veto_id' as keyof MatchMapVetoParams)
    }`

  const PLAYER_DETAIL = (id?: PlayerConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ?? getParsedParam('tournament_id' as keyof PlayerParams)
    }/${Tab.Matches}/${
      id?.match ?? getParsedParam('match_id' as keyof PlayerParams)
    }/${Tab.Players}-form/${
      id?.player ?? getParsedParam('player_id' as keyof PlayerParams)
    }`

  const SPORTSMAN_SELECT = () => `/${Tab.Sportsmen}`
  const SPORTSMAN_DETAIL = (id?: SportsmanConstantParams) =>
    `/${Tab.Sports}/${
      id?.sport ?? getParsedParam('sport_id' as keyof SportsmanParams)
    }/${Tab.Sportsmen}-form/${
      id?.sportsman ?? getParsedParam('sportsman_id' as keyof SportsmanParams)
    }`

  const SPORTSMAN_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.SportsmanDatasourceIdentifiers}`
  const SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL = (id?: SportsmanDatasourceIdentifierConstantParams) =>
    `/${Tab.SportsmanDatasourceIdentifiers}-form/${
      id?.sportsmanDatasourceIdentifier ??
      getParsedParam('sportsman_datasource_identifier_id' as keyof SportsmanDatasourceIdentifierConstantParams)
    }`
  const SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL_FROM_SPORTSMAN = (id?:SportsmanDatasourceIdentifierConstantParams) =>
    `/${Tab.Sports}/${
      id?.sport ??
      getParsedParam('sport_id' as keyof SportsmanDatasourceIdentifierParams)
    }/${Tab.Sportsmen}/${
      id?.sportsman ??
      getParsedParam('sportsman_id' as keyof SportsmanDatasourceIdentifierParams)
    }${SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL(id)}`

  const SPORTSMAN_RANK_SELECT = () => `/${Tab.SportsmanRanks}`
  const SPORTSMAN_RANK_DETAIL = (id?: SportsmanRankConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ??
      getParsedParam('tournament_id' as keyof SportsmanRankConstantParams)
    }/${Tab.SportsmanRanks}-form/${
      id?.sportsmanRank ??
      getParsedParam('sportsman_rank_id' as keyof SportsmanRankConstantParams)
    }`

  const SPORTSMAN_STAT_SELECT = () => `/${Tab.SportsmanStats}`

  const SPORTSMAN_BIOGRAPHY_DETAIL = (
    id?: SportsmanBiographyConstantParams
  ) =>
    `/${Tab.SportsmanBiographies}-form/${
      id?.sportsmanBiography ??
      getParsedParam(
        'sportsman_biography_id' as keyof SportsmanBiographyParams
      )
    }`

  const SPORTSMAN_BIOGRAPHY_DETAIL_FROM_SPORTSMAN = (
    id?: SportsmanBiographyConstantParams
  ) =>
    `/${Tab.Sportsmen}/${
      id?.sportsmanBiography ??
      getParsedParam('sportsman_id' as keyof SportsmanBiographyParams)
    }${SPORTSMAN_BIOGRAPHY_DETAIL(id)}`

  const SPORTSMAN_GAMING_GEAR_DETAIL = (
    id?: SportsmanGamingGearsConstantParams
  ) =>
    `/${Tab.SportsmanGamingGears}-form/${
      id?.sportsmanGamingGears ??
      getParsedParam(
        'sportsman_gaming_gears_id' as keyof SportsmanBiographyConstantParams
      )
    }`

  const SPORTSMAN_GAMING_GEARS_DETAIL_FROM_SPORTSMAN = (
    id?: SportsmanGamingGearsConstantParams
  ) =>
  `/${Tab.Sportsmen}/${
    id?.sportsmanGamingGears ??
    getParsedParam('sportsman_id' as keyof SportsmanBiographyConstantParams)
  }${SPORTSMAN_GAMING_GEAR_DETAIL(id)}`

  const SPORTSMAN_TEAM_MEMBERSHIP_DETAIL = (
    id?: SportsmanTeamMembershipConstantParams
  ) =>
    `/${Tab.SportsmanTeamMemberships}-form/${
      id?.sportsmanTeamMembership ??
      getParsedParam(
        'sportsman_team_membership_id' as keyof SportsmanTeamMembershipParams
      )
    }`

  const SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_TEAM = (
    id?: SportsmanTeamMembershipConstantParams
  ) =>
    `/${Tab.Sports}/${
      id?.sport ??
      getParsedParam('sport_id' as keyof SportsmanTeamMembershipParams)
    }/${Tab.Teams}/${
      id?.team ??
      getParsedParam('team_id' as keyof SportsmanTeamMembershipParams)
    }${SPORTSMAN_TEAM_MEMBERSHIP_DETAIL(id)}`

  const SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_SPORTSMAN = (
    id?: SportsmanTeamMembershipConstantParams
  ) =>
    `/${Tab.Sports}/${
      id?.sport ??
      getParsedParam('sport_id' as keyof SportsmanTeamMembershipParams)
    }/${Tab.Sportsmen}/${
      id?.sportsman ??
      getParsedParam('sportsman_id' as keyof SportsmanTeamMembershipParams)
    }${SPORTSMAN_TEAM_MEMBERSHIP_DETAIL(id)}`

  const TEAM_SELECT = () => `/${Tab.Teams}`
  const TEAM_DETAIL = (id?: TeamConstantParams) =>
    `/${Tab.Sports}/${
      id?.sport ?? getParsedParam('sport_id' as keyof TeamParams)
    }/${Tab.Teams}-form/${
      id?.team ?? getParsedParam('team_id' as keyof TeamParams)
    }`

  const TEAM_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.TeamDatasourceIdentifiers}`
  const TEAM_DATASOURCE_IDENTIFIER_DETAIL = (id?: TeamDatasourceIdentifiersConstantParams) =>
  `/${Tab.TeamDatasourceIdentifiers}-form/${
    id?.teamDatasourceIdentifier ??
    getParsedParam('team_datasource_identifier_id' as keyof TeamDatasourceIdentifiersConstantParams)
  }`
  const TEAM_DATASOURCE_IDENTIFIER_DETAIL_FROM_TEAM = (
    id?: TeamDatasourceIdentifiersConstantParams
  ) =>
    `/${Tab.Sports}/${
      id?.sport ??
      getParsedParam('sport_id' as keyof TeamDatasourceIdentifierParams)
    }/${Tab.Teams}/${
      id?.team ??
      getParsedParam('team_id' as keyof TeamDatasourceIdentifierParams)
    }${TEAM_DATASOURCE_IDENTIFIER_DETAIL(id)}
    `

  const TEAM_ORGANIZATION_DATASOURCE_IDENTIFIER_SELECT = () => `/${Tab.TeamOrganizatonDatasourceIdentifiers}`
  const TEAM_ORGANIZATION_DATASOURCE_IDENTIFIER_DETAIL = (id?: TeamOrganizationDatasourceIdentifiersConstantParams) =>
  `/${Tab.TeamOrganizatonDatasourceIdentifiers}-form/${
    id?.teamOrganizationDatasourceIdentifier ??
    getParsedParam('team_organization_datasource_identifier_id' as keyof TeamOrganizationDatasourceIdentifiersConstantParams)
  }`

  const TEAM_RANK_SELECT = () => `/${Tab.TeamRanks}`
  const TEAM_RANK_DETAIL = (id?: TeamRankConstantParams) =>
    `/${Tab.Tournaments}/${
      id?.tournament ??
      getParsedParam('tournament_id' as keyof TeamRankConstantParams)
    }/${Tab.TeamRanks}-form/${
      id?.teamRank ??
      getParsedParam('team_rank_id' as keyof TeamRankConstantParams)
    }`

  const TEAM_STAT_SELECT = () => `/${Tab.TeamStats}`

  const TEAM_BIOGRAPHY_DETAIL = (
    id?: TeamBiographyConstantParams
  ) =>
  `/${Tab.TeamBiographies}-form/${
    id?.teamBiography ??
    getParsedParam('team_id' as keyof TeamBiographyConstantParams)
  }`

  const TEAM_BIOGRAPHY_DETAIL_FROM_TEAM = (
    id?: TeamBiographyConstantParams
  ) =>
  `/${Tab.TeamBiographies}-form/${
    id?.teamBiography ??
    getParsedParam('team_id' as keyof TeamBiographyConstantParams)
  }${TEAM_BIOGRAPHY_DETAIL(id)}`

  const TIMESPACE_SELECT = () => `/${Tab.Timespaces}`
  const TIMESPACE_DETAIL = (id?: TimespaceConstantParams) =>
    `/${Tab.Timespaces}-form/${
      id?.timespace ?? getParsedParam('timespace_id' as keyof TimespaceParams)
    }`

  const SPORTSMAN_STAT_SCALES_SELECT = () => `/${Tab.SportsmanStatScales}`
  const SPORTSMAN_STAT_SCALES_DETAIL = (
    id?: SportsmanStatScalesConstantParams
  ) =>
    `/${Tab.SportsmanStatScales}-form/${
      id?.sportsmanStatScales ??
      getParsedParam(
        'sportsman_stat_scales_id' as keyof SportsmanStatScalesParams
      )
    }`

  const TEAM_STAT_SCALES_SELECT = () => `/${Tab.TeamStatScales}`
  const TEAM_STAT_SCALES_DETAIL = (id?: TeamStatScalesConstantParams) =>
    `/${Tab.TeamStatScales}-form/${
      id?.teamStatScales ??
      getParsedParam('team_stat_scales_id' as keyof TeamStatScalesParams)
    }`

  const GAMING_GEAR_SELECT = () => `/${Tab.GammingGears}`
  const GAMING_GEAR_DETAIL = (id?: GamingGearConstantParams) =>
    `/${Tab.GammingGears}-form/${
      id?.gamingGear ??
      getParsedParam('gaming_gear_id' as keyof GamingGearParams)
    }`

  const NEAREST_MATCH_SELECT = () => `/${Tab.NearestMatches}`
  const NEAREST_MATCHES_DETAIL = (id?: NearestMatchConstantParams) =>
    `/${Tab.NearestMatches}-form/${
      id?.nearestMatch ??
      getParsedParam('nearest_match_id' as keyof NearestMatchParams)
    }`

  const TEAM_ORGANIZATION_SELECT = () => `/${Tab.TeamOrganizations}`
  const TEAM_ORGANIZATION_DETAIL = (id?: TeamOrganizationConstantParams) =>
    `/${Tab.TeamOrganizations}-form/${
      id?.teamOrganization ??
      getParsedParam('team_organization_id' as keyof TeamOrganizationParams)
    }`
  //#endregion

  //#region Methods
  const getParsedParam = (param: string) => `:${param}`

  const getParsedRoute = (route: string): string =>
    String.spaceTo(route, '-').toLowerCase()

  export const getIsNewRecord = (id: string) => id === NewRecordSymbol

  export const getDefaultPath = () => getParsedRoute(DEFAULT())

  export const getDatasourceSelectPath = () => getParsedRoute(DATASOURCE_SELECT())
  export const getDatasourceDetailPath = () => getParsedRoute(DATASOURCE_DETAIL())
  export const getDatasourceDetailUrl = (id: DatasourceConstParams) =>
    getParsedRoute(DATASOURCE_DETAIL(id))

  export const getSportSelectPath = () => getParsedRoute(SPORT_SELECT())
  export const getSportDetailPath = () => getParsedRoute(SPORT_DETAIL())
  export const getSportDetailUrl = (id: SportConstantParams) =>
    getParsedRoute(SPORT_DETAIL(id))

  export const getTournamentSelectPath = () =>
    getParsedRoute(TOURNAMENT_SELECT())
  export const getTournamentDetailPath = () =>
    getParsedRoute(TOURNAMENT_DETAIL())
  export const getTournamentDetailUrl = (id: TournamentConstantParams) =>
    getParsedRoute(TOURNAMENT_DETAIL(id))

  export const getTournamentDatasourceIdentifierSelectPath = () =>
    getParsedRoute(TOURNAMENT_DATASOURCE_IDENTIFIER_SELECT())
  export const getTournamentDatasourceIdentifiersDetailPath = () =>
    getParsedRoute(TOURNAMENT_DATASOURCE_IDENTIFIER_DETAIL())
  export const getTournamentDatasourceIdentifierDetailUrl = (id: TournamentDatasourceIdentifiersConstantParams) =>
    getParsedRoute(TOURNAMENT_DATASOURCE_IDENTIFIER_DETAIL(id))

  export const getTournamentStageDatasourceIdentifierSelectPath = () =>
    getParsedRoute(TOURNAMENT_STAGE_DATASOURCE_IDENTIFIER_SELECT())
  export const getTournamentStageDatasourceIdentifiersDetailPath = () =>
    getParsedRoute(TOURNAMENT_STAGE_DATASOURCE_IDENTIFIER_DETAIL())
  export const getTournamentStageDatasourceIdentifierDetailUrl = (id: TournamentStageDatasourceIdentifiersConstantParams) =>
    getParsedRoute(TOURNAMENT_STAGE_DATASOURCE_IDENTIFIER_DETAIL(id))

  export const getStageSelectPath = () => getParsedRoute(STAGE_SELECT())
  export const getStageDetailPath = () => getParsedRoute(STAGE_DETAIL())
  export const getStageDetailUrl = (id: StageConstantParams) =>
    getParsedRoute(STAGE_DETAIL(id))

  export const getRoundSelectPath = () => getParsedRoute(ROUND_SELECT())
  export const getRoundDetailPath = () => getParsedRoute(ROUND_DETAIL())
  export const getRoundDetailUrl = (id: RoundConstantParams) =>
    getParsedRoute(ROUND_DETAIL(id))

  export const getMatchSelectPath = () => getParsedRoute(MATCH_SELECT())
  export const getMatchDetailPath = () => getParsedRoute(MATCH_DETAIL())
  export const getMatchDetailUrl = (id: MatchConstantParams) =>
    getParsedRoute(MATCH_DETAIL(id))

  export const getMatchDatasourceIdentifierSelectPath = () => getParsedRoute(MATCH_DATASOURCE_IDENTIFIER_SELECT())
  export const getMatchDatasourceIdentifierDetailPath = () => getParsedRoute(MATCH_DATASOURCE_IDENTIFIER_DETAIL())
  export const getMatchDatasourceIdentifierDetailUrl = (id: MatchDatasourceIdentifierConstantParams) =>
    getParsedRoute(MATCH_DATASOURCE_IDENTIFIER_DETAIL(id))
  export const getMatchDatasourceIdentifierUrlFromMatch = (id: MatchDatasourceIdentifierConstantParams) =>
    getParsedRoute(MATCH_DATASOURCE_IDENTIFIER_DETAIL_FROM_TEAM(id))

  export const getMatchMapDetailPath = () => getParsedRoute(MATCH_MAP_DETAIL())
  export const getMatchMapDetailUrl = (id: MatchMapConstantParams) =>
    getParsedRoute(MATCH_MAP_DETAIL(id))

  export const getMatchMapVetoDetailPath = () =>
    getParsedRoute(MATCH_MAP_VETO_DETAIL())
  export const getMatchMapVetoDetailUrl = (id: MatchMapVetoConstantParams) =>
    getParsedRoute(MATCH_MAP_VETO_DETAIL(id))

  export const getSportsmanSelectPath = () => getParsedRoute(SPORTSMAN_SELECT())
  export const getSportsmanDetailPath = () => getParsedRoute(SPORTSMAN_DETAIL())
  export const getSportsmanDetailUrl = (id: SportsmanConstantParams) =>
    getParsedRoute(SPORTSMAN_DETAIL(id))

  export const getSportsmanRankSelectPath = () =>
    getParsedRoute(SPORTSMAN_RANK_SELECT())
  export const getSportsmanRankDetailPath = () =>
    getParsedRoute(SPORTSMAN_RANK_DETAIL())
  export const getSportsmanRankDetailUrl = (id: SportsmanRankConstantParams) =>
    getParsedRoute(SPORTSMAN_RANK_DETAIL(id))

  export const getSportsmanStatSelectPath = () =>
    getParsedRoute(SPORTSMAN_STAT_SELECT())

  export const getSportsmanBiographyDetailPathFromSportsman = () =>
    getParsedRoute(SPORTSMAN_BIOGRAPHY_DETAIL_FROM_SPORTSMAN())
  export const getSportsmanBiographyDetailUrlFromSportsman = (
    id: SportsmanBiographyConstantParams
  ) => getParsedRoute(SPORTSMAN_BIOGRAPHY_DETAIL_FROM_SPORTSMAN(id))

  export const getSportsmanGamingGearsDetailPathFromSportsman = () =>
    getParsedRoute(SPORTSMAN_GAMING_GEARS_DETAIL_FROM_SPORTSMAN())
  export const getSportsmanGamingGearsDetailUrlFromSportsman = (
    id: SportsmanGamingGearsConstantParams
  ) => getParsedRoute(SPORTSMAN_GAMING_GEARS_DETAIL_FROM_SPORTSMAN(id))

  export const getSportsmanTeamMembershipDetailPathFromTeam = () =>
    getParsedRoute(SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_TEAM())
  export const getSportsmanTeamMembershipDetailUrlFromTeam = (
    id: SportsmanTeamMembershipConstantParams
  ) => getParsedRoute(SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_TEAM(id))

  export const getSportsmanTeamMembershipDetailPathFromSportsman = () =>
    getParsedRoute(SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_SPORTSMAN())
  export const getSportsmanTeamMembershipDetailUrlFromSportsman = (
    id: SportsmanTeamMembershipConstantParams
  ) => getParsedRoute(SPORTSMAN_TEAM_MEMBERSHIP_DETAIL_FROM_SPORTSMAN(id))

  export const getPlayerDetailPath = () => getParsedRoute(PLAYER_DETAIL())
  export const getPlayerDetailUrl = (id: PlayerConstantParams) =>
    getParsedRoute(PLAYER_DETAIL(id))

  export const getTeamSelectPath = () => getParsedRoute(TEAM_SELECT())
  export const getTeamDetailPath = () => getParsedRoute(TEAM_DETAIL())
  export const getTeamDetailUrl = (id: TeamConstantParams) =>
    getParsedRoute(TEAM_DETAIL(id))

  export const getTeamOrganizationSelectPath = () => getParsedRoute(TEAM_ORGANIZATION_SELECT())
  export const getTeamOrganizationDetailPath = () => getParsedRoute(TEAM_ORGANIZATION_DETAIL())
  export const getTeamOrganizationDetailUrl = (id: TeamOrganizationConstantParams) =>
    getParsedRoute(TEAM_ORGANIZATION_DETAIL(id))

  export const getTeamRankSelectPath = () => getParsedRoute(TEAM_RANK_SELECT())
  export const getTeamRankDetailPath = () => getParsedRoute(TEAM_RANK_DETAIL())
  export const getTeamRankDetailUrl = (id: TeamRankConstantParams) =>
    getParsedRoute(TEAM_RANK_DETAIL(id))

  export const getTeamStatSelectPath = () => getParsedRoute(TEAM_STAT_SELECT())

  export const getTeamBiographyDetailPatchFromTeam = () =>
    getParsedRoute(TEAM_BIOGRAPHY_DETAIL_FROM_TEAM())
  export const getTeamBiographyDetailUrlFromTeam = (
    id: TeamBiographyConstantParams
  ) => getParsedRoute(TEAM_BIOGRAPHY_DETAIL_FROM_TEAM(id))

  export const getTimespaceSelectPath = () => getParsedRoute(TIMESPACE_SELECT())
  export const getTimespaceDetailPath = () => getParsedRoute(TIMESPACE_DETAIL())
  export const getTimespaceDetailUrl = (id: TimespaceConstantParams) =>
    getParsedRoute(TIMESPACE_DETAIL(id))

  export const getSportsmanStatScalesSelectPath = () =>
    getParsedRoute(SPORTSMAN_STAT_SCALES_SELECT())
  export const getSportsmanStatScalesDetailPath = () =>
    getParsedRoute(SPORTSMAN_STAT_SCALES_DETAIL())
  export const getSportsmanStatScalesDetailUrl = (
    id: SportsmanStatScalesConstantParams
  ) => getParsedRoute(SPORTSMAN_STAT_SCALES_DETAIL(id))

  export const getSportsmanDatasourceIdentifierSelectPath = () =>
    getParsedRoute(SPORTSMAN_DATASOURCE_IDENTIFIER_SELECT())
  export const getSportsmanDatasourceIdentifierDetailPath = () =>
    getParsedRoute(SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL())
  export const getSportsmanDatasourceIdentifierDetailUrl = (
    id: SportsmanDatasourceIdentifierConstantParams
  ) => getParsedRoute(SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL(id))
  export const getSportsmanDatasourceIdentifierUrlFromSportsman = (
    id: SportsmanDatasourceIdentifierConstantParams
  ) => getParsedRoute(SPORTSMAN_DATASOURCE_IDENTIFIER_DETAIL_FROM_SPORTSMAN(id))

  export const getTeamStatScalesSelectPath = () =>
    getParsedRoute(TEAM_STAT_SCALES_SELECT())
  export const getTeamStatScalesDetailPath = () =>
    getParsedRoute(TEAM_STAT_SCALES_DETAIL())
  export const getTeamStatScalesDetailUrl = (
    id: TeamStatScalesConstantParams
  ) => getParsedRoute(TEAM_STAT_SCALES_DETAIL(id))

  export const getGamingGearSelectPath = () =>
    getParsedRoute(GAMING_GEAR_SELECT())
  export const getGamingGearDetailPath = () =>
    getParsedRoute(GAMING_GEAR_DETAIL())
  export const getGamingGearDetailUrl = (
    id: GamingGearConstantParams
  ) => getParsedRoute(GAMING_GEAR_DETAIL(id))

  export const getNearestMatchSelectPath = () =>
    getParsedRoute(NEAREST_MATCH_SELECT())
  export const getNearestMatchDetailPath = () =>
    getParsedRoute(NEAREST_MATCHES_DETAIL())
  export const getNearestMatchDetailUrl = (
    id: NearestMatchConstantParams
  ) => getParsedRoute(NEAREST_MATCHES_DETAIL(id))

  export const getTeamDatasourceIdentifierSelectPath = () =>
    getParsedRoute(TEAM_DATASOURCE_IDENTIFIER_SELECT())
  export const getTeamDatasourceIdentifierDetailPath = () =>
    getParsedRoute(TEAM_DATASOURCE_IDENTIFIER_DETAIL())
  export const getTeamDatasourceIdentifierDetailUrlFromTeam = (
    id: TeamDatasourceIdentifiersConstantParams
  ) => getParsedRoute(TEAM_DATASOURCE_IDENTIFIER_DETAIL_FROM_TEAM(id))
  export const getTeamDatasourceIdentifierDetailUrl = (
    id: TeamDatasourceIdentifiersConstantParams
  ) => getParsedRoute(TEAM_DATASOURCE_IDENTIFIER_DETAIL(id))

  export const getTeamOrganizationDatasourceIdentifierSelectPath = () =>
    getParsedRoute(TEAM_ORGANIZATION_DATASOURCE_IDENTIFIER_SELECT())
  export const getTeamOrganizationDatasourceIdentifierDetailPath = () =>
    getParsedRoute(TEAM_ORGANIZATION_DATASOURCE_IDENTIFIER_DETAIL())
  export const getTeamOrganizationDatasourceIdentifierDetailUrl = (
    id: TeamOrganizationDatasourceIdentifiersConstantParams
  ) => getParsedRoute(TEAM_ORGANIZATION_DATASOURCE_IDENTIFIER_DETAIL(id))
  //#endregion

}
