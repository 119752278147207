import React, { useContext } from 'react'
// Components
import Accordion from 'components/utils/Accordion/Accordion'
import Table from './Table'
import CTAButton from 'components/utils/Button/CTAButton'
import TouchButton from 'components/utils/Button/TouchButton'
import ReloadButton from 'components/utils/Button/ReloadButton'
import Container from 'components/utils/GridContainer/GridContainer'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Reload as ReloadHelper } from 'helpers/reload'
import { Data as DataHelper } from 'helpers/data'
import { SportContext } from 'components/Sports/__Sports.context'

const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)
  const { params: s_params } = useContext(SportContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.Teams,
      },
    },
  }

  const touchAll = async () => {
    if (!db?.teams) return

    await FormHelper.touchAll({
      repository: db.teams,
      optional: opt,
    })
  }

  const reload = async () => {
    if (!db?.teams) return

    if (s_params?.sport_id)
      return await ReloadHelper.reload({
        getAllFn: () => DataHelper.getTeamsBySportId(db!, s_params?.sport_id),
        optional: opt,
      })

    return await ReloadHelper.reload({
      getAllFn: () => DataHelper.getAllTeams(db),
      optional: opt,
    })
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <CTAButton
                key={'Container.CTA_New'}
                path={RoutingHelper.getTeamDetailUrl({
                  sport: s_params?.sport_id ?? RoutingHelper.NewRecordSymbol,
                  team: RoutingHelper.NewRecordSymbol,
                })}
              >
                New
              </CTAButton>,
              <TouchButton key={'Container.CTA_Touch_All'}>
                Touch All
              </TouchButton>,
              <ReloadButton
                key={'Container.RealoadButton'}
                reloadFn={reload}
                from={opt.alert.texts.form}
              />,
            ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )
}

export default TableWithAdd
