import React, { useState, useContext } from 'react'
// Components
import Prompt, { Response } from 'components/utils/Prompt'
// Styles
import CTAButton from './CTAButton'
// Additional
import { FormContext } from 'components/utils/Form.context'

const DeleteButton: React.FC<{
  isLocked?: boolean
}> = (p) => {
  const { delete: f_delete } = useContext(FormContext.Context)

  const [delete_, setDelete] = useState<{
    isBtnLocked: boolean
    isPrompt: boolean
  }>({
    isBtnLocked: false,
    isPrompt: false,
  })

  const handleDelete = (): void => {
    setDelete({
      isBtnLocked: true,
      isPrompt: true,
    })
  }
  const handleSubmitDeleteResponse = (response: Response) => {
    setDelete({
      isBtnLocked: false,
      isPrompt: false,
    })

    if (response === 'Yes') {
      f_delete?.()
    }
  }

  return (
    <>
      {delete_.isPrompt && (
        <Prompt
          question={`Are you sure, you want to delete record?`}
          callback={handleSubmitDeleteResponse}
        />
      )}
      <CTAButton
        theme={'red'}
        onClick={handleDelete}
        disabled={p.isLocked || delete_.isBtnLocked}
      >
        Delete
      </CTAButton>
    </>
  )
}

export default DeleteButton
