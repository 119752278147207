import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { SportsmanRankContext } from 'components/SportsmanRanks/__SportsmanRanks.context'

const SportsmanRank: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.SportsmanRankParams>()

  return (
    <SportsmanRankContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </SportsmanRankContext.Provider>
  )
}

export default SportsmanRank
