import { AppContext } from "App";
import { TeamOrganizationRecord } from "data/TeamOrganizationRecord";
import { useFormik } from "formik";
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import React, { ComponentProps, useContext, useState } from "react";
import * as Yup from 'yup'
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
import { ItemWrapper, OffsetedContainer } from "styles/common";
import { FormContext } from "components/utils/Form.context";

const submitKeys = [
  'name'
] as Array<keyof TeamOrganizationRecord>

type ValidationKeys = Record<
  keyof Pick<TeamOrganizationRecord, 'name'>,
  any
>

const TeamOrganizationForm: React.FC<FormHelper.Props<TeamOrganizationRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: TeamOrganizationRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new'})

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'TeamOrganization'
      },
    },
    history: history,
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,
    },

    onSubmit: async (values) => {
      if (!db?.teamOrganizations) return

      try{
        const data = await FormHelper.submitChanges<TeamOrganizationRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: submitKeys,
          data: Object.assign(new TeamOrganizationRecord(), values ?? {}),
          repository: db.teamOrganizations,
          optional: opt
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing'})

          history?.replace(
            RoutingHelper.getTeamOrganizationDetailUrl({
              teamOrganization: data.id
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      name: Yup.string().required('Name is a required field')
    })
  })

  const delete_ = async () => {
    if (!db?.teamOrganizations) return

    await FormHelper.delete_({
      data: Object.assign(new TeamOrganizationRecord(), formik.values ?? {}),
      repository: db.teamOrganizations,
      optional: opt
    })
  }

  //#region Form Components

  const _Id = (
    <FormItem
      label = {<Form.Label>Id</Form.Label>}
      input={
        <>
          <Form.Control
            name={'id' as keyof TeamOrganizationRecord}
            value={formik.values.id}
            readOnly={true}
          />
        </>
      }
    />
  )

  const _Name = (
    <FormItem
      label={<Form.Label>Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'name' as keyof TeamOrganizationRecord}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {}
      }

    return { useSave: {}, useDelete: {}}
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header />

          <Form.Row>
            <Col as={ItemWrapper}>{_Id}</Col>
          </Form.Row>
          <Form.Row>
            <Col as={ItemWrapper}>{_Name}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default TeamOrganizationForm
