import React, { useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import CTAButton from 'components/utils/Button/CTAButton'
import FormItem from 'components/utils/FormItem'
// Styles
import { ItemWrapper } from 'styles/common'
import { Center } from 'styles/common'
// Additional
import { AppContext } from 'App'

const Login: React.FC = () => {
  const [showIncorrectLogin, setShowIncorrectLogin] = useState<{
    incrLogin: boolean
    info?: string
  }>({ incrLogin: false })
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const { alert, login } = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    onSubmit: async ({email, password}) => {
      try {
        setIsSubmitted(true)
  
        const loggedIn = await login?.(email, password)
  
        if (!loggedIn?.ok) {
          setShowIncorrectLogin({
            incrLogin: true,
            info: loggedIn?.error,
          })
  
          return
        }
  
        alert?.show(
          `User has been successfully signed in as ${email}`,
          {
            type: 'success',
          }
        )
      } finally {
        setIsSubmitted(false)
      }
    },

    //@ts-ignore
    validationSchema: Yup.object({
      email: Yup.string()
        .email('It should be a valid email address')
        .required('Email is a required'),
      password: Yup.string().required('Password is a required'),
    }),
  })

  return (
    <Center as={Container} style={{ height: '100vh' }}>
      <Col xs={12} sm={10} md={8} lg={6} xl={4}>
        <ItemWrapper>
          <Center as={'h2'}>Sign in</Center>
        </ItemWrapper>
        <Alert
          variant={'danger'}
          dismissible
          transition
          onClose={() => {
            setShowIncorrectLogin({ incrLogin: false })
          }}
          show={showIncorrectLogin.incrLogin}
        >
          {showIncorrectLogin.info}
        </Alert>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Row as={ItemWrapper}>
            <FormItem
              label={<Form.Label>Email</Form.Label>}
              input={
                <>
                  <Form.Control
                    name={'email'}
                    type={'email'}
                    value={formik.values.email ?? ''}
                    required
                    isInvalid={formik.submitCount > 0 && !!formik.errors.email}
                    onChange={formik.handleChange}
                  />
                  <Form.Control.Feedback type={'invalid'}>
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </>
              }
            />
          </Form.Row>
          <Form.Row as={ItemWrapper}>
            <FormItem
              label={<Form.Label>Password</Form.Label>}
              input={
                <>
                  <Form.Control
                    name={'password'}
                    type={'password'}
                    value={formik.values.password ?? ''}
                    required
                    isInvalid={
                      formik.submitCount > 0 && !!formik.errors.password
                    }
                    onChange={formik.handleChange}
                  />
                  <Form.Control.Feedback type={'invalid'}>
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </>
              }
            />
          </Form.Row>

          <Form.Row as={ItemWrapper}>
            <CTAButton useLoading={isSubmitted} type={'submit'}>
              Sign in
            </CTAButton>
          </Form.Row>
        </Form>
      </Col>
    </Center>
  )
}

export default Login
