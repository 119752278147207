import React from 'react'
import styled from 'styled-components'
// Components
import { Spinner } from 'react-bootstrap'
import { Center } from 'styles/common'

const LoadingWrapper = styled.div`
  height: 256px;
`

const Loading: React.FC = () => (
  <Center as={LoadingWrapper}>
    <LoadingSpinner />
  </Center>
)

export const LoadingSpinner = React.forwardRef(
  (props: any, ref: React.Ref<HTMLElement>) => (
    <Spinner
      {...ref}
      {...props}
      as={'span'}
      animation={'border'}
      className={'loading-spinner'}
    />
  )
)
LoadingSpinner.displayName = 'LoadingSpinner'

export default Loading
