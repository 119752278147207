import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  Provider as AlertProvider,
  AlertProviderProps,
  AlertComponentPropsWithStyle,
} from 'react-alert'
// Components
import { Alert } from 'react-bootstrap'
import { BrowserRouter as Router } from 'react-router-dom'

const options = {
  transition: 'fade',
  timeout: 5000
} as AlertProviderProps

const AlertTemplate: React.FC<AlertComponentPropsWithStyle> = (p) => (
  <div style={p.style}>
    <Alert variant={p.options.type} onClose={p.close} dismissible show transition>
      {p.message}
    </Alert>
  </div>
)

const SmallMobileBreakpoint = 500
const DesktopBreakpoint = 767

ReactDOM.render(
  <>
    {/* -------------- Alert Styles --------------  */}
    <style>{`
      @media screen and (min-width: ${SmallMobileBreakpoint}px) {
        body #__react-alert__ > div > div {
          min-width: ${SmallMobileBreakpoint}px;
        }
      }

      @media screen and (max-width: ${DesktopBreakpoint}px) {
        body #__react-alert__ > div > div {
          width: 100%;
        }
      }
    `}</style>
    {/* ------------------------------------------- */}
    {/* ------------- Root/App Styles ------------- */}
    <style>{`
      #root {
        height: 100vh;
        overflow-x: hidden;
      }
    `}</style>
    {/* ------------------------------------------- */}
    <React.StrictMode>
      <AlertProvider {...options} template={AlertTemplate}>
        <Router>
          <App />
        </Router>
      </AlertProvider>
    </React.StrictMode>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
