import React from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
// Additional
import { OnReloadTriggerListener } from 'components/utils/Reload.event'

interface Props {
  readonly reloadFn: () => Promise<void>
  readonly from: OnReloadTriggerListener.Location
}

const ReloadButton: React.FC<Props> = (p) => {
  const handleReloadClick = async () => {
    try {
      await p.reloadFn()

      OnReloadTriggerListener.dispatch(p.from)
    } catch (err) {
      console.error(`Could not be reloaded, due to: ${err}`)
    }
  }

  return <_ReloadButton callbackClick={handleReloadClick} />
}

const _ReloadButton: React.FC<{ callbackClick: () => void }> = (p) => (
  <CTAButton onClick={p.callbackClick} theme={'blue'}>
    Reload
  </CTAButton>
)

export default ReloadButton
