import React from 'react'
// Components
import SportsmanTeamMemberships from 'components/SportsmanTeamMemberships/Table/TableWithAdd'
import SportsmanBiographyForm from 'components/SportsmanBiographies/_Detail'
// Additional
import { Tab } from 'static/tabs'
import { TabMapper } from 'components/utils/TabsLoader'
import SportsmanGamingGearsDetail from 'components/SportsmanGamingGears/_Detail'
import SportsmanDatasourceIdentifier from 'components/SportsmanDatasourceIdentifiers/__SportsmanDatasourceIdentifiers'

export const Mapper: TabMapper = {
  [Tab.SportsmanBiographies]: {
    routes: {
      [`/${Tab.SportsmanBiographies}`]: {
        node: <SportsmanBiographyForm />
      }
    }
  },
  [Tab.SportsmanTeamMemberships]: {
    routes: {
      [`/${Tab.SportsmanTeamMemberships}`]: {
        node: <SportsmanTeamMemberships />,
      },
    },
  },
  [Tab.SportsmanGamingGears]: {
    routes: {
      [`/${Tab.SportsmanGamingGears}`]: {
        node: <SportsmanGamingGearsDetail />
      }
    }
  },
  [Tab.SportsmanDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.SportsmanDatasourceIdentifiers}`]: {
        node: <SportsmanDatasourceIdentifier phase={'select'} />
      }
    }
  }

}
