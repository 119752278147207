import { AppContext } from 'App'
import React, { useContext } from 'react'
import { Tab } from 'static/tabs'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Reload as ReloadHelper } from 'helpers/reload'
import { Data as DataHelper } from 'helpers/data'
import Accordion from 'components/utils/Accordion/Accordion'
import Container from 'components/utils/GridContainer/GridContainer'
import CTAButton from 'components/utils/Button/CTAButton'
import Table from './Table'
import ReloadButton from 'components/utils/Button/ReloadButton'

const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.TournamentStageDatasourceIdentifiers,
      },
    },
  }

  const touchAll = async () => {
    if (!db?.gamingGears) return

    await FormHelper.touchAll({
      repository: db.tournamentStageDatasourceIdentifiers,
      optional: opt,
    })
  }

  const reload = async () => {
    if (!db?.datasources) return

    return await ReloadHelper.reload({
      getAllFn: () => DataHelper.getAllTournamentStageDatasourceIdentifiers(db),
      optional: opt
    })
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <CTAButton
                key={'Container.CTA_New'}
                path={RoutingHelper.getTournamentStageDatasourceIdentifierDetailUrl({
                  tournamentStageDatasourceIdentifier: RoutingHelper.NewRecordSymbol,
                })}
              >
                New
              </CTAButton>,
              <ReloadButton
              key={'Container.RealoadButton'}
              reloadFn={reload}
              from={opt.alert.texts.form}
            />
          ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )
}

export default TableWithAdd
