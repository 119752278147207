import React, { useContext } from 'react'
// Styles
import * as S from './LoginDropdown.styles'
// Components
import { CustomTextToggle, CustomMenu } from '../CustomDropdown'
import { Dropdown } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// Additional
import { AppContext } from 'App'

const DropdownToggle: React.FC = (p) => (
  <>
    <S.OverflowTextWrapper>{p.children}</S.OverflowTextWrapper>
    <S.ArrowDown />
  </>
)

const DropdownMenu: React.FC<{
  callback?: React.MouseEventHandler<HTMLElement>
}> = (p) => (
  <S.MenuWrapper>
    <S.ItemWrapper>
      <Link to={`/change-password`}>Change password?</Link>
    </S.ItemWrapper>

    <Button variant={'secondary'} onClick={p.callback}>
      Logout
    </Button>
  </S.MenuWrapper>
)

const LoginDropdown: React.FC = () => {
  const { alert, user, logout } = useContext(AppContext)

  const handleLogoutClick = async () => {
    const loggedOut = await logout?.()

    if (!loggedOut?.ok) {
      alert?.show('Something went wrong, the user cannot be logged out', {
        //@ts-ignore
        type: 'danger',
      })
      
      return
    }
    
    alert?.show('User has been successfully logged out', {
      type: 'success',
    })
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomTextToggle}>
        <DropdownToggle>{user?.email}</DropdownToggle>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} alignRight>
        <DropdownMenu callback={handleLogoutClick} />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LoginDropdown
