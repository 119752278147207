import { AppContext } from "App"
import { TeamContext } from "components/Team/__Team.context"
import React, { useContext, useState } from "react"
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'
import { TeamBiographyRecord } from "data/TeamBiographyRecord"
import useAsyncEffect from 'use-async-effect'
import { Text } from 'static/text'
import Warning from 'components/utils/Warning'
import Loading from 'components/utils/Loading'
import { ItemWrapper } from "styles/common"
import TeamBiographyForm from "./Form"

const Detail: React.FC<{ isNew?: boolean }> = (p) => {
  const { db: a_db, alert: a_alert, locked: a_locked } = useContext(AppContext)

  const { params: t_params } = useContext(TeamContext)

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: TeamBiographyRecord
    load: {
      isInProgress?: boolean
      isNewRecord?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (!t_params?.team_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true} }))

    try {
      const _data = await DataHelper.getTeamBiographyByTeamId(
        a_db!,
        t_params?.team_id,
        {
          alert: a_alert,
        }
      )

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false, isNewRecord: !_data },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true }
      }))
    }
  }, [t_params])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked:boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <TeamBiographyForm
          data={(content?.data)}
          state={content?.load.isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
