import React from 'react'
// Styles
import { LockIcon } from 'styles/fontAwesome'
// Additional
import { SportsmanStatSummaryRecord } from 'data/SportsmanStatSummaryRecord'
import { RecordSetting } from 'components/utils/Table/_Table.settings'

const Settings: RecordSetting<SportsmanStatSummaryRecord> = [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'isFrozen',
    className: 'table-lock',
    Header: function HeaderContent({ row }: any) {
      return <LockIcon />
    },
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isFrozen && <LockIcon />}</>
    },
  },
  {
    accessor: 'sportsman_nickname',
    Header: 'Nickname',
    className: 'table-stick-left',
    sticky: 'left'
  },
  {
    accessor: 'sportsman_name',
    Header: 'Name',
  },
  {
    accessor: 'group_size',
    Header: 'Group size',
    className: 'table-group-size',
  },
  // >>> SF points >>>
  // {
  //   accessor: 'fantasy_points_sum',
  //   Header: 'Fantasy Points Sum',
  // },
  // {
  //   accessor: 'fantasy_points_per_match_avg',
  //   Header: 'Fantasy Points Per Match Avg',
  // },
  // {
  //   accessor: 'fantasy_points_per_map_avg',
  //   Header: 'Fantasy Points Per Map Avg',
  // },
  // {
  //   accessor: 'fantasy_points_per_round_avg',
  //   Header: 'Fantasy Points Per Round Avg',
  // },
  // {
  //   accessor: 'fantasy_points_rank_from',
  //   Header: 'Fantasy Points Per Rank From',
  // },
  // {
  //   accessor: 'fantasy_points_rank_to',
  //   Header: 'Fantasy Points Per Rank To',
  // },
  // <<< SF points <<<
  // >>> match summary >>>
  {
    accessor: 'matches_played_sum',
    Header: 'Matches Played Sum',
  },
  {
    accessor: 'matches_won_sum',
    Header: 'Matches Won Sum',
  },
  {
    accessor: 'matches_lost_sum',
    Header: 'Matches Lost Sum',
  },
  {
    accessor: 'matches_tied_sum',
    Header: 'Matches Tied Sum',
  },
  {
    accessor: 'matches_won_ratio',
    Header: 'Matches Won Ratio',
  },
  // <<< match summary <<<
  // >>> map summary >>>
  {
    accessor: 'maps_played_sum',
    Header: 'Maps Played Sum',
  },
  {
    accessor: 'maps_won_sum',
    Header: 'Maps Won Sum',
  },
  {
    accessor: 'maps_lost_sum',
    Header: 'Maps Lost Sum',
  },
  {
    accessor: 'maps_tied_sum',
    Header: 'Maps Tied Sum',
  },
  // <<< map summary <<<
  // >>> round summary >>>
  {
    accessor: 'rounds_played_sum',
    Header: 'Rounds Played Sum',
  },
  {
    accessor: 'rounds_played_ct_sum',
    Header: 'Rounds Played CT Sum',
  },
  {
    accessor: 'rounds_played_t_sum',
    Header: 'Rounds Played T Sum',
  },
  {
    accessor: 'rounds_won_sum',
    Header: 'Rounds Won Sum',
  },
  {
    accessor: 'rounds_won_ct_sum',
    Header: 'Rounds Won CT Sum',
  },
  {
    accessor: 'rounds_won_t_sum',
    Header: 'Rounds Won T Sum',
  },
  {
    accessor: 'rounds_lost_sum',
    Header: 'Rounds Lost Sum',
  },
  {
    accessor: 'rounds_lost_ct_sum',
    Header: 'Rounds Lost CT Sum',
  },
  {
    accessor: 'rounds_lost_t_sum',
    Header: 'Rounds Lost T Sum',
  },
  {
    accessor: 'rounds_won_ratio',
    Header: 'Rounds Won Ratio',
  },
  {
    accessor: 'rounds_won_t_ratio',
    Header: 'Rounds Won T Ratio',
  },
  {
    accessor: 'rounds_won_ct_ratio',
    Header: 'Rounds Won CT Ratio',
  },
  // <<< round summary <<<
  // >>> kill summary >>>
  {
    accessor: 'kills_sum',
    Header: 'Kill Sum',
  },
  {
    accessor: 'kills_sum_rank_from',
    Header: 'Kill Sum Rank From',
  },
  {
    accessor: 'kills_sum_rank_to',
    Header: 'Kill Sum Rank To',
  },
  {
    accessor: 'kill_death_ratio',
    Header: 'Kill Death Ratio',
  },
  {
    accessor: 'kill_death_ratio_rank_from',
    Header: 'Kill Death Ratio Rank From',
  },
  {
    accessor: 'kill_death_ratio_rank_to',
    Header: 'Kill Death Ratio Rank To',
  },
  {
    accessor: 'teamkills_sum',
    Header: 'Teamkills Sum',
  },
  {
    accessor: 'assists_sum',
    Header: 'Assists Sum',
  },
  {
    accessor: 'deaths_sum',
    Header: 'Deaths Sum',
  },
  {
    accessor: 'suicides_sum',
    Header: 'Suicides Sum',
  },
  {
    accessor: 'headshots_sum',
    Header: 'Headshots Sum',
  },
  {
    accessor: 'entry_kills_sum',
    Header: 'Entry Kills Sum',
  },
  // <<< kill summary <<<
  // >>> bombs >>>
  {
    accessor: 'opponent_bomb_plants_sum',
    Header: 'Opponent Bomb Plants Sum',
  },
  {
    accessor: 'bomb_defuses_sum',
    Header: 'Bomb Defuses Sum',
  },
  {
    accessor: 'bomb_plants_sum',
    Header: 'Bomb Plants Sum',
  },
  {
    accessor: 'bomb_explodes_sum',
    Header: 'Bomb Explodes Sum',
  },
  {
    accessor: 'bomb_plants_ratio_per_round',
    Header: 'Bomb Plants Ratio Per Round',
  },
  {
    accessor: 'bomb_defuses_ratio_per_round',
    Header: 'Bomb Defuses Ratio Per Round',
  },
  {
    accessor: 'bomb_explodes_ratio_per_round',
    Header: 'Bomb Explodes Ratio Per Round',
  },
  // <<< bombs <<<
  // >>> clutches >>>
  {
    accessor: 'clutches_v1_sum',
    Header: 'Clutches v1 Sum',
  },
  {
    accessor: 'clutches_v1_won_sum',
    Header: 'Clutches v1 Won Sum',
  },
  {
    accessor: 'clutches_v2_sum',
    Header: 'Clutches v2 Sum',
  },
  {
    accessor: 'clutches_v2_won_sum',
    Header: 'Clutches v2 Won Sum',
  },
  {
    accessor: 'clutches_v3_sum',
    Header: 'Clutches v3 Sum',
  },
  {
    accessor: 'clutches_v3_won_sum',
    Header: 'Clutches v3 Won Sum',
  },
  {
    accessor: 'clutches_v4_sum',
    Header: 'Clutches v4 Sum',
  },
  {
    accessor: 'clutches_v4_won_sum',
    Header: 'Clutches v4 Won Sum',
  },
  {
    accessor: 'clutches_v5_sum',
    Header: 'Clutches v5 Sum',
  },
  {
    accessor: 'clutches_v5_won_sum',
    Header: 'Clutches v5 Won Sum',
  },
  {
    accessor: 'clutches_ratio_per_round',
    Header: 'Clutches Ratio Per Round',
  },
  {
    accessor: 'clutches_won_ratio_per_round',
    Header: 'Clutches Won Ratio Per Round',
  },
  // <<< clutches <<<
  {
    accessor: 'damage_sum',
    Header: 'Damage Sum',
  },
  {
    accessor: 'weapon_kills',
    Header: 'Weapon Kills',
  },
]

export default Settings
