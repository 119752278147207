import React, { useEffect, useMemo, useContext } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  Row,
  Column,
  UseTableRowProps,
} from 'react-table'
import { matchSorter } from 'match-sorter'
import { Table } from 'react-bootstrap'
// Styles
import * as T from './_Table.styles'
// Additional
import { OnGlobalFilterChangeListener } from 'components/utils/Filter/_Filter'
import { AppContext } from 'App'
import { Url } from 'utils/url'
import { URLParams } from 'components/utils/Pagination/Pagination'

/* eslint-disable @typescript-eslint/no-var-requires */
const { useSticky } = require('react-table-sticky')

interface Props {
  readonly style?: T.StyleProps

  readonly content: {
    readonly columns?: Array<Column>
    readonly data?: any
  }

  readonly disableNoDataText?: boolean
}

/* eslint-disable @typescript-eslint/ban-ts-comment*/

function fuzzyTextFilterFn(
  rows: Array<UseTableRowProps<any>>,
  id: any,
  filterValue: any
) {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
    threshold: matchSorter.rankings.CONTAINS,
    keepDiacritics: true,
  })
}

const UITable: React.FC<Props & { syncKey?: string }> = (p) => {
  const columns = useMemo(() => p.content.columns, []) as Array<Column>
  const data = useMemo(() => p.content.data, [p.content.data])

  const filterTypes = useMemo(() => ({ fuzzyText: fuzzyTextFilterFn }), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // @ts-ignore
    page,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    prepareRow,
    // @ts-ignore
    setPageSize,
    state,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      filterTypes,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    // @ts-ignore
    useSticky
  )

  // @ts-ignore
  const { pageIndex, pageSize } = state
  
  const { history: a_history } = useContext(AppContext)

  useEffect(() => {
    const _page = Url.parseAndMapParams<URLParams>(
      a_history?.location?.search
    ).page

    if (!_page) return

    const _index = _page - 1

    if (_index === undefined) return
    
    gotoPage(_index)
  })

  useEffect(() => {
    const _size = Url.parseAndMapParams<URLParams>(
      a_history?.location?.search
    ).size

    if (!_size) return
    
    if (pageSize == _size) return
    
    // @ts-ignore
    setPageSize(parseInt(_size))
  })

  useEffect(() => {
    OnTableDataChangeListener.dispatch(rows)
  }, [rows])

  useEffect(() => {
    OnTablePageChangeListener.dispatch(
      {
        response: {
          type: OnTablePageChangeListener.RequestType.SIZE,
          pages: {
            page: { size: pageSize },
            length: pageOptions.length,
          },
          records: rows.length,
        },
      },
      p.syncKey
    )
  }, [rows, pageSize])

  useEffect(() => {
    OnTablePageChangeListener.dispatch(
      {
        response: {
          type: OnTablePageChangeListener.RequestType.PAGE,
          pages: {
            page: { index: pageIndex },
            length: pageOptions.length,
          },
          records: rows.length,
        },
      },
      p.syncKey
    )
  }, [rows, pageIndex])

  useEffect(() => {
    const handlePageChange = (e: CustomEvent): void => {
      const _data = e.detail as OnTablePageChangeListener.PageChangeEventArgs

      if (!_data.request) return

      switch (_data.request?.type) {
        case OnTablePageChangeListener.RequestType.PAGE: {
          gotoPage(_data.request?.page?.index ?? 0)
          break
        }
        case OnTablePageChangeListener.RequestType.PREV: {
          previousPage()
          break
        }
        case OnTablePageChangeListener.RequestType.NEXT: {
          nextPage()
          break
        }
        case OnTablePageChangeListener.RequestType.SIZE: {
          setPageSize(_data.request.page?.setSize ?? 10)
          break
        }
      }
    }

    const handleGlobalFilterChange = (e: CustomEvent): void => {
      const _data =
        e.detail as OnGlobalFilterChangeListener.GlobalFilterChangeEventArgs

      setGlobalFilter(_data.filterValue)
    }

    OnTablePageChangeListener.addAndRemove(
      handlePageChange as EventListener,
      p.syncKey
    )

    OnGlobalFilterChangeListener.addAndRemove(
      handleGlobalFilterChange as EventListener
    )
  }, [p.syncKey])

  return (
    <>
      <T.Table as={Table} bordered {...getTableProps()}>
        <T.TableHead addCSS={p.style?.thead}>
          {headerGroups.map((headerGroup, idx) => (
            <T.TableHeadRow
              addCSS={p.style?.theadR}
              {...headerGroup.getHeaderGroupProps()}
              key={`THeadR_${idx}`}
            >
              {headerGroup.headers.map((column, idx) => (
                <T.TableHeadHeader
                  addCSS={p.style?.theadH}
                  {...column.getHeaderProps([
                    {
                      // @ts-ignore
                      style: column.style,
                      // @ts-ignore
                      className: column.className,
                    },
                  ])}
                  key={`THeadH_${idx}`}
                >
                  {
                    // @ts-ignore
                    column.Header ? column.render('Header') : null
                  }
                  {
                    // @ts-ignore
                    column.Filter ? column.render('Filter') : null
                  }
                </T.TableHeadHeader>
              ))}
            </T.TableHeadRow>
          ))}
        </T.TableHead>
        <T.TableBody addCSS={p.style?.tbody} {...getTableBodyProps()}>
          {
            // @ts-ignore
            page.map((row, idx) => {
              prepareRow(row)
              return (
                <T.TableBodyRow
                  addCSS={p.style?.tbodyR}
                  {...row.getRowProps()}
                  key={`TBodyR_${idx}`}
                >
                  {
                    // @ts-ignore
                    row.cells.map((cell, idx) => {
                      return (
                        <T.TableBodyDataCell
                          addCSS={p.style?.tbodyD}
                          {...cell.getCellProps([
                            {
                              // @ts-ignore
                              style: cell.style,
                              // @ts-ignore
                              className: cell.column.className,
                            },
                          ])}
                          key={`TBodyTd_${idx}`}
                        >
                          {
                            // @ts-ignore
                            cell ? cell.render('Cell') : null
                          }
                        </T.TableBodyDataCell>
                      )
                    })
                  }
                </T.TableBodyRow>
              )
            })
          }
        </T.TableBody>
      </T.Table>
      {!p.disableNoDataText && page.length === 0 && (
        <T.NoDataAvailable>No data available in table</T.NoDataAvailable>
      )}
    </>
  )
}

export default UITable

export module OnTableDataChangeListener {
  const BASE_EVENT_NAME = 'onTableDataChange'

  export function add(listener: EventListener) {
    document.addEventListener(BASE_EVENT_NAME, listener)
  }

  export function remove(listener: EventListener) {
    document.removeEventListener(BASE_EVENT_NAME, listener)
  }

  export function dispatch(rows: Row[]) {
    document.dispatchEvent(
      new CustomEvent(BASE_EVENT_NAME, {
        detail: {
          ...rows.map((r) => {
            return r.values
          }),
        },
      })
    )
  }

  export function addAndRemove(listener: EventListener) {
    add(listener)

    return () => {
      remove(listener)
    }
  }
}

export module OnTablePageChangeListener {
  const BASE_EVENT_NAME = 'onTablePageChange'

  type SyncIdType = string

  const getEventName = (syncId?: SyncIdType) =>
    `${BASE_EVENT_NAME}${syncId ? `_${syncId}` : ''}`

  export enum RequestType {
    PREV = 0,
    NEXT = 1,
    PAGE = 2,
    SIZE = 3,
  }

  export interface PageChangeEventArgs {
    request?: {
      type: RequestType
      page?: {
        index?: number
        setSize?: number
      }
    }

    response?: {
      type: RequestType

      pages?: {
        page?: {
          index?: number
          size?: number
        }
        length?: number
      }

      records: number
    }
  }

  export function add(listener: EventListener, syncId?: SyncIdType) {
    document.addEventListener(getEventName(syncId), listener)
  }

  export function remove(listener: EventListener, syncId?: SyncIdType) {
    document.removeEventListener(getEventName(syncId), listener)
  }

  export function dispatch(args: PageChangeEventArgs, syncId?: SyncIdType) {
    document.dispatchEvent(
      new CustomEvent(getEventName(syncId), {
        detail: args,
      })
    )
  }

  export function addAndRemove(listener: EventListener, syncId?: SyncIdType) {
    add(listener, syncId)

    return () => {
      remove(listener, syncId)
    }
  }
}
