import React from 'react'
// Components
import { Col } from 'react-bootstrap'
import DeleteButton from 'components/utils/Button/DeleteButton'
import SaveButton from 'components/utils/Button/SaveButton'
// Styles
import { FormRowAlignRight, ItemWrapper } from 'styles/common'
import { Container } from './Form.styles'

interface Props {
  readonly useSave?: {
    readonly desktopSize?: number
  }
  readonly useDelete?: {
    readonly desktopSize?: number
  }

  readonly locked?: boolean
}

const Footer: React.FC<Props> = (p) => {
  const _DeleteButton = <DeleteButton isLocked={p.locked} />

  const _SaveButton = <SaveButton isLocked={p.locked} />

  return (
    <Container>
      <FormRowAlignRight>
        {p.useDelete && (
          <Col
            as={ItemWrapper}
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: p.useDelete.desktopSize ?? 3 }}
          >
            {_DeleteButton}
          </Col>
        )}
        {p.useSave && (
          <Col
            as={ItemWrapper}
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: p.useSave.desktopSize ?? 3 }}
          >
            {_SaveButton}
          </Col>
        )}
      </FormRowAlignRight>
    </Container>
  )
}

export default Footer
