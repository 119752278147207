import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import Settings from './Table.settings'
import { SportRecord } from 'data/SportRecord'
import { AppContext } from 'App'
import { Data } from 'helpers/data'

const SportsTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const loadData = async () => {
    return await Data.getAllSports(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<SportRecord>
      reloadFrom={[Tab.Sports]}
      loadDataFn={loadData}
      settings={Settings}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default SportsTable
