import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import { LockIcon, CheckIcon, EllipsisHIcon, StarIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { TeamRecord } from 'data/TeamRecord'
import { RecordSetting } from 'components/utils/Table/_Table.settings'

export const Settings = (
  options?: {
  disableNew?: boolean
  }
  ): RecordSetting<TeamRecord> => [
    {
      accessor: 'id',
      className: 'table-hidden',
    },
    {
      accessor: 'isFrozen',
      className: 'table-lock',
      Header: function HeaderContent({ row }: any) {
        return <LockIcon />
      },
      Cell: function CellContent({ row }: any) {
        return <>{row.values.isFrozen && <LockIcon />}</>
      },
    },
    {
      accessor: 'sport_id',
      className: 'table-hidden',
      Filter: function FilterContent(p: any) {
        return <UIColumnFilter from={Tab.Teams} filter={p} />
      },
      filter: 'fuzzyText',
    },
    {
      accessor: 'full_name',
      Header: 'Full Name',
      className: 'table-full-name',
    },
    {
      accessor: 'short_name',
      Header: 'Short Name',
      className: 'table-short-name',
    },
    {
      accessor: 'isApproved',
      Header: 'Approved',
      className: 'table-approved',
      Cell: function CellContent({ row }: any) {
        return <>{row.values.isApproved && <CheckIcon />}</>
      },
    },
    {
      accessor: 'is_popular',
      Header: <StarIcon />,
      className: 'table-approved',
      Cell: function CellContent({ row }: any) {
        return <>{row.values.is_popular && <StarIcon color='goldenrod' />}</>
      },
      Filter: function FilterContent(p: any) {
        return <UIColumnFilter from={Tab.Teams} filter={p} />
      }
    },
    {
      id: 'ctaButton',
      sticky: 'right',
      className: 'table-cta-button',
      Header: function HeaderContent({ row }: any) {
        const { toggle } = useContext(AccordionContext)

        if (options?.disableNew) return <></>

        return (
          <CTAButton onClick={toggle}>
            <EllipsisHIcon />
          </CTAButton>
        )
      },
      Cell: function CellContent({ row }: any) {
        return (
          <CTAButton
            path={Routing.getTeamDetailUrl({
              sport: row.values.sport_id ?? Routing.NewRecordSymbol,
              team: row.values.id,
            })}
          >
            {row.values.isFrozen ? 'View' : 'Edit'}
          </CTAButton>
        )
      },
    },
  ]
