import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router";

import React from 'react'
// Component
import Select from './_Select'
import Detail from './_Detail'
import { NearestMatchesContext } from "./__NearestMatches.context";

const NearestMatch: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.NearestMatchParams>()

  return (
    <NearestMatchesContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </NearestMatchesContext.Provider>
  )
}

export default NearestMatch
