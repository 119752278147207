import styled from 'styled-components/macro'
// Components
import { CrossIcon } from 'styles/fontAwesome'

const OFFSET = '15px'

export const Close = styled(CrossIcon)`
  font-size: 2.25rem;

  color: black;

  z-index: 999;
`

export const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999;

  padding: 45px;

  background-color: #000000cc;
  backdrop-filter: blur(5px);
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: calc(100vw - 15px);
  max-height: calc(100vh - 15px);
  
  min-width: 300px;
  min-height: 300px;

  padding: ${OFFSET};

  box-sizing: border-box;

  position: relative;
  ${Close} {
    position: absolute;

    top: ${OFFSET};
    right: ${OFFSET};

    padding: ${OFFSET};
    margin: -${OFFSET};

    cursor: pointer;
  }

  background-color: #ffffffaa;
  border-radius: 6px;
`
