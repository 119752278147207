import { DateTime } from '../utils/dateTime'
import { BaseRecord } from './BaseRecord'
import { BaseRootRepository } from './repository/BaseRootRepository'
import { RecordRepository } from './repository/RecordRepository'
import { SportsmanRecord } from './SportsmanRecord'
import { TeamRecord } from './TeamRecord'
import { TournamentRecord } from './TournamentRecord'

export class TournamentSportsmanRankRecord<
  TJsonData extends TournamentSportsmanRankRecord.CtorJsonData = TournamentSportsmanRankRecord.CtorJsonData
> extends BaseRecord<TJsonData> {
  rank_from?: number
  rank_to?: number
  data_frozen_at?: Date | null

  /// >>> tournament >>>
  #tournament: BaseRecord.RefField<TournamentRecord> = {}
  
  tournament_id?: string

  get tournament() {
    return this.#tournament.object
  }

  getTournament() {
    this.#tournament.id = this.tournament_id

    return this.getObjectFromRefField(this.#tournament, 'tournaments')
  }
  /// <<< tournament <<<

  /// >>> team >>>
  #team: BaseRecord.RefField<TeamRecord> = {}

  team_id?: string

  get team() {
    return this.#team.object
  }

  getTeam() {
    this.#team.id = this.team_id

    return this.getObjectFromRefField(this.#team, 'teams')
  }
  /// <<< team <<<

  /// >>> sportsman >>>
  #sportsman: BaseRecord.RefField<SportsmanRecord> = {}

  sportsman_id?: string

  get sportsman() {
    return this.#sportsman.object
  }

  getSportman() {
    this.#sportsman.id = this.sportsman_id

    return this.getObjectFromRefField(this.#sportsman, 'sportsmen')
  }
  /// <<< sportsman <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get team_name() {
    return this.team?.short_name
  }
  
  get sportsman_name() {
    return this.sportsman?.full_name
  }

  get sportsman_is_approved() {
    return this.sportsman?.isApproved
  }
  
  get sportsman_nickname() {
    return this.sportsman?.nickname
  }

  get sportsman_name_nickname() {
    return `${this.sportsman?.full_name} - ${this.sportsman?.nickname}`
  }

  get sortValues() {
    return [
      this.rank_from ?? Number.MAX_SAFE_INTEGER,
      this.team_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
      this.sportsman_nickname?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
    ]
  }
  /// <<< computed_values <<<

  static createNew(
    db?: BaseRootRepository.OrNothing,
    jsonData?: TournamentSportsmanRankRecord.CtorJsonData
  ) {
    return new TournamentSportsmanRankRecord(db, jsonData)
  }

  constructor(db?: BaseRootRepository.OrNothing, jsonData?: TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.tournament_id = jsonData?.tournament_id
    this.team_id = jsonData?.team_id
    this.sportsman_id = jsonData?.sportsman_id
    this.rank_from = jsonData?.rank_from
    this.rank_to = jsonData?.rank_to
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson(): TJsonData {
    return Object.assign(super.toJson(), {
      tournament_id: this.tournament_id,
      team_id: this.team_id,
      sportsman_id: this.sportsman_id,
      rank_from: this.rank_from,
      rank_to: this.rank_to,
      data_frozen_at: this.data_frozen_at?.toISOString(),
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const tournament = await this.getTournament()
    await tournament?.preload()

    const team = await this.getTeam()
    await team?.preload()

    const sportsman = await this.getSportman()
    await sportsman?.preload()
  }
}

export module TournamentSportsmanRankRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    tournament_id?: string
    team_id?: string
    sportsman_id?: string
    rank_from?: number
    rank_to?: number
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<TournamentSportsmanRankRecord> {
    #getAll: RecordRepository.GetFieldFlags = {}
    getAll(options?: RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll(),
      })
    }

    /// >>> by tournament_id >>>
    #getByTournamentId: RecordRepository.NestingGetFieldFlags = {}

    getByTournamentId(
      tournamentId: string,
      options?: RecordRepository.GetOptions
    ) {
      return this.nestedPeekOrLoad(
        this.#getByTournamentId,
        tournamentId,
        options,
        {
          peek: () => this.peekByTournamentId(tournamentId),
          load: () => this.loadByTournamentId(tournamentId),
        }
      )
    }

    peekByTournamentId(tournamentId: string) {
      return this.peekAll((x) => x?.tournament_id == tournamentId)
    }

    protected loadByTournamentId(tournamentId: string) {
      return this.getApi().getMoreTournamentSportsmanRanksByTournamentId(
        tournamentId
      )
    }
    /// <<< by tournament id <<<

    create(record: Partial<TournamentSportsmanRankRecord<CtorJsonData>>): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTournamentSportsmanRank(record)
    }

    protected loadAll() {
      return this.getApi().getAllTournamentSportsmanRanks()
    }

    protected loadById(id: string) {
      return this.getApi().getOneTournamentSportsmanRankById(id)
    }

    protected updateById(
      id: string,
      patch: Partial<TournamentSportsmanRankRecord<CtorJsonData>>
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTournamentSportsmanRank(id, patch)
    }

    protected deleteById(
      id: string
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTournamentSportsmanRank(id)
    }

    protected approveById(
      id: string,
      patch: Partial<TournamentSportsmanRankRecord<CtorJsonData>>
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(
      id: string,
      patch: Partial<TournamentSportsmanRankRecord<CtorJsonData>>
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(
      id: string,
      patch: Partial<TournamentSportsmanRankRecord<CtorJsonData>>
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTournamentSportsmanRankById(id, patch)
    }

    protected unfreezeById(
      id: string,
      patch: Partial<TournamentSportsmanRankRecord<CtorJsonData>>
    ): Promise<TournamentSportsmanRankRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTournamentSportsmanRankById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTournamentSportsmanRanks()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTournamentSportsmanRankById(id)
    }
  }
}
