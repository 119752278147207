import { AppContext } from "App"
import React,  { useContext, useEffect, useState } from "react"
import { GamingGearContext } from "./__GamingGears.context"
import { String as StringHelper } from 'helpers/string'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'
import { GamingGearRecord } from "data/GamingGearRecord"
import { useAsyncEffect } from 'use-async-effect'
import { Tab } from "static/tabs"
import { Text } from 'static/text'
import Warning from "components/utils/Warning"
import Loading from "components/utils/Loading"
import { ItemWrapper } from "styles/common"
import GamingGearForm from "./Form"

const Detail: React.FC = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    locked: a_locked,
    location: a_location,
  } = useContext(AppContext)

  const { params: g_params } = useContext(GamingGearContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    g_params?.gaming_gear_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //

  const [content, setContent] = useState<{
    data?: GamingGearRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !g_params?.gaming_gear_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true }}))

    try{
      const _data = await DataHelper.getGamingGearById(a_db!, g_params?.gaming_gear_id, {
        alert: a_alert
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false }
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [g_params])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: GamingGearRecord) => {
    a_location?.set?.(
      Tab.GammingGears,
      data && `${StringHelper.spaceTo(data?.name ?? '')}`
    )
  }

  useEffect(() => {
    _setLocation(content?.data)

    content?.data && a_locked?.set(!!content?.data?.data_frozen_at)
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <GamingGearForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
          submitCallback={_setLocation}
        />
      </ItemWrapper>
    </>
  )

}

export default Detail
