import styled from 'styled-components'
// Styles
import { ItemWrapper } from 'styles/common'

export { ItemWrapper }

export const _Image = styled.img`
  min-height: 50px;
  min-width: 50px;

  border: 1px solid gray;
`

export const ImageWrapper = styled(ItemWrapper)`
  display: flex;
  max-width: -webkit-fill-available;

  justify-content: center;

  ${_Image} {
    position: relative;
  }
`

export const PhotoGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  max-width: -webkit-fill-available;
`
