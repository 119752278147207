import styled, { css } from 'styled-components'

export type IconSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

interface IconProps {
  readonly size?: IconSize
  readonly color?: 'darkorange' | 'black' | 'darkgoldenrod' | 'green' | 'darkred' | 'goldenrod'
}

const Icon = styled.i.attrs<IconProps>(
  ({ size }) => ({
    'aria-hidden': 'true',
    className: `fa-${size ?? 1}x`
  })
)<IconProps>`
  ${({ color }) => {
    if(!color) return css``

    return css`
      color: ${color};
    `
  }}
`

export const FlagIcon = styled(Icon).attrs({
  className: 'fa fa-flag',
})``

export const LockIcon = styled(Icon).attrs({
  className: 'fa fa-lock',
})``

export const CheckIcon = styled(Icon).attrs({
  className: 'fa fa-check',
})``

export const CrossIcon = styled(Icon).attrs({
  className: 'fa fa-times',
})``

export const FilledCircleIcon = styled(Icon).attrs({
  className: 'fa fa-circle'
})``

export const CircleIcon = styled(Icon).attrs({
  className: 'fa fa-circle-o'
})``

export const LoadIcon = styled(Icon).attrs({
  className: 'fa fa-spinner'
})``

export const WarningIcon = styled(Icon).attrs({
  className: 'fa fa-exclamation-triangle'
})``

export const EllipsisHIcon = styled(Icon).attrs({
  className: 'fa fa-ellipsis-h fa-lg',
})``

export const EllipsisVIcon = styled(Icon).attrs({
  className: 'fa fa-ellipsis-v fa-lg',
})``

export const ChevronDownIcon = styled(Icon).attrs({
  className: 'fa fa-chevron-down ',
})``

export const StarIcon = styled(Icon).attrs({
  className: 'fa fa-star fa-solid',
})``

export const TimesIcon = styled(Icon).attrs({
  className: 'fas fa-times',
})``