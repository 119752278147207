import { AnyRecord } from "types/anyRecord";
import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { SportsmanRecord } from "./SportsmanRecord"
import { TeamRecord } from "./TeamRecord"
import { TournamentRecord } from "./TournamentRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"

export class SportsmanTeamMembershipRecord<TJsonData extends SportsmanTeamMembershipRecord.CtorJsonData = SportsmanTeamMembershipRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  first_match_at?: Date | null
  last_match_at?: Date | null
  is_current?: boolean
  is_last?: boolean
  data_frozen_at?: Date | null

  /// >>> sportsman >>>
  #sportsman:BaseRecord.RefField<SportsmanRecord> = {}

  sportsman_id?: string

  get sportsman() {
    return this.#sportsman?.object
  }
  getSportsman() {
    this.#sportsman.id = this.sportsman_id

    return this.getObjectFromRefField(this.#sportsman, "sportsmen")
  }
  /// <<< sportsman <<<

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}

  team_id?: string

  get team() {
    return this.#team?.object
  }
  async getTeam() {
    this.#team.id = this.team_id

    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  /// >>> computed values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get team_name() {
    return this.team?.short_name
  }

  get sportsman_name() {
    return this.sportsman?.full_name
  }

  get sportsman_is_approved() {
    return this.sportsman?.isApproved
  }

  get sportsman_nickname() {
    return this.sportsman?.nickname
  }

  get tournamentSortValues() {
    return [
      this.team_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
      this.sportsman_nickname?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
    ]
  }

  get teamSortValues() {
    return [
      // Number.MAX_SAFE_INTEGER - (this.tournament?.starts_at?.getTime() ?? 0),
      this.sportsman_nickname?.toLowerCase() ?? Number.MAX_SAFE_INTEGER,
    ]
  }

  get sportsmanSortValues() {
    return [
      // Number.MAX_SAFE_INTEGER - (this.tournament?.starts_at?.getTime() ?? 0),
      Number.MAX_SAFE_INTEGER - (this.first_match_at?.getTime() ?? 0),
    ]
  }
  /// <<< computed values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanTeamMembershipRecord.CtorJsonData) {
    return new SportsmanTeamMembershipRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.sportsman_id = jsonData?.sportsman_id
    this.team_id = jsonData?.team_id
    this.first_match_at = DateTime.parseDateOrNull(jsonData?.first_match_at)
    this.last_match_at = DateTime.parseDateOrNull(jsonData?.last_match_at)
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.is_current = jsonData?.is_current
    this.is_last = jsonData?.is_last
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      sportsman_id: this.sportsman_id,
      team_id: this.team_id,
      first_match_at: this.first_match_at?.toISOString(),
      last_match_at: this.last_match_at?.toISOString(),
      data_frozen_at: this.data_frozen_at?.toISOString(),
      is_current: this.is_current,
      is_last: this.is_last,
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const sportsman = await this.getSportsman()
    await sportsman?.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module SportsmanTeamMembershipRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    sportsman_id?: string
    team_id?: string
    first_match_at?: string
    last_match_at?: string
    data_frozen_at?: string
    is_current?: boolean
    is_last?: boolean
  }

  export class Repository extends RecordRepository<SportsmanTeamMembershipRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by sportsman_id >>>
    #getBySportsmanId:RecordRepository.NestingGetFieldFlags = {}

    getBySportsmanId(sportsmanId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getBySportsmanId, sportsmanId, options, {
        peek: () => this.peekBySportsmanId(sportsmanId),
        load: () => this.loadBySportsmanId(sportsmanId)
      })
    }

    peekBySportsmanId(sportsmanId:string) {
      return this.peekAll(x => x?.sportsman_id == sportsmanId)
    }

    protected loadBySportsmanId(sportsmanId:string) {
      return this.getApi().getMoreSportsmanTeamMembershipsBySportsmanId(sportsmanId)
    }
    /// <<< by sportsman_id <<<

    /// >>> by team_id >>>
    #getByTeamId:RecordRepository.NestingGetFieldFlags = {}

    getByTeamId(teamId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTeamId, teamId, options, {
        peek: () => this.peekByTeamId(teamId),
        load: () => this.loadByTeamId(teamId)
      })
    }

    peekByTeamId(teamId:string) {
      return this.peekAll(x => x?.team_id == teamId)
    }

    protected loadByTeamId(teamId:string) {
      return this.getApi().getMoreSportsmanTeamMembershipsByTeamId(teamId)
    }
    /// <<< by team_id <<<

    create(record: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSportsmanTeamMembership(record)
    }

    protected loadAll() {
      return this.getApi().getAllSportsmanTeamMemberships()
    }

    protected loadById(id: string): Promise<SportsmanTeamMembershipRecord | null> {
      return this.getApi().getOneSportsmanTeamMembershipById(id)
    }

    protected updateById(id: string, patch: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsmanTeamMembership(id, patch)
    }

    protected deleteById(id: string): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSportsmanTeamMembership(id)
    }

    protected approveById(id: string, patch: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneSportsmanTeamMembershipById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<SportsmanTeamMembershipRecord<CtorJsonData>>): Promise<SportsmanTeamMembershipRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneSportsmanTeamMembershipById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSportsmanTeamMemberships()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportsmanTeamMembershipById(id)
    }
  }
}
