import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { TeamContext } from 'components/Team/__Team.context'

const Team: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TeamParams>()

  return (
    <TeamContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TeamContext.Provider>
  )
}

export default Team
