import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { TeamRankContext } from 'components/TeamRanks/__TeamRanks.context'

const TeamRank: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TeamRankParams>()

  return (
    <TeamRankContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TeamRankContext.Provider>
  )
}

export default TeamRank
