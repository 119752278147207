import React, { useState, useEffect, useContext, useRef } from 'react'
// Components
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Item } from 'components/utils/Dropdown'
import FormItem from 'components/utils/FormItem'
import DropdownFilter from 'components/utils/Filter/DropdownFilter'
// Styles
import { ItemWrapper, FormRowAlignBottom } from 'styles/common'
// Addtitional
import { Loadable } from 'types/loadable'
import { AppContext } from 'App'
import { Tab } from 'static/tabs'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { Data } from 'helpers/data'
import { MatchRecord } from 'data/MatchRecord'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const { db } = useContext(AppContext)
  const { params } = useContext(TournamentContext)

  const stageDefaultItem: Item = { id: -1, text: 'Select Stage ...' }
  const roundDefaultItem: Item = { id: -1, text: 'Select Round ...' }

  const [stages, setStages] = useState<
    { value: Item; data?: Array<Item> } & Loadable
  >({
    value: stageDefaultItem,
    loaded: false,
  })
  const [rounds, setRounds] = useState<
    { value: Item; data?: Array<Item> } & Loadable
  >({
    value: roundDefaultItem,
    loaded: false,
  })

  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )

    _setStages()
  }, [])

  useEffect(() => {
    _setRounds()
  }, [stages])

  const stageFilterRef = useRef<React.ElementRef<typeof DropdownFilter>>(null)
  const roundFilterRef = useRef<React.ElementRef<typeof DropdownFilter>>(null)

  const _setStages = async () => {
    const _data = (await Data.getAllStages(db!))?.filter(
      (x) => x.tournament?.id === params?.tournament_id
    )

    const _mappedData = _data?.map((s) => {
      return { id: s.id, text: s.name } as Item
    })

    // Prepend 'No stage'
    _mappedData?.unshift({ id: 'null', text: 'No stage' } as Item)

    setStages((prev) => ({
      loaded: true,
      value: prev.value,
      data: _mappedData,
    }))
  }

  const _setRounds = async () => {
    const _data = (await Data.getAllRounds(db!))?.filter(
      (f) => stages.value.id === f.tournament_stage_id
    )

    const _mappedData = _data?.map((s) => {
      return { id: s.id, text: s.name } as Item
    })

    // Prepend 'No stage'
    _mappedData?.unshift({ id: 'null', text: 'No round' } as Item)

    setRounds((prev) => ({
      loaded: true,
      value: prev.value,
      data: _mappedData,
    }))
  }

  const _resetRound = () => {
    setRounds({ value: roundDefaultItem, loaded: false })

    roundFilterRef.current?.forceFilter(roundDefaultItem)
  }

  const handleStageChange = (item: Item) => {
    setStages((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))

    _resetRound()
  }

  const handleRoundChange = (item: Item) => {
    setRounds((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  return (
    <FormRowAlignBottom style={{ marginTop: 9 }}>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Stage</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Matches}
              ref={stageFilterRef}
              accessor={'tournamentStage_id_filter' as keyof MatchRecord}
              items={stages.data ?? []}
              onSelect={handleStageChange}
              value={stages.value}
              defaultItem={stageDefaultItem}
              loading={!stages.loaded || !table.loaded}
            />
          }
        />
      </Col>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Round</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Matches}
              ref={roundFilterRef}
              accessor={'tournamentRound_id_filter' as keyof MatchRecord}
              items={rounds.data ?? []}
              onSelect={handleRoundChange}
              value={rounds.value}
              defaultItem={roundDefaultItem}
              loading={!rounds.loaded || !table.loaded}
              disabled={stages.value.id === stageDefaultItem.id}
            />
          }
        />
      </Col>
    </FormRowAlignBottom>
  )
}

export default Filters
