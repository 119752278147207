import React, { useState, useEffect, useContext } from 'react'
// Components
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Item } from 'components/utils/Dropdown'
import FormItem from 'components/utils/FormItem'
import DropdownFilter from 'components/utils/Filter/DropdownFilter'
import UIFullTextGlobalFilter from 'components/utils/Filter/FullTextGlobalFilter'
// Styles
import { ItemWrapper, FormRowAlignBottom } from 'styles/common'
// Addtitional
import { Loadable } from 'types/loadable'
import { AppContext } from 'App'
import { Tab } from 'static/tabs'
import { Data } from 'helpers/data'
import { TeamRecord } from 'data/TeamRecord'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const { db } = useContext(AppContext)

  const sportDefaultItem: Item = { id: -1, text: 'Select Sport ...' }
  const defaultPopularShowItem: Item = { id: -1, text: 'Show all' }

  const [sports, setSports] = useState<
    { value: Item; data?: Array<Item> } & Loadable
  >({
    value: sportDefaultItem,
    loaded: false,
  })

  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  const [popularShowItems, setPopularShowItems] = useState<{
    value: Item; data?: Array<Item>
  }>({
    value: defaultPopularShowItem,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )

    _setSports()
    _setShowItems()
  }, [])

  const _setSports = async () => {
    const _data = await Data.getAllSports(db!)

    setSports((prev) => ({
      loaded: true,
      value: prev.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.short_name } as Item
      }),
    }))
  }

  const _setShowItems = async () => {
    const popularConstantShowItems: Item[] = [
      { id: 'true', text: 'Show Popular' },
      { id: 'false', text: 'Show Unpopular' }
    ]

    setPopularShowItems((prev) => ({
      value: prev.value,
      data: popularConstantShowItems
    }))
  }

  const handleSportChange = (item: Item) => {
    setSports((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handlePopularItems = (item: Item) => {
    setPopularShowItems((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  return (
    <FormRowAlignBottom>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Sport</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Teams}
              accessor={'sport_id' as keyof TeamRecord}
              items={sports.data ?? []}
              onSelect={handleSportChange}
              value={sports.value}
              defaultItem={sportDefaultItem}
              loading={!sports.loaded || !table.loaded}
            />
          }
        />
      </Col>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Popular</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Teams}
              accessor={'is_popular' as keyof TeamRecord}
              items={popularShowItems.data ?? []}
              onSelect={handlePopularItems}
              value={popularShowItems.value}
              defaultItem={defaultPopularShowItem}
              loading={false}
            />
          }
        />
      </Col>
      <Col as={ItemWrapper} lg={6}>
        <UIFullTextGlobalFilter
          from={Tab.Teams}
          style={{ disabled: !table.loaded }}
        />
      </Col>
    </FormRowAlignBottom>
  )
}

export default Filters
