import React, { useEffect } from 'react'
import styled from 'styled-components'
// Components
import { Portal } from 'react-portal'
import { Alert } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
// Styles
import { ItemWrapper } from 'styles/common'
// Addtitional
import { Layer } from 'static/layers'

const PortaledPrompt = styled.div`
  position: fixed;

  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: ${Layer.Alert};

  background-color: #00000080;

  display: flex;
  justify-content: center;
`

const PromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const PromptButtonContainer = styled.div`
  align-self: flex-end;

  button {
    min-width: 100px;
    margin-left: 10px;
  }
`

const AlertWrapper = styled.div`
  width: 500px;
  height: fit-content;
  margin-top: 10px;
`

export type Response = 'No' | 'Yes'

const ScrollbarHelper = {
  isVisible: () => {
    return document.body.scrollHeight > window.innerHeight
  },
  getWidth: () => {
    return window.innerWidth - document.body.scrollWidth
  }
}

const Prompt: React.FC<{
  question: string
  callback: (response: Response) => void
}> = (p) => {
  useEffect(() => {
    if (!ScrollbarHelper.isVisible()) { return }

    document.body.style.paddingRight = `${ScrollbarHelper.getWidth()}px`

    document.body.style.overflowY = 'hidden'
  }, [])

  const handleResponse = (response: Response) => {
    document.body.style.paddingRight = 'unset'
    document.body.style.overflowY = 'unset'

    p.callback(response)
  }

  return (
    <Portal>
      <PortaledPrompt>
        <AlertWrapper>
          <Alert variant={'danger'}>
            <PromptWrapper>
              <ItemWrapper>{p.question}</ItemWrapper>
              <PromptButtonContainer>
                <Button
                  variant={'outline-danger'}
                  onClick={() => handleResponse('No')}
                >
                  No
                </Button>
                <Button variant={'danger'} onClick={() => handleResponse('Yes')}>
                  Yes
                </Button>
              </PromptButtonContainer>
            </PromptWrapper>
          </Alert>
        </AlertWrapper>
      </PortaledPrompt>
    </Portal>
  )
}

export default Prompt
