import React from "react";
import { TabMapper } from "components/utils/TabsLoader";
import { Tab } from "static/tabs";
import TeamOrganizationDatasourceIdentifier from "components/TeamOrganizationDatasourceIdentifiers/__TeamOrganizationDatasourceIdentifiers";
import Team from "components/Team/__Team";

export const Mapper: TabMapper = {
  [Tab.Teams]: {
    routes: {
      [`/${Tab.Teams}`]: {
        node: <Team phase={"select"} />
      }
    }
  },
  [Tab.TeamOrganizatonDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.TeamOrganizatonDatasourceIdentifiers}`]: {
        node: <TeamOrganizationDatasourceIdentifier phase={"select"} />
      }
    }
  },
}
