import React, { useState, useEffect, useContext } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import { ItemWrapper } from 'styles/common'
// Compornents
import MatchForm from './Form'
import Loading from 'components/utils/Loading'
import Warning from 'components/utils/Warning'
import Tabs from './Tabs/Tabs'
// Additional
import { Tab } from 'static/tabs'
import { Text } from 'static/text'
import { AppContext } from 'App'
import { MatchContext } from 'components/Matches/__Matches.context'
import { MatchRecord } from 'data/MatchRecord'
import { String as StringHelper } from 'helpers/string'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'

const Detail: React.FC<{ isNew?: boolean }> = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    locked: a_locked,
    location: a_location,
  } = useContext(AppContext)

  const { params: m_params } = useContext(MatchContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    m_params?.match_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: MatchRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !m_params?.match_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try {
      const _data = await DataHelper.getMatchById(a_db!, m_params?.match_id, {
        alert: a_alert,
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [m_params?.match_id])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: MatchRecord) => {
    a_location?.set?.(
      Tab.Matches,
      data &&
        `${StringHelper.spaceTo(
          `${data?.team1_name ?? ''} vs ${
            data?.team2_name ?? ''
          }`
        )}`
    )
  }

  useEffect(() => {
    _setLocation(content?.data)

    content?.data && a_locked?.set(!!content?.data?.data_frozen_at)
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <MatchForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
          submitCallback={_setLocation}
        />
      </ItemWrapper>

      {!isNewRecord && <Tabs />}
    </>
  )
}

export default Detail
