import React from "react";
import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router";
import Detail from "./_Detail";
import Select from "./_Select"
import { TeamOrganizationContext } from "./__TeamOrganizations.context";

const TeamOrganization: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TeamOrganizationParams>()

  return (
    <TeamOrganizationContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TeamOrganizationContext.Provider>
  )
}

export default TeamOrganization
