import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'

const Sportsman: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.SportsmanParams>()

  return (
    <SportsmanContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </SportsmanContext.Provider>
  )
}

export default Sportsman
