import React, { useState, useContext } from 'react'
// Components
import SwitchButton from './SwitchButton'
import Prompt, { Response as PromtResponse } from 'components/utils/Prompt'
import { FormContext } from 'components/utils/Form.context'

const FreezeButton: React.FC<{
  defaultValue: boolean
}> = (p) => {
  const { freeze: f_freeze, unfreeze: f_unfreeze } = useContext(
    FormContext.Context
  )

  const [freeze, setFreeze] = useState<{
    value: boolean
    isBtnLocked: boolean
    isPrompt: boolean
  }>({
    value: p.defaultValue,
    isBtnLocked: false,
    isPrompt: false,
  })

  const handleSubmitFreezeResponse = async (response: PromtResponse) => {
    setFreeze((prev) => ({
      value: prev.value,
      isBtnLocked: false,
      isPrompt: false,
    }))

    if (response === 'Yes') {
      if (freeze.value) {
        try {
          await f_unfreeze?.()

          setFreeze((prev) => ({
            value: false,
            isBtnLocked: prev.isBtnLocked,
            isPrompt: prev.isPrompt,
          }))
        } catch (err) {
          console.error(err)
        }
      } else {
        try {
          await f_freeze?.()

          setFreeze((prev) => ({
            value: true,
            isBtnLocked: prev.isBtnLocked,
            isPrompt: prev.isPrompt,
          }))
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  const handleSubmitFreeze = (): void => {
    setFreeze((prev) => ({
      value: prev.value,
      isBtnLocked: true,
      isPrompt: true,
    }))
  }

  const handleSubmitUnfreeze = (): void => {
    setFreeze((prev) => ({
      value: prev.value,
      isBtnLocked: true,
      isPrompt: true,
    }))
  }

  return (
    <>
      {freeze.isPrompt && (
        <Prompt
          question={`Are you sure, you want to ${
            freeze.value ? 'unfreeze' : 'freeze'
          } record?`}
          callback={handleSubmitFreezeResponse}
        />
      )}
      <SwitchButton
        offset={{ direction: 'left' }}
        title={'Freeze'}
        scheme={'red'}
        checked={freeze.value}
        checkedCallback={handleSubmitFreeze}
        uncheckedCallback={handleSubmitUnfreeze}
      />
    </>
  )
}

export default FreezeButton
