import React, { useState, useContext, ComponentProps } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
// Additional
import { FormContext } from 'components/utils/Form.context'

/*eslint-disable @typescript-eslint/ban-types */
interface Props {
  readonly isLocked?: boolean

  readonly beforeSubmitFn?: Function
  readonly afterSubmitFn?: Function
}

// TODO another solution e.g. form item wrapper ... no injection of margin through styles
type CTAStyleProps = Pick<ComponentProps<typeof CTAButton>, 'style'>

const SaveButton: React.FC<Props & CTAStyleProps> = (p) => {
  const { submit: f_submit } = useContext(FormContext.Context)

  const [submitting, setSubmitting] = useState<boolean>(false)

  useAsyncEffect(async () => {
    if (submitting) {
      try {
        await p.beforeSubmitFn?.()

        await f_submit?.()

        await p.afterSubmitFn?.()
      } finally {
        setSubmitting(false)
      }
    }
  }, [submitting])

  const handleSubmitCallback = () => {
    setSubmitting(true)
  }

  return (
    <_SaveButton
      clickCallback={handleSubmitCallback}
      disabled={p.isLocked ?? false}
      submitting={submitting}
      style={p.style}
    />
  )
}

const _SaveButton: React.FC<
  ComponentProps<typeof CTAButton> & {
    clickCallback: () => void
    submitting: boolean
    disabled: boolean
    style?: CTAStyleProps
  }
> = (p) => (
  <CTAButton
    onClick={p.clickCallback}
    disabled={p.disabled}
    useLoading={p.submitting}
    style={p.style}
  >
    Save
  </CTAButton>
)

export default SaveButton
