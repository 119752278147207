import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
// Components
import { Row, Col } from 'react-bootstrap'
import LoginDropdown from './utils/LoginDropdown/LoginDropdown'
// Styles
import { LockIcon } from 'styles/fontAwesome'
import { Breakpoint } from 'styles/responsive'
import {
  NavbarSmall,
  NavbarLarge,
  FillAvailableWidth,
  Center,
  ColAlignLeft,
  ColAlignRight,
} from 'styles/common'
// Additional
import { AppContext } from 'App'
import { Layer } from 'static/layers'
// Images
import logo from 'assets/logo.png'

const Header: React.FC = () => {
  const { location: a_location, locked: a_locked } =
    useContext(AppContext)

  return (
    <>
      <Breakpoint.DesktopTablet>
        <NavbarLarge>
          <Center as={ColAlignLeft} disablePadding>
            <Center as={LocationWrapper}>
              <Location
                dangerouslySetInnerHTML={{
                  __html: a_location?.value ?? '',
                }}
              />
              {a_locked?.value && <LockIcon />}
            </Center>
          </Center>
          <ColAlignRight lg={3} md={3} disablePadding>
            <LoginDropdown />
          </ColAlignRight>
        </NavbarLarge>
      </Breakpoint.DesktopTablet>
      <Breakpoint.Mobile>
        <NavbarSmall>
          <FillAvailableWidth as={Row}>
            <Center as={ColAlignLeft}>
              <Image src={String(logo)} />
            </Center>
            <Center as={ColAlignRight}>
              <LoginDropdown />
            </Center>
          </FillAvailableWidth>
          <FillAvailableWidth as={Row}>
            <Center as={Col}>
              <Center as={LocationWrapper}>
                <Location
                  dangerouslySetInnerHTML={{
                    __html: a_location?.value ?? '',
                  }}
                />
                {a_locked?.value && <LockIcon />}
              </Center>
            </Center>
          </FillAvailableWidth>
        </NavbarSmall>
      </Breakpoint.Mobile>
    </>
  )
}

export default Header

export const HeaderWrapper = styled.div`
  background-color: white;

  z-index: ${Layer.Header} !important;
`

const Image = styled.img.attrs({ alt: 'logo' })`
  height: 85px;
`

const LocationWrapper = styled.div`
  display: inline-flex;

  width: min-content;

  position: relative;
  
  ${LockIcon} {
    position: absolute;
    
    right: -35px;
    bottom: 6px;
  }
  
  font-size: 1.5em;

  max-width: 90%;

  ${Breakpoint.ForMobile(css`
    text-align: center;
  `)}
`

const Location = styled.div``
