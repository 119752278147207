import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { Settings } from './Table.settings'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { TournamentTeamRankRecord } from 'data/TournamentTeamRankRecord'
import { AppContext } from 'App'
import { Data } from 'helpers/data'

const TeamRanks: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: t_params } = useContext(TournamentContext)

  const loadData = async () => {
    if (t_params?.tournament_id)
      return await Data.getTeamRanksByTournamentId(a_db!, t_params?.tournament_id, { alert: a_alert })

    return await Data.getAllTeamRanks(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<TournamentTeamRankRecord>
      reloadFrom={[Tab.TeamRanks, Tab.Tournaments]}
      loadDataFn={loadData}
      settings={Settings.get({ tournament: t_params?.tournament_id })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TeamRanks
