import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import SportsmanNickname from '../../Sportsmen/SportsmanNickname'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import { LockIcon, CheckIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { Routing } from 'helpers/routing'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { DateTime } from 'utils/dateTime'
import { RecordSetting } from 'components/utils/Table/_Table.settings'

export const Settings = (
  id?: {
    sport?: string
    team?: string
    sportsman?: string
    tournament?: string
  },
  options?: {
    hide?: {
      team?: boolean
      sportsman?: boolean
      tournament?: boolean
    }
  }
): RecordSetting<SportsmanTeamMembershipRecord> => [
  {
    accessor: 'id',
    className: 'table-hidden',
  },
  {
    accessor: 'sportsman_is_approved',
    className: 'table-hidden',
  },
  {
    accessor: 'isFrozen',
    className: 'table-lock',
    Header: function HeaderContent({ row }: any) {
      return <LockIcon />
    },
    Cell: function CellContent({ row }: any) {
      return <>{row.values.isFrozen && <LockIcon />}</>
    },
  },
  {
    accessor: 'team_name',
    Header: 'Team',
    className: options?.hide?.team ? 'table-hidden' : 'table-team',
  },
  {
    accessor: 'sportsman_nickname',
    Header: 'Sportsman',
    className: options?.hide?.sportsman ? 'table-hidden' : 'table-sportsman',
    Cell: function CellContent({ row }: any) {
      return (
        <SportsmanNickname
          nickname={row.values.sportsman_nickname}
          isApproved={row.values.sportsman_is_approved}
        />
      )
    },
  },
  {
    accessor: 'first_match_at',
    Header: 'First Match At',
    className: 'table-first-match-at',
    Cell: ({ row }: any) =>
      row.values?.first_match_at
        ? DateTime.shortDate(row.values?.first_match_at)
        : '---',
  },
  {
    accessor: 'last_match_at',
    Header: 'Last Match At',
    className: 'table-last-match-at',
    Cell: ({ row }: any) =>
      row.values?.last_match_at
        ? DateTime.shortDate(row.values?.last_match_at)
        : '---',
  },
  {
    accessor: 'is_current',
    Header: 'Current',
    className: 'table-current',
    Cell: function CellContent({ row }: any) {
      return <>{row.values.is_current && <CheckIcon />}</>
    },
  },
  {
    accessor: 'is_last',
    Header: 'Last',
    className: 'table-last',
    Cell: function CellContent({ row }: any) {
      return <>{row.values.is_last && <CheckIcon />}</>
    },
  },
  {
    id: 'ctaButton',
    sticky: 'right',
    className: 'table-cta-button',
    Header: function HeaderContent({ row }: any) {
      const { toggle } = useContext(AccordionContext)

      return (
        <CTAButton onClick={toggle}>
          <EllipsisHIcon />
        </CTAButton>
      )
    },
    Cell: function CellContent({ row }: any) {
      const computeRoutingPath = () => {
        const _routingParams = {
          tournament: id?.tournament ?? Routing.NewRecordSymbol,
          sportsman: id?.sportsman ?? Routing.NewRecordSymbol,
          team: id?.team ?? Routing.NewRecordSymbol,
          sportsmanTeamMembership: row.values.id ?? Routing.NewRecordSymbol,
          sport: id?.sport ?? Routing.NewRecordSymbol,
        }

        if (id?.sportsman)
          return Routing.getSportsmanTeamMembershipDetailUrlFromSportsman(
            _routingParams
          )

        if (id?.team)
          return Routing.getSportsmanTeamMembershipDetailUrlFromTeam(
            _routingParams
          )
      }

      return (
        <CTAButton path={computeRoutingPath()}>
          {row.values.isFrozen ? 'View' : 'Edit'}
        </CTAButton>
      )
    },
  },
]
