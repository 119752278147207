import UIFullTextFilter from "components/utils/Filter/FullTextGlobalFilter"
import { OnTableDataChangeListener } from "components/utils/Table/_Table"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Tab } from "static/tabs"
import { ItemWrapper } from "styles/common"
import { Loadable } from "types/loadable"

const Filters: React.FC = () => {
  const [table, settable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && settable({ loaded: true })
    )
  }, [])

  return (
    <Row>
      <Col as={ItemWrapper} lg={12}>
        <UIFullTextFilter
          from={Tab.GammingGears}
          style={{ disabled: !table.loaded }}
        />
      </Col>
    </Row>
  )
}

export default Filters
