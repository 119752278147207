import React, { ComponentProps, useContext } from 'react'
import CTAButton from 'components/utils/Button/CTAButton'
// Additional
import { FormContext } from 'components/utils/Form.context'

const DEFAULT_TEXT = 'Touch'

const TouchButton: React.FC<ComponentProps<typeof CTAButton>> = (p) => {
  const { touch: f_touch } = useContext(FormContext.Context)

  return <CTAButton onClick={f_touch}>{p.children ?? DEFAULT_TEXT}</CTAButton>
}

export default TouchButton
