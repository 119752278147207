import { DateTime } from "../utils/dateTime"
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { TeamRecord } from "./TeamRecord"

export class TeamStatSummaryRecord<TJsonData extends TeamStatSummaryRecord.CtorJsonData = TeamStatSummaryRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  timespace_id?: string
  group_size?: number

  // >>> SF points >>>
  fantasy_points_sum?: number
  fantasy_points_per_match_avg?: number
  fantasy_points_per_map_avg?: number
  fantasy_points_per_round_avg?: number
  fantasy_points_rank_from?: number
  fantasy_points_rank_to?: number
  // <<< SF points <<<

  // >>> match summary >>>
  matches_played_sum?: number
  matches_won_sum?: number
  matches_lost_sum?: number
  matches_tied_sum?: number
  matches_won_ratio?: number
  // <<< match summary <<<

  // >>> map summary >>>
  maps_played_sum?: number
  maps_won_sum?: number
  maps_lost_sum?: number
  maps_tied_sum?: number
  // <<< map summary <<<

  // >>> round summary >>>
  rounds_played_sum?: number
  rounds_played_ct_sum?: number
  rounds_played_t_sum?: number
  rounds_won_sum?: number
  rounds_won_ct_sum?: number
  rounds_won_t_sum?: number
  rounds_lost_sum?: number
  rounds_lost_ct_sum?: number
  rounds_lost_t_sum?: number
  rounds_won_ratio?: number
  rounds_won_t_ratio?: number
  rounds_won_ct_ratio?: number
  // <<< round summary <<<

  // >>> kill summary >>>
  kills_sum?: number
  teamkills_sum?: number
  deaths_sum?: number
  suicides_sum?: number
  // <<< kill summary <<<
  
  // >>> bombs >>>
  opponent_bomb_plants_sum?: number
  bomb_defuses_sum?: number
  bomb_plants_sum?: number
  bomb_explodes_sum?: number
  bomb_plants_ratio_per_round?: number
  bomb_defuses_ratio_per_round?: number
  bomb_explodes_ratio_per_round?: number
  // <<< bombs <<<
  
  // >>> league points >>>
  league_points_sum?: number
  league_points_rank_from?: number
  league_points_rank_to?: number
  // <<< league points <<<
  
  match_win_streak?: number
  last_match_id?: string
  
  data_frozen_at?: Date | null

  /// >>> team >>>
  #team:BaseRecord.RefField<TeamRecord> = {}
  get team_id() {
    return this.#team.id
  }
  set team_id(value) {
    this.#team.id = value
  }

  get team() {
    return this.#team?.object
  }
  getTeam() {
    return this.getObjectFromRefField(this.#team, "teams")
  }
  /// <<< team <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }
  get team_name() {
    return this.team?.full_name
  }
  get sortValues() {
    return [
      this.team_name?.toLowerCase() ?? Number.MAX_SAFE_INTEGER
    ]
  }
  /// <<< computed_values <<<
  
  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamStatSummaryRecord.CtorJsonData) {
    return new TeamStatSummaryRecord(db, jsonData)
  }
  
  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)
    
    this.timespace_id = jsonData?.timespace_id
    this.team_id = jsonData?.team_id
    this.group_size = jsonData?.group_size

    // >>> SF points >>>
    this.fantasy_points_sum = jsonData?.fantasy_points_sum
    this.fantasy_points_per_match_avg = jsonData?.fantasy_points_per_match_avg
    this.fantasy_points_per_map_avg = jsonData?.fantasy_points_per_map_avg
    this.fantasy_points_per_round_avg = jsonData?.fantasy_points_per_round_avg
    this.fantasy_points_rank_from = jsonData?.fantasy_points_rank_from
    this.fantasy_points_rank_to = jsonData?.fantasy_points_rank_to
    // <<< SF points <<<

    // >>> match summary >>>
    this.matches_played_sum = jsonData?.matches_played_sum
    this.matches_won_sum = jsonData?.matches_won_sum
    this.matches_lost_sum = jsonData?.matches_lost_sum
    this.matches_tied_sum = jsonData?.matches_tied_sum
    this.matches_won_ratio = jsonData?.matches_won_ratio
    // <<< match summary <<<

    // >>> map summary >>>
    this.maps_played_sum = jsonData?.maps_played_sum
    this.maps_won_sum = jsonData?.maps_won_sum
    this.maps_lost_sum = jsonData?.maps_lost_sum
    this.maps_tied_sum = jsonData?.maps_tied_sum
    // <<< map summary <<<

    // >>> round summary >>>
    this.rounds_played_sum = jsonData?.rounds_played_sum
    this.rounds_played_ct_sum = jsonData?.rounds_played_ct_sum
    this.rounds_played_t_sum = jsonData?.rounds_played_t_sum
    this.rounds_won_sum = jsonData?.rounds_won_sum
    this.rounds_won_ct_sum = jsonData?.rounds_won_ct_sum
    this.rounds_won_t_sum = jsonData?.rounds_won_t_sum
    this.rounds_lost_sum = jsonData?.rounds_lost_sum
    this.rounds_lost_ct_sum = jsonData?.rounds_lost_ct_sum
    this.rounds_lost_t_sum = jsonData?.rounds_lost_t_sum
    this.rounds_won_ratio = jsonData?.rounds_won_ratio
    this.rounds_won_t_ratio = jsonData?.rounds_won_t_ratio
    this.rounds_won_ct_ratio = jsonData?.rounds_won_ct_ratio
    // <<< round summary <<<

    // >>> kill summary >>>
    this.kills_sum = jsonData?.kills_sum
    this.teamkills_sum = jsonData?.teamkills_sum
    this.deaths_sum = jsonData?.deaths_sum
    this.suicides_sum = jsonData?.suicides_sum
    // <<< kill summary <<<

    // >>> bombs >>>
    this.opponent_bomb_plants_sum = jsonData?.opponent_bomb_plants_sum
    this.bomb_defuses_sum = jsonData?.bomb_defuses_sum
    this.bomb_plants_sum= jsonData?.bomb_plants_sum
    this.bomb_explodes_sum = jsonData?.bomb_explodes_sum
    this.bomb_plants_ratio_per_round = jsonData?.bomb_plants_ratio_per_round
    this.bomb_defuses_ratio_per_round = jsonData?.bomb_defuses_ratio_per_round
    this.bomb_explodes_ratio_per_round = jsonData?.bomb_explodes_ratio_per_round
    // <<< bombs <<<

    // >>> league points >>>
    this.league_points_sum = jsonData?.league_points_sum
    this.league_points_rank_from = jsonData?.league_points_rank_from
    this.league_points_rank_to = jsonData?.league_points_rank_to
    // <<< league points <<<
    
    this.match_win_streak = jsonData?.match_win_streak
    this.last_match_id = jsonData?.last_match_id
  
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      timespace_id: this.timespace_id,
      group_size: this.group_size,

      // >>> SF points >>>
      fantasy_points_sum: this.fantasy_points_sum,
      fantasy_points_per_match_avg: this.fantasy_points_per_match_avg,
      fantasy_points_per_map_avg: this.fantasy_points_per_map_avg,
      fantasy_points_per_round_avg: this.fantasy_points_per_round_avg,
      fantasy_points_rank_from: this.fantasy_points_rank_from,
      fantasy_points_rank_to: this.fantasy_points_rank_to,
      // <<< SF points <<<

      // >>> match summary >>>
      matches_played_sum: this.matches_played_sum,
      matches_won_sum: this.matches_won_sum,
      matches_lost_sum: this.matches_lost_sum,
      matches_tied_sum: this.matches_tied_sum,
      matches_won_ratio: this.matches_won_ratio,
      // <<< match summary <<<

      // >>> map summary >>>
      maps_played_sum: this.maps_played_sum,
      maps_won_sum: this.maps_won_sum,
      maps_lost_sum: this.maps_lost_sum,
      maps_tied_sum: this.maps_tied_sum,
      // <<< map summary <<<

      // >>> round summary >>>
      rounds_played_sum: this.rounds_played_sum,
      rounds_played_ct_sum: this.rounds_played_ct_sum,
      rounds_played_t_sum: this.rounds_played_t_sum,
      rounds_won_sum: this.rounds_won_sum,
      rounds_won_ct_sum: this.rounds_won_ct_sum,
      rounds_won_t_sum: this.rounds_won_t_sum,
      rounds_lost_sum: this.rounds_lost_sum,
      rounds_lost_ct_sum: this.rounds_lost_ct_sum,
      rounds_lost_t_sum: this.rounds_lost_t_sum,
      rounds_won_ratio: this.rounds_won_ratio,
      rounds_won_t_ratio: this.rounds_won_t_ratio,
      rounds_won_ct_ratio: this.rounds_won_ct_ratio,
      // <<< round summary <<<

      // >>> kill summary >>>
      kills_sum: this.kills_sum,
      teamkills_sum: this.teamkills_sum,
      deaths_sum: this.deaths_sum,
      suicides_sum: this.suicides_sum,
      // <<< kill summary <<<

      // >>> bombs >>>
      opponent_bomb_plants_sum: this.opponent_bomb_plants_sum,
      bomb_defuses_sum: this.bomb_defuses_sum,
      bomb_plants_sum: this.bomb_plants_sum,
      bomb_explodes_sum: this.bomb_explodes_sum,
      bomb_plants_ratio_per_round: this.bomb_plants_ratio_per_round,
      bomb_defuses_ratio_per_round: this.bomb_defuses_ratio_per_round,
      bomb_explodes_ratio_per_round: this.bomb_explodes_ratio_per_round,
      // <<< bombs <<<

      // >>> league points >>>
      league_points_sum: this.league_points_sum,
      league_points_rank_from: this.league_points_rank_from,
      league_points_rank_to: this.league_points_rank_to,
      // <<< league points <<<
      
      match_win_streak: this.match_win_streak,
      last_match_id: this.last_match_id,
      data_frozen_at: this.data_frozen_at?.toISOString()
    }) as TJsonData
  }

  async preload() {
    await super.preload()

    const team = await this.getTeam()
    await team?.preload()
  }
}

export module TeamStatSummaryRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    timespace_id?: string
    team_id?: string

    group_size?: number

    // >>> SF points >>>
    fantasy_points_sum?: number
    fantasy_points_per_match_avg?: number
    fantasy_points_per_map_avg?: number
    fantasy_points_per_round_avg?: number
    fantasy_points_rank_from?: number
    fantasy_points_rank_to?: number
    // <<< SF points <<<

    // >>> match summary >>>
    matches_played_sum?: number
    matches_won_sum?: number
    matches_lost_sum?: number
    matches_tied_sum?: number
    matches_won_ratio?: number
    // <<< match summary <<<

    // >>> map summary >>>
    maps_played_sum?: number
    maps_won_sum?: number
    maps_lost_sum?: number
    maps_tied_sum?: number
    // <<< map summary <<<

    // >>> round summary >>>
    rounds_played_sum?: number
    rounds_played_ct_sum?: number
    rounds_played_t_sum?: number
    rounds_won_sum?: number
    rounds_won_ct_sum?: number
    rounds_won_t_sum?: number
    rounds_lost_sum?: number
    rounds_lost_ct_sum?: number
    rounds_lost_t_sum?: number
    rounds_won_ratio?: number
    rounds_won_t_ratio?: number
    rounds_won_ct_ratio?: number
    // <<< round summary <<<

    // >>> kill summary >>>
    kills_sum?: number
    teamkills_sum?: number
    deaths_sum?: number
    suicides_sum?: number
    // <<< kill summary <<<
    
    // >>> bombs >>>
    opponent_bomb_plants_sum?: number
    bomb_defuses_sum?: number
    bomb_plants_sum?: number
    bomb_explodes_sum?: number
    bomb_plants_ratio_per_round?: number
    bomb_defuses_ratio_per_round?: number
    bomb_explodes_ratio_per_round?: number
    // <<< bombs <<<
    
    // >>> league points >>>
    league_points_sum?: number
    league_points_rank_from?: number
    league_points_rank_to?: number
    // <<< league points <<<
    
    match_win_streak?: number
    last_match_id?: string
    
    data_frozen_at?: string
  }

  export class Repository extends RecordRepository<TeamStatSummaryRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    protected loadAll() {
      return this.getApi().getAllTeamStatSummaries()
    }

    protected loadById(id: string): Promise<TeamStatSummaryRecord | null> {
      return this.getApi().getOneTeamStatSummaryById(id)
    }

    protected updateById(id: string, patch: Partial<TeamStatSummaryRecord<CtorJsonData>>): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected deleteById(id: string): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<TeamStatSummaryRecord<CtorJsonData>>): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<TeamStatSummaryRecord<CtorJsonData>>): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<TeamStatSummaryRecord<CtorJsonData>>): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unfreezeById(id: string, patch: Partial<TeamStatSummaryRecord<CtorJsonData>>): Promise<TeamStatSummaryRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTeamStatSummaries()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTeamStatSummaryById(id)
    }
  }
}