import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { Settings } from './Table.settings'
import { MatchContext } from 'components/Matches/__Matches.context'
import { MatchMapRecord } from 'data/MatchMapRecord'
import { AppContext } from 'App'
import { Data } from 'helpers/data'

const MatchMapsTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: m_params } = useContext(MatchContext)

  const loadData = async () => {
    if (m_params?.match_id)
      return await Data.getMatchMapsByMatchId(a_db!, m_params?.match_id, {
        alert: a_alert,
      })

    return []
  }

  return (
    <TableLoader<MatchMapRecord>
      reloadFrom={[Tab.MatchMaps]}
      loadDataFn={loadData}
      settings={Settings.get({
        tournament: m_params?.tournament_id,
        match: m_params?.match_id,
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default MatchMapsTable
