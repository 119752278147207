import React, { useState } from 'react'
import styled from 'styled-components'
import { useAlert } from 'react-alert'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
// Styles
import { ItemWrapper } from 'styles/common'
import { Center } from 'styles/common'
import { SecondaryColor } from 'styles/common'
import { FillAvailableWidth } from 'styles/common'
// Additional
import { ChroupatorApi } from 'data/api/ChroupatorApi'

const Divider = styled.div`
  border: 1px solid ${SecondaryColor};

  margin-left: -5px;
  margin-right: -5px;
`

const api = new ChroupatorApi()

const ResetPassword: React.FC = (p) => {
  const [showIncrLogin, setShowIncrLogin] = useState<{
    incrLogin: boolean
    info?: string
  }>({ incrLogin: false })
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const alert = useAlert()

  const formik = useFormik({
    initialValues: {
      email: '',
    },

    onSubmit: async (values) => {
      setIsSubmitted(true)

      try {
        const reset = await api.resetPassword(values.email)
        
        if (reset.ok) {
          alert.show('Reset link has been sent to your email', {
            type: 'success',
          })
          
          setIsLinkSent((prev) => !prev)
        } else {
          alert.show(
            "Something went wrong. Reset link has't been sent to your email",
            {
              //@ts-ignore
              type: 'danger',
            }
          )

          setIsSubmitted(false)
        }
      } catch (err) {
        alert.show(
          "Something went wrong. Reset link has't been sent to your email",
          {
            //@ts-ignore
            type: 'danger',
          }
        )
        setIsSubmitted(false)
      }
    },

    //@ts-ignore
    validationSchema: Yup.object<LeagueRecord>({
      email: Yup.string()
        .email('It should be a valid email address')
        .required('Email is a required'),
    }),
  })

  return (
    <Center as={Container} style={{ height: '100vh' }}>
      <Col xs={12} sm={10} md={8} lg={6} xl={4}>
        {isLinkSent && (
          <ItemWrapper>
            <Center as={'p'}>
              The password reset link has been successfully sent to your email
            </Center>
          </ItemWrapper>
        )}
        {!isLinkSent && (
          <>
            <ItemWrapper>
              <Center as={'h2'}>Reset password</Center>
            </ItemWrapper>
            <Alert
              variant={'danger'}
              dismissible
              transition
              onClose={() => {
                setShowIncrLogin({ incrLogin: false })
              }}
              show={showIncrLogin.incrLogin}
            >
              {showIncrLogin.info}
            </Alert>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Row as={ItemWrapper}>
                <FormItem
                  label={<Form.Label>Email</Form.Label>}
                  input={
                    <>
                      <Form.Control
                        name={'email'}
                        type={'email'}
                        value={formik.values.email ?? ''}
                        required
                        isInvalid={
                          formik.submitCount > 0 && !!formik.errors.email
                        }
                        onChange={formik.handleChange}

                        // autoComplete={'username'}
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    </>
                  }
                />
              </Form.Row>

              <Divider as={ItemWrapper} />

              <Form.Row as={ItemWrapper}>
                <FillAvailableWidth
                  as={Button}
                  variant={'outline-secondary'}
                  type={'submit'}
                  disabled={isSubmitted}
                >
                  Reset
                </FillAvailableWidth>
              </Form.Row>
            </Form>
          </>
        )}
      </Col>
    </Center>
  )
}

export default ResetPassword
