import React, { ComponentProps, useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { SportRecord } from 'data/SportRecord'
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = [
  'full_name',
  'short_name',
  'logo_url',
  'ident',
  'staging_id',
] as Array<keyof SportRecord>

type ValidationKeys = Record<
  keyof Pick<SportRecord, 'full_name' | 'short_name' | 'ident'>,
  any
>

const SportForm: React.FC<FormHelper.Props<SportRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: SportRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Sport',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,
    },

    onSubmit: async (values) => {
      if (!db?.sports) return

      try {
        const data = await FormHelper.submitChanges<SportRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new SportRecord(), values ?? {}),
          repository: db.sports,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getSportDetailUrl({
              sport: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      full_name: Yup.string().required('Full name is a required field'),
      short_name: Yup.string().required('Short name is a required field'),
      ident: Yup.string().required('Ident is a required field'),
    }),
  })

  const approve = async () => {
    if (!db?.sports) return

    await FormHelper.approve({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.sports) return

    await FormHelper.unapprove({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  const freeze = async () => {
    if (!db?.sports) return

    await FormHelper.freeze({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.sports) return

    await FormHelper.unfreeze({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.sports) return

    await FormHelper.delete_({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.sports) return

    await FormHelper.touch({
      data: Object.assign(new SportRecord(), formik.values ?? {}),
      repository: db.sports,
      optional: opt,
    })
  }

  //#region Form Components
  const _FullName = (
    <FormItem
      label={<Form.Label>Full Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'full_name' as keyof SportRecord}
            value={formik.values.full_name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.full_name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.full_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _ShortName = (
    <FormItem
      label={<Form.Label>Short Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'short_name' as keyof SportRecord}
            value={formik.values.short_name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.short_name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.short_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _LogoUrl = (
    <FormItem
      label={<Form.Label>Logo Url</Form.Label>}
      input={
        <Form.Control
          name={'logo_url' as keyof SportRecord}
          value={formik.values.logo_url ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _Ident = (
    <FormItem
      label={<Form.Label>Ident</Form.Label>}
      input={
        <>
          <Form.Control
            name={'ident' as keyof SportRecord}
            value={formik.values.ident ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.ident}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.ident}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_FullName}</Col>
          </Form.Row>
          <Form.Row>
            <Col as={ItemWrapper}>{_ShortName}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_LogoUrl}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_Ident}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportForm
