export module OnFilterChangeListener {
  const BASE_EVENT_NAME = 'onFilterChange'

  export type Location = string

  const getEventName = (from: Location) => `${BASE_EVENT_NAME}.from${from}`

  export interface FilterOptions {
    readonly useForceFilter?: boolean
    readonly disableConcatAndReset?: boolean
  }
  export interface FilterEventArgs {
    readonly columnName?: string
    readonly filteredValue?: string | number | null
    readonly options?: FilterOptions
  }

  export function add(from: Location, listener: EventListener) {
    document.addEventListener(getEventName(from), listener)
  }

  export function remove(from: Location, listener: EventListener) {
    document.removeEventListener(getEventName(from), listener)
  }

  export function dispatch(from: Location, args?: FilterEventArgs) {
    document.dispatchEvent(
      new CustomEvent(getEventName(from), {
        detail: args,
      })
    )
  }
}

export module OnGlobalFilterChangeListener {
  const BASE_EVENT_NAME = 'onGlobalFilterChange'

  export interface GlobalFilterChangeEventArgs {
    readonly filterValue?: string
  }

  export function add(listener: EventListener) {
    document.addEventListener(BASE_EVENT_NAME, listener)
  }

  export function remove(listener: EventListener) {
    document.removeEventListener(BASE_EVENT_NAME, listener)
  }

  export function dispatch(args: GlobalFilterChangeEventArgs) {
    document.dispatchEvent(
      new CustomEvent(BASE_EVENT_NAME, {
        detail: args,
      })
    )
  }

  export function addAndRemove(listener: EventListener) {
    add(listener)

    return () => {
      remove(listener)
    }
  }
}

export module OnReloadFilterListener {
  const BASE_EVENT_NAME = 'onReloadFilterListener'

  export type Location = string

  const getEventName = (from: Location) => `${BASE_EVENT_NAME}.from${from}`

  export function add(from: Location, listener: EventListener) {
    document.addEventListener(getEventName(from), listener)
  }

  export function remove(from: Location, listener: EventListener) {
    document.removeEventListener(getEventName(from), listener)
  }

  export function dispatch(from: Location) {
    document.dispatchEvent(new CustomEvent(getEventName(from)))
  }

  export function addAndRemove(from: Location, listener: EventListener) {
    add(from, listener)

    return () => {
      remove(from, listener)
    }
  }
}
