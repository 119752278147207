import { BaseRecord } from 'data/BaseRecord'
import { Form as _Form } from './form'

export module Form {
  type RecalculateActionType = 'recalculate'

  const translation: { [K in RecalculateActionType]: string } = {
    recalculate: 'recalculated',
  }

  type RecalculateStatsParams<T extends BaseRecord> = _Form.DataParams<T> & {
    optional?: {
      alert?: _Form.AlertParams
    }
  }

  export const recalculate = async <T extends BaseRecord>(params: RecalculateStatsParams<T>) => {
    const { data, repository, optional } = params

    try {
      const existingRecord = await repository?.get(data.id)

      if (existingRecord == null) {
        throw new Error('Record was not found.')
      }

      await repository?.recalculateStatsById(existingRecord.id!, existingRecord)

      optional?.alert?.manager &&
      _Form.alert('success', optional.alert.manager, {
          name: `${optional.alert.texts.form} stats`,
          action: translation['recalculate'],
          forcePlural: true
        })
    } catch (err) {
      optional?.alert?.manager &&
      _Form.alert('danger', optional.alert.manager, {
          name: `${optional.alert.texts.form} stats`,
          action: translation['recalculate'],
          forcePlural: true
        })

      throw err
    }
  }
}