import React, { DOMAttributes } from 'react'
import styled, { css } from 'styled-components'
// Components
import { Button, ButtonProps } from 'react-bootstrap'
import { Spinner } from 'react-bootstrap'
import { Link, LinkProps } from 'react-router-dom'

type ThemeType = 'white' | 'blue' | 'red'

interface ThemeProps {
  readonly theme?: ThemeType
}

const variantMapper: { [K in ThemeType]: string } = {
  white: 'outline-secondary',
  blue: 'outline-info',
  red: 'outline-danger',
}

const backgroundColorMapper: { [K in ThemeType]: string } = {
  white: 'white',
  blue: 'white',
  red: 'white',
}

interface Props extends ButtonProps, ThemeProps {
  readonly path?: string
  readonly useLoading?: boolean
}

const CTAButton: React.FC<Props> = (p) => {
  const pathProps = p.path
    ? ({
        as: Link,
        to: {
          pathname: p.path,
        },
      } as LinkProps)
    : null

  const clickProps = p.onClick
    ? ({ onClick: p.onClick } as DOMAttributes<HTMLElement>)
    : null

  const theme = p.theme ?? 'white'

  return (
    <CustomCTAButton
      {...p}
      as={(props) => (
        <>
          <Button
            {...props}
            {...pathProps}
            {...clickProps}
            disabled={p.disabled || p.useLoading}
            variant={variantMapper[theme]}
          >
            {p.children}
            {p.useLoading && (
              <CustomLoadingSpinner as={(props_) => <Spinner {...props_} />} />
            )}
          </Button>
        </>
      )}
      theme={backgroundColorMapper[theme]}
    />
  )
}

const CustomCTAButton = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)<ThemeProps>`
  position: relative;

  width: 100%;

  background-color: ${({ theme }) => theme};

  :disabled {
    ${({ useLoading }) => {
      if (useLoading) return css``

      return css`
        background-color: #e9ecef !important;
        border-color: #ced4da !important;
        color: #495057 !important;
        opacity: 1 !important;
      `
    }}
  }
`

const CustomLoadingSpinner = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
).attrs({
  size: 'sm',
  animation: 'border',
})<ThemeProps>`
  position: absolute;

  top: 9px;
  right: 9px;
`

export default CTAButton
