import React from "react";
import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router-dom";
import { TeamDatasourceIdentifierContext } from "./TeamDatasourceIdentifiers.context";
import Detail from "./_Detail";
import Select from "./_Select";



const TeamDatasourceIdentifier: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TeamDatasourceIdentifierParams>()

  return (
    <TeamDatasourceIdentifierContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TeamDatasourceIdentifierContext.Provider>
  )
}

export default TeamDatasourceIdentifier
