import React, { useState, useEffect, useContext } from 'react'
// Components
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Item } from 'components/utils/Dropdown'
import FormItem from 'components/utils/FormItem'
import DropdownFilter from 'components/utils/Filter/DropdownFilter'
import UIFullTextGlobalFilter from 'components/utils/Filter/FullTextGlobalFilter'
// Styles
import { ItemWrapper, FormRowAlignBottom } from 'styles/common'
// Addtitional
import { Loadable } from 'types/loadable'
import { AppContext } from 'App'
import { Tab } from 'static/tabs'
import { Data } from 'helpers/data'
import { TournamentStageRecord } from 'data/TournamentStageRecord'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const { db } = useContext(AppContext)

  const tournamentDefaultItem: Item = { id: -1, text: 'Select Tournament ...' }

  const [tournaments, setTournaments] = useState<
    {
      value: Item
      data?: Array<Item>
    } & Loadable
  >({
    value: tournamentDefaultItem,
    loaded: false,
  })

  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )

    _setTournaments()
  }, [])

  const _setTournaments = async () => {
    const _data = await Data.getAllTournaments(db!)

    setTournaments((prev) => ({
      loaded: true,
      value: prev.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.name } as Item
      }),
    }))
  }

  const handleTournamentChange = (item: Item) => {
    setTournaments((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  return (
    <FormRowAlignBottom>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Tournament</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Stages}
              accessor={'tournament_id' as keyof TournamentStageRecord}
              items={tournaments.data ?? []}
              onSelect={handleTournamentChange}
              value={tournaments.value}
              defaultItem={tournamentDefaultItem}
              loading={!tournaments.loaded || !table.loaded}
            />
          }
        />
      </Col>
      <Col as={ItemWrapper} lg={9}>
        <UIFullTextGlobalFilter
          from={Tab.Stages}
          style={{ disabled: !table.loaded }}
        />
      </Col>
    </FormRowAlignBottom>
  )
}

export default Filters
