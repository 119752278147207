import React from "react";
import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router-dom";
import { TournamentDatasourceIdentifierContext } from "./TournamentDatasourceIdentifiers.context";
import Detail from "./_Detail";
import Select from "./_Select";

const TournamentDatasourceIdentifier: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TournamentDatasourceIdentifiersParams>()

  return (
    <TournamentDatasourceIdentifierContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TournamentDatasourceIdentifierContext.Provider>
  )
}

export default TournamentDatasourceIdentifier
