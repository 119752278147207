import React from 'react'
import styled from 'styled-components'
// Additional
import { Props as RefProps } from 'types/reactRef'

type CustomToggleProps = {
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const CustomTextToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <TextToggle
      ref={ref}
      onClick={(e) => {
        e.preventDefault()

        props.onClick && props.onClick(e)
      }}
    >
      {props.children}
    </TextToggle>
  )
)
CustomTextToggle.displayName = 'Custom Text Dopdown Toggle'

const Menu = styled.div.attrs<{ ref: React.Ref<HTMLAnchorElement> }>((p) => ({
  ref: p.ref,
}))``

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef(
  (props: RefProps, ref: React.Ref<HTMLDivElement>) => (
    <Menu ref={ref} className={props.className}>
      {props.children}
    </Menu>
  )
)
CustomMenu.displayName = 'Custom Dropdown Menu'

const TextToggle = styled.a.attrs<{
  ref: React.Ref<HTMLAnchorElement>
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}>((p) => ({
  ref: p.ref,
  onclick: p.onClick,
}))`
  display: flex;

  justify-content: flex-end;

  user-select: none;
  cursor: pointer;

  &,
  &:hover {
    color: black !important;
  }

  &:hover {
    text-decoration: none;
  }
`
