import { AppContext } from "App"
import { StageContext } from "components/Stages/__Stages.context"
import TableLoader from "components/utils/Table/Table"
import { TournamentStageDatasourceIdentifierRecord } from "data/TournamentStageDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const TournamentStageDatasourceIdentifiersTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: ts_params } = useContext(StageContext)

  const loadData = async () => {
    if (ts_params?.stage_id)
    return await Data.getTournamentsStageDatasourceIdentifiersByTournamentStageId(
      a_db!,
      ts_params.stage_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<TournamentStageDatasourceIdentifierRecord>
      reloadFrom={[Tab.TournamentStageDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        tournamentStage: ts_params?.stage_id
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TournamentStageDatasourceIdentifiersTable
