import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class AzureDiretcoryRecord<TJsonData extends AzureDiretcoryRecord.CtorJsonData = AzureDiretcoryRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  prefix?:string
  name?:string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:AzureDiretcoryRecord.CtorJsonData) {
    return new AzureDiretcoryRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.prefix = jsonData?.prefix
    this.name = jsonData?.name
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      prefix: this.prefix,
      name: this.name
    }) as TJsonData
  }
}

export module AzureDiretcoryRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    prefix?: string
    name?: string
  }

  export class Repository extends RecordRepository<AzureDiretcoryRecord> {
    //TODO vyresit peekOrLoad
    getAllByNameAndPrefix(name:string, prefix:string, withSubDirs:boolean) {
      const api = this.getApiOrThrow()
      return api.getAllAzureDirectoriesByNameAndPrefix(name, prefix, withSubDirs)
    }

    protected loadById(id: string): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected updateById(id: string, patch: Partial<AzureDiretcoryRecord<CtorJsonData>>): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected deleteById(id: string): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected approveById(id: string, patch: Partial<AzureDiretcoryRecord<CtorJsonData>>): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<AzureDiretcoryRecord<CtorJsonData>>): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<AzureDiretcoryRecord<CtorJsonData>>): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<AzureDiretcoryRecord<CtorJsonData>>): Promise<AzureDiretcoryRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
