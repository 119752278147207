import React from 'react'
import styled from 'styled-components'
// Components
import { Route, Switch } from 'react-router-dom'
// Additional
import { ItemPixelOffset } from 'styles/common'
import { TabMapper } from 'static/tabMapper'

const CustomContentContainer = styled.div`
  max-width: unset !important;

  .offseted-container {
    margin-top: ${ItemPixelOffset * 0.5}px;
  }
`

const UIContent: React.FC = () => {
  const getRoute = (
    key: string,
    content: TabMapper.RouteProps,
    route: string
  ) => (
    <Route exact={!!content.isExact} key={key} path={route}>
      {content.node}
    </Route>
  )

  const setRoutes = (mapper: { [id: string]: TabMapper.Node }): JSX.Element => (
    <>
      {Object.entries(mapper).map(([k, v], idx) => (
        <React.Fragment key={`${k}_${idx}`}>
          {v.routes &&
            Object.entries(v.routes).map(([k2, v2], idx2) => (
              <React.Fragment key={`${k2}_${idx2}`}>
                {getRoute(k2, v2, k2.toLowerCase())}
              </React.Fragment>
            ))}
          {v.nodes && setRoutes(v.nodes)}
        </React.Fragment>
      ))}
    </>
  )

  return (
    <CustomContentContainer>
      <Switch>
        {setRoutes(TabMapper.Settings)}
        {/* <Route render={() => <p>Not found</p>} /> */}
      </Switch>
    </CustomContentContainer>
  )
}

export default UIContent
