import React, { useState, useEffect } from 'react'
// Components
import { Row, Col } from 'react-bootstrap'
import UIFullTextFilter from 'components/utils/Filter/FullTextGlobalFilter'
// Styles
import { ItemWrapper } from 'styles/common'
// Adiitional
import { Tab } from 'static/tabs'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(() => !loaded && setLoaded(true))
  }, [])

  const [loaded, setLoaded] = useState<boolean>(false)

  return (
    <Row>
      <Col as={ItemWrapper} lg={12}>
        <UIFullTextFilter from={Tab.SportsmanRanks} style={{ disabled: !loaded }} />
      </Col>
    </Row>
  )
}

export default Filters
