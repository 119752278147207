import { ChroupatorApi } from "../api/ChroupatorApi";
import { BaseRecord } from "../BaseRecord";
import { RecordRepository } from "./RecordRepository";

export abstract class BaseRootRepository {
  #api:ChroupatorApi
  get api() {
    return this.#api
  }

  #recordRepositories:Record<any, RecordRepository<BaseRecord>> = {}
  protected get recordRepositories() {
    return this.#recordRepositories
  }

  constructor(options?:{
    api?: ChroupatorApi
  }) {
    this.#api = options?.api ?? new ChroupatorApi()
    this.#api.db = this
  }
}

export module BaseRootRepository {
  export type OrNothing = BaseRootRepository | null | undefined
}