import { css } from 'styled-components'
// Styles
import * as stls from 'styles/common'
import { TableRowStyle as _TableRowStyle } from 'components/utils/Table/_Table.styles'

export const TableHeadStyle = css`
  ${stls.SecondaryBackgroundColor}
`

export const TableRowStyle = css`
  ${_TableRowStyle}

  .table-data-id,
  .table-datasource-id,
  .table-staging-id,
  .table-approved {
    text-align: center;
  }
`

export const TableCellStyle = css`
  ${stls.CenteredContent}
`
