import React from 'react'
// Components
import SportsmanTeamMemberships from 'components/SportsmanTeamMemberships/Table/TableWithAdd'
// Additional
import { Tab } from 'static/tabs'
import { TabMapper } from 'components/utils/TabsLoader'
import TeamBiographyDetail from 'components/TeamBiographies/_Detail'
import TeamOrganization from 'components/TeamOrganizations/__TeamOrganizations'
import TeamDatasourceIdentifier from 'components/TeamDatasourceIdentifiers/__TeamDatasourceIdentifiers'

export const Mapper: TabMapper = {
  [Tab.TeamBiographies]: {
    routes: {
      [`/${Tab.TeamBiographies}`]: {
        node: <TeamBiographyDetail />
      }
    }
  },
  [Tab.SportsmanTeamMemberships]: {
    routes: {
      [`/${Tab.SportsmanTeamMemberships}`]: {
        node: <SportsmanTeamMemberships />,
      },
    },
  },
  // [Tab.TeamOrganizations]: {
  //   routes: {
  //     [`/${Tab.TeamOrganizations}`]: {
  //       node: <TeamOrganization phase={'detail'} />,
  //     },
  //   },
  // },
  [Tab.TeamDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.TeamDatasourceIdentifiers}`]: {
        node: <TeamDatasourceIdentifier phase={'select'} />
      }
    }
  }
}
