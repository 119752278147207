import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";

export class UserRecord<TJsonData extends UserRecord.CtorJsonData = UserRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  user_group_id?: string
  name?: string
  email?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:UserRecord.CtorJsonData) {
    return new UserRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.user_group_id = jsonData.user_group_id
    this.name = jsonData.name
    this.email = jsonData.email
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      user_group_id: this.user_group_id,
      name: this.name,
      email: this.email
    }) as TJsonData
  }
}

export module UserRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    user_group_id?: string
    name?: string
    email?: string
  }
}