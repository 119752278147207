import React, { useState, useEffect, useContext } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import { ItemWrapper } from 'styles/common'
// Compornents
import SportmanTeamMembershipForm from './Form'
import Loading from 'components/utils/Loading'
import Warning from 'components/utils/Warning'
// Additional
import { Tab } from 'static/tabs'
import { Text } from 'static/text'
import { AppContext } from 'App'
import { SportsmanTeamMembershipRecord } from 'data/SportsmanTeamMembershipRecord'
import { SportsmanTeamMembershipContext } from 'components/SportsmanTeamMemberships/__SportsmanTeamMemberships.context'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'

const Detail: React.FC<{ isNew?: boolean }> = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    locked: a_locked,
    location: a_location,
  } = useContext(AppContext)

  const { params: stm_params } = useContext(SportsmanTeamMembershipContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    stm_params?.sportsman_team_membership_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: SportsmanTeamMembershipRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !stm_params?.sportsman_team_membership_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try {
      const _data = await DataHelper.getSportsmanTeamMembershipById(
        a_db!,
        stm_params?.sportsman_team_membership_id,
        {
          alert: a_alert,
        }
      )

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [stm_params?.sportsman_team_membership_id])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: SportsmanTeamMembershipRecord) => {
    a_location?.set?.(Tab.SportsmanTeamMemberships)
  }

  useEffect(() => {
    _setLocation(content?.data)

    content?.data && a_locked?.set(!!content?.data?.data_frozen_at)
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <SportmanTeamMembershipForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
          submitCallback={_setLocation}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
