import React, { useState, useEffect, useContext } from 'react'
import { useAsyncEffect } from 'use-async-effect'
// Styles
import { ItemWrapper } from 'styles/common'
// Compornents
import PlayerForm from './Form'
import Loading from 'components/utils/Loading'
import Warning from 'components/utils/Warning'
// Additional
import { Tab } from 'static/tabs'
import { Text } from 'static/text'
import { AppContext } from 'App'
import { PlayerRecord } from 'data/PlayerRecord'
import { PlayerContext } from 'components/Player/__Players.context'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'

const Detail: React.FC<{ isNew?: boolean }> = (p) => {
  const {
    db: a_db,
    alert: a_alert,
    locked: a_locked,
    location: a_location,
  } = useContext(AppContext)

  const { params: p_params } = useContext(PlayerContext)
  const isNewRecord = RoutingHelper.getIsNewRecord(
    p_params?.player_id ?? RoutingHelper.NewRecordSymbol
  )

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: PlayerRecord
    load: {
      isInProgress?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (isNewRecord || !p_params?.player_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try {
      const _data = await DataHelper.getPlayerById(a_db!, p_params?.player_id, {
        alert: a_alert,
      })

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true },
      }))
    }
  }, [p_params?.player_id])

  // -------------------- Set Location ------------------- //
  const _setLocation = (data?: PlayerRecord) => {
    a_location?.set?.(Tab.Players)
  }

  useEffect(() => {
    _setLocation(content?.data)
  }, [content?.data, isNewRecord])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return (
    <>
      <ItemWrapper>
        <PlayerForm
          data={content?.data}
          state={isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
          submitCallback={_setLocation}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
