import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { Settings } from './Table.settings'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { TournamentStageRecord } from 'data/TournamentStageRecord'
import { AppContext } from 'App'
import { Data } from 'helpers/data'

const StagesTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: t_params } = useContext(TournamentContext)

  const loadData = async () => {
    if (t_params?.tournament_id)
      return await Data.getStagesByTournamentId(a_db!, t_params?.tournament_id, { alert: a_alert })
        
    return await Data.getAllStages(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<TournamentStageRecord>
      reloadFrom={[Tab.Stages]}
      loadDataFn={loadData}
      settings={Settings.get(t_params?.tournament_id, {
        disableNew: !t_params?.tournament_id,
        hide: { tournament: !!t_params?.tournament_id },
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default StagesTable
