import React, { useRef } from 'react'
// Styles
import * as S from './Image.styles'
// Components
import { Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'

interface Props {
  readonly imageUrl: string

  readonly inputName?: string

  readonly isLocked?: boolean

  readonly labelName?: string

  readonly onImageClickCallback?: () => void
  readonly onUpdateClickCallback?: (updateVal: string) => void
}

const ImageGallery: React.FC<Props> = (p) => {
  const inputUrlRef = useRef<HTMLInputElement>(null)

  const images: string[] = p.imageUrl.split('|')

  return (
    <S.PhotoGroupWrapper>
      <S.ImageWrapper>
        {images.map((x, idx) => (
        <S._Image key={`image_${idx}`} src={x} onClick={p.onImageClickCallback} />
        ))}
        {/* <S._Image src={p.imageUrl} onClick={p.onImageClickCallback} /> */}
      </S.ImageWrapper>
      <Form.Group style={{ margin: 0 }}>
        <InputGroup as={S.ItemWrapper}>
          <InputGroup.Text>{p.labelName}</InputGroup.Text>
          <Form.Control
            name={p.inputName}
            value={p.imageUrl ?? ''}
            ref={inputUrlRef}
            readOnly={p.isLocked}
          />
          <InputGroup.Append>
            <Button
              variant={'outline-secondary'}
              onClick={(e) => {
                p.onUpdateClickCallback?.(inputUrlRef.current?.value ?? '')
              }}
              disabled={p.isLocked}
            >
              Update
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </S.PhotoGroupWrapper>
  )
}

export default ImageGallery
