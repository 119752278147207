import React, { ComponentProps, useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { TournamentStageRecord } from 'data/TournamentStageRecord'
import { AppContext } from 'App'
import { StageContext } from 'components/Stages/__Stages.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = ['tournament_id', 'name', 'order_number'] as Array<
  keyof TournamentStageRecord
>

type ValidationKeys = Record<keyof Pick<TournamentStageRecord, 'name'>, any>

const StagesForm: React.FC<FormHelper.Props<TournamentStageRecord>> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: s_params } = useContext(StageContext)
  const { params: t_params } = useContext(TournamentContext)

  const [form, setForm] = useState<{
    data?: TournamentStageRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Stage',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      tournament_id: form.data?.tournament_id ?? s_params?.tournament_id,
    },

    onSubmit: async (values) => {
      if (!db?.tournamentStages) return

      try {
        const data = await FormHelper.submitChanges<TournamentStageRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TournamentStageRecord(), values ?? {}),
          repository: db.tournamentStages,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getStageDetailUrl({
              tournament:
                t_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              stage: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      name: Yup.string().required('Name is a required field'),
    }),
  })

  const approve = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.approve({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.unapprove({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  const freeze = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.freeze({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.unfreeze({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.delete_({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.tournamentStages) return

    await FormHelper.touch({
      data: Object.assign(new TournamentStageRecord(), formik.values ?? {}),
      repository: db.tournamentStages,
      optional: opt,
    })
  }

  //#region Form Components
  const _Tournament = (
    <Form.Control
      name={'tournament_id' as keyof TournamentStageRecord}
      value={formik.values.tournament_id ?? ''}
      hidden
    />
  )

  const _Name = (
    <FormItem
      label={<Form.Label>Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'name' as keyof TournamentStageRecord}
            value={formik.values.name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _OrderNumber = (
    <FormItem
      label={<Form.Label>Order Number</Form.Label>}
      input={
        <Form.Control
          name={'order_number' as keyof TournamentStageRecord}
          type={'number'}
          value={formik.values.order_number ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* ----------- Hidden Inputs Section ----------- */}
          {_Tournament}
          {/* --------------------------------------------- */}

          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_Name}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_OrderNumber}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default StagesForm
