import React from 'react'
// Components
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// Styles
import { FlagIcon } from 'styles/fontAwesome'

const VARIANT = 'outline-secondary'

const LinkToButton: React.FC<{ path?: string }> = (p) => (
  <>
    {p.path && (
      <Button variant={VARIANT} as={Link} to={{ pathname: p.path }}>
        <FlagIcon />
      </Button>
    )}
    {!p.path && (
      <Button variant={VARIANT} disabled>
        <FlagIcon />
      </Button>
    )}
  </>
)

export default LinkToButton
