import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportRecord } from "./SportRecord"

export class TeamStatScalesRecord<TJsonData extends TeamStatScalesRecord.CtorJsonData = TeamStatScalesRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  data_frozen_at?: Date | null

  /// >>> sport >>>
  #sport:BaseRecord.RefField<SportRecord> = {}

  sport_id?: string

  get sport() {
    return this.#sport.object
  }
  getSport() {
    this.#sport.id = this.sport_id

    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  matches_won_ratio_scale?: TeamStatScalesRecord.StatScale
  rounds_won_ratio_scale?: TeamStatScalesRecord.StatScale
  rounds_won_t_ratio_scale?: TeamStatScalesRecord.StatScale
  rounds_won_ct_ratio_scale?: TeamStatScalesRecord.StatScale
  bomb_plants_ratio_per_round_scale?: TeamStatScalesRecord.StatScale
  bomb_defuses_ratio_per_round_scale?: TeamStatScalesRecord.StatScale
  
  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get name() {
    return `${this.sport?.full_name}`
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamStatScalesRecord.CtorJsonData) {
    return new TeamStatScalesRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)
    
    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)

    this.sport_id = jsonData?.sport_id

    this.matches_won_ratio_scale = jsonData?.matches_won_ratio_scale
    this.rounds_won_ratio_scale = jsonData?.rounds_won_ratio_scale
    this.rounds_won_t_ratio_scale = jsonData?.rounds_won_t_ratio_scale
    this.rounds_won_ct_ratio_scale = jsonData?.rounds_won_ct_ratio_scale
    this.bomb_plants_ratio_per_round_scale = jsonData?.bomb_plants_ratio_per_round_scale
    this.bomb_defuses_ratio_per_round_scale = jsonData?.bomb_defuses_ratio_per_round_scale
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      data_frozen_at: this.data_frozen_at?.toISOString(),

      sport_id: this.sport_id,

      matches_won_ratio_scale: this.matches_won_ratio_scale,
      rounds_won_ratio_scale: this.rounds_won_ratio_scale,
      rounds_won_t_ratio_scale: this.rounds_won_t_ratio_scale,
      rounds_won_ct_ratio_scale: this.rounds_won_ct_ratio_scale,
      bomb_plants_ratio_per_round_scale: this.bomb_plants_ratio_per_round_scale,
      bomb_defuses_ratio_per_round_scale: this.bomb_defuses_ratio_per_round_scale,
    }) as TJsonData
  }

  async preload() {
    await super.preload()
    
    const sport = await this.getSport()
    await sport?.preload()
  }
}

export module TeamStatScalesRecord {
  export type StatScale = {
    min: number
    max: number
    breakpoints: number[]
  }

  export type StatHistogramItem = {
    value?: number | null
    count?: number | null
  }

  export type StatHistogram = {
    min_value: number
    max_value: number
    items: Array<StatHistogramItem>
  }
  
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    data_frozen_at?: string
    
    sport_id?: string

    matches_won_ratio_scale?: TeamStatScalesRecord.StatScale
    rounds_won_ratio_scale?: TeamStatScalesRecord.StatScale
    rounds_won_t_ratio_scale?: TeamStatScalesRecord.StatScale
    rounds_won_ct_ratio_scale?: TeamStatScalesRecord.StatScale
    bomb_plants_ratio_per_round_scale?: TeamStatScalesRecord.StatScale
    bomb_defuses_ratio_per_round_scale?: TeamStatScalesRecord.StatScale
  }

  export class Repository extends RecordRepository<TeamStatScalesRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected loadById(id: string) {
      return this.getApi().getOneTeamStatScaleById(id)
    }

    protected loadAll() {
      return this.getApi().getAllTeamStatScales()
    }

    protected updateById(id: string, patch: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeamStatScale(id, patch)
    }

    protected deleteById(id: string): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneTeamStatScaleById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<TeamStatScalesRecord<CtorJsonData>>): Promise<TeamStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneTeamStatScaleById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllTeamStatScales()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneTeamStatScaleById(id)
    }
  }
}