export module String {
  /**
   * Space replacer
   *
   * @param input input value
   * @param to optional - if not provided -> replace space by &nbsp
   * @returns Replaced value
   */
   export const spaceTo = (input: string, to?: string): string => {
    return input.replaceAll(' ', to ?? '&nbsp;')
  }
}
