import React from 'react'
// Components
import Sports from 'components/Sports/__Sports'
import Tournaments from 'components/Tournament/__Tournament'
import Stages from 'components/Stages/__Stages'
import Round from 'components/Rounds/__Rounds'
import Matches from 'components/Matches/__Matches'
import MatchMaps from 'components/MatchMaps/__MatchMaps'
import MatchMapVetos from 'components/MatchMapVetos/__MatchMapVetos'
import Team from 'components/Team/__Team'
import Sportsmen from 'components/Sportsmen/__Sportsmen'
import Players from 'components/Player/__Players'
import SportsmanRanks from 'components/SportsmanRanks/__SportsmanRanks'
import SportsmanTeamMemberhips from 'components/SportsmanTeamMemberships/__SportsmanTeamMemberships'
import TeamRanks from 'components/TeamRanks/__TeamRanks'
import SportsmanStats from 'components/SportsmanStatSummary/__SportsmanStatSummaries'
import TeamStats from 'components/TeamStatSummary/__TeamStatSummaries'
import NearestMatch from 'components/NearestMatches/__NearestMatches'
import GamingGear from 'components/GamingGears/__GamingGears'
import SportsmanDatasourceIdentifier from 'components/SportsmanDatasourceIdentifiers/__SportsmanDatasourceIdentifiers'
import TeamOrganization from 'components/TeamOrganizations/__TeamOrganizations'
import TeamDatasourceIdentifier from 'components/TeamDatasourceIdentifiers/__TeamDatasourceIdentifiers'
import TeamOrganizationDatasourceIdentifier from 'components/TeamOrganizationDatasourceIdentifiers/__TeamOrganizationDatasourceIdentifiers'
import TournamentDatasourceIdentifier from 'components/TournamentDatasourceIdentifiers/__TournamentDatasourceIdentifiers'
import MatchDatasourceIdentifier from 'components/MatchDatasourceIdentifiers/__MatchDatasourceIdentifier'
import TournamentStageDatasourceIdentifier from 'components/TournamentStageDatasourceIdentifiers/__TournamentStagetDatasourceIdentifier'
// Additional
import { Tab } from 'static/tabs'
import { Routing } from 'helpers/routing'


export module TabMapper {
  export interface RouteProps {
    readonly node: React.ReactNode
    readonly isExact?: boolean
  }

  export interface Node {
    readonly nodes?: { [id: string]: Node }

    readonly routes?: { [id: string]: RouteProps }

    readonly disableTabRender?: boolean
    readonly overrideText?: string
  }

  export const Settings: { [id: string]: Node } = {
    [Tab.Sports]: {
      routes: {
        [Routing.getSportSelectPath()]: {
          node: <Sports phase={'select'} />,
          isExact: true,
        },
        [Routing.getDefaultPath()]: {
          node: <Sports phase={'select'} />,
          isExact: true,
        },

        [Routing.getSportDetailPath()]: {
          node: <Sports phase={'detail'} />,
        },
      },
      nodes: {
        [Tab.Teams]: {
          routes: {
            [Routing.getTeamSelectPath()]: {
              node: <Team phase={'select'} />,
              isExact: true,
            },
            [Routing.getTeamDetailPath()]: {
              node: <Team phase={'detail'} />,
            },
          },
        },
        [Tab.TeamOrganizations]: {
          routes: {
            [Routing.getTeamOrganizationSelectPath()]: {
              node: <TeamOrganization phase={'select'} />,
              isExact: true,
            },
            [Routing.getTeamOrganizationDetailPath()]: {
              node: <TeamOrganization phase={'detail'} />,
            },
          },
        },
        [Tab.Sportsmen]: {
          routes: {
            [Routing.getSportsmanSelectPath()]: {
              node: <Sportsmen phase={'select'} />,
              isExact: true,
            },
            [Routing.getSportsmanDetailPath()]: {
              node: <Sportsmen phase={'detail'} />,
            },
          },
          nodes: {
            [Tab.SportsmanTeamMemberships]: {
              routes: {
                [Routing.getSportsmanTeamMembershipDetailPathFromTeam()]: {
                  node: <SportsmanTeamMemberhips phase={'detail'} />,
                },
                [Routing.getSportsmanTeamMembershipDetailPathFromSportsman()]: {
                  node: <SportsmanTeamMemberhips phase={'detail'} />,
                },
              },
              disableTabRender: true,
            },
          },
        }
      }
    },
    [Tab.Tournaments]: {
      routes: {
        [Routing.getTournamentSelectPath()]: {
          node: <Tournaments phase={'select'} />,
          isExact: true,
        },
        [Routing.getTournamentDetailPath()]: {
          node: <Tournaments phase={'detail'} />,
        },
      },
      nodes: {
        [Tab.Stages]: {
          routes: {
            [Routing.getStageSelectPath()]: {
              node: <Stages phase={'select'} />,
              isExact: true,
            },
            [Routing.getStageDetailPath()]: {
              node: <Stages phase={'detail'} />,
            },
          },
        },
        [Tab.Rounds]: {
          routes: {
            [Routing.getRoundSelectPath()]: {
              node: <Round phase={'select'} />,
              isExact: true,
            },
            [Routing.getRoundDetailPath()]: {
              node: <Round phase={'detail'} />,
            },
          },
        },
        [Tab.Matches]: {
          routes: {
            [Routing.getMatchSelectPath()]: {
              node: <Matches phase={'select'} />,
              isExact: true,
            },
            [Routing.getMatchDetailPath()]: {
              node: <Matches phase={'detail'} />,
            },
          },
          nodes: {
            [Tab.MatchMaps]: {
              routes: {
                [Routing.getMatchMapDetailPath()]: {
                  node: <MatchMaps phase={'detail'} />,
                },
              },
              disableTabRender: true,
            },
            [Tab.MatchMapVetos]: {
              routes: {
                [Routing.getMatchMapVetoDetailPath()]: {
                  node: <MatchMapVetos phase={'detail'} />,
                },
              },
              disableTabRender: true,
            },
            [Tab.Players]: {
              routes: {
                [Routing.getPlayerDetailPath()]: {
                  node: <Players phase={'detail'} />,
                },
              },
              disableTabRender: true,
            },
          },
        },
      },
    },
    [Tab.NearestMatches]:{
      routes: {
        [Routing.getNearestMatchSelectPath()]: {
          node: <NearestMatch phase={'select'} />,
          isExact: true,
        },
        [Routing.getNearestMatchDetailPath()]: {
          node: <NearestMatch phase={'detail'} />,
        },
      }
    },
    [Tab.GammingGears]: {
      routes: {
        [Routing.getGamingGearSelectPath()]: {
          node: <GamingGear phase={'select'} />,
          isExact: true,
        },
        [Routing.getGamingGearDetailPath()]: {
          node: <GamingGear phase={'detail'} />,
        },
      }
    },
    [Tab.TeamStats]: {
      routes: {
        [Routing.getTeamStatSelectPath()]: {
          node: <TeamStats phase={'select'} />,
          isExact: true,
        },
      },
      disableTabRender: true,
    },
    [Tab.TeamRanks]: {
      routes: {
        [Routing.getTeamRankSelectPath()]: {
          node: <TeamRanks phase={'select'} />,
          isExact: true,
        },
        [Routing.getTeamRankDetailPath()]: {
          node: <TeamRanks phase={'detail'} />,
        },
      },
      disableTabRender: true,
    },
    [Tab.SportsmanStats]: {
      routes: {
        [Routing.getSportsmanStatSelectPath()]: {
          node: <SportsmanStats phase={'select'} />,
          isExact: true,
        },
      },
      disableTabRender: true,
    },
    [Tab.SportsmanRanks]: {
      routes: {
        [Routing.getSportsmanRankSelectPath()]: {
          node: <SportsmanRanks phase={'select'} />,
          isExact: true,
        },
        [Routing.getSportsmanRankDetailPath()]: {
          node: <SportsmanRanks phase={'detail'} />,
        },
      },
      disableTabRender: true,
    },
  }
}
