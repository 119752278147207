import React, { ComponentProps, useState, useContext } from 'react'
import { SportsmanBiographyRecord } from 'data/SportsmanBiographyRecord'
import { useFormik } from 'formik'

import { Form as FormHelper } from 'helpers/form'
import { AppContext } from 'App'
import { Col, Form } from 'react-bootstrap'
import Footer from 'components/utils/Form/Footer'
import FormItem from 'components/utils/FormItem'
import Image from 'components/utils/Image/Image'
import { ItemWrapper, OffsetedContainer } from 'styles/common'
import { FormContext } from 'components/utils/Form.context'
import RichTextEditor from 'components/RichTextEditor'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'
import ImageGallery from 'components/utils/Image/ImageGallery'

const SubmitKeys = [
  'facebook',
  'images',
  'instagram',
  'perex',
  'sportsman_id',
  'text',
  'twich',
  'twitter',
] as Array<keyof SportsmanBiographyRecord>

const SportsmanBiographyForm: React.FC<
  FormHelper.Props<SportsmanBiographyRecord> & { sportsmanId?: string }
> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: SportsmanBiographyRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Sportsman Biography',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      sportsman_id: p.sportsmanId,
    },

    onSubmit: async (values) => {
      if (!db?.sportsmanBiographies) return

      try {
        values.id = form?.data?.id // TMP the form somehow loses its id

        const data = await FormHelper.submitChanges<SportsmanBiographyRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new SportsmanBiographyRecord(), values ?? {}),
          repository: db.sportsmanBiographies,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },
  })

  //#region Form Components
  const _Facebook = (
    <FormItem
      label={<Form.Label>Facebook</Form.Label>}
      input={
        <>
          <Form.Control
            name={'facebook' as keyof SportsmanBiographyRecord}
            value={formik.values.facebook ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Instagram = (
    <FormItem
      label={<Form.Label>Instagram</Form.Label>}
      input={
        <>
          <Form.Control
            name={'instagram' as keyof SportsmanBiographyRecord}
            value={formik.values.instagram ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Twich = (
    <FormItem
      label={<Form.Label>Twich</Form.Label>}
      input={
        <>
          <Form.Control
            name={'twich' as keyof SportsmanBiographyRecord}
            value={formik.values.twich ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Twitter = (
    <FormItem
      label={<Form.Label>Twitter</Form.Label>}
      input={
        <>
          <Form.Control
            name={'twitter' as keyof SportsmanBiographyRecord}
            value={formik.values.twitter ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Perex = (
    <FormItem
      label={<Form.Label>Perex</Form.Label>}
      input={
        <>
          <Form.Control
            name={'perex' as keyof SportsmanBiographyRecord}
            value={formik.values.perex ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Text = (
    <FormItem
      label={<Form.Label>Text</Form.Label>}
      input={
        <>
          <ItemWrapper />
          <RichTextEditor
            text={formik.values.text ?? ''}
            readonly={p.locked?.value}
            onChange={(text?: string) => {
              formik.setFieldValue(
                'text' as keyof SportsmanBiographyRecord,
                text
              )
            }}
          />
        </>
      }
    />
  )

  const _Image = (
    <ImageGallery
      inputName={'images' as keyof SportsmanBiographyRecord}
      imageUrl={formik.values.images ?? ''}
      isLocked={p.locked?.value}
      labelName='Bio Photo'
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'sportsmen'}
                actualImageString={formik.values.images ?? ''}
                useMultipleSelect={{
                  divider: '|',
                }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'images' as keyof SportsmanBiographyRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('images' as keyof SportsmanBiographyRecord, val)
      }
    />
  )
  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Footer {..._ComputeFooterProps()} />

          <Form.Row>
            <Col lg={12}>
              <ItemWrapper />
              <Form.Row>
                <Col as={ItemWrapper}>{_Facebook}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Instagram}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Twich}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Twitter}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Perex}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Text}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Image}</Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportsmanBiographyForm
