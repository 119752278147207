import React from 'react'
// Components
import { Accordion as _Accordion, useAccordionToggle } from 'react-bootstrap'
import { AccordionContext } from './Accordion.context'

const EVENT_KEY = '0'

const AccordionToggle: React.FC = (p) => {
  const toggle = useAccordionToggle(EVENT_KEY)

  return (
    <AccordionContext.Provider value={{ toggle }}>
      {p.children}
    </AccordionContext.Provider>
  )
}

const Accordion: React.FC<{
  content: React.ReactNode
  toggle: React.ReactNode
  direction?: 'top' | 'bottom'
}> = (p) => {
  const _direction = p.direction ?? 'top'

  const _Collapse = (
    <_Accordion.Collapse eventKey={EVENT_KEY}>
      <>{p.content}</>
    </_Accordion.Collapse>
  )

  return (
    <_Accordion>
      {_direction === 'top' && _Collapse}
      <AccordionToggle>{p.toggle}</AccordionToggle>
      {_direction === 'bottom' && _Collapse}
    </_Accordion>
  )
}

export default Accordion
