import React, { useEffect, useContext } from 'react'
// Components
import Filters from './Filters'
import Table from './Table/TableWithAdd'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'

const Select: React.FC = () => {
  const { location: a_location } = useContext(AppContext)

  useEffect(() => {
    a_location?.set(Tab.Sports)
  }, [])

  return (
    <>
      <Filters />
      <Table />
    </>
  )
}

export default Select
