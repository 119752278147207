import { AppContext } from "App"
import { TeamOrganizationContext } from "components/TeamOrganizations/__TeamOrganizations.context"
import TableLoader from "components/utils/Table/Table"
import { TeamOrganizationDatasourceIdentifierRecord } from "data/TeamOrganizationDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const TeamOrganizationDatasourceIdentifiersTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: to_params } = useContext(TeamOrganizationContext)

  const loadData = async () => {
    if(to_params?.team_organization_id)
    return await Data.getTeamOrganizationDatasourceIdentifiersByTeamOrgId(
      a_db!,
      to_params.team_organization_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<TeamOrganizationDatasourceIdentifierRecord>
      reloadFrom={[Tab.TeamOrganizatonDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        teamOrganization: to_params?.team_organization_id
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TeamOrganizationDatasourceIdentifiersTable
