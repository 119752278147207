import { AppContext } from "App"
import { MatchContext } from "components/Matches/__Matches.context"
import TableLoader from "components/utils/Table/Table"
import { MatchDatasourceIdentifierRecord } from "data/MatchDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const MatchDatasourceIdentifierTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: m_params } = useContext(MatchContext)

  const loadData = async () => {
    if (m_params?.match_id)
    return await Data.getMatchDatasourceIdentifiersByMatchId(
      a_db!,
      m_params?.match_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<MatchDatasourceIdentifierRecord>
      reloadFrom={[Tab.MatchDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        match: m_params?.match_id,
        tournament: m_params?.tournament_id
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
    }}
    />
  )
}

export default MatchDatasourceIdentifierTable
