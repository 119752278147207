import { MatchRecord } from "./MatchRecord";
import { MatchMapRecord } from "./MatchMapRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { SportRecord } from "./SportRecord";
import { PlayerRecord } from "./PlayerRecord";
import { SportsmanRecord } from "./SportsmanRecord";
import { TeamRecord } from "./TeamRecord";
import { TournamentRecord } from "./TournamentRecord";
import { TournamentStageRecord } from "./TournamentStageRecord";
import { TournamentRoundRecord } from "./TournamentRoundRecord";
import { TournamentTeamRankRecord } from "./TournamentTeamRankRecord";
import { TournamentSportsmanRankRecord } from "./TournamentSportsmanRankRecord";
import { GameMapRecord } from "./GameMapRecord";
import { RegionRecord } from "./RegionRecord";
import { PeriodKindRecord } from "./PeriodKindRecord";
import { TimespacesRecord } from "./TimespacesRecord";
import { SportsmanStatSummaryRecord } from "./SportsmanStatSummaryRecord";
import { TeamStatSummaryRecord } from "./TeamStatSummaryRecord";
import { TeamStatScalesRecord } from "./TeamStatScalesRecord";
import { MatchMapVetoRecord } from "./MatchMapVetoRecord";
import { SportsmanTeamMembershipRecord } from "./SportsmanTeamMembershipRecord";
import { SportsmanRoleRecord } from "./SportsmanRoleRecord";
import { SportsmanStatScalesRecord } from "./SportsmanStatScalesRecord";
import { SportsmanBiographyRecord } from "./SportsmanBiographyRecord";
import { TeamBiographyRecord } from "./TeamBiographyRecord";
import { GamingGearRecord } from "./GamingGearRecord";
import { SportsmanGamingGearsRecord } from "./SportsmanGamingGearsRecord";
import { NearestMatchRecord } from "./NearesMatchRecord";
import { AzureDiretcoryRecord } from 'data/AzureDirectoryRecord'
import { AzureBlobRecord } from "./AzureBlobRecord";
import { DatasourceRecord } from "./DatasourceRecord";
import { TeamOrganizationRecord } from "./TeamOrganizationRecord";
import { SportsmanDatasourceIdentifierRecord } from "./SportsmanDatasourceIdentifierRecord";
import { TeamDatasourceIdentifierRecord } from "./TeamDatasourceIdentifierRecord";
import { TeamOrganizationDatasourceIdentifierRecord } from "./TeamOrganizationDatasourceIdentifierRecord";
import { TournamentDatasourceIdentifierRecord } from "./TournamentDatasourceIdentifierRecord";
import { TournamentStageDatasourceIdentifierRecord } from "./TournamentStageDatasourceIdentifierRecord";
import { MatchDatasourceIdentifierRecord } from "./MatchDatasourceIdentifierRecord";

export class RootRepository extends BaseRootRepository {
  get datasources() {
    return (this.recordRepositories["datasources"] ?? (
      this.recordRepositories["datasources"] = new DatasourceRecord.Repository(this)
    )) as DatasourceRecord.Repository
  }

  get sports() {
    return (this.recordRepositories["sports"] ?? (
      this.recordRepositories["sports"] = new SportRecord.Repository(this)
    )) as SportRecord.Repository
  }

  get tournaments() {
    return (this.recordRepositories["tournaments"] ?? (
      this.recordRepositories["tournaments"] = new TournamentRecord.Repository(this)
    )) as TournamentRecord.Repository
  }

  get tournamentDatasourceIdentifiers() {
    return (this.recordRepositories["tournamentDatasourceIdentifiers"] ?? (
      this.recordRepositories["tournamentDatasourceIdentifiers"] = new TournamentDatasourceIdentifierRecord.Repository(this)
    )) as TournamentDatasourceIdentifierRecord.Repository
  }

  get tournamentStageDatasourceIdentifiers() {
    return (this.recordRepositories["tournamentStageDatasourceIdentifiers"] ?? (
      this.recordRepositories["tournamentStageDatasourceIdentifiers"] = new TournamentStageDatasourceIdentifierRecord.Repository(this)
    )) as TournamentStageDatasourceIdentifierRecord.Repository
  }

  get tournamentStages() {
    return (this.recordRepositories["tournamentStages"] ?? (
      this.recordRepositories["tournamentStages"] = new TournamentStageRecord.Repository(this)
    )) as TournamentStageRecord.Repository
  }

  get matches() {
    return (this.recordRepositories["matches"] ?? (
      this.recordRepositories["matches"] = new MatchRecord.Repository(this)
    )) as MatchRecord.Repository
  }

  get matchDatasourceIdentifiers() {
    return (this.recordRepositories["matches_datasource_identifiers"] ?? (
      this.recordRepositories["matches_datasource_identifiers"] = new MatchDatasourceIdentifierRecord.Repository(this)
    )) as MatchDatasourceIdentifierRecord.Repository
  }

  get matchMaps() {
    return (this.recordRepositories["matchMaps"] ?? (
      this.recordRepositories["matchMaps"] = new MatchMapRecord.Repository(this)
    )) as MatchMapRecord.Repository
  }

  get matchMapVetos() {
    return (this.recordRepositories["matchMapVetos"] ?? (
      this.recordRepositories["matchMapVetos"] = new MatchMapVetoRecord.Repository(this)
    )) as MatchMapVetoRecord.Repository
  }

  get tournamentRounds() {
    return (this.recordRepositories["tournamentRounds"] ?? (
      this.recordRepositories["tournamentRounds"] = new TournamentRoundRecord.Repository(this)
    )) as TournamentRoundRecord.Repository
  }

  get tournamentTeamRanks() {
    return (this.recordRepositories["tournamentTeamRanks"] ?? (
      this.recordRepositories["tournamentTeamRanks"] = new TournamentTeamRankRecord.Repository(this)
    )) as TournamentTeamRankRecord.Repository
  }

  get tournamentSportsmanRanks() {
    return (this.recordRepositories["tournamentSportsmanRanks"] ?? (
      this.recordRepositories["tournamentSportsmanRanks"] = new TournamentSportsmanRankRecord.Repository(this)
    )) as TournamentSportsmanRankRecord.Repository
  }

  get players() {
    return (this.recordRepositories["players"] ?? (
      this.recordRepositories["players"] = new PlayerRecord.Repository(this)
    )) as PlayerRecord.Repository
  }

  get teams() {
    return (this.recordRepositories["teams"] ?? (
      this.recordRepositories["teams"] = new TeamRecord.Repository(this)
    )) as TeamRecord.Repository
  }

  get teamDatasourceIdentifiers() {
    return (this.recordRepositories["teamDatasourceIdentifiers"] ?? (
      this.recordRepositories["teamDatasourceIdentifiers"] = new TeamDatasourceIdentifierRecord.Repository(this)
    )) as TeamDatasourceIdentifierRecord.Repository
  }

  get teamOrganizationDatasourceIdentifiers() {
    return (this.recordRepositories["teamOrganizationDatasourceIdentifiers"] ?? (
      this.recordRepositories["teamOrganizationDatasourceIdentifiers"] = new TeamOrganizationDatasourceIdentifierRecord.Repository(this)
    )) as TeamOrganizationDatasourceIdentifierRecord.Repository
  }

  get teamBiographies() {
    return (this.recordRepositories["teamBiographies"] ?? (
      this.recordRepositories["teamBiographies"] = new TeamBiographyRecord.Repository(this)
    )) as TeamBiographyRecord.Repository
  }

  get teamOrganizations() {
    return (this.recordRepositories["teamOrganizations"] ?? (
      this.recordRepositories["teamOrganizations"] = new TeamOrganizationRecord.Repository(this)
    )) as TeamOrganizationRecord.Repository
  }

  get sportsmen() {
    return (this.recordRepositories["sportsmen"] ?? (
      this.recordRepositories["sportsmen"] = new SportsmanRecord.Repository(this)
    )) as SportsmanRecord.Repository
  }

  get sportsmanBiographies() {
    return (this.recordRepositories["sportmanBiographies"] ?? (
      this.recordRepositories["sportsmanBiographies"] = new SportsmanBiographyRecord.Repository(this)
    )) as SportsmanBiographyRecord.Repository
  }

  get sportsmanDatasourceIdentifiers() {
    return (this.recordRepositories["sportsmanDatasourceIdentifiers"] ?? (
      this.recordRepositories["sportsmanDatasourceIdentifiers"] = new SportsmanDatasourceIdentifierRecord.Repository(this)
    )) as SportsmanDatasourceIdentifierRecord.Repository
  }

  get sportsmanGamingGears() {
    return (this.recordRepositories["sportsmanGamingGears"] ?? (
      this.recordRepositories["sportsmanGamingGears"] = new SportsmanGamingGearsRecord.Repository(this)
    )) as SportsmanGamingGearsRecord.Repository
  }

  get sportsmanRoles() {
    return (this.recordRepositories["sportsmanRoles"] ?? (
      this.recordRepositories["sportsmanRoles"] = new SportsmanRoleRecord.Repository(this)
    )) as SportsmanRoleRecord.Repository
  }

  get gameMaps() {
    return (this.recordRepositories["gameMaps"] ?? (
      this.recordRepositories["gameMaps"] = new GameMapRecord.Repository(this)
    )) as GameMapRecord.Repository
  }

  get regions() {
    return (this.recordRepositories["regions"] ?? (
      this.recordRepositories["regions"] = new RegionRecord.Repository(this)
    )) as RegionRecord.Repository
  }

  get periodKinds() {
    return (this.recordRepositories["periodKinds"] ?? (
      this.recordRepositories["periodKinds"] = new PeriodKindRecord.Repository(this)
    )) as PeriodKindRecord.Repository
  }

  get timespaces() {
    return (this.recordRepositories["timespaces"] ?? (
      this.recordRepositories["timespaces"] = new TimespacesRecord.Repository(this)
    )) as TimespacesRecord.Repository
  }

  get sportsmanStatSummaries() {
    return (this.recordRepositories["sportsmanStatSummaries"] ?? (
      this.recordRepositories["sportsmanStatSummaries"] = new SportsmanStatSummaryRecord.Repository(this)
    )) as SportsmanStatSummaryRecord.Repository
  }

  get teamStatSummaries() {
    return (this.recordRepositories["teamStatSummaries"] ?? (
      this.recordRepositories["teamStatSummaries"] = new TeamStatSummaryRecord.Repository(this)
    )) as TeamStatSummaryRecord.Repository
  }

  get sportsmanTeamMemberships() {
    return (this.recordRepositories["sportsmanTeamMemberships"] ?? (
      this.recordRepositories["sportsmanTeamMemberships"] = new SportsmanTeamMembershipRecord.Repository(this)
    )) as SportsmanTeamMembershipRecord.Repository
  }

  get sportsmanStatScales() {
    return (this.recordRepositories["sportsmanStatScales"] ?? (
      this.recordRepositories["sportsmanStatScales"] = new SportsmanStatScalesRecord.Repository(this)
    )) as SportsmanStatScalesRecord.Repository
  }

  get teamStatScales() {
    return (this.recordRepositories["teamStatScales"] ?? (
      this.recordRepositories["teamStatScales"] = new TeamStatScalesRecord.Repository(this)
    )) as TeamStatScalesRecord.Repository
  }

  get gamingGears() {
    return (this.recordRepositories["gamingGears"] ?? (
      this.recordRepositories["gamingGears"] = new GamingGearRecord.Repository(this)
    )) as GamingGearRecord.Repository
  }

  get nearestMatches() {
    return (this.recordRepositories["nearestMatches"] ?? (
      this.recordRepositories["nearestMatches"] = new NearestMatchRecord.Repository(this)
    )) as NearestMatchRecord.Repository
  }

  get azureDirectory() {
    return (this.recordRepositories["azureDirectory"] ?? (
      this.recordRepositories["azureDirectory"] = new AzureDiretcoryRecord.Repository(this)
    )) as AzureDiretcoryRecord.Repository
  }

  get azureBlobs() {
    return (this.recordRepositories["azureBlobs"] ?? (
      this.recordRepositories["azureBlobs"] = new AzureBlobRecord.Repository(this)
    )) as AzureBlobRecord.Repository
  }
}
