import React, { useState, useEffect } from 'react'
// Components
import { Row, Col } from 'react-bootstrap'
import UIFullTextFilter from 'components/utils/Filter/FullTextGlobalFilter'
// Styles
import { ItemWrapper } from 'styles/common'
// Adiitional
import { Loadable } from 'types/loadable'
import { Tab } from 'static/tabs'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )
  }, [])

  return (
    <Row>
      <Col as={ItemWrapper} lg={12}>
        <UIFullTextFilter
          from={Tab.MatchMapVetos}
          style={{ disabled: !table.loaded }}
        />
      </Col>
    </Row>
  )
}

export default Filters
