import React, { useState, createContext } from 'react'
import { AlertManager, useAlert } from 'react-alert'
import { useAsyncEffect } from 'use-async-effect'
import { useHistory } from 'react-router-dom'
// Components
import { Route, Switch } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import Login from 'components/Login'
import ResetPassword from 'components/ResetPassword'
import ChangePassword from 'components/ChangePassword'
import Header, { HeaderWrapper } from 'components/Header'
import Tabs from 'components/Tabs'
import Content from 'components/Content'
import Modal from 'components/utils/Modal/Modal'
// Additional
import { Tab } from 'static/tabs'
import { RootRepository } from 'data/RootRepository'
import { String } from 'helpers/string'
// Styles
import { Breakpoint } from 'styles/responsive'
import { StickyTop } from 'styles/common'
import { UserRecord } from 'data/User'

type SignReturnType = { ok: boolean; error?: string }

interface AppContextData {
  db?: RootRepository 

  readonly user?: UserRecord

  readonly alert?: AlertManager
  readonly history?: any /* History<unknown> */

  readonly activeTab?: Tab

  readonly location?: {
    readonly value?: string
    set: (tab: Tab, addInfo?: string) => void
  }

  readonly modal?: {
    open?: (args?: { content: JSX.Element }) => void
    close?: () => void
  }

  readonly login?: (email: string, password: string) => Promise<SignReturnType>
  readonly logout?: () => Promise<SignReturnType>

  readonly locked?: { readonly value?: boolean; set: (value: boolean) => void }
}

export const AppContext = createContext<AppContextData>({})

const db = new RootRepository()

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>()
  const [location, setLocation] = useState<string>()
  const [locked, setLocked] = useState<boolean>()
  const [modal, setModal] = useState<JSX.Element>()
  const [user, setUser] = useState<{
    loaded: boolean
    data?: UserRecord
  }>({
    loaded: false,
  })

  const alert = useAlert()
  const history = useHistory()

  useAsyncEffect(async () => {
    await trySetUser()
  }, [])

  const trySetUser = async () => {
    try {
      const _user = await db?.api.getMyProfile()

      if (!_user) return

      setUser((prev) => ({ ...prev, data: _user }))

      return true
    } finally {
      setUser((prev) => ({ ...prev, loaded: true }))
    }
  }

  const handleLogin = async (
    email: string,
    password: string
  ): Promise<SignReturnType> => {
    try {
      const loggedIn = await db?.api.login(email, password)

      if (loggedIn.ok && (await trySetUser())) return { ok: true }

      return {
        ok: false,
      }
    } catch (err) {
      console.error(err)

      return {
        ok: false,
        //@ts-ignore
        error: err?.response?.data?.error?.message ?? 'Something went wrong',
      }
    }
  }

  const handleLogout = async (): Promise<SignReturnType> => {
    try {
      const loggedOut = await db?.api.logout()

      if (loggedOut.ok) setUser((prev) => ({ ...prev, data: undefined }))

      return {
        ok: loggedOut.ok,
      }
    } catch (err) {
      console.error(err)

      return {
        ok: false,
        //@ts-ignore
        error: err?.response?.data?.error?.message ?? 'Something went wrong',
      }
    }
  }

  const handleSetLocation = (location: Tab, addInfo?: string): void => {
    setActiveTab(location)

    setLocation(
      `<b>${String.spaceTo(location, '&nbsp;')}</b> ${
        addInfo ? ` ${addInfo}` : ''
      }`
    )

    setLocked(false)
  }

  const handleSetLocked = (locked: boolean) => {
    setLocked(locked)
  }

  if (!user.loaded) return <></>

  if (!user.data) {
    return (
      <AppContext.Provider
        value={{
          db,
          alert,
          login: handleLogin,
          history,
        }}
      >
        <Switch>
          <Route path={`/reset-password`}>
            <ResetPassword />
          </Route>

          <Route>
            <Login />
          </Route>
        </Switch>
      </AppContext.Provider>
    )
  }

  return (
    <AppContext.Provider
      value={{
        db,
        user: user.data,
        alert,
        history,
        activeTab,
        logout: handleLogout,
        location: {
          value: location,
          set: handleSetLocation,
        },
        locked: {
          value: locked,
          set: handleSetLocked,
        },
        modal: {
          open: (args) => setModal(args?.content),
          close: () => setModal(undefined),
        },
      }}
    >
      {modal && <Modal>{modal}</Modal>}

      <Route exact path={`/change-password`}>
        <ChangePassword />
      </Route>

      <Breakpoint.DesktopTablet>
        <Row noGutters>
          <StickyTop as={Col} xl={2} lg={2} md={3} style={{ height: '100vh' }}>
            <Tabs />
          </StickyTop>
          <Col>
            <StickyTop as={HeaderWrapper}>
              <Header />
            </StickyTop>
            <Container fluid>
              <Content />
            </Container>
          </Col>
        </Row>
      </Breakpoint.DesktopTablet>

      <Breakpoint.Mobile>
        <Row>
          <Container fluid>
            <Col>
              <StickyTop as={HeaderWrapper}>
                <Header />
                <Tabs />
              </StickyTop>
              <Content />
            </Col>
          </Container>
        </Row>
      </Breakpoint.Mobile>
    </AppContext.Provider>
  )
}

export default App
