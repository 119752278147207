import React, { ComponentProps, useEffect, useState, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import FormDropdown from 'components/utils/FormDropdown'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { TournamentSportsmanRankRecord } from 'data/TournamentSportsmanRankRecord'
import { AppContext } from 'App'
import { SportsmanRankContext } from './__SportsmanRanks.context'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'

const SubmitKeys = [
  'rank_from',
  'rank_to',
  'tournament_id',
  'team_id',
  'sportsman_id',
] as Array<keyof TournamentSportsmanRankRecord>

type ValidationKeys = Record<
  keyof Pick<TournamentSportsmanRankRecord, 'team_id' | 'sportsman_id'>,
  any
>

const SportmanRankForm: React.FC<
  FormHelper.Props<TournamentSportsmanRankRecord>
> = (p) => {
  const { db, alert, history } = useContext(AppContext)
  const { params: sr_params } = useContext(SportsmanRankContext)
  const { params: t_params } = useContext(TournamentContext)

  const [form, setForm] = useState<{
    data?: TournamentSportsmanRankRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Sportman rank',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...p.data,

      tournament_id: p.data?.tournament?.id ?? sr_params?.tournament_id,

      team_id: p.data?.team?.id,
      team_text: p.data?.team?.full_name,

      sportsman_id: p.data?.sportsman?.id,
      sportsman_text: p.data?.sportsman_nickname,
    },

    onSubmit: async (values) => {
      if (!db?.tournamentSportsmanRanks) return

      try {
        const data =
          await FormHelper.submitChanges<TournamentSportsmanRankRecord>({
            action: form.state === 'new' ? 'create' : 'update',
            keys: SubmitKeys,
            data: Object.assign(
              new TournamentSportsmanRankRecord(),
              values ?? {}
            ),
            repository: db.tournamentSportsmanRanks,
            optional: opt,
          })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getSportsmanRankDetailUrl({
              tournament:
                t_params?.tournament_id ?? RoutingHelper.NewRecordSymbol,
              sportsmanRank: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
      FormHelper.submitChanges<TournamentSportsmanRankRecord>({
        action: form.state === 'new' ? 'create' : 'update',
        keys: SubmitKeys,
        data: Object.assign(new TournamentSportsmanRankRecord(), values ?? {}),
        repository: db.tournamentSportsmanRanks,
        optional: opt,
      })
    },

    validationSchema: Yup.object<ValidationKeys>({
      team_id: Yup.string().required('Team is a required field'),
      sportsman_id: Yup.string().required('Sportsman is a required field'),
    }),
  })

  const freeze = async () => {
    if (!db?.tournamentSportsmanRanks) return

    await FormHelper.freeze({
      data: Object.assign(
        new TournamentSportsmanRankRecord(),
        formik.values ?? {}
      ),
      repository: db.tournamentSportsmanRanks,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.tournamentSportsmanRanks) return

    await FormHelper.unfreeze({
      data: Object.assign(
        new TournamentSportsmanRankRecord(),
        formik.values ?? {}
      ),
      repository: db.tournamentSportsmanRanks,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.tournamentSportsmanRanks) return

    await FormHelper.delete_({
      data: Object.assign(
        new TournamentSportsmanRankRecord(),
        formik.values ?? {}
      ),
      repository: db.tournamentSportsmanRanks,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.tournamentSportsmanRanks) return

    await FormHelper.touch({
      data: Object.assign(
        new TournamentSportsmanRankRecord(),
        formik.values ?? {}
      ),
      repository: db.tournamentSportsmanRanks,
      optional: opt,
    })
  }

  const [teams, setTeams] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team_id ?? '',
      text: formik.values.team_text,
    },
    loaded: false,
  })

  const [sportsmen, setSportsmen] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.sportsman_id ?? '',
      text: formik.values.sportsman_text,
    },
    loaded: false,
  })

  const _setTeams = async () => {
    const _data = await DataHelper.getTeamsByTournamentId(
      db!,
      p.data?.tournament?.id ?? sr_params?.tournament_id ?? '-'
    )

    setTeams((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.full_name ?? '' } as Item
      }),
    }))
  }

  const _setSportsmen = async () => {
    const _data = await DataHelper.getSportsmenByTournamentId(
      db!,
      p.data?.tournament?.id ?? sr_params?.tournament_id ?? '-'
    )

    setSportsmen((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data?.map((s) => {
        return {
          id: s.id ?? '',
          text: s.nickname ?? '',
          color: !s.isApproved ? 'red' : null,
        } as Item
      }),
    }))
  }

  const handleTeamSelect = (item: Item) => {
    formik.setFieldValue(
      'team_id' as keyof TournamentSportsmanRankRecord,
      item.id
    )

    setTeams((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleSportsmanSelect = (item: Item) => {
    formik.setFieldValue(
      'sportsman_id' as keyof TournamentSportsmanRankRecord,
      item.id
    )

    setSportsmen((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  useEffect(() => {
    _setTeams()
    _setSportsmen()
  }, [])

  //#region Form Components
  const _Team = (
    <FormItem
      label={<Form.Label>Team</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'team_id' as keyof TournamentSportsmanRankRecord}
            items={teams.data ?? []}
            onSelect={handleTeamSelect}
            value={teams.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.team_id}
            disabled={p.locked?.value}
            loading={!teams.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.team_id &&
                  RoutingHelper.getTeamDetailUrl({
                    sport: RoutingHelper.NewRecordSymbol,
                    team: formik.values.team_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.team_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Sportsman = (
    <FormItem
      label={<Form.Label>Sportsman</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'sportsman_id' as keyof TournamentSportsmanRankRecord}
            items={sportsmen.data ?? []}
            onSelect={handleSportsmanSelect}
            value={sportsmen.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.sportsman_id}
            disabled={p.locked?.value}
            loading={!sportsmen.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.sportsman_id &&
                  RoutingHelper.getSportsmanDetailUrl({
                    sport: RoutingHelper.NewRecordSymbol,
                    sportsman: formik.values.sportsman_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.sportsman_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Tournament = (
    <Form.Control
      name={'tournament_id' as keyof TournamentSportsmanRankRecord}
      value={formik.values.tournament_id}
      hidden
    />
  )

  const _RankFrom = (
    <FormItem
      label={<Form.Label>Rank From</Form.Label>}
      input={
        <Form.Control
          name={'rank_from' as keyof TournamentSportsmanRankRecord}
          type={'number'}
          value={formik.values.rank_from ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _RankTo = (
    <FormItem
      label={<Form.Label>Rank To</Form.Label>}
      input={
        <Form.Control
          name={'rank_to' as keyof TournamentSportsmanRankRecord}
          type={'number'}
          value={formik.values.rank_to ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )
  //#endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* ----------- Hidden Inputs Section ----------- */}
          {_Tournament}
          {/* --------------------------------------------- */}

          <Header {..._ComputeHeaderProps()} />

          <Form.Row>
            <Col as={ItemWrapper}>{_Team}</Col>
          </Form.Row>
          <Form.Row>
            <Col as={ItemWrapper}>{_Sportsman}</Col>
          </Form.Row>

          <ItemWrapper />

          <Form.Row>
            <Col as={ItemWrapper}>{_RankFrom}</Col>
            <Col as={ItemWrapper}>{_RankTo}</Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportmanRankForm
