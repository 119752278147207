import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class TeamDatasourceIdentifierRecord<TJsonData extends TeamDatasourceIdentifierRecord.CtorJsonData = TeamDatasourceIdentifierRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  team_id?: string
  identifier?: string
  identifier_kind?: string
  is_enabled?: boolean | null
  admin_note?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:TeamDatasourceIdentifierRecord.CtorJsonData) {
    return new TeamDatasourceIdentifierRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db);

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.team_id = jsonData?.team_id
    this.identifier = jsonData?.identifier
    this.identifier_kind = jsonData?.identifier_kind
    this.is_enabled = jsonData?.is_enabled
    this.admin_note = jsonData?.admin_note
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      team_id: this.team_id,
      identifier: this.identifier,
      identifier_kind: this.identifier_kind,
      is_enabled: this.is_enabled,
      admin_note: this.admin_note
    }) as TJsonData
  }
}

export module TeamDatasourceIdentifierRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    team_id?: string
    identifier?: string
    identifier_kind?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
  }

  export class Repository extends RecordRepository<TeamDatasourceIdentifierRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by team_id >>>
    #getByTeamId:RecordRepository.NestingGetFieldFlags = {}

    getByTeamId(teamId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getByTeamId, teamId, options, {
        peek: () => this.peekByTeamId(teamId),
        load: () => this.loadByTeamId(teamId)
      })
    }

    peekByTeamId(teamId:string) {
      return this.peekAll(x => x?.team_id == teamId)
    }

    protected loadByTeamId(teamId:string) {
      return this.getApi().getMoreTeamDatasourceIdentifiersByTeamId(teamId)
    }

    /// <<< by team_id <<<

    create(record: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneTeamDatasourceIdentifier(record)
    }

    protected loadAll() {
      return this.getApi().getAllTeamDatasourceIdentifiers()
    }

    protected loadById(id: string): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      return  this.getApi().getOneTeamDatasourceIdentifierById(id)
    }
    protected updateById(id: string, patch: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneTeamDatasourceIdentifier(id, patch)
    }
    protected deleteById(id: string): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneTeamDatasourceIdentifier(id)
    }
    protected approveById(id: string, patch: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<TeamDatasourceIdentifierRecord<CtorJsonData>>): Promise<TeamDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
