import { AppContext } from 'App'
import React, { useContext, useEffect, useState } from 'react'
import { SportsmanContext } from 'components/Sportsmen/__Sportsmen.context'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Data as DataHelper } from 'helpers/data'
import { useAsyncEffect } from 'use-async-effect'
import { Text } from 'static/text'
import Warning from 'components/utils/Warning'
import Loading from 'components/utils/Loading'
import { ItemWrapper } from 'styles/common'
import SportsmanGamingGearsForm from './Form'
import { SportsmanGamingGearsRecord } from 'data/SportsmanGamingGearsRecord'

const Detail: React.FC<{ isNew?: boolean }> = (p) => {
  const { db: a_db, alert: a_alert, locked: a_locked } = useContext(AppContext)

  const { params: stm_params } = useContext(SportsmanContext)

  // --------------------- Load Data --------------------- //
  const [content, setContent] = useState<{
    data?: SportsmanGamingGearsRecord
    load: {
      isInProgress?: boolean
      isNewRecord?: boolean
      isError?: boolean
    }
  }>()

  useAsyncEffect(async () => {
    if (!stm_params?.sportsman_id) return

    setContent((prev) => ({ ...prev, load: { isInProgress: true } }))

    try{
      const _data = await DataHelper.getSportsmanGamingGearsBySportsmanId(
        a_db!,
        stm_params?.sportsman_id,
        {
          alert: a_alert,
        }
      )

      setContent((prev) => ({
        ...prev,
        data: _data,
        load: { isInProgress: false, isNewRecord: !_data },
      }))
    } catch {
      setContent((prev) => ({
        ...prev,
        load: { isInProgress: false, isError: true }
      }))
    }
  }, [stm_params])

  // ---------------------- Handlers --------------------- //
  const handleLockedChange = (isLocked: boolean) => {
    a_locked?.set(isLocked)
  }

  if (content?.load.isError) return <Warning text={Text.NoServerData} />

  if (content?.load.isInProgress) return <Loading />

  return(
    <>
      <ItemWrapper>
        <SportsmanGamingGearsForm
          data={content?.data}
          sportsmanId={stm_params?.sportsman_id}
          state={content?.load.isNewRecord ? 'new' : 'existing'}
          locked={{
            value: a_locked?.value ?? false,
            callback: handleLockedChange,
          }}
        />
      </ItemWrapper>
    </>
  )
}

export default Detail
