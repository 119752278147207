import React, { ComponentProps, useContext, useState } from 'react'
import { useAsyncEffect } from 'use-async-effect'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import FormDropdown from 'components/utils/FormDropdown'
import FormItem from 'components/utils/FormItem'
import FormCheck from 'components/utils/FormCheck'
import LinkToButton from 'components/utils/Button/LinkToButton'
import Header from 'components/utils/Form/Header'
import Footer from 'components/utils/Form/Footer'
import Image from 'components/utils/Image/Image'
import ImageGalleryPickerParent from 'components/utils/ImageGalleryPicker/ImageGalleryPickerParent'
// Styles
import { ItemWrapper, OffsetedContainer } from 'styles/common'
// Additional
import { TeamRecord } from 'data/TeamRecord'
import { AppContext } from 'App'
import { TeamContext } from 'components/Team/__Team.context'
import { FormContext } from 'components/utils/Form.context'
import { Item } from 'components/utils/Dropdown'
import { Form as FormHelper } from 'helpers/form'
import { Data as DataHelper } from 'helpers/data'
import { Routing as RoutingHelper } from 'helpers/routing'
import { SportContext } from 'components/Sports/__Sports.context'
import { SportRecord } from 'data/SportRecord'
import { TeamOrganizationRecord } from 'data/TeamOrganizationRecord'
import { fromJS } from 'immutable'
import { TeamOrganizationContext } from 'components/TeamOrganizations/__TeamOrganizations.context'

const SubmitKeys = [
  'team_organization_id',
  'full_name',
  'short_name',
  'binding_id',
  'sport_id',
  'logo_url',
  'photo_url',
  'country',
  'sponzored_by_sazka',
  'is_clickable',
] as Array<keyof TeamRecord>

type ValidationKeys = Record<
  keyof Pick<
    TeamRecord,
    'full_name' | 'short_name' | 'binding_id' | 'sport_id'
  >,
  any
>

const TeamForm: React.FC<FormHelper.Props<TeamRecord>> = (p) => {
  const { db, alert, history, modal } = useContext(AppContext)
  const { params: t_params } = useContext(TeamContext)
  const { params: s_params } = useContext(SportContext)
  const { params: o_params } = useContext(TeamOrganizationContext)

  const [form, setForm] = useState<{
    data?: TeamRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Team',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...p.data,

      sport_id: p.data?.sport_id,
      sport_text: p.data?.sport?.full_name,

      team_organization_id: p.data?.teamOrganizations?.id,
      team_organization_text: p.data?.teamOrganizations?.name,
    },

    onSubmit: async (values) => {
      if (!db?.teams) return

      try {
        const data = await FormHelper.submitChanges<TeamRecord>({
          action: form.state === 'new' ? 'create' : 'update',
          keys: SubmitKeys,
          data: Object.assign(new TeamRecord(), values ?? {}),
          repository: db.teams,
          optional: opt,
        })

        if (data?.id) {
          setForm({ data: data, state: 'existing' })

          history?.replace(
            RoutingHelper.getTeamDetailUrl({
              sport: s_params?.sport_id ?? RoutingHelper.NewRecordSymbol,
              team: data.id,
            })
          )
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },

    validationSchema: Yup.object<ValidationKeys>({
      full_name: Yup.string()
        .nullable()
        .required('Full name is a required field'),
      short_name: Yup.string()
        .nullable()
        .required('Short name is a required field'),
      binding_id: Yup.string().required('Binding-ID is a required field'),
      sport_id: Yup.string().required('Sport is a required field'),
    }),
  })

  const approve = async () => {
    if (!db?.teams) return

    await FormHelper.approve({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const unapprove = async () => {
    if (!db?.teams) return

    await FormHelper.unapprove({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const freeze = async () => {
    if (!db?.teams) return

    await FormHelper.freeze({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const unfreeze = async () => {
    if (!db?.teams) return

    await FormHelper.unfreeze({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const delete_ = async () => {
    if (!db?.teams) return

    await FormHelper.delete_({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const touch = async () => {
    if (!db?.teams) return

    await FormHelper.touch({
      data: Object.assign(new TeamRecord(), formik.values ?? {}),
      repository: db.teams,
      optional: opt,
    })
  }

  const [sports, setSports] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.sport_id ?? '',
      text: formik.values.sport_text,
    },
    loaded: false,
  })

  const getSportItem = (record: SportRecord): Item => ({
    id: record.id ?? '',
    text: record.full_name,
  })

  const _setSports = async () => {
    const _data = (await DataHelper.getAllSports(db!))?.map((s) =>
      getSportItem(s)
    )

    setSports((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data,
    }))

    return _data
  }

  const _setSport = (item: Item) => {
    formik.setFieldValue('sport_id' as keyof TeamRecord, item.id)

    setSports((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleSportChange = (item: Item) => {
    _setSport(item)
  }

  const [teamOrganizations, setTeamOrganizations] = useState<{
    value?: Item
    data?: Array<Item>
    loaded: boolean
  }>({
    value: {
      id: formik.values.team_organization_id ?? '',
      text: `${formik.values.team_organization_id} - ${formik.values.team_organization_text}`,
    },
    loaded: false
  })

  const getTeamOrganizationItem = (record: TeamOrganizationRecord): Item => ({
    id: record.id ?? '',
    text: `${record.id} - ${record.name}`,
  })

  const _setTeamOrganizations = async () => {
    const _data = (await DataHelper.getAllTeamOrganizations(db!))?.map((s) =>
      getTeamOrganizationItem(s)
    )

    setTeamOrganizations((prev) => ({
      loaded: true,
      value: prev?.value,
      data: _data
    }))

    return _data
  }

  const _setTeamOrganization = (item: Item) => {
    formik.setFieldValue('team_organization_id' as keyof TeamRecord, item.id)

    setTeamOrganizations((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  const handleTeamOrganizationChange = (item: Item) => {
    _setTeamOrganization(item)
  }


  useAsyncEffect(async () => {
    const _sports = await _setSports()

    if (form.state === 'new' && t_params?.sport_id) {
      const _findItem = _sports?.find((d) => d.id === t_params?.sport_id)

      _findItem && _setSport(_findItem)
    }

    const _teamOrganizations = await _setTeamOrganizations()

    if (form.state === 'new' && o_params?.team_organization_id) {
      const _findItem = _teamOrganizations?.find((d) => d.id === o_params?.team_organization_id)

      _findItem && _setTeamOrganization(_findItem)
    }
  }, [])

  //#region Form Components
  const _Sport = (
    <FormItem
      label={<Form.Label>Sport</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'sport_id' as keyof TeamRecord}
            items={sports.data ?? []}
            onSelect={handleSportChange}
            value={sports.value}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.sport_id}
            disabled={p.locked?.value}
            loading={!sports.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.sport_id &&
                  RoutingHelper.getSportDetailUrl({
                    sport: formik.values.sport_id,
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.sport_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _FullName = (
    <FormItem
      label={<Form.Label>Full Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'full_name' as keyof TeamRecord}
            value={formik.values.full_name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.full_name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.full_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _ShortName = (
    <FormItem
      label={<Form.Label>Short Name</Form.Label>}
      input={
        <>
          <Form.Control
            name={'short_name' as keyof TeamRecord}
            value={formik.values.short_name ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.short_name}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.short_name}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _BindingId = (
    <FormItem
      label={<Form.Label>Binding-ID</Form.Label>}
      input={
        <>
          <Form.Control
            name={'binding_id' as keyof TeamRecord}
            value={formik.values.binding_id ?? ''}
            onChange={formik.handleChange}
            required
            isInvalid={formik.submitCount > 0 && !!formik.errors.binding_id}
            readOnly={p.locked?.value}
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.binding_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _OrganizationId = (
    <FormItem
      label={<Form.Label>Organization Id</Form.Label>}
      input={
        <>
          <FormDropdown
            name={'team_organization_id' as keyof TeamRecord}
            items={teamOrganizations.data ?? []}
            onSelect={handleTeamOrganizationChange}
            value={teamOrganizations.value}
            isInvalid={formik.submitCount > 0 && !!formik.errors.team_organization_id}
            disabled={p.locked?.value}
            loading={!teamOrganizations.loaded}
            append={
              <LinkToButton
                path={
                  formik.values.team_organization_id &&
                  RoutingHelper.getTeamOrganizationDetailUrl({
                    teamOrganization: formik.values.team_organization_id
                  })
                }
              />
            }
          />
          <Form.Control.Feedback type={'invalid'}>
            {formik.errors.team_organization_id}
          </Form.Control.Feedback>
        </>
      }
    />
  )

  const _Country = (
    <FormItem
      label={<Form.Label>Country</Form.Label>}
      input={
        <Form.Control
          name={'country' as keyof TeamRecord}
          value={formik.values.country ?? ''}
          onChange={formik.handleChange}
          readOnly={p.locked?.value}
        />
      }
    />
  )

  const _SponzoredBySazka = (
    <FormCheck
      name={'sponzored_by_sazka' as keyof TeamRecord}
      value={formik.values.sponzored_by_sazka ?? false}
      checked={formik.values.sponzored_by_sazka ?? false}
      offset={{ direction: 'left' }}
      title={'Sponzored by Sazka'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue('sponzored_by_sazka' as keyof TeamRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('sponzored_by_sazka' as keyof TeamRecord, false)
      }}
      disabled={p.locked?.value}
    />
  )

  const _IsClickable = (
    <FormCheck
      name={'is_clickable' as keyof TeamRecord}
      value={formik.values.is_clickable ?? false}
      checked={formik.values.is_clickable ?? false}
      offset={{ direction: 'left' }}
      title={'Is Clickable'}
      scheme={'green'}
      checkedCallback={() => {
        formik.setFieldValue('is_clickable' as keyof TeamRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('is_clickable' as keyof TeamRecord, false)
      }}
      disabled={p.locked?.value}
    />
  )

  const _Logo = (
    <Image
      inputName={'logo_url' as keyof TeamRecord}
      imageUrl={formik.values.logo_url ?? ''}
      isLocked={p.locked?.value}
      labelName={'Logo'}
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'teams'}
                actualImageString={formik.values.logo_url ?? ''}
                // useMultipleSelect={{
                //   divider: '|',
                // }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'logo_url' as keyof TeamRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('logo_url' as keyof TeamRecord, val)
      }
    />
  )

  const _Photo = (
    <Image
      inputName={'photo_url' as keyof TeamRecord}
      imageUrl={formik.values.photo_url ?? ''}
      isLocked={p.locked?.value}
      labelName={'Photo'}
      onImageClickCallback={() => {
        modal?.open?.({
          content: (
            <>
              <ImageGalleryPickerParent
                actualDir={'teams'}
                actualImageString={formik.values.photo_url ?? ''}
                // useMultipleSelect={{
                //   divider: '|',
                // }}
                onSubmitCallback={(actualImageString: string) => {
                  formik.setFieldValue(
                    'photo_url' as keyof TeamRecord,
                    actualImageString
                  )

                  modal?.close?.()
                }}
              />
            </>
          ),
        })
      }}
      onUpdateClickCallback={(val?: string) =>
        formik.setFieldValue('photo_url' as keyof TeamRecord, val)
      }
    />
  )

  const _PopularIcon = (
    <FormCheck
      name={'is_popular' as keyof TeamRecord}
      value={formik.values.is_popular ?? false}
      checked={formik.values.is_popular ?? false}
      offset={{ direction: 'left' }}
      title={'Popular'}
      scheme={'yellow'}
      checkedCallback={() => {
        formik.setFieldValue('is_popular' as keyof TeamRecord, true)
      }}
      uncheckedCallback={() => {
        formik.setFieldValue('popularity_order' as keyof TeamRecord, '')
        formik.setFieldValue('is_popular' as keyof TeamRecord, false)
      }}
      disabled={p.locked?.value}
    />
  )

  const _PopularOrder = (
    <FormItem
      label={<Form.Label>Popularity order</Form.Label>}
      input={
        <Form.Control
          name={'popularity_order' as keyof TeamRecord}
          value={formik.values.popularity_order ?? ''}
          onChange={formik.handleChange}
          readOnly={!_PopularIcon.props.checked}
        />
      }
    />
  )

  //@endregion

  const _ComputeHeaderProps = (): ComponentProps<typeof Header> => {
    if (form.state === 'new') return {}

    return {
      useApprove: {
        defaultValue: !!p.data?.data_approved_at,
      },
      useFreeze: {
        defaultValue: !!p.data?.data_frozen_at,
      },
      useTouch: true,
      locked: p.locked?.value,
    }
  }

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, useDelete: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
          delete: delete_,
          approve: approve,
          unapprove: unapprove,
          freeze: freeze,
          unfreeze: unfreeze,
          touch: touch,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Header {..._ComputeHeaderProps()} />
          <Form.Row>
            <Col lg={5}>
              <Form.Row>{_Logo}</Form.Row>
              <ItemWrapper />
              <Form.Row>{_Photo}</Form.Row>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <Form.Row>
                <Col as={ItemWrapper}>{_PopularIcon}</Col>
                <Col>{_PopularOrder}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_FullName}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_ShortName}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Country}</Col>
              </Form.Row>
              <Form.Row>
                <ItemWrapper />
                <Col>{_SponzoredBySazka}</Col>
                <Col>{_IsClickable}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_Sport}</Col>
              </Form.Row>

              <ItemWrapper />

              <Form.Row>
                <Col as={ItemWrapper}>{_BindingId}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_OrganizationId}</Col>
              </Form.Row>
            </Col>
          </Form.Row>

          <Footer {..._ComputeFooterProps()} />
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default TeamForm
