import { AppContext } from "App"
import { TeamContext } from "components/Team/__Team.context"
import TableLoader from "components/utils/Table/Table"
import { TeamDatasourceIdentifierRecord } from "data/TeamDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const TeamDatasourceIdentifiersTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: t_params } = useContext(TeamContext)

  const loadData = async () => {
    if(t_params?.team_id)
    return await Data.getTeamDatasourceIdentifiersByTeamId(
      a_db!,
      t_params?.team_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<TeamDatasourceIdentifierRecord>
      reloadFrom={[Tab.TeamDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        team: t_params?.team_id,
        sport: t_params?.sport_id
      }
      )}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TeamDatasourceIdentifiersTable
