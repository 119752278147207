import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// Components
import { Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
// Styles
import { ItemWrapper } from 'styles/common'
import { Center } from 'styles/common'
import { Divider } from 'styles/common'
import { FillAvailableWidth } from 'styles/common'
// Additional
import { AppContext } from 'App'

const ResetPassword: React.FC = (p) => {
  const [showIncrLogin, setShowIncrLogin] = useState<{
    incrLogin: boolean
    info?: string
  }>({ incrLogin: false })
  const [isLinkSent, setIsLinkSent] = useState<boolean>(false)
  const [isRedirect, setIsRedirect] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const { alert, db } = useContext(AppContext)

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },

    onSubmit: async (values) => {
      setIsSubmitted(true)

      try {
        const reset = await db?.api.changePassword(
          values.oldPassword,
          values.newPassword
        )

        if (!reset) return

        if (reset.ok) {
          alert?.show('Password was successfully changed', {
            type: 'success',
          })

          setIsLinkSent((prev) => !prev)
          
          // setTimeout(() => {
          //   setIsRedirect(true)
          // }, 5000)

          setIsRedirect(true)
        } else {
          alert?.show('Password was not successfully changed.', {
            //@ts-ignore
            type: 'danger',
          })

          setShowIncrLogin({
            incrLogin: true,
            info: reset.error.message,
          })

          setIsSubmitted(false)
        }
      } catch (err) {
        alert?.show(
          'Something went wrong. Password was not successfully changed.',
          {
            //@ts-ignore
            type: 'danger',
          }
        )

        setIsSubmitted(false)
      }
    },

    //@ts-ignore
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old password is a required'),
      newPassword: Yup.string()
        .oneOf([Yup.ref('confirmPassword')], 'Passwords are not same')
        .required('New password is a required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords are not same')
        .required('Confirm password is a required'),
    }),
  })

  return (
    <Center as={Container} style={{ height: '100vh' }}>
      <Col xs={12} sm={10} md={8} lg={6} xl={4}>
        {isLinkSent && (
          <>
            {/* <ItemWrapper>
              <Center as={'p'}>The password has been successfully changed</Center>
            </ItemWrapper> */}
            {isRedirect && <Redirect to='/' />}
          </>
        )}
        {!isLinkSent && (
          <>
            <ItemWrapper>
              <Center as={'h2'}>Change password</Center>
            </ItemWrapper>
            <Alert
              variant={'danger'}
              dismissible
              transition
              onClose={() => {
                setShowIncrLogin({ incrLogin: false })
              }}
              show={showIncrLogin.incrLogin}
            >
              {showIncrLogin.info}
            </Alert>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Row as={ItemWrapper}>
                <FormItem
                  label={<Form.Label>Old password</Form.Label>}
                  input={
                    <>
                      <Form.Control
                        name={'oldPassword'}
                        type={'password'}
                        value={formik.values.oldPassword ?? ''}
                        required
                        isInvalid={
                          formik.submitCount > 0 && !!formik.errors.oldPassword
                        }
                        onChange={formik.handleChange}

                        // autoComplete={'username'}
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {formik.errors.oldPassword}
                      </Form.Control.Feedback>
                    </>
                  }
                />
              </Form.Row>

              <Form.Row as={ItemWrapper}>
                <FormItem
                  label={<Form.Label>New password</Form.Label>}
                  input={
                    <>
                      <Form.Control
                        name={'newPassword'}
                        type={'password'}
                        value={formik.values.newPassword ?? ''}
                        required
                        disabled={formik.values.oldPassword.length === 0}
                        isInvalid={
                          formik.submitCount > 0 && !!formik.errors.newPassword
                        }
                        onChange={formik.handleChange}

                        // autoComplete={'username'}
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {formik.errors.newPassword}
                      </Form.Control.Feedback>
                    </>
                  }
                />
              </Form.Row>

              <Form.Row as={ItemWrapper}>
                <FormItem
                  label={<Form.Label>Confirm password</Form.Label>}
                  input={
                    <>
                      <Form.Control
                        name={'confirmPassword'}
                        type={'password'}
                        value={formik.values.confirmPassword ?? ''}
                        required
                        disabled={
                          formik.values.oldPassword.length === 0 ||
                          formik.values.newPassword.length === 0
                        }
                        isInvalid={
                          formik.submitCount > 0 &&
                          !!formik.errors.confirmPassword
                        }
                        onChange={formik.handleChange}

                        // autoComplete={'username'}
                      />
                      <Form.Control.Feedback type={'invalid'}>
                        {formik.errors.confirmPassword}
                      </Form.Control.Feedback>
                    </>
                  }
                />
              </Form.Row>
              <Divider as={ItemWrapper} />

              <Form.Row as={ItemWrapper}>
                <FillAvailableWidth
                  as={Button}
                  variant={'outline-secondary'}
                  type={'submit'}
                  disabled={isSubmitted}
                >
                  Change
                </FillAvailableWidth>
              </Form.Row>
            </Form>
          </>
        )}
      </Col>
    </Center>
  )
}

export default ResetPassword
