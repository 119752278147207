import React, { useState, useEffect, useContext, useRef } from 'react'
// Components
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Item } from 'components/utils/Dropdown'
import FormItem from 'components/utils/FormItem'
import DropdownFilter from 'components/utils/Filter/DropdownFilter'
// Styles
import { ItemWrapper } from 'styles/common'
// Addtitional
import { Loadable } from 'types/loadable'
import { AppContext } from 'App'
import { Tab } from 'static/tabs'
import { Data } from 'helpers/data'
import { TournamentRecord } from 'data/TournamentRecord'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const { db } = useContext(AppContext)

  const sportDefaultItem: Item = { id: -1, text: 'Select Sport ...' }

  const [sports, setSports] = useState<
    { value: Item; data?: Array<Item> } & Loadable
  >({
    value: sportDefaultItem,
    loaded: false,
  })

  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )

    _setSports()
  }, [])


  const sportFilterRef = useRef<React.ElementRef<typeof DropdownFilter>>(null)

  const _setSports = async () => {
    const _data = await Data.getAllSports(db!)

    setSports((prev) => ({
      loaded: true,
      value: prev.value,
      data: _data?.map((s) => {
        return { id: s.id ?? '', text: s.short_name } as Item
      }),
    }))
  }

  const handleSportChange = (item: Item) => {
    setSports((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))

  }

  return (
    <Form.Row>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Sport</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Tournaments}
              ref={sportFilterRef}
              accessor={'sport_id' as keyof TournamentRecord}
              items={sports.data ?? []}
              onSelect={handleSportChange}
              value={sports.value}
              defaultItem={sportDefaultItem}
              loading={!sports.loaded || !table.loaded}
            />
          }
        />
      </Col>
    </Form.Row>
  )
}

export default Filters
