import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
// Styles
import { LockIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { MatchMapRecord } from 'data/MatchMapRecord'
import { RecordSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (id?: {
    tournament?: string
    match?: string
  }): RecordSetting<MatchMapRecord> => {
    return [
      {
        accessor: 'id',
        className: 'table-hidden',
      },
      {
        accessor: 'isFrozen',
        className: 'table-lock',
        Header: function HeaderContent({ row }: any) {
          return <LockIcon />
        },
        Cell: function CellContent({ row }: any) {
          return <>{row.values.isFrozen && <LockIcon />}</>
        },
      },
      {
        accessor: 'game_map_name',
        Header: 'Game Map',
        className: 'table-game-map',
      },
      {
        accessor: 'team1_rounds_won_sum_table',
        Header: 'Team 1 Won Sum',
        className: 'table-team-1-won-sum',
      },
      {
        accessor: 'team2_rounds_won_sum_table',
        Header: 'Team 2 Won Sum',
        className: 'table-team-2-won-sum',
      },
      {
        accessor: 'order_number',
        Header: 'Order',
        className: 'table-order',
      },
      {
        id: 'ctaButton',
        sticky: 'right',
        className: 'table-cta-button',
        Header: function HeaderContent({ row }: any) {
          const { toggle } = useContext(AccordionContext)

          return (
            <CTAButton onClick={toggle}>
              <EllipsisHIcon />
            </CTAButton>
          )
        },
        Cell: function CellContent({ row }: any) {
          return (
            <CTAButton
              path={Routing.getMatchMapDetailUrl({
                tournament: id?.tournament ?? Routing.NewRecordSymbol,
                match: id?.match ?? Routing.NewRecordSymbol,
                matchMap: row.values.id,
              })}
            >
              {row.values.isFrozen ? 'View' : 'Edit'}
            </CTAButton>
          )
        },
      },
    ]
  }
}
