import React, { ComponentProps, useState, useContext } from 'react'
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { AppContext } from 'App'
import { Col, Form } from 'react-bootstrap'
import FormItem from 'components/utils/FormItem'
import { ItemWrapper, OffsetedContainer } from 'styles/common'
import { FormContext } from 'components/utils/Form.context'
import { useFormik } from 'formik'
import { SportsmanGamingGearsRecord } from 'data/SportsmanGamingGearsRecord'
import Footer from 'components/utils/Form/Footer'

const SubmitKeys = [
  'processor_id',
  'graphics_card_id',
  'memory_id',
  'hard_drive_id',
  'case_id',
  'monitor_id',
  'chair_id',
  'headset_id',
  'keyboard_id',
  'mouse_id',
  'mousepad_id',
  'sportsman_id',
] as Array<keyof SportsmanGamingGearsRecord>

const SportsmanGamingGearsForm: React.FC<
  FormHelper.Props<SportsmanGamingGearsRecord> & { sportsmanId?: string }
> = (p) => {
  const { db, alert, history } = useContext(AppContext)

  const [form, setForm] = useState<{
    data?: SportsmanGamingGearsRecord
    state: FormHelper.FormState
  }>({ data: p.data, state: p.state ?? 'new' })

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: 'Sportsman Gaming Gears',
      },
    },
    history: history,
    lock: {
      callback: p.locked?.callback,
    },
  }

  const formik = useFormik({
    initialValues: {
      ...form.data,

      sportsman_id: p.sportsmanId,
    },

    onSubmit: async (values) => {
      if (!db?.sportsmanGamingGears) return

      try {
        values.id = form?.data?.id // TMP the form somehow loses its id

        const data = await FormHelper.submitChanges<SportsmanGamingGearsRecord>(
          {
            action: form.state === 'new' ? 'create' : 'update',
            keys: SubmitKeys,
            data: Object.assign(new SportsmanGamingGearsRecord(), values ?? {}),
            repository: db.sportsmanGamingGears,
            optional: opt,
          }
        )

        if (data?.id) {
          setForm({ data: data, state: 'existing' })
        }

        data && p.submitCallback?.(data)
      } catch (err) {
        console.error(err)
      }
    },
  })

  //#region Form Components
  const _Processor = (
    <FormItem
      label={<Form.Label>Processor</Form.Label>}
      input={
        <>
          <Form.Control
            name={'processor_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.processor_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _GraphicCard = (
    <FormItem
      label={<Form.Label>Graphic Card</Form.Label>}
      input={
        <>
          <Form.Control
            name={'graphics_card_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.graphics_card_id}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Memory = (
    <FormItem
      label={<Form.Label>Memory</Form.Label>}
      input={
        <>
          <Form.Control
            name={'memory_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.memory_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _HardDrive = (
    <FormItem
      label={<Form.Label>Hard drive</Form.Label>}
      input={
        <>
          <Form.Control
            name={'hard_drive_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.hard_drive_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Case = (
    <FormItem
      label={<Form.Label>Case</Form.Label>}
      input={
        <>
          <Form.Control
            name={'case_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.case_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Monitor = (
    <FormItem
      label={<Form.Label>Monitor</Form.Label>}
      input={
        <>
          <Form.Control
            name={'monitor_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.monitor_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Chair = (
    <FormItem
      label={<Form.Label>Chair</Form.Label>}
      input={
        <>
          <Form.Control
            name={'chair_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.chair_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Headset = (
    <FormItem
      label={<Form.Label>Headset</Form.Label>}
      input={
        <>
          <Form.Control
            name={'headset_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.headset_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Keyboard = (
    <FormItem
      label={<Form.Label>Keyboard</Form.Label>}
      input={
        <>
          <Form.Control
            name={'keyboard_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.keyboard_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Mouse = (
    <FormItem
      label={<Form.Label>Mouse</Form.Label>}
      input={
        <>
          <Form.Control
            name={'mouse_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.mouse_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )

  const _Mousepad = (
    <FormItem
      label={<Form.Label>Mousepad</Form.Label>}
      input={
        <>
          <Form.Control
            name={'mousepad_id' as keyof SportsmanGamingGearsRecord}
            value={formik.values.mousepad_id ?? ''}
            onChange={formik.handleChange}
            readOnly={p.locked?.value}
          />
        </>
      }
    />
  )
  //#endregion

  const _ComputeFooterProps = (): ComponentProps<typeof Footer> => {
    if (form.state === 'new')
      return {
        useSave: {},
      }

    return { useSave: {}, locked: p.locked?.value }
  }

  return (
    <OffsetedContainer>
      <FormContext.Provider
        value={{
          submit: formik.submitForm,
        }}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Footer {..._ComputeFooterProps()} />

          <Form.Row>
            <Col lg={12}>
              <ItemWrapper />
              <Form.Row>
                <Col as={ItemWrapper}>{_Processor}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_GraphicCard}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Memory}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_HardDrive}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Case}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Monitor}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Chair}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Headset}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Keyboard}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Mouse}</Col>
              </Form.Row>
              <Form.Row>
                <Col as={ItemWrapper}>{_Mousepad}</Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Form>
      </FormContext.Provider>
    </OffsetedContainer>
  )
}

export default SportsmanGamingGearsForm
