import { AppContext } from "App"
import { TournamentContext } from "components/Tournament/__Tournament.context"
import TableLoader from "components/utils/Table/Table"
import { TeamDatasourceIdentifierRecord } from "data/TeamDatasourceIdentifierRecord"
import { TournamentDatasourceIdentifierRecord } from "data/TournamentDatasourceIdentifierRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from "./Table.settings"
import * as R from './Table.styles'

const TournamentDatasourceIdentifiersTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)
  const { params: t_params } = useContext(TournamentContext)

  const loadData = async () => {
    if (t_params?.tournament_id)
    return await Data.getTournamentDatasourceIdentifiersByTournamentId(
      a_db!,
      t_params.tournament_id,
      { alert: a_alert }
    )
  }

  return (
    <TableLoader<TournamentDatasourceIdentifierRecord>
      reloadFrom={[Tab.TournamentDatasourceIdentifiers]}
      loadDataFn={loadData}
      settings={Settings({
        tournament: t_params?.tournament_id
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TournamentDatasourceIdentifiersTable
