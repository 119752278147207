import React from "react";
import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router-dom";
import { TeamOrganizationDatasourceIdentifierContext } from "./TeamOrganizationDatasourceIdentifiers.context";
import Detail from "./_Detail";
import Select from "./_Select";

const TeamOrganizationDatasourceIdentifier: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.TeamOrganizationDatasourceIdentifierParams>()

  return (
    <TeamOrganizationDatasourceIdentifierContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </TeamOrganizationDatasourceIdentifierContext.Provider>
  )
}

export default TeamOrganizationDatasourceIdentifier
