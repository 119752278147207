import React, { useState } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
// Additional
import { OnReloadTriggerListener } from 'components/utils/Reload.event'

interface Props {
  readonly recalculateFn: () => Promise<void>
  readonly from: OnReloadTriggerListener.Location
}

const RecalculateButton: React.FC<Props> = (p) => {
  const [recalculating, setRecalculating] = useState<boolean>(false)

  const handleSubmitRecalculate = async (): Promise<void> => {
    try {
      setRecalculating(true)

      await p.recalculateFn()

      OnReloadTriggerListener.dispatch(p.from)
    } catch (err) {
      console.error(`Could not be recalculate, due to: ${err}`)
    } finally {
      setRecalculating(false)
    }
  }

  return (
    <_RecalculateButton
      callbackClick={handleSubmitRecalculate}
      loading={recalculating}
    />
  )
}

interface _Props {
  readonly callbackClick: () => void
  readonly loading: boolean
}

const _RecalculateButton: React.FC<_Props> = (p) => (
  <CTAButton onClick={p.callbackClick} useLoading={p.loading}>
    Recalculate Stats
  </CTAButton>
)

export default RecalculateButton
