import React from 'react'
// Components
import Filters from './Filters'
import Table from './Table/TableWithAdd'
// Additional
import { AppContext } from 'App'

const Select: React.FC = () => {
  return (
    <>
      <Table />
    </>
  )
}

export default Select
