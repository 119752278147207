import { Phase } from "helpers/app";
import { Routing } from "helpers/routing";
import { useParams } from "react-router";
import { GamingGearContext } from "./__GamingGears.context";
import React from 'react'
// Component
import Select from './_Select'
import Detail from './_Detail'

const GamingGear: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.GamingGearParams>()

  return (
    <GamingGearContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </GamingGearContext.Provider>
  )
}

export default GamingGear
