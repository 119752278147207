import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { SportsmanStatSummaryContext } from 'components/SportsmanStatSummary/__SportsmanStatSummaries.context'

const SportsmanStatSummary: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.SportsmanStatSummaryParams>()

  return (
    <SportsmanStatSummaryContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
    </SportsmanStatSummaryContext.Provider>
  )
}

export default SportsmanStatSummary
