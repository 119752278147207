import { AppContext } from "App"
import React, { useContext, useEffect } from "react"
import { Tab } from "static/tabs"
import Filters from "./Filters"
import Table from "./Table/TableWithAdd"

const Select: React.FC = () => {
  const { location: a_location } = useContext(AppContext)

  useEffect(() => {
    a_location?.set(Tab.NearestMatches)
  }, [])

  return (
    <>
      <Filters />
      <Table />
    </>
  )
}

export default Select
