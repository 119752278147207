import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
// Styles
import { Overflow, CustomTabsWrapper } from 'styles/common'
// Components
import { Tabs as BTabs, Tab as BTab } from 'react-bootstrap'
import { Route } from 'react-router-dom'
// Additional
import { String } from 'helpers/string'

const TabsLoaderHelper = {
  stringToUrl: (input: string): string => {
    return String.spaceTo(input.toLowerCase(), '-')
  },
}

interface Content {
  readonly headerNode?: React.ReactNode
  readonly node?: React.ReactNode
}

interface TabMapperNode {
  readonly routes: { [id: string]: Content }
}

export type TabMapper = { [key: string]: TabMapperNode }

interface Props {
  readonly mapper: TabMapper
  readonly defaultTab: string

  readonly history: any /* History<unknown> */
}

const TabsLoader: React.FC<Props> = (p) => {
  const { path, url } = useRouteMatch()

  const { pathname } = useLocation()

  const getTab = (emptyTab: string) => {
    const _urlToFind = `${url}/`

    if (!pathname.includes(_urlToFind)) {
      p.history.replace(TabsLoaderHelper.stringToUrl(`${url}/${emptyTab}`))

      return TabsLoaderHelper.stringToUrl(emptyTab)
    }

    return TabsLoaderHelper.stringToUrl(pathname.replace(_urlToFind, ''))
  }

  const getRoute = (key: string, content: React.ReactNode, route: string) => (
    <Route key={key} path={TabsLoaderHelper.stringToUrl(route)}>
      {content}
    </Route>
  )

  const handleOnSelect = (key: any) => {
    const _key = key as string

    p.history.push(TabsLoaderHelper.stringToUrl(`${url}/${_key}`))
  }

  return (
    <CustomTabsWrapper>
      <BTabs activeKey={getTab(p.defaultTab)} onSelect={handleOnSelect}>
        {Object.entries(p.mapper).map(([k, v], idx) => (
          <BTab
            key={`${k}_${idx}`}
            eventKey={TabsLoaderHelper.stringToUrl(k)}
            title={k}
            mountOnEnter
          >
            <>
              {Object.entries(v.routes).map(([k2, v2], idx2) => (
                <React.Fragment key={`${k2}_${idx2}`}>
                  {v2.headerNode}
                  <Overflow as={'div'} key={`Overflow_${k2}_${idx2}`}>
                    {getRoute(`Route_${k2}_${idx2}`, v2.node, `${path}${k2}`)}
                  </Overflow>
                </React.Fragment>
              ))}
            </>
          </BTab>
        ))}
      </BTabs>
    </CustomTabsWrapper>
  )
}

export default TabsLoader
