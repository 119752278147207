import styled, { css } from 'styled-components'
import { FillAvailableWidthCSS } from 'styles/common'

import 'font-awesome/css/font-awesome.css'
import 'tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css'

export const DateTimePicker = styled.div<{ isColored?: boolean }>`
  &,
  > div[class*='styles_dateTimePicker'] {
    ${FillAvailableWidthCSS}
  }

  input.datetimepicker-input {
    cursor: default;

    ${({ isColored }) => {
      if (isColored) {
        return css`
          background-color: #fcfcd3;
        `
      }
    }}

    &:read-only ~ .input-group-append {
      pointer-events: none;
    }
  }
`