import styled, { css } from 'styled-components'
import { CheckIcon } from 'styles/fontAwesome'

export const PickerContainer = styled.div`
  max-height: 50vh;

  display: grid;

  grid-template-columns: repeat(auto-fill,minmax(160px,1fr));

  width: fill-available;
  height: fill-available;

  align-items: center;

  gap: 10px;

  overflow: auto;
`

export const GalleryPicker = styled.div`
  max-height: 98vh;
  max-width: -webkit-fill-available;

  ${PickerContainer} {
    margin-top: 50px;
  }

  overflow: auto;

  display: flex;
  flex-direction: column;

  gap: 20px;
`

export interface ImageProps {
  readonly src?: string
  readonly selected?: boolean
}

export const PickerImage = styled.img.attrs<ImageProps>(({ src }) => ({
  src: src
}))<ImageProps>`
  cursor: pointer;

  transition: all 350ms ease;

  width: inherit;

  ${({ selected }) => selected && css`
    transform: scale(0.85);
  `}
`

export interface CheckProps {
  readonly selected?: boolean
}

export const Check = styled(CheckIcon)<CheckProps>`
  font-size: 2rem;

  color: gray;

  ${({ selected }) => selected && css`
    color: green;
  `}

  z-index: 9;
`

export const PickerItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  height: inherit;
  width: inherit;

  position: relative;
  ${Check} {
    position: absolute;

    left: 10px;
    top: 10px;
  }
`
