import Cookies from 'universal-cookie'

const cookies = new Cookies()

export module Token {
  const TOKEN_ITEM_NAME = 'token'
  
  export const get = (): string | null => {
    return cookies.get(TOKEN_ITEM_NAME)
  }

  export const set = (
    userToken: string | null
  ) => {
    const _date = new Date()
    _date.setDate(_date.getDate() + 1)

    cookies.set(TOKEN_ITEM_NAME, JSON.stringify(userToken), { path: '/', expires: _date })
  }
}