import React, { useContext } from 'react'
// Components
import Accordion from 'components/utils/Accordion/Accordion'
import Table from './Table'
import TouchButton from 'components/utils/Button/TouchButton'
import Container from 'components/utils/GridContainer/GridContainer'
// Additional
import { Tab } from 'static/tabs'
import { AppContext } from 'App'
import { FormContext } from 'components/utils/Form.context'
import { Form as FormHelper } from 'helpers/form'

const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.TeamStats,
      },
    },
  }

  const touchAll = async () => {
    if (!db?.teamStatSummaries) return

    await FormHelper.touchAll({
      repository: db.teamStatSummaries,
      optional: opt,
    })
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <TouchButton key={'Container.CTA_Touch_All'}>
                Touch All
              </TouchButton>,
            ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )
}

export default TableWithAdd
