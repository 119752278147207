import React from 'react'
// Components
import MatchMaps from 'components/MatchMaps/Table/TableWithAdd'
import MatchMapVetos from 'components/MatchMapVetos/Table/TableWithAdd'
import Players from 'components/Player/Table/TableWithAdd'
import TeamStats from 'components/TeamStatSummary/Table/Table'
import SportsmanStats from 'components/SportsmanStatSummary/Table/Table'
// Additional
import { Tab } from 'static/tabs'
import { TabMapper } from 'components/utils/TabsLoader'
import MatchDatasourceIdentifier from 'components/MatchDatasourceIdentifiers/__MatchDatasourceIdentifier'

export const Mapper: TabMapper = {
  [Tab.MatchMaps]: {
    routes: { [`/${Tab.MatchMaps}`]: { node: <MatchMaps /> } },
  },
  [Tab.MatchMapVetos]: {
    routes: { [`/${Tab.MatchMapVetos}`]: { node: <MatchMapVetos /> } },
  },
  [Tab.Players]: {
    routes: {
      [`/${Tab.Players}`]: { node: <Players /> },
    },
  },
  [Tab.MatchDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.MatchDatasourceIdentifiers}`]: { node: <MatchDatasourceIdentifier phase={'select'} />}
    }
  }
  // [Tab.TeamStats]: {
  //   routes: {
  //     [`/${Tab.TeamStats}`]: {
  //       node: <TeamStats />,
  //     },
  //   },
  // },
  // [Tab.SportsmanStats]: {
  //   routes: {
  //     [`/${Tab.SportsmanStats}`]: {
  //       node: <SportsmanStats />,
  //     },
  //   },
  // },
}
