import React from 'react'
import styled, { css } from 'styled-components'
// Components
import Dropdown, { Props as DProps } from 'components/utils/Dropdown'
import { Form } from 'react-bootstrap'
import { InputGroup } from 'react-bootstrap'
import { DropdownInputGroup as _DropdownInputGroup } from 'styles/common'

interface Props extends DProps {
  name: string
  required?: boolean
  isInvalid?: boolean

  append?: React.ReactNode
}

const FormDropdownV2: React.FC<Props> = (p) => (
  <>
    <Form.Control
      hidden
      readOnly
      name={p.name}
      value={p.value?.id ?? ''}
      isInvalid={p.isInvalid}
      required={p.required}
    />
    <DropdownInputGroup
      isColored={p.required && !p.value?.id}
      isInvalid={p.isInvalid}
      isDisabled={p.disabled}
    >
      {!p.append && <Dropdown {...p} />}
      {p.append && (
        <InputGroup>
          {p.append && (
            <InputGroup.Append style={{ order: 1 }}>
              {p.append}
            </InputGroup.Append>
          )}
          <Dropdown {...p} />
        </InputGroup>
      )}
    </DropdownInputGroup>
  </>
)

const DropdownInputGroup = styled(_DropdownInputGroup)<{
  isDisabled?: boolean
  isColored?: boolean
  isInvalid?: boolean
}>`
  .input-group-append ~ .dropdown {
    width: calc(100% - 42px);
  }

  .input-group-append ~ .dropdown > * {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-append > * {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  &,
  *:not(.dropdown, .dropdown-toggle, .input-group-append) {
    min-width: 100%;
  }

  .dropdown-toggle:not(:disabled),
  .dropdown-toggle:not(:disabled):hover {
    ${({ isColored }) => {
      if (isColored) {
        return css`
          background-color: #fcfcd3 !important;
        `
      }
    }}

    ${({ isInvalid }) => {
      if (isInvalid) {
        return css`
          border-color: #dc3545;
        `
      }
    }}
  }

  ${({ isDisabled }) => {
    if (!isDisabled) return css``

    return css`
      .input-group-append > * {
        border-color: #ced4da !important;
      }
    `
  }}
`

export default FormDropdownV2
