import { AppContext } from "App"
import TableLoader from "components/utils/Table/Table"
import { GamingGearRecord } from "data/GamingGearRecord"
import { Data } from "helpers/data"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import Settings from './Table.settings'
// Styles
import * as R from './Table.styles'

const GamingGearsTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const loadData = async () => {
    return await Data.getAllGamingGears(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<GamingGearRecord>
      reloadFrom={[Tab.GammingGears]}
      loadDataFn={loadData}
      settings={Settings}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default GamingGearsTable
