import React, { useContext } from 'react'
// Components
import CTAButton from 'components/utils/Button/CTAButton'
import UIColumnFilter from 'components/utils/Filter/ColumnFilter'
// Styles
import { LockIcon, EllipsisHIcon } from 'styles/fontAwesome'
// Additional
import { Tab } from 'static/tabs'
import { AccordionContext } from 'components/utils/Accordion/Accordion.context'
import { Routing } from 'helpers/routing'
import { TournamentTeamRankRecord } from 'data/TournamentTeamRankRecord'
import { RecordSetting } from 'components/utils/Table/_Table.settings'

export module Settings {
  export const get = (id?: {
    tournament?: string
  }): RecordSetting<TournamentTeamRankRecord> => {
    return [
      {
        accessor: 'id',
        className: 'table-hidden',
      },
      {
        accessor: 'tournament_id',
        className: 'table-hidden',
        Filter: function FilterContent(p: any) {
          return <UIColumnFilter from={Tab.TeamRanks} filter={p} />
        },
        filter: 'fuzzyText',
      },
      {
        accessor: 'isFrozen',
        className: 'table-lock',
        Header: function HeaderContent({ row }: any) {
          return <LockIcon />
        },
        Cell: function CellContent({ row }: any) {
          return <>{row.values.isFrozen && <LockIcon />}</>
        },
      },
      {
        accessor: 'team_name',
        Header: 'Team',
        className: 'table-team-name',
      },
      {
        accessor: 'rank_from',
        Header: 'Rank From',
        className: 'table-rank-from',
      },
      {
        accessor: 'rank_to',
        Header: 'Rank To',
        className: 'table-rank-to',
      },
      {
        id: 'ctaButton',
        className: 'table-cta-button',
        sticky: 'right',
        Header: function HeaderContent({ row }: any) {
          const { toggle } = useContext(AccordionContext)

          return (
            <CTAButton onClick={toggle}>
              <EllipsisHIcon />
            </CTAButton>
          )
        },
        Cell: function CellContent({ row }: any) {
          return (
            <CTAButton
              path={Routing.getTeamRankDetailUrl({
                tournament: id?.tournament ?? Routing.NewRecordSymbol,
                teamRank: row.values.id,
              })}
            >
              {row.values.isFrozen ? 'View' : 'Edit'}
            </CTAButton>
          )
        },
      },
    ]
  }
}
