import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
// Components
import { Nav } from 'react-bootstrap'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// Styles
import { Breakpoint } from 'styles/responsive'
import {
  SelectedColor,
  FillAvailableWidth,
  FillAvailableHeight,
} from 'styles/common'
// Additional
import { AppContext } from 'App'
import { TabMapper } from 'static/tabMapper'
// Images
import logo from 'assets/logo.png'

const Tabs: React.FC = () => {
  const { activeTab } = useContext(AppContext)

  const getTab = (key: string, text: string, route: string, level?: number) => (
    <Nav.Item key={key} as={NavItem} level={level} active={key === activeTab}>
      <Nav.Link eventKey={key} as={Link} to={route}>
        {text}
      </Nav.Link>
    </Nav.Item>
  )

  const setTabs = (
    mapper: { [id: string]: TabMapper.Node },
    level?: number
  ): JSX.Element => {
    const _level = level ?? 0
    const _levelInc = 1

    return (
      <>
        {Object.entries(mapper).map(([k, v], idx) => (
          <React.Fragment key={`${k}_${idx}`}>
            {!v.disableTabRender &&
              getTab(
                k,
                v.overrideText ?? k,
                (v.routes && Object.keys(v.routes)[0]) ?? '',
                _level
              )}
            {v.nodes && setTabs(v.nodes, _level + _levelInc)}
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <>
      <FillAvailableHeight as={TabsWrapper}>
        <Breakpoint.DesktopTablet>
          <ImageWrapper>
            <Image src={String(logo)} />
          </ImageWrapper>

          <Nav className={'flex-column'}>{setTabs(TabMapper.Settings)}</Nav>
        </Breakpoint.DesktopTablet>
      </FillAvailableHeight>
      <Breakpoint.Mobile>
        <FillAvailableWidth
          as={MobileNavbar}
          collapseOnSelect
          expand={'lg'}
        >
          <FillAvailableWidth
            as={Navbar.Toggle}
            aria-controls={'responsive-navbar-nav'}
          />
          <Navbar.Collapse id={'responsive-navbar-nav'}>
            <Nav>{setTabs(TabMapper.Settings)}</Nav>
          </Navbar.Collapse>
        </FillAvailableWidth>
      </Breakpoint.Mobile>
    </>
  )
}

export default Tabs

const LEVEL_PADDING = 10
const BORDER_PADDING = 6

const DEFAULT_FONT_SETTINGS = css`
  font-size: 1.3em;
  font-weight: 500;
  font-variant: petite-caps;
`

const ACTIVE_FONT_SETTINGS = css`
  font-weight: 600;
`

const BORDER_COLOR = 'gray'

const NavItem = styled.div<{ level?: number; active?: boolean }>`
  ${DEFAULT_FONT_SETTINGS}

  transition: background-color 0.3s, color 0.3s;

  a {
    color: black;

    padding-left: ${LEVEL_PADDING}px !important;
  }

  border-left: ${BORDER_PADDING}px solid transparent;

  ${({ active }) => {
    if (active) {
      return css`
        ${ACTIVE_FONT_SETTINGS}

        background-color: ${SelectedColor};

        border-left: ${BORDER_PADDING}px solid ${BORDER_COLOR};
      `
    }
  }}

  ${({ level }) => {
    return css`
      padding-left: ${(level ?? 0) * LEVEL_PADDING}px !important;
    `
  }}

  :hover {
    background-color: ${SelectedColor};
  }
`

const MobileNavbar = styled(Navbar)`
  padding-left: 0;
  padding-right: 0;
`

const ImageWrapper = styled.div`
  display: flex;

  align-items: center;

  min-height: 110px;
  padding: 10px;
`

const Image = styled.img.attrs({
  alt: 'logo',
})`
  height: 85px;
  mix-blend-mode: multiply;
`

const TabsWrapper = styled.div`
  height: inherit;

  background-color: #e8e8e8bd;
`
