import { AppContext } from "App"
import React, { useContext } from "react"
import { Tab } from "static/tabs"
import { Form as FormHelper } from 'helpers/form'
import { Routing as RoutingHelper } from 'helpers/routing'
import { Reload as ReloadHelper } from 'helpers/reload'
import { Data as DataHelper } from 'helpers/data'
import Accordion from 'components/utils/Accordion/Accordion'
import Container from 'components/utils/GridContainer/GridContainer'
import CTAButton from "components/utils/Button/CTAButton"
import ReloadButton from "components/utils/Button/ReloadButton"
import Table from './Table'
import { FormContext } from "components/utils/Form.context"


const TableWithAdd: React.FC = () => {
  const { db, alert } = useContext(AppContext)

  const opt = {
    alert: {
      manager: alert,
      texts: {
        form: Tab.GammingGears
      },
    },
  }

  const touchAll = async () => {
    if (!db?.gamingGears) return

    await FormHelper.touchAll({
      repository: db.gamingGears,
      optional: opt,
    })
  }

  const reload = async () => {
    if(!db?.gamingGears) return

    return await ReloadHelper.reload({
      getAllFn: () => DataHelper.getAllGamingGears(db),
      optional: opt,
    })
  }

  return (
    <FormContext.Provider value={{ touch: touchAll }}>
      <Accordion
        content={
          <Container
            items={[
              <CTAButton
                key={'Container.CTA_NEW'}
                path={RoutingHelper.getGamingGearDetailUrl({
                  gamingGear: RoutingHelper.NewRecordSymbol,
                })}
              >
                New
              </CTAButton>,
              <ReloadButton
                key={'Container.ReloadButton'}
                reloadFn={reload}
                from={opt.alert.texts.form}
              />,
            ]}
          />
        }
        toggle={<Table />}
      />
    </FormContext.Provider>
  )

}

export default TableWithAdd
