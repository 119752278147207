import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import Settings from './Table.settings'
import { TimespaceContext } from 'components/Timespaces/__Timespaces.context'
import { TeamStatSummaryRecord } from 'data/TeamStatSummaryRecord'
import { AppContext } from 'App'
import { Data } from 'helpers/data'

const TeamStatSummariesTable: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params } = useContext(TimespaceContext)

  const loadData = async () => {
    if (params?.timespace_id)
      return await Data.getTeamStatSummariesByTimespaceId(
        a_db!,
        params?.timespace_id,
        { alert: a_alert }
      )

    return await Data.getAllTeamStatSummaries(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<TeamStatSummaryRecord>
      loadDataFn={loadData}
      settings={Settings}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default TeamStatSummariesTable
