import React, { createContext } from 'react'
import { useParams } from 'react-router'
// Component
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { MatchMapContext } from 'components/MatchMaps/__MatchMaps.context'

const MatchMap: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.MatchMapParams>()

  return (
    <MatchMapContext.Provider value={{ params: params }}>
      {p.phase === 'detail' && <Detail />}
    </MatchMapContext.Provider>
  )
}

export default MatchMap
