import React, { useEffect } from 'react'
// Additional
import { OnFilterChangeListener } from 'components/utils/Filter/_Filter'

// filters are able to clear themselves in useEffect fn
let filters: { [id: string]: string } = {}

const UIColumnFilter: React.FC<{
  from: OnFilterChangeListener.Location
  filter: any
}> = (p) => {
  useEffect(() => {
    OnFilterChangeListener.add(p.from, handleFilterChange as EventListener)

    return () => {
      OnFilterChangeListener.remove(p.from, handleFilterChange as EventListener)

      // clear filters
      filters = {}
    }
  }, [])

  const handleFilterChange = (e: CustomEvent): void => {
    const _data = e.detail as OnFilterChangeListener.FilterEventArgs
    
    if (!_data?.options?.useForceFilter) {
      p.filter.setAllFilters([])

      if (_data.options?.disableConcatAndReset) {
        // clear filters
        filters = {}
      }

      // @ts-ignore
      filters[_data.columnName] = _data.filteredValue
    }

    Object.entries(filters).map(([k, v]) => {
      if (v) {
        p.filter.setFilter(k, v)
      }
    })
  }

  return <></>
}

export default UIColumnFilter
