import React from 'react'
// Components
import Stages from 'components/Stages/Table/TableWithAdd'
import Matches from 'components/Matches/Table/TableWithAdd'
import MatchFilters from 'components/Matches/Filter/FiltersInTournament'
import Rounds from 'components/Rounds/Table/TableWithAdd'
import Teams from 'components/Team/Table/TableWithAdd'
import Sportsmen from 'components/Sportsmen/Table/TableWithAdd'
import TeamRanks from 'components/TeamRanks/Table/TableWithAdd'
import SportsmanRanks from 'components/SportsmanRanks/Table/TableWithAdd'
import TeamStats from 'components/TeamStatSummary/Table/TableWithAdd'
import SportsmanStats from 'components/SportsmanStatSummary/Table/TableWithAdd'
// Additional
import { Tab } from 'static/tabs'
import { TabMapper } from 'components/utils/TabsLoader'
import TournamentDatasourceIdentifier from 'components/TournamentDatasourceIdentifiers/__TournamentDatasourceIdentifiers'

export const Mapper: TabMapper = {
  [Tab.Stages]: {
    routes: {
      [`/${Tab.Stages}`]: {
        node: <Stages />,
      },
    },
  },
  [Tab.Rounds]: {
    routes: {
      [`/${Tab.Rounds}`]: {
        node: <Rounds />,
      },
    },
  },
  [Tab.Matches]: {
    routes: {
      [`/${Tab.Matches}`]: {
        node: <Matches />,
        headerNode: <MatchFilters />,
      },
    },
  },
  [Tab.Teams]: {
    routes: {
      [`/${Tab.Teams}`]: {
        node: <Teams />,
      },
    },
  },
  [Tab.Sportsmen]: {
    routes: {
      [`/${Tab.Sportsmen}`]: {
        node: <Sportsmen />,
      },
    },
  },
  [Tab.TeamRanks]: {
    routes: {
      [`/${Tab.TeamRanks}`]: {
        node: <TeamRanks />,
      },
    },
  },
  [Tab.SportsmanRanks]: {
    routes: {
      [`/${Tab.SportsmanRanks}`]: {
        node: <SportsmanRanks />,
      },
    },
  },
  [Tab.TournamentDatasourceIdentifiers]: {
    routes: {
      [`/${Tab.TournamentDatasourceIdentifiers}`]: {
        node: <TournamentDatasourceIdentifier phase={'select'} />
      }
    }
  },
  // [Tab.TeamStats]: {
  //   routes: {
  //     [`/${Tab.TeamStats}`]: {
  //       node: <TeamStats />,
  //     },
  //   },
  // },
  // [Tab.SportsmanStats]: {
  //   routes: {
  //     [`/${Tab.SportsmanStats}`]: {
  //       node: <SportsmanStats />,
  //     },
  //   },
  // },
}
