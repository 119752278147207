import React from 'react'
import { css, FlattenSimpleInterpolation } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

/* eslint-disable react/prop-types*/

export module Breakpoint {
  //#region CSS
  export const ForDesktop = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 991px) {
      ${content}
    }
  `

  export const ForSmallDesktop = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 991px) and (max-width: 1199px) {
      ${content}
    }
  `

  export const ForBigDesktop = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 1200px) {
      ${content}
    }
  `

  export const ForDesktopTablet = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 768px) {
      ${content}
    }
  `

  export const ForTablet = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 768px) and (max-width: 990px) {
      ${content}
    }
  `

  export const ForTabletMobile = (content: FlattenSimpleInterpolation) => css`
    @media screen and (max-width: 990px) {
      ${content}
    }
  `

  export const ForMobile = (content: FlattenSimpleInterpolation) => css`
    @media screen and (max-width: 767px) {
      ${content}
    }
  `

  export const ForBigMobile = (content: FlattenSimpleInterpolation) => css`
    @media screen and (min-width: 426px) and (max-width: 767px) {
      ${content}
    }
  `

  export const ForSmallMobile = (content: FlattenSimpleInterpolation) => css`
    @media screen and (max-width: 425px) {
      ${content}
    }
  `
  //#endregion 
  //#region Components
  export const Desktop: React.FC = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 991 })

    return (
      <>
        {isDesktop && children}
      </>
    )
  }

  export const SmallDesktop: React.FC = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 991, maxWidth: 1199 })

    return (
      <>
        {isDesktop && children}
      </>
    )
  }

  export const BigDesktop: React.FC = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 })

    return (
      <>
        {isDesktop && children}
      </>
    )
  }

  export const DesktopTablet: React.FC = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })

    return (
      <>
        {isDesktop && children}
      </>
    )
  }

  export const Tablet: React.FC = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 990 })

    return (
      <>
        {isTablet && children}
      </>
    )
  }
  
  export const TabletMobile: React.FC = ({ children }) => {
    const isTabletMobile = useMediaQuery({ maxWidth: 990 })

    return (
      <>
        {isTabletMobile && children}
      </>
    )
  }

  export const Mobile: React.FC = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return (
      <>
        {isMobile && children}
      </>
    )
  }

  export const BigMobile: React.FC = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 426, maxWidth: 767 })

    return (
      <>
        {isMobile && children}
      </>
    )
  }

  export const SmallMobile: React.FC = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 425 })

    return (
      <>
        {isMobile && children}
      </>
    )
  }
  //#endregion
}
