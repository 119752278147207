import { Id } from 'types/id'
import { getUniqueId } from 'utils/common'

export class Semaphore<T extends any> {
  #maxWaitingTimeout: number
  #checkReadyInterval: number

  #queue: Id[] = []

  constructor(maxWaitingTimeout: number, checkReadyInterval: number) {
    this.#maxWaitingTimeout = maxWaitingTimeout
    this.#checkReadyInterval = checkReadyInterval
  }

  async registerAndSolveRequest(request: () => Promise<T | null>): Promise<T | null> {
    const requestId = this.#pushRequestToQueue()

    let result: T | null = null
    let isIntervalCleared = false

    setTimeout(() => {
      isIntervalCleared = true
    }, this.#maxWaitingTimeout)

    while (!isIntervalCleared) {
      await new Promise(resolve => setTimeout(resolve, this.#checkReadyInterval));

      if (requestId === this.#getReadyRequestId()) {
        result = await request()

        this.#shiftRequestFromQueue()

        isIntervalCleared = true
      }
    }

    return result
  }

  #pushRequestToQueue() {
    const uid = getUniqueId()

    this.#queue.push(uid)

    return uid
  }

  #shiftRequestFromQueue() {
    this.#queue.length > 0 && this.#queue.shift()
  }

  #getReadyRequestId() {
    return this.#queue.length > 0 && this.#queue[0]
  }
}