import { type } from "os";
import { BaseRecord } from "./BaseRecord";
import { BaseRootRepository } from "./repository/BaseRootRepository";
import { RecordRepository } from "./repository/RecordRepository";

export class SportsmanDatasourceIdentifierRecord<TJsonData extends SportsmanDatasourceIdentifierRecord.CtorJsonData = SportsmanDatasourceIdentifierRecord.CtorJsonData>
extends BaseRecord<TJsonData> {
  sportsmen_id?: string
  identifier?: string
  identifier_kind?: string
  is_enabled?: boolean | null
  admin_note?: string

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanDatasourceIdentifierRecord.CtorJsonData) {
    return new SportsmanDatasourceIdentifierRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if(jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData: TJsonData) {
    super.patchData(jsonData)

    this.sportsmen_id = jsonData?.sportsmen_id
    this.identifier = jsonData?.identifier
    this.identifier_kind = jsonData?.identifier_kind
    this.is_enabled = jsonData?.is_enabled
    this.admin_note = jsonData?.admin_note
  }

  toJson():TJsonData {
    return Object.assign(super.toJson(), {
      sportsmen_id: this.sportsmen_id,
      identifier: this.identifier,
      identifier_kind: this.identifier_kind,
      is_enabled: this.is_enabled,
      admin_note: this.admin_note
    }) as TJsonData
  }
}

export module SportsmanDatasourceIdentifierRecord {
  export type CtorJsonData = BaseRecord.CtorJsonData & {
    sportsmen_id?: string
    identifier?: string
    identifier_kind?: string
    is_enabled?: boolean | undefined
    priority?: number
    admin_note?: string
  }

  export class Repository extends RecordRepository<SportsmanDatasourceIdentifierRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    /// >>> by sportsman_id >>>
    #getBySportsmanId:RecordRepository.NestingGetFieldFlags = {}

    getBySportsmanId(sportsmanId:string, options?:RecordRepository.GetOptions) {
      return this.nestedPeekOrLoad(this.#getBySportsmanId, sportsmanId, options, {
        peek: () => this.peekBySportsmanId(sportsmanId),
        load: () => this.loadBySportsmanId(sportsmanId)
      })
    }

    peekBySportsmanId(sportsmanId:string) {
      return this.peekAll(x => x?.sportsmen_id == sportsmanId)
    }

    protected loadBySportsmanId(sportsmanId:string) {
      return this.getApi().getMoreSportsmanDatasourceIdentifiersBySportsmanId(sportsmanId)
    }
    /// <<< by sportsman_id <<<

    create(record: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.createOneSportsmanDatasourceIdentifier(record)
    }

    protected loadAll() {
      return this.getApi().getAllSportsmanDatasourceIdentifiers()
    }

    protected loadById(id: string): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      return this.getApi().getOneSportsmanDatasourceIdentifierById(id)
    }
    protected updateById(id: string, patch: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsmanDatasourceIdentifier(id, patch)
    }
    protected deleteById(id: string): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.deleteOneSportsmanDatasourceIdentifier(id)
    }
    protected approveById(id: string, patch: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unapproveById(id: string, patch: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected freezeById(id: string, patch: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected unfreezeById(id: string, patch: Partial<SportsmanDatasourceIdentifierRecord<CtorJsonData>>): Promise<SportsmanDatasourceIdentifierRecord<CtorJsonData> | null> {
      throw new Error("Method not implemented.");
    }
    protected touchById(id: string): Promise<number | null> {
      throw new Error("Method not implemented.");
    }
    protected touchAll_(): Promise<number | null> {
      throw new Error("Method not implemented.");
    }

  }
}
