import React, { useContext } from 'react'
// Components
import TableLoader from 'components/utils/Table/Table'
// Styles
import * as R from './Table.styles'
// Additional
import { Tab } from 'static/tabs'
import { Settings } from './Table.settings'
import { TournamentContext } from 'components/Tournament/__Tournament.context'
import { AppContext } from 'App'
import { TeamRecord } from 'data/TeamRecord'
import { Data } from 'helpers/data'
import { SportContext } from 'components/Sports/__Sports.context'
import { TeamOrganizationContext } from 'components/TeamOrganizations/__TeamOrganizations.context'

const Table: React.FC = () => {
  const { db: a_db, alert: a_alert } = useContext(AppContext)

  const { params: t_params } = useContext(TournamentContext)
  const { params: s_params } = useContext(SportContext)
  const { params: to_params } = useContext(TeamOrganizationContext)

  const loadData = async () => {
    if (s_params?.sport_id)
      return await Data.getTeamsBySportId(a_db!, s_params?.sport_id, {
        alert: a_alert,
      })

    if (t_params?.tournament_id)
      return await Data.getTeamsByTournamentId(a_db!, t_params?.tournament_id, {
        alert: a_alert,
      })

    if (to_params?.team_organization_id)
      return await Data.getTeamByTeamOrgId(a_db!, to_params.team_organization_id, {
        alert: a_alert
      })

    return await Data.getAllTeams(a_db!, { alert: a_alert })
  }

  return (
    <TableLoader<TeamRecord>
      reloadFrom={[Tab.Teams]}
      loadDataFn={loadData}
      settings={Settings({
        disableNew: !!t_params?.tournament_id,
      })}
      style={{
        thead: R.TableHeadStyle,
        theadH: R.TableCellStyle,
        theadR: R.TableRowStyle,
        tbodyR: R.TableRowStyle,
        tbodyD: R.TableCellStyle,
      }}
    />
  )
}

export default Table
