import { DateTime } from "../utils/dateTime";
import { BaseRecord } from "./BaseRecord"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { SportRecord } from "./SportRecord"

export class SportsmanStatScalesRecord<TJsonData extends SportsmanStatScalesRecord.CtorJsonData = SportsmanStatScalesRecord.CtorJsonData> extends BaseRecord<TJsonData> {
  data_frozen_at?: Date | null

  /// >>> sport >>>
  #sport:BaseRecord.RefField<SportRecord> = {}

  sport_id?: string

  get sport() {
    return this.#sport.object
  }
  getSport() {
    this.#sport.id = this.sport_id

    return this.getObjectFromRefField(this.#sport, "sports")
  }
  /// <<< sport <<<

  // >>> fantasy points >>>
  fantasy_points_sum_scale?: SportsmanStatScalesRecord.StatScale
  fantasy_points_per_match_avg_scale?: SportsmanStatScalesRecord.StatScale
  fantasy_points_per_round_avg_scale?: SportsmanStatScalesRecord.StatScale
  // <<< fantasy points <<<
  // >>> matches >>>
  matches_played_sum_scale?: SportsmanStatScalesRecord.StatScale
  matches_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  matches_lost_sum_scale?: SportsmanStatScalesRecord.StatScale
  matches_tied_sum_scale?: SportsmanStatScalesRecord.StatScale
  matches_won_ratio_scale?: SportsmanStatScalesRecord.StatScale
  // <<< matches <<<
  // >>> rounds >>>
  rounds_played_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_played_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_played_t_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< rounds <<<
  // >>> won >>>
  rounds_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_won_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_won_t_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< won <<<
  // >>> won ratio >>>
  rounds_won_ratio_scale?: SportsmanStatScalesRecord.StatScale
  rounds_won_t_ratio_scale?: SportsmanStatScalesRecord.StatScale
  rounds_won_ct_ratio_scale?: SportsmanStatScalesRecord.StatScale
  // <<< won ratio <<<
  // >>> lost ratio >>>
  rounds_lost_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_lost_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
  rounds_lost_t_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< lost ratio <<<
  // >>> event stats >>>
  kills_sum_scale?: SportsmanStatScalesRecord.StatScale
  kills_per_round_scale?: SportsmanStatScalesRecord.StatScale
  teamkills_sum_scale?: SportsmanStatScalesRecord.StatScale
  assists_sum_scale?: SportsmanStatScalesRecord.StatScale
  deaths_sum_scale?: SportsmanStatScalesRecord.StatScale
  suicides_sum_scale?: SportsmanStatScalesRecord.StatScale
  headshots_sum_scale?: SportsmanStatScalesRecord.StatScale
  entry_kills_sum_scale?: SportsmanStatScalesRecord.StatScale
  bomb_defuses_sum_scale?: SportsmanStatScalesRecord.StatScale
  bomb_plants_sum_scale?: SportsmanStatScalesRecord.StatScale
  damage_sum_scale?: SportsmanStatScalesRecord.StatScale
  kill_death_ratio_scale?: SportsmanStatScalesRecord.StatScale
  // <<< event stats <<<
  // >>> bombs >>>
  bomb_defuses_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
  bomb_plants_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
  bomb_explodes_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
  // <<< bombs <<<
  // >>> clutches >>>
  clutches_per_round_scale?: SportsmanStatScalesRecord.StatScale
  clutches_won_per_round_scale?: SportsmanStatScalesRecord.StatScale
  // <<< clutches <<<
  // >>> ratio >>>
  clutches_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
  clutches_won_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
  // <<< ratio <<<
  // >>> v1 >>>
  clutches_v1_sum_scale?: SportsmanStatScalesRecord.StatScale
  clutches_v1_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< v1 <<<
  // >>> v2 >>>
  clutches_v2_sum_scale?: SportsmanStatScalesRecord.StatScale
  clutches_v2_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< v2 <<<
  // >>> v3 >>>
  clutches_v3_sum_scale?: SportsmanStatScalesRecord.StatScale
  clutches_v3_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< v3 <<<
  // >>> v4 >>>
  clutches_v4_sum_scale?: SportsmanStatScalesRecord.StatScale
  clutches_v4_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< v4 <<<
  // >>> v5 >>>
  clutches_v5_sum_scale?: SportsmanStatScalesRecord.StatScale
  clutches_v5_won_sum_scale?: SportsmanStatScalesRecord.StatScale
  // <<< v5 <<<

  /// >>> computed_values >>>
  get isFrozen() {
    return !!this.data_frozen_at
  }

  get name() {
    return `${this.sport?.full_name}`
  }
  /// <<< computed_values <<<

  static createNew(db?:BaseRootRepository.OrNothing, jsonData?:SportsmanStatScalesRecord.CtorJsonData) {
    return new SportsmanStatScalesRecord(db, jsonData)
  }

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData) {
    super(db)

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  patchData(jsonData:TJsonData) {
    super.patchData(jsonData)

    this.data_frozen_at = DateTime.parseDateOrNull(jsonData?.data_frozen_at)
    this.sport_id = jsonData?.sport_id

    // >>> fantasy points >>>
    this.fantasy_points_sum_scale = jsonData?.fantasy_points_sum_scale
    this.fantasy_points_per_match_avg_scale = jsonData?.fantasy_points_per_match_avg_scale
    this.fantasy_points_per_round_avg_scale = jsonData?.fantasy_points_per_round_avg_scale
    // <<< fantasy points <<<
    // >>> matches >>>
    this.matches_played_sum_scale = jsonData?.matches_played_sum_scale
    this.matches_won_sum_scale = jsonData?.matches_won_sum_scale
    this.matches_lost_sum_scale = jsonData?.matches_lost_sum_scale
    this.matches_tied_sum_scale = jsonData?.matches_tied_sum_scale
    this.matches_won_ratio_scale = jsonData?.matches_won_ratio_scale
    // <<< matches <<<
    // >>> rounds >>>
    this.rounds_played_sum_scale = jsonData?.rounds_played_sum_scale
    this.rounds_played_ct_sum_scale = jsonData?.rounds_played_ct_sum_scale
    this.rounds_played_t_sum_scale = jsonData?.rounds_played_t_sum_scale
    // <<< rounds <<<
    this.rounds_won_sum_scale = jsonData?.rounds_won_sum_scale
    this.rounds_won_ct_sum_scale = jsonData?.rounds_won_ct_sum_scale
    this.rounds_won_t_sum_scale = jsonData?.rounds_won_t_sum_scale
    // >>> won >>>
    // >>> won ratio >>>
    this.rounds_won_ratio_scale = jsonData?.rounds_won_ratio_scale
    this.rounds_won_t_ratio_scale = jsonData?.rounds_won_t_ratio_scale
    this.rounds_won_ct_ratio_scale = jsonData?.rounds_won_ct_ratio_scale
    // <<< won ratio <<<
    // >>> lost ratio >>>
    this.rounds_lost_sum_scale = jsonData?.rounds_lost_sum_scale
    this.rounds_lost_ct_sum_scale = jsonData?.rounds_lost_ct_sum_scale
    this.rounds_lost_t_sum_scale = jsonData?.rounds_lost_t_sum_scale
    // <<< lost ratio <<<
    // >>> event stats >>>
    this.kills_sum_scale = jsonData?.kills_sum_scale
    this.kills_per_round_scale = jsonData?.kills_per_round_scale
    this.teamkills_sum_scale = jsonData?.teamkills_sum_scale
    this.assists_sum_scale = jsonData?.assists_sum_scale
    this.deaths_sum_scale = jsonData?.deaths_sum_scale
    this.suicides_sum_scale = jsonData?.suicides_sum_scale
    this.headshots_sum_scale = jsonData?.headshots_sum_scale
    this.entry_kills_sum_scale = jsonData?.entry_kills_sum_scale
    this.bomb_defuses_sum_scale = jsonData?.bomb_defuses_sum_scale
    this.bomb_plants_sum_scale = jsonData?.bomb_plants_sum_scale
    this.damage_sum_scale = jsonData?.damage_sum_scale
    this.kill_death_ratio_scale = jsonData?.kill_death_ratio_scale
    // <<< event stats <<<
    // >>> bombs >>>
    this.bomb_defuses_ratio_per_round_scale = jsonData?.bomb_defuses_ratio_per_round_scale
    this.bomb_plants_ratio_per_round_scale = jsonData?.bomb_plants_ratio_per_round_scale
    this.bomb_explodes_ratio_per_round_scale = jsonData?.bomb_explodes_ratio_per_round_scale
    // <<< bombs <<<
    // >>> clutches >>>
    this.clutches_per_round_scale = jsonData?.clutches_per_round_scale
    this.clutches_won_per_round_scale = jsonData?.clutches_won_per_round_scale
    // <<< clutches <<<
    // >>> ratio >>>
    this.clutches_ratio_per_round_scale = jsonData?.clutches_ratio_per_round_scale
    this.clutches_won_ratio_per_round_scale = jsonData?.clutches_won_ratio_per_round_scale
    // <<< ratio <<<
    // >>> v1 >>>
    this.clutches_v1_sum_scale = jsonData?.clutches_v1_sum_scale
    this.clutches_v1_won_sum_scale = jsonData?.clutches_v1_won_sum_scale
    // <<< v1 <<<
    // >>> v2 >>>
    this.clutches_v2_sum_scale = jsonData?.clutches_v2_sum_scale
    this.clutches_v2_won_sum_scale = jsonData?.clutches_v2_won_sum_scale
    // <<< v2 <<<
    // >>> v3 >>>
    this.clutches_v3_sum_scale = jsonData?.clutches_v3_sum_scale
    this.clutches_v3_won_sum_scale = jsonData?.clutches_v3_won_sum_scale
    // <<< v3 <<<
    // >>> v4 >>>
    this.clutches_v4_sum_scale = jsonData?.clutches_v4_sum_scale
    this.clutches_v4_won_sum_scale = jsonData?.clutches_v4_won_sum_scale
    // <<< v4 <<<
    // >>> v5 >>>
    this.clutches_v5_sum_scale = jsonData?.clutches_v5_sum_scale
    this.clutches_v5_won_sum_scale = jsonData?.clutches_v5_won_sum_scale
    // <<< v5 <<<
  }

  toJson():TJsonData {
    return Object.assign(super.toJson() ?? {}, {
      data_frozen_at: this.data_frozen_at?.toISOString(),

      sport_id: this.sport_id,

      // >>> fantasy points >>>
      fantasy_points_sum_scale: this.fantasy_points_sum_scale,
      fantasy_points_per_match_avg_scale: this.fantasy_points_per_match_avg_scale,
      fantasy_points_per_round_avg_scale: this.fantasy_points_per_round_avg_scale,
      // <<< fantasy points <<<
      // >>> matches >>>
      matches_played_sum_scale: this.matches_played_sum_scale,
      matches_won_sum_scale: this.matches_won_sum_scale,
      matches_lost_sum_scale: this.matches_lost_sum_scale,
      matches_tied_sum_scale: this.matches_tied_sum_scale,
      matches_won_ratio_scale: this.matches_won_ratio_scale,
      // <<< matches <<<
      // >>> rounds >>>
      rounds_played_sum_scale: this.rounds_played_sum_scale,
      rounds_played_ct_sum_scale: this.rounds_played_ct_sum_scale,
      rounds_played_t_sum_scale: this.rounds_played_t_sum_scale,
      // <<< rounds <<<
      rounds_won_sum_scale: this.rounds_won_sum_scale,
      rounds_won_ct_sum_scale: this.rounds_won_ct_sum_scale,
      rounds_won_t_sum_scale: this.rounds_won_t_sum_scale,
      // >>> won >>>
      // >>> won ratio >>>
      rounds_won_ratio_scale: this.rounds_won_ratio_scale,
      rounds_won_t_ratio_scale: this.rounds_won_t_ratio_scale,
      rounds_won_ct_ratio_scale: this.rounds_won_ct_ratio_scale,
      // <<< won ratio <<<
      // >>> lost ratio >>>
      rounds_lost_sum_scale: this.rounds_lost_sum_scale,
      rounds_lost_ct_sum_scale: this.rounds_lost_ct_sum_scale,
      rounds_lost_t_sum_scale: this.rounds_lost_t_sum_scale,
      // <<< lost ratio <<<
      // >>> event stats >>>
      kills_sum_scale: this.kills_sum_scale,
      kills_per_round_scale: this.kills_per_round_scale,
      teamkills_sum_scale: this.teamkills_sum_scale,
      assists_sum_scale: this.assists_sum_scale,
      deaths_sum_scale: this.deaths_sum_scale,
      suicides_sum_scale: this.suicides_sum_scale,
      headshots_sum_scale: this.headshots_sum_scale,
      entry_kills_sum_scale: this.entry_kills_sum_scale,
      bomb_defuses_sum_scale: this.bomb_defuses_sum_scale,
      bomb_plants_sum_scale: this.bomb_plants_sum_scale,
      damage_sum_scale: this.damage_sum_scale,
      kill_death_ratio_scale: this.kill_death_ratio_scale,
      // <<< event stats <<<
      // >>> bombs >>>
      bomb_defuses_ratio_per_round_scale: this.bomb_defuses_ratio_per_round_scale,
      bomb_plants_ratio_per_round_scale: this.bomb_plants_ratio_per_round_scale,
      bomb_explodes_ratio_per_round_scale: this.bomb_explodes_ratio_per_round_scale,
      // <<< bombs <<<
      // >>> clutches >>>
      clutches_per_round_scale: this.clutches_per_round_scale,
      clutches_won_per_round_scale: this.clutches_won_per_round_scale,
      // <<< clutches <<<
      // >>> ratio >>>
      clutches_ratio_per_round_scale: this.clutches_ratio_per_round_scale,
      clutches_won_ratio_per_round_scale: this.clutches_won_ratio_per_round_scale,
      // <<< ratio <<<
      // >>> v1 >>>
      clutches_v1_sum_scale: this.clutches_v1_sum_scale,
      clutches_v1_won_sum_scale: this.clutches_v1_won_sum_scale,
      // <<< v1 <<<
      // >>> v2 >>>
      clutches_v2_sum_scale: this.clutches_v2_sum_scale,
      clutches_v2_won_sum_scale: this.clutches_v2_won_sum_scale,
      // <<< v2 <<<
      // >>> v3 >>>
      clutches_v3_sum_scale: this.clutches_v3_sum_scale,
      clutches_v3_won_sum_scale: this.clutches_v3_won_sum_scale,
      // <<< v3 <<<
      // >>> v4 >>>
      clutches_v4_sum_scale: this.clutches_v4_sum_scale,
      clutches_v4_won_sum_scale: this.clutches_v4_won_sum_scale,
      // <<< v4 <<<
      // >>> v5 >>>
      clutches_v5_sum_scale: this.clutches_v5_sum_scale,
      clutches_v5_won_sum_scale: this.clutches_v5_won_sum_scale,
      // <<< v5 <<<
    }) as TJsonData
  }

}

export module SportsmanStatScalesRecord {
  export type StatScale = {
    min: number
    max: number
    breakpoints: number[]
  }

  export type CtorJsonData = BaseRecord.CtorJsonData & {
    data_frozen_at?: string

    sport_id?: string

    // >>> fantasy points >>>
    fantasy_points_sum_scale?: SportsmanStatScalesRecord.StatScale
    fantasy_points_per_match_avg_scale?: SportsmanStatScalesRecord.StatScale
    fantasy_points_per_round_avg_scale?: SportsmanStatScalesRecord.StatScale
    // <<< fantasy points <<<
    // >>> matches >>>
    matches_played_sum_scale?: SportsmanStatScalesRecord.StatScale
    matches_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    matches_lost_sum_scale?: SportsmanStatScalesRecord.StatScale
    matches_tied_sum_scale?: SportsmanStatScalesRecord.StatScale
    matches_won_ratio_scale?: SportsmanStatScalesRecord.StatScale
    // <<< matches <<<
    // >>> rounds >>>
    rounds_played_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_played_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_played_t_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< rounds <<<
    // >>> won >>>
    rounds_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_won_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_won_t_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< won <<<
    // >>> won ratio >>>
    rounds_won_ratio_scale?: SportsmanStatScalesRecord.StatScale
    rounds_won_t_ratio_scale?: SportsmanStatScalesRecord.StatScale
    rounds_won_ct_ratio_scale?: SportsmanStatScalesRecord.StatScale
    // <<< won ratio <<<
    // >>> lost ratio >>>
    rounds_lost_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_lost_ct_sum_scale?: SportsmanStatScalesRecord.StatScale
    rounds_lost_t_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< lost ratio <<<
    // >>> event stats >>>
    kills_sum_scale?: SportsmanStatScalesRecord.StatScale
    kills_per_round_scale?: SportsmanStatScalesRecord.StatScale
    teamkills_sum_scale?: SportsmanStatScalesRecord.StatScale
    assists_sum_scale?: SportsmanStatScalesRecord.StatScale
    deaths_sum_scale?: SportsmanStatScalesRecord.StatScale
    suicides_sum_scale?: SportsmanStatScalesRecord.StatScale
    headshots_sum_scale?: SportsmanStatScalesRecord.StatScale
    entry_kills_sum_scale?: SportsmanStatScalesRecord.StatScale
    bomb_defuses_sum_scale?: SportsmanStatScalesRecord.StatScale
    bomb_plants_sum_scale?: SportsmanStatScalesRecord.StatScale
    damage_sum_scale?: SportsmanStatScalesRecord.StatScale
    kill_death_ratio_scale?: SportsmanStatScalesRecord.StatScale
    // <<< event stats <<<
    // >>> bombs >>>
    bomb_defuses_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
    bomb_plants_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
    bomb_explodes_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
    // <<< bombs <<<
    // >>> clutches >>>
    clutches_per_round_scale?: SportsmanStatScalesRecord.StatScale
    clutches_won_per_round_scale?: SportsmanStatScalesRecord.StatScale
    // <<< clutches <<<
    // >>> ratio >>>
    clutches_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
    clutches_won_ratio_per_round_scale?: SportsmanStatScalesRecord.StatScale
    // <<< ratio <<<
    // >>> v1 >>>
    clutches_v1_sum_scale?: SportsmanStatScalesRecord.StatScale
    clutches_v1_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< v1 <<<
    // >>> v2 >>>
    clutches_v2_sum_scale?: SportsmanStatScalesRecord.StatScale
    clutches_v2_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< v2 <<<
    // >>> v3 >>>
    clutches_v3_sum_scale?: SportsmanStatScalesRecord.StatScale
    clutches_v3_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< v3 <<<
    // >>> v4 >>>
    clutches_v4_sum_scale?: SportsmanStatScalesRecord.StatScale
    clutches_v4_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< v4 <<<
    // >>> v5 >>>
    clutches_v5_sum_scale?: SportsmanStatScalesRecord.StatScale
    clutches_v5_won_sum_scale?: SportsmanStatScalesRecord.StatScale
    // <<< v5 <<<
  }

  export class Repository extends RecordRepository<SportsmanStatScalesRecord> {
    #getAll:RecordRepository.GetFieldFlags = {}
    getAll(options?:RecordRepository.GetOptions) {
      return this.peekOrLoad(this.#getAll, options, {
        peek: () => this.peekAll(),
        load: () => this.loadAll()
      })
    }

    create(record: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected loadById(id: string) {
      return this.getApi().getOneSportsmanStatScaleById(id)
    }

    protected loadAll() {
      return this.getApi().getAllSportsmanStatScales()
    }

    protected updateById(id: string, patch: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.updateOneSportsmanStatScale(id, patch)
    }

    protected deleteById(id: string): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected approveById(id: string, patch: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected unapproveById(id: string, patch: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      throw new Error('Not yet implemented')
    }

    protected freezeById(id: string, patch: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.freezeOneSportsmanStatScaleById(id, patch)
    }

    protected unfreezeById(id: string, patch: Partial<SportsmanStatScalesRecord<CtorJsonData>>): Promise<SportsmanStatScalesRecord<CtorJsonData> | null> {
      const api = this.getApiOrThrow()
      return api.unfreezeOneSportsmanStatScaleById(id, patch)
    }

    protected touchAll_(): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchAllSportsmanStatScales()
    }

    protected touchById(id: string): Promise<number | null> {
      const api = this.getApiOrThrow()
      return api.touchOneSportsmanStatScaleById(id)
    }
  }
}
