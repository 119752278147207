import React from 'react'
import { useParams } from 'react-router'
// Component
import Select from './_Select'
import Detail from './_Detail'
// Additional
import { Phase } from 'helpers/app'
import { Routing } from 'helpers/routing'
import { SportContext } from 'components/Sports/__Sports.context'

const Sport: React.FC<Phase.Props> = (p) => {
  const params = useParams<Routing.SportParams>()

  return (
    <SportContext.Provider value={{ params: params }}>
      {p.phase === 'select' && <Select />}
      {p.phase === 'detail' && <Detail />}
    </SportContext.Provider>
  )
}

export default Sport
