import { DateTime } from "../utils/dateTime"
import { BaseRootRepository } from "./repository/BaseRootRepository"
import { RecordRepository } from "./repository/RecordRepository"
import { RootRepository } from "./RootRepository"

/**
 * Bázová třída pro všechny datové záznamy.
 */
export abstract class BaseRecord<TJsonData extends BaseRecord.CtorJsonData = BaseRecord.CtorJsonData> {
  #db?:BaseRootRepository.OrNothing

  static getDb(record:BaseRecord) {
    return record.#db
  }

  id?: string
  created_at?: Date | null | undefined
  updated_at?: Date | null | undefined
  deleted_at?: Date | null | undefined

  constructor(db?:BaseRootRepository.OrNothing, jsonData?:TJsonData | null | undefined) {
    this.#db = db

    if (jsonData) {
      this.patchData(jsonData)
    }
  }

  cloneRecord() {
    const myJsonData = this.toJson()
    const ctor = Object.getPrototypeOf(this).constructor
    const clone = new ctor(this.#db, myJsonData)
    return clone
  }

  patchData(jsonData:TJsonData | null) {
    this.id = jsonData?.id
    this.created_at = DateTime.parseDateOrNull(jsonData?.created_at)
    this.updated_at = DateTime.parseDateOrNull(jsonData?.updated_at)
    this.deleted_at = DateTime.parseDateOrNull(jsonData?.deleted_at)
  }

  toJson():Partial<TJsonData> {
    return {
      id: this.id,
      created_at: this.created_at?.toISOString(),
      updated_at: this.updated_at?.toISOString(),
      deleted_at: this.deleted_at?.toISOString()
    } as TJsonData
  }

  /**
   * Zajišťuje načtení navázaných objektů.
   */
  async preload() {
    // Tato bázová třída nic nedonačítá.
    // Ale poděděné třídy už budou.
  }

  protected async getObjectFromRefField<TRecord extends BaseRecord>(refField:BaseRecord.RefField<TRecord>, refRepositoryName:string) {
    const refId = refField.id
    if (refId == null) return null

    const existing = refField.object
    if (existing?.id == refId) return existing

    const db = this.#db
    if (db == null) return null

    const repository = (db as any)?.[refRepositoryName] as RecordRepository<TRecord>
    if (repository == null) throw new Error(`Record repository with key '${refRepositoryName}' was not found.`)

    const loaded = await repository.get(refId)
    if (loaded?.id != refId) return null

    refField.object = loaded
    return loaded
  }

  protected peekObjectFromRefField<TRecord extends BaseRecord>(refField:BaseRecord.RefField<TRecord>, refRepositoryName:string) {
    const refId = refField.id
    if (refId == null) return null

    const existing = refField.object
    if (existing?.id == refId) return existing

    const db = this.#db
    if (db == null) return null

    const repository = (db as any)?.[refRepositoryName] as RecordRepository<TRecord>
    if (repository == null) throw new Error(`Record repository with key '${refRepositoryName}' was not found.`)

    const loaded = repository.peek(refId)
    if (loaded?.id != refId) return null

    refField.object = loaded
    return loaded
  }
}

export module BaseRecord {
  /**
   * Datový formát vsupních json dat pro všechny datové záznamy.
   */
  export type CtorJsonData = {
    id?: string
    created_at?: string
    updated_at?: string
    deleted_at?: string
  }

  export type RefField<TRecord extends BaseRecord> = {
    id?: string
    object?: TRecord
  }
}
