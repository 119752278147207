import { BaseRecord } from 'data/BaseRecord'
import { Form as _Form } from './form'

export module Reload {
  type ReloadActionType = 'reload'

  const translation: { [K in ReloadActionType]: string } = {
    reload: 'reloaded',
  }

  type ReloadStatsParams<T extends BaseRecord> = {
    getAllFn: () => Promise<(T | null | undefined)[] | null | undefined>
    optional?: {
      alert?: _Form.AlertParams
    }
  }

  export const reload = async <T extends BaseRecord>(
    params: ReloadStatsParams<T>
  ) => {
    const { getAllFn, optional } = params

    const _textSettings = {
      name: optional?.alert?.texts?.form ?? '',
      action: translation['reload'],
      forcePlural: true,
    }

    try {
      await getAllFn()

      optional?.alert?.manager &&
        _Form.alert('success', optional.alert.manager, _textSettings)
    } catch (err) {
      optional?.alert?.manager &&
        _Form.alert('danger', optional.alert.manager, _textSettings)

      throw err
    }
  }
}
