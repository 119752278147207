import React, { useContext } from 'react'
// Styles
import * as S from './Modal.styles'
// Components
import { Portal } from 'react-portal'
import { AppContext } from 'App'

interface Props extends _Props {}

const Modal: React.FC<Props> = (p) => {
  const { modal } = useContext(AppContext)

  return <_Modal {...p} closeCallback={() => {
    modal?.close?.()
  }} />
}

interface _Props {
  readonly children?: JSX.Element

  readonly closeCallback?: () => void
}

export const _Modal: React.FC<_Props> = (p) => (
  <Portal>
    <S.OuterWrapper>
      <S.InnerWrapper>
        <S.Close onClick={p.closeCallback} />
        {p.children}
      </S.InnerWrapper>
    </S.OuterWrapper>
  </Portal>
)

export default Modal
