import React, { useState, useEffect, useContext } from 'react'
// Components
import { Col } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Item } from 'components/utils/Dropdown'
import FormItem from 'components/utils/FormItem'
import DropdownFilter from 'components/utils/Filter/DropdownFilter'
import UIFullTextGlobalFilter from 'components/utils/Filter/FullTextGlobalFilter'
// Styles
import { ItemWrapper, FormRowAlignBottom } from 'styles/common'
// Addtitional
import { Loadable } from 'types/loadable'
import { AppContext } from 'App'
import { Tab } from 'static/tabs'
import { Data } from 'helpers/data'
import { TournamentRoundRecord } from 'data/TournamentRoundRecord'
import { OnTableDataChangeListener } from 'components/utils/Table/_Table'

const Filters: React.FC = () => {
  const { db } = useContext(AppContext)

  const stageDefaultItem: Item = { id: -1, text: 'Select Stage ...' }

  const [stages, setStages] = useState<
    { value: Item; data?: Array<Item> } & Loadable
  >({
    value: stageDefaultItem,
    loaded: false,
  })

  const [table, setTable] = useState<Loadable>({
    loaded: false,
  })

  useEffect(() => {
    OnTableDataChangeListener.addAndRemove(
      () => !table.loaded && setTable({ loaded: true })
    )

    _setStages()
  }, [])

  const _setStages = async () => {
    const _data = await Data.getAllStages(db!)

    setStages((prev) => ({
      loaded: true,
      value: prev.value,
      data: _data?.map((s) => {
        return {
          id: s.id ?? '',
          text: `${s.tournament_name} - ${s.name}`,
        } as Item
      }),
    }))
  }

  const handleStageChange = (item: Item) => {
    setStages((prev) => ({ ...prev, value: item, data: prev.data ?? [] }))
  }

  return (
    <FormRowAlignBottom>
      <Col as={ItemWrapper} lg={3}>
        <FormItem
          label={<Form.Label>Stage</Form.Label>}
          input={
            <DropdownFilter
              from={Tab.Rounds}
              accessor={'tournament_stage_id' as keyof TournamentRoundRecord}
              items={stages.data ?? []}
              onSelect={handleStageChange}
              value={stages.value}
              defaultItem={stageDefaultItem}
              loading={!stages.loaded || !table.loaded}
            />
          }
        />
      </Col>
      <Col as={ItemWrapper} lg={9}>
        <UIFullTextGlobalFilter
          from={Tab.Rounds}
          style={{ disabled: !table.loaded }}
        />
      </Col>
    </FormRowAlignBottom>
  )
}

export default Filters
